import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { hasPermission } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import ExportButtonComponent from '../../components/ExportButtonComponent';
import { ProfileService } from '../../service/ProfileService';
import ProfileToolbar from '../profile/ProfileToolbar';
import ProfileCredentialEditDialog from '../../components/profile/ProfileCredentialEditDialog';
import ProfileCredentialViewDialogComponent from '../../components/profile/ProfileCredentialViewDialogComponent';
import DeleteModalComponent from "../../components/DeleteModalComponent";
import EntityFileUpload from '../../components/file/EntityFileUpload';
import StorageFileDownloadTableButton from '../../components/file/StorageFileDownloadTableButton';
import GeneralUtils from "../../utilities/GeneralUtils";
import StorageFileViewTableButton from '../../components/file/StorageFileViewTableButton';

const sortOrderHeader = ["profileName", "name", "description", "file", "createdOn"]

const tableHeader = [
    translatedMessage("profileCredential.profile"),
    translatedMessage("profileCredential.name"),
    translatedMessage("profileCredential.description"),
    translatedMessage("generic.file"),
    translatedMessage("generic.created.on")
]

const ProfileCredentialList = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [profileCredentials, setProfileCredentials] = useState(null);
    const [profile, setProfile] = useState(null);
    const [selectedProfileCredential, setSelectedProfileCredential] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showViewDialog, setShowViewDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [uploadDialogVisible, setUploadDialogVisible] = useState(false);

    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'description': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const profileService = useMemo(() => new ProfileService(), []);
    const { profileIdParam } = useParams();

    useEffect(() => {
        setLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        // };       

        const getProfile = async () => {
            await profileService.getProfile(profileIdParam)
                .then(async (_data) => {
                    setProfile(_data);

                    // await checkEditPermission()
                    await getProfileCredentials()

                    setLoading(false);
                })
                .catch((error) => {
                    setError(true)
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        getProfile();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileIdParam, profileService]);

    const getProfileCredentials = async () => {
        let criteria = { profileId: profileIdParam }

        await profileService.getProfileCredentialList(criteria)
            .then(async (_data) => {
                setProfileCredentials(_data);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const addNew = () => {
        setShowEditDialog(true);
    };

    const editData = (_profileCredential) => {
        setSelectedProfileCredential({ ..._profileCredential });
        setShowEditDialog(true);
    };

    const viewData = (_profileCredential) => {
        setSelectedProfileCredential({ ..._profileCredential });
        setShowViewDialog(true);
    };

    const deleteData = (_profileCredential) => {
        setSelectedProfileCredential({ ..._profileCredential });
        setShowDeleteDialog(true);
    };

    const showUpload = (_profileCredential) => {
        setSelectedProfileCredential({ ..._profileCredential });
        setUploadDialogVisible(true);
    };

    const updateList = async (savedData, isNew) => {
        let updatedData = DataTableUtils.updateTableList(profileCredentials, savedData, isNew)

        setProfileCredentials(updatedData);
    };

    const onFileUploadSuccess = (savedData) => {
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
        let updatedData = DataTableUtils.updateTableList(profileCredentials, savedData, false)
        setProfileCredentials(updatedData);
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    const deleteProfileCredential = () => {
        profileService
            .deleteProfileCredential(selectedProfileCredential.id)
            .then(() => {
                let _profileCredentials = profileCredentials.filter(
                    (item) => item.id !== selectedProfileCredential.id
                );
                setProfileCredentials(_profileCredentials);
                toast.current.show({
                    severity: "success",
                    summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            })
            .finally(() => {
                setShowDeleteDialog(false);
                setSelectedProfileCredential(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('RELATED_PROFILE_EDIT') &&
                        <Button
                            label={translatedMessage("profileCredential.add")}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={addNew}
                        />
                    }
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={translatedMessage("menu.profile.credential")}
            />
        );
    };

    const handleExport = async () => {
        let criteria = { profileId: profileIdParam }

        return new Promise((resolve, reject) => {
            profileService.getProfileCredentialList(criteria)
                .then((data) => {
                    let exportData = data.map(item => ({ ...item }));
                    exportData.map(item => {
                        let exportItem = item
                        exportItem.profileName = item?.profile?.fullName
                        exportItem.file = item?.file?.name
                        exportItem.createdOn = GeneralUtils.formatDateTime(new Date(Date.parse(item.createdOn)));
                        return exportItem;
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const displayEditDialog = (_display) => {
        setShowEditDialog(_display)
        setSelectedProfileCredential(null)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {hasPermission('PROFILE_VIEW') &&
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => viewData(rowData)}
                            title={translatedMessage("generic.view")} />
                    }
                    {hasPermission('PROFILE_EDIT') &&
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => editData(rowData)}
                            title={translatedMessage("generic.edit")} />
                    }
                    {hasPermission('PROFILE_EDIT') &&
                        <Button icon="pi pi-upload"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showUpload(rowData)}
                            tooltip={translatedMessage("generic.file.upload")}
                            tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                    }
                    {hasPermission('PROFILE_VIEW') &&
                        <>
                            <StorageFileViewTableButton fileUuid={rowData.file?.uuid} fileName={rowData.file?.name} />
                            <StorageFileDownloadTableButton fileUuid={rowData.file?.uuid} fileName={rowData.file?.name} />
                        </>
                    }
                    {hasPermission("PROFILE_EDIT") &&
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => deleteData(rowData)}
                            disabled={rowData.id < 1000}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }} />
                    }
                </div>
            </>
        );
    };

    return (
        <>
            <div className="grid h-full">
                <Toast ref={toast} />
                <div className="col-12">
                    <div className="card h-full">
                        <ProfileToolbar profile={profile} code="CREDENTIALS" />

                        {loading && (
                            <div className="w-full flex align-items-center">
                                <ProgressSpinner />
                            </div>
                        )}
                        {!loading && !error && (
                            <>
                                <div className='w-full text-align-left'>
                                    <h5 className="m-0">{translatedMessage("profile.credentials")}</h5>
                                </div>
                                <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                                <DataTable
                                    ref={dt}
                                    value={profileCredentials}
                                    dataKey="id"
                                    paginator
                                    rows={DataTableUtils.defalRowsPerPage()}
                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                    className="datatable-responsive pcn-datatable"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                                    filterDisplay="row"
                                    filters={filters}
                                    globalFilterFields={['profile.fullName', 'name', 'description']}
                                >
                                    {props?.profile &&
                                        <Column
                                            field="profile.fullName"
                                            header={translatedMessage("profileCredential.profile")}
                                            sortable
                                            headerStyle={{ minWidth: '8rem' }}
                                            filter
                                            showFilterMenu={false}
                                        />}
                                    <Column
                                        field="name"
                                        header={translatedMessage("profileCredential.name")}
                                        sortable
                                        headerStyle={{ minWidth: '8rem' }}
                                        filter
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="description"
                                        header={translatedMessage("profileCredential.description")}
                                        sortable
                                        headerStyle={{ minWidth: '8rem' }}
                                        filter
                                        showFilterMenu={false}
                                    />
                                    <Column body={actionBodyTemplate} />
                                </DataTable>

                                <ProfileCredentialEditDialog
                                    value={selectedProfileCredential}
                                    profile={profile}
                                    visible={showEditDialog}
                                    afterSave={(savedData, isNew) => updateList(savedData, isNew)}
                                    visibleSetter={(value) => displayEditDialog(value)}
                                    profileId={profile?.profile?.id}
                                />

                                <ProfileCredentialViewDialogComponent
                                    value={selectedProfileCredential}
                                    visible={showViewDialog}
                                    visibleSetter={(value) => setShowViewDialog(value)}
                                />


                                <DeleteModalComponent
                                    visible={showDeleteDialog}
                                    item={selectedProfileCredential ? selectedProfileCredential.profile?.fullName + ' / ' + selectedProfileCredential.name + ' / ' + selectedProfileCredential.description : ""}
                                    closeDialog={() => { setShowDeleteDialog(false); }}
                                    deleteRecord={() => deleteProfileCredential()}
                                />

                                <EntityFileUpload
                                    endpoint={`/profileCredential/${selectedProfileCredential?.id}/file`}
                                    deletePrevious={true}
                                    usageId={null}
                                    accept={["pdf", "image"]}
                                    maxFileSize={26214400}
                                    visible={uploadDialogVisible}
                                    visibleSetter={setUploadDialogVisible}
                                    onUploadSuccess={onFileUploadSuccess}
                                    onUploadError={onFileUploadError}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileCredentialList;