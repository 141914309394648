import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from '../../service/UserService';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CenterEditDialog from "./CenterEditDialog";
import GeneralUtils from "../../utilities/GeneralUtils";
import AddressViewComponent from '../address/AddressViewComponent';
import AddressEditDialogComponent from '../address/AddressEditDialogComponent';
import { OrganizationService } from '../../service/OrganizationService';

const CenterViewComponent = (props) => {
    const [center, setCenter] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [centerComplexes, setCenterComplexes] = useState([]);
    const [socialServiceTypes, setSocialServiceTypes] = useState([]);
    const [showAddressDialog, setShowAddressDialog] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(false);

    const toast = useRef(null);
    const navigate = useNavigate();

    const organizationService = useMemo(() => new OrganizationService(), []);

    useEffect(() => {
        setCenter(props?.center)
        setCenterComplexes(props?.centerComplexes)
        setSocialServiceTypes(props?.socialServiceTypes)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const onAddressEditDialog = (_address, _type) => {
        setSelectedAddress(_address)
        setShowAddressDialog(true)
    }

    const getAddressButtons = (_address) => {
        return (
            <>
                <Button
                    text
                    className="aspc-button-icon-slim p-button p-button-icon-only ml-1"
                    onClick={() => onAddressEditDialog(_address)}
                    tooltip={translatedMessage("address.editExisting")}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                >
                    <div className='flex align-items-center'>
                        <FontAwesomeIcon icon='fa-solid fa-pencil' />
                    </div>
                </Button>
            </>
        )
    }

    const saveAddress = (_address, _isNew = false) => {
        organizationService.saveOrganizationAddress(center.id, _address)
            .then((savedData) => {
                let _center = { ...center }
                _center = { ..._center, address: savedData }

                setCenter(_center)
                toast?.current?.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    return (
        <>
            <Toast ref={toast} />

            <div className='w-full grid pcn-data-view-panel mb-5'>
                <div className='col-12 flex align-items-center'>
                    <span className='entity-view-subtitle mr-2'>{translatedMessage("center.details")}</span>
                    <Button
                        text
                        className="pcn-button-slim p-button p-button-icon-only"
                        onClick={() => setShowEditDialog(true)}
                        visible={hasPermission('ORGANIZATION_EDIT')}
                        disabled={!center?.actions?.ADD_ORGANIZATION}
                        
                    >
                        <div className='flex align-items-center'>
                            <FontAwesomeIcon icon='fa-solid fa-pencil' />
                        </div>
                    </Button>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('department.parent')}</div>
                    <div className='filed-value'>
                        {hasPermission('ORGANIZATION_VIEW')
                            ? <Button text label={center?.department?.name} className="aspc-button-filed-text p-button"
                                onClick={() => navigate(`/department/${center?.department?.id}/`)} />
                            : center?.department?.name
                        }
                    </div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('center.complex')}</div>
                    <div className='filed-value'>{center?.centerDetails?.complex?.label}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('generic.nameRo')}</div>
                    <div className='filed-value'>{center?.name}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('center.socialServiceCode')}</div>
                    <div className='filed-value'>{center?.centerDetails?.socialServiceCode}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('center.maxCapacity')}</div>
                    <div className='filed-value'>{center?.centerDetails?.maxCapacity}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('center.provLicenseDate')}</div>
                    <div className='filed-value'>{GeneralUtils.formatDate(center?.centerDetails?.provLicenseDate)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('center.termLicenseDate')}</div>
                    <div className='filed-value'>{GeneralUtils.formatDate(center?.centerDetails?.termLicenseDate)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('center.termLicenseNumber')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(center?.centerDetails?.termLicenseNumber)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('center.relicensingDecisionDate')}</div>
                    <div className='filed-value'>{GeneralUtils.formatDate(center?.centerDetails?.relicensingDecisionDate)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('center.relicensingDecisionNumber')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(center?.centerDetails?.relicensingDecisionNumber)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('center.licenseIssuer')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(center?.centerDetails?.licenseIssuer)}</div>
                </div>
                <div className='col-12'>
                    <div className='filed-label'>{translatedMessage('generic.description')}</div>
                    <div className='filed-value'>{center?.description}</div>
                </div>
            </div>

            <div className='w-full grid pcn-data-view-panel'>
                <div className='col-12'>
                    <div className='flex align-items-center w-100'>
                        <span className='entity-view-subtitle mr-2'>{translatedMessage("address.address")}</span>
                        {hasPermission("ORGANIZATION_EDIT") && getAddressButtons(center?.address)}
                    </div>
                    <AddressViewComponent address={center?.address} />
                </div>
            </div>


            <CenterEditDialog
                value={center}
                visible={showEditDialog}
                afterSave={(savedData) => setCenter(savedData)}
                visibleSetter={() => setShowEditDialog(false)}
                centerComplexes={centerComplexes}
                socialServiceTypes={socialServiceTypes}
            />

            <AddressEditDialogComponent
                value={selectedAddress}
                visible={showAddressDialog}
                afterSave={(savedData, isNew) => saveAddress(savedData, isNew)}
                visibleSetter={() => setShowAddressDialog(false)}
                hasHomeAndResidence={false}
            />
        </>
    );
};

export default CenterViewComponent;
