import React, { useState, useEffect, useRef } from 'react';

import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const ProfileCredentialViewDialogComponent = (props) => {
    const [profileCredential, setProfileCredential] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        setProfileCredential(props?.value);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            setProfileCredential(null)
            props.visibleSetter(false);
        }
    };

    const dialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const dialogHeader = (
        <>
            <div className='mb-1'>{translatedMessage('profileCredential.details')}</div>
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                modal
                header={dialogHeader}
                className="pcn-dialog"
                footer={dialogFooter}
                onHide={hideDialog}
            >

                <div className='w-full grid pcn-data-view-panel mb-5'>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('profileCredential.profile')}</div>
                        <div className='filed-value'>{profileCredential?.profile?.fullName}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('profileCredential.name')}</div>
                        <div className='filed-value'>{profileCredential?.name}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('profileCredential.description')}</div>
                        <div className='filed-value'>{profileCredential?.description}</div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ProfileCredentialViewDialogComponent;
