import ApiService from "./ApiService";
import GeneralUtils from "../utilities/GeneralUtils";

export class FamilyPlacementService {

    async getList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/familyPlacement/list', criteria)
                .then((response) => {
                    response.data.items.map((item) => {
                        let _birthday = item.profile?.birthday ? new Date(Date.parse(item.profile?.birthday)) : ''
                        item.profile = { ...item.profile, birthday: _birthday}
                        return item
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getFamilyPlacement(id) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/familyPlacement/${id}`)
                .then(response => {      
                    let _birthday = response.data.profile?.birthday ? new Date(Date.parse(response.data.profile?.birthday)) : ''
                    response.data.profile = { ...response.data.profile, birthday: _birthday}
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    

    async saveFamilyPlacement(familyPlacement) {
        let _profile = { id: familyPlacement.profile.id }
        let _familyPlacement = GeneralUtils.nullIfEmptyForObjectFields({ ...familyPlacement, profile: _profile })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/familyPlacement/save', _familyPlacement)
                .then(response => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getFamilyPlacementBeneficiaryList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post(`/familyPlacement/beneficiary/list`, criteria)
                .then((response) => {
                    response.data.map((item) => {
                        item.startDate = item.startDate ? new Date(Date.parse(item.startDate)) : '';                    
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';      
                        return item
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveFamilyPlacementBeneficiary(familyBeneficiary) {
        console.log(familyBeneficiary)

        let _familyPlacement = { ...familyBeneficiary.familyPlacement, profile: { id: familyBeneficiary.familyPlacement.profile.id } }
        let _beneficiary = { ...familyBeneficiary.beneficiary, profile: { id: familyBeneficiary.beneficiary.profile.id } }
        let _familyBeneficiary = GeneralUtils.nullIfEmptyForObjectFields({ ...familyBeneficiary, familyPlacement: _familyPlacement, beneficiary: _beneficiary })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/familyPlacement/beneficiary/save', _familyBeneficiary)
                .then(response => {
                    response.data.startDate = response.data.startDate ? new Date(Date.parse(response.data.startDate)) : '';                    
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : '';                                        
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
}