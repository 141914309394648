import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';

import SolutionListComponent from '../../components/solution/SolutionListComponent';

const SolutionList = () => {

    const toast = useRef(null);    
    
    return (
        <>
            <Toast ref={toast} />
            <div className="grid h-full">                
                <div className="col-12">
                    <div className="card h-full">
                        <SolutionListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SolutionList;
