import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import LanguageService, { translatedMessage } from '../../service/LanguageService';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';

import GeneralUtils from '../../utilities/GeneralUtils';
import { AmpService } from '../../service/AmpService';
import { InputNumber } from 'primereact/inputnumber';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import ProfileAutocompleteFieldComponent from '../profile/ProfileAutocompleteFieldComponent';

let emptyRecord = {
    id: null,
    householdResidents: 0,
    householdDetail: '',
    ampCertificateExpiryDate: '',
    profile: null
};

const AmpEditComponent = (props) => {
    const [amp, setAmp] = useState(emptyRecord)

    const ampService = new AmpService();

    useEffect(() => {
        const computeAmp = async () => {
            let _amp = emptyRecord
            if (props?.value) {
                _amp = GeneralUtils.emptyInNullForObjectFields(props.value)
            }

            setAmp(_amp)
        }

        computeAmp()
    }, [props]);

    const formik = useFormik({
        initialValues: amp ? amp : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.householdResidents) {
                errors.householdResidents = translatedMessage("form.error.householdResidents.required");
            }

            if (!data.profile) {
                errors.profile = translatedMessage("form.error.profile.required");
            }            

            return errors;
        },
        onSubmit: (data) => {
            //console.log("save amp: ", data)

            ampService.saveAmp(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const onEditorChange = (content) => {
        formik.setFieldValue("householdDetail", content)
    }

    const handleProfileChange = async (_existingProfile) => {
        setAmp({ ...formik.values, profile: _existingProfile })
    }

    return (
        <>
            <ProfileAutocompleteFieldComponent
                profile={amp?.profile}
                setExistingProfile={(e) => handleProfileChange(e)}
                entityName="AMP"
                showAddProfile={true}
                validationError={isFormFieldValid('profile')}
                disabled={props?.disableProfile}
            />

            <div className="col-12">
                <form id="amp-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className="col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputNumber id="householdResidents" name="householdResidents" value={formik.values.householdResidents}
                                        onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('householdResidents') })}
                                        mode="decimal" useGrouping={false} />
                                    <label htmlFor="householdResidents" className={classNames({ 'p-error': isFormFieldValid('householdResidents') })}>
                                        {translatedMessage('amp.householdResidents')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('householdResidents')}
                        </div>

                        <div className="col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="ampCertificateExpiryDate" name="ampCertificateExpiryDate" value={formik.values.ampCertificateExpiryDate}
                                        onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                        className={classNames({ 'p-invalid': isFormFieldValid('ampCertificateExpiryDate') })} />
                                    <label htmlFor="ampCertificateExpiryDate" className={classNames({ 'p-error': isFormFieldValid('ampCertificateExpiryDate') })}>
                                        {translatedMessage("amp.ampCertificateExpiryDate")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('startDate')}
                        </div>

                        <div className="col-12">
                            <div className='filed-label mb-1'>{translatedMessage('amp.householdDetail')}</div>
                            <SunEditor
                                id="householdDetail" name="householdDetail"
                                lang={LanguageService.getCurrentLanguage}
                                height="250px"
                                width='100%'
                                setContents={formik.values.householdDetail}
                                setOptions={{
                                    buttonList: GeneralUtils.getSunEditorButtons(),
                                    font: GeneralUtils.getSunEditorFonts(),
                                    defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                }}
                                onChange={onEditorChange}
                            />
                            {getFormErrorMessage('householdDetail')}
                        </div>


                    </div>
                </form>
            </div>
        </>
    );
};

export default AmpEditComponent;
