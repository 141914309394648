import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../../service/LanguageService';
import { ProfileService } from '../../service/ProfileService';
import DataTableUtils from '../../utilities/DataTableUtils';
import GeneralUtils from '../../utilities/GeneralUtils';

const AddressHistoryDialogComponent = (props) => {
    const [addressList, setAddressList] = useState([]);

    const dt = useRef(null);

    const profileService = useMemo(() => new ProfileService(), []);

    useEffect(() => {
        const getAddressList = async () => {
            await profileService.getProfileAddressHistory(props?.profile.id, props?.addressType)
                .then(response => {
                    setAddressList(response)
                })
        }

        if (props?.visible) {
            getAddressList()
        }
    }, [profileService, props]);

    const hideDialog = () => {
        setAddressList([])
        props.visibleSetter()
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    return (
        <>
            <Dialog
                visible={props.visible}
                header={translatedMessage('address.history')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <DataTable
                    ref={dt}
                    value={addressList}
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive pcn-datatable"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                >
                    <Column
                        field="address.county.label"
                        header={translatedMessage("generic.county")}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '5rem' }}
                    />
                    <Column
                        field="address.locality.label"
                        header={translatedMessage("generic.locality")}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '5rem' }}
                    />
                    <Column
                        field="address.address"
                        header={translatedMessage("address.address")}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '5rem' }}
                    />
                    <Column
                        field="startDate"
                        header={translatedMessage("generic.startDate")}
                        body={(e) => GeneralUtils.formatDate(e.startDate)}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '8rem' }}
                    />
                    <Column
                        field="endDate"
                        header={translatedMessage("generic.endDate")}
                        body={(e) => GeneralUtils.formatDate(e.endDate)}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '8rem' }}
                    />
                </DataTable>
            </Dialog>
        </>
    );
};

export default AddressHistoryDialogComponent;
