import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from "primereact/toolbar";
import { ToggleButton } from 'primereact/togglebutton';
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { CenterBeneficiaryService } from '../../service/CenterBeneficiaryService';
import { translatedMessage } from "../../service/LanguageService";
import { hasPermission } from '../../service/UserService';
import GeneralUtils from "../../utilities/GeneralUtils";
import EnumService from '../../service/EnumService';
import DeleteModalComponent from "../DeleteModalComponent";
import CenterBeneficiaryEditDialog from "./CenterBeneficiaryEditDialog";
import DataTableUtils from "../../utilities/DataTableUtils";
import CenterDataTableUtils from "../../utilities/CenterDataTableUtils";
import ExportButtonComponent from "../ExportButtonComponent";
import { Dialog } from "primereact/dialog";
import StorageFolderFileList from "../file/StorageFolderFileList";
import CenterBeneficiaryViewDialogComponent from "./CenterBeneficiaryViewDialogComponent";
import BeneficiaryExportDataUtils from "../../utilities/BeneficiaryExportDataUtils";
import SecurityService from '../../service/SecurityService';

const CenterBeneficiaryListComponent = (props) => {
    const [centerBeneficiaryList, setCenterBeneficiaryList] = useState([]);
    const [selectedCenterBeneficiary, setSelectedCenterBeneficiary] = useState(null);
    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [statuses, setStatuses] = useState([])
    const [totalRecords, setTotalRecords] = useState(0);
    const [showFilesDialog, setShowFilesDialog] = useState(false);

    const [canCreate, setCanCreate] = useState(true);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            center: { value: null, matchMode: FilterMatchMode.CONTAINS },
            beneficiary: { value: null, matchMode: FilterMatchMode.CONTAINS },
            status: { value: null, matchMode: FilterMatchMode.IN },
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        "organizationCenter.organization.name": "center_name",
        "beneficiary.profile.fullName": "beneficiary_name",
        startDate: "start_date",
        endDate: "end_date",
        status: "status",
    };

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const centerBeneficiaryService = useMemo(() => new CenterBeneficiaryService(), []);

    useEffect(() => {
        setFirstLoading(true);
        const getStatuses = async () => {
            setStatuses(await EnumService.getEnumByName('CenterBeneficiaryStatus'));
        };

        const checkCanCreate = async () => {
            if (props?.beneficiary?.id) {
                setCanCreate(await SecurityService.check('SET_BENEFICIARY_CENTER', props?.beneficiary?.id));
            } else {
                setCanCreate(await SecurityService.check('SET_BENEFICIARY_CENTER'));
            }
        };

        getStatuses()
        checkCanCreate()
        setFirstLoading(false);
    }, [centerBeneficiaryService, props]);

    useEffect(() => {
        loadCenterBeneficiaryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues("CenterBeneficiaryList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";

        criteria.centerName = lazyParams.filters.center.value;
        criteria.beneficiaryName = lazyParams.filters.beneficiary.value;
        criteria.status = lazyParams.filters.status.value;
        criteria.centerId = props?.center?.id;
        criteria.beneficiaryId = props?.beneficiary?.id;

        return criteria;
    };

    const loadCenterBeneficiaryList = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await centerBeneficiaryService
            .getCenterBeneficiaryListByCriteria(criteria)
            .then((data) => {
                setCenterBeneficiaryList(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                //setError(true)
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const updateList = async (savedCenterBeneficiary, isNew) => {
        if (isNew) {
            setSelectedCenterBeneficiary(savedCenterBeneficiary);
        }
        loadCenterBeneficiaryList();
    };

    const showViewDialog = (centerBeneficiary) => {
        setSelectedCenterBeneficiary(centerBeneficiary);
        setViewDialogVisible(true);
    };

    const showNewDialog = () => {
        setSelectedCenterBeneficiary(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (centerBeneficiary) => {
        setSelectedCenterBeneficiary(centerBeneficiary);
        setEditDialogVisible(true);
    };

    const handleViewFiles = (centerBeneficiary) => {
        setSelectedCenterBeneficiary(centerBeneficiary);
        setShowFilesDialog(true);
    }

    const showDeleteDialog = (centerBeneficiary) => {
        setSelectedCenterBeneficiary(centerBeneficiary);
        setDeleteDialogVisible(true);
    };

    const deleteService = () => {
        centerBeneficiaryService
            .deleteCenterBeneficiary(selectedCenterBeneficiary.id)
            .then(() => {
                let _services = centerBeneficiaryList.filter(
                    (item) => item.id !== selectedCenterBeneficiary.id
                );
                setCenterBeneficiaryList(_services);
                toast.current.show({
                    severity: "success", summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: translatedMessage(error), life: 5000, });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedCenterBeneficiary(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("CENTER_BENEFICIARY_CREATE") &&
                        (
                            <Button label={translatedMessage("centerBeneficiary.new")}
                                con="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreate} />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            centerBeneficiaryService
                .getCenterBeneficiaryListByCriteria(criteria)
                .then((data) => {
                    let exportData = []
                    if (props?.beneficiary) {
                        data.items.forEach((item) => {
                            let exportItem = CenterDataTableUtils.computeCenterExportFields(item.center);
                            exportItem.beneficiary = item.beneficiary?.profile?.fullName;
                            exportItem.status = translatedMessage('CenterBeneficiaryStatus.' + item.status);
                            exportItem.startDate = GeneralUtils.formatDate(item.startDate);
                            exportItem.endDate = GeneralUtils.formatDate(item.endDate);
                            exportItem.comment = item.comment;
                            exportItem.createdOn = GeneralUtils.formatDate(item.createdOn);

                            exportData.push(exportItem)
                        });
                    } else {
                        data.items.forEach((item) => {
                            let exportItem = BeneficiaryExportDataUtils.computeExportFields(item.beneficiary);
                            exportItem.organizationCenter = item.center?.name;
                            exportItem.status = translatedMessage('CenterBeneficiaryStatus.' + item.status);
                            exportItem.startDate = GeneralUtils.formatDate(item.startDate);
                            exportItem.endDate = GeneralUtils.formatDate(item.endDate);
                            exportItem.comment = item.comment;
                            exportItem.createdOn = GeneralUtils.formatDate(item.createdOn);

                            exportData.push(exportItem)
                        });
                    }

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={CenterDataTableUtils.centerBeneficiaryListTableHeader(props?.beneficiary)}
                sortOrderHeader={CenterDataTableUtils.centerBeneficiaryListSortOrderHeader(props?.beneficiary)}
                fileName={GeneralUtils.computeExportFileName("centerBeneficiary.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-service justify-content-end">
                {hasPermission("CENTER_BENEFICIARY_VIEW") &&
                    (
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => showViewDialog(rowData)}
                            tooltip={translatedMessage("generic.view")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.VIEW_ORGANIZATION}
                        />
                    )}
                {hasPermission("CENTER_BENEFICIARY_EDIT") &&
                    (
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showEditDialog(rowData)}
                            tooltip={translatedMessage("generic.edit")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.SET_BENEFICIARY_CENTER}
                        />
                    )}
                {hasPermission("CENTER_BENEFICIARY_VIEW") &&
                    <Button icon="pi pi-file" className="p-button-rounded p-button-info m-1" onClick={() => handleViewFiles(rowData)}
                        tooltip={translatedMessage("generic.files")} tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={!rowData.actions?.VIEW_ORGANIZATION} />
                }
                {hasPermission("CENTER_BENEFICIARY_CREATE") &&
                    (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => showDeleteDialog(rowData)}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.SET_BENEFICIARY_CENTER}
                        />
                    )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    const statusTemplate = (rowData) => {
        let isActive = 'ACTIVE' === rowData.status;
        return (
            <>
                {hasPermission('CENTER_BENEFICIARY_EDIT') && (
                    <ToggleButton
                        id={`status_${rowData.id}`}
                        name={`status_${rowData.id}`}
                        checked={isActive}
                        onChange={() => {
                            updateStatus(rowData);
                        }}
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        aria-label={translatedMessage("centerBeneficiary.status")}
                        className="pcn-status-toogle"
                        onLabel={translatedMessage('CenterBeneficiaryStatus.ACTIVE')}
                        offLabel={translatedMessage('CenterBeneficiaryStatus.INACTIVE')}
                        tooltip={translatedMessage(isActive ? 'centerBeneficiary.inactivate' : 'centerBeneficiary.activate')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={!rowData.actions?.SET_BENEFICIARY_CENTER}
                    />
                )}
                {!hasPermission('CENTER_BENEFICIARY_EDIT') &&
                    <span className={`pill status status-${rowData.status.toString().toLowerCase()}`}>
                        {translatedMessage('CenterBeneficiaryStatus.' + rowData.status)}
                    </span>}
            </>
        );
    };

    const updateStatus = async (_centerBeneficiary) => {
        let oldStatus = _centerBeneficiary.status;
        let newStatus = oldStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'

        centerBeneficiaryService.saveCenterBeneficiary({ ..._centerBeneficiary, status: newStatus })
            .then(() => {
                toast.current.show({ severity: 'success', summary: translatedMessage('centerBeneficiary.save.success'), life: 3000 });
                loadCenterBeneficiaryList()
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const statusRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={statuses}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter"
            showClear
            itemTemplate={statusItemTemplate}
        />
    };

    const statusItemTemplate = (option) => {
        return <span className={`inline-block status status-${option.value.toString().toLowerCase()}`}>{option.label}</span>;
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.endDate)
    }

    const centerBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('ORGANIZATION_VIEW') &&
                        <Button
                            text
                            label={rowData.center.name}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/center/${rowData.center.id}`)}
                        />
                    }
                    {!hasPermission('ORGANIZATION_VIEW') &&
                        rowData.center.name
                    }
                </div>
            </>
        );
    };

    const beneficiaryBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button
                            text
                            label={rowData.beneficiary.profile.fullName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/beneficiary/${rowData.beneficiary.id}`)}
                        />
                    }
                    {!hasPermission('BENEFICIARY_VIEW') &&
                        rowData.beneficiary.profile.fullName
                    }
                </div>
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-service">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className='mb-0'>{props.pageTitle ? props.pageTitle : translatedMessage("centerBeneficiary.list")}</h5>
                    </div>

                    <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0 pt-0" ></Toolbar>

                    <DataTable
                        ref={dt}
                        value={centerBeneficiaryList}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        scroll="true"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        {!props?.center &&
                            <Column
                                field="center.name"
                                header={translatedMessage("centerBeneficiary.center")}
                                headerStyle={{ width: "25%", minWidth: "5rem" }}
                                body={centerBodyTemplate}
                                sortable
                                filter
                                filterField="center"
                                showFilterMenu={false}
                            />
                        }
                        {!props?.beneficiary &&
                            <Column
                                field="beneficiary.profile.fullName"
                                header={translatedMessage("centerBeneficiary.beneficiary")}
                                headerStyle={{ width: "25%", minWidth: "5rem" }}
                                body={beneficiaryBodyTemplate}
                                sortable
                                filter
                                filterField="beneficiary"
                                showFilterMenu={false}
                            />
                        }
                        <Column
                            field="status"
                            filterField="status"
                            header={translatedMessage('generic.status')}
                            sortable
                            headerStyle={{ width: "8%", minWidth: '8rem' }}
                            body={statusTemplate}
                            showFilterMenu={false}
                            filter
                            filterElement={statusRowFilterTemplate}
                        />
                        <Column
                            field="startDate"
                            header={translatedMessage("generic.interval")}
                            body={dateBodyTemplate}
                            sortable
                            headerStyle={{ width: "10%", minWidth: '8rem' }}
                        />
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <CenterBeneficiaryViewDialogComponent
                        value={selectedCenterBeneficiary}
                        visible={viewDialogVisible}
                        visibleSetter={setViewDialogVisible}
                    />

                    <CenterBeneficiaryEditDialog
                        value={selectedCenterBeneficiary}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={setEditDialogVisible}
                        center={props?.center}
                        beneficiary={props?.beneficiary}
                    />

                    <Dialog
                        visible={showFilesDialog}
                        closable={true}
                        onHide={() => setShowFilesDialog(false)}
                        header={translatedMessage("generic.files")}
                        modal
                        className="pcn-dialog-big"
                        footer={
                            <Button
                                label={translatedMessage("generic.cancel")}
                                icon="pi pi-times"
                                className="p-button-text"
                                onClick={() => setShowFilesDialog(false)}
                            />
                        }
                    >
                        <StorageFolderFileList
                            folderId={selectedCenterBeneficiary?.folderId}
                            folderName={selectedCenterBeneficiary?.beneficiary?.profile?.fullName}
                            showUploadButton={true}
                            multiple={false}
                            entityName={"CENTER"}
                            hasFileProperties={true}
                            readOnly={!selectedCenterBeneficiary?.actions?.SET_BENEFICIARY_CENTER}
                        />
                    </Dialog>

                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedCenterBeneficiary ? selectedCenterBeneficiary?.beneficiary?.profile?.fullName + ' / ' + selectedCenterBeneficiary?.center?.organization?.name : ""}
                        closeDialog={() => { setDeleteDialogVisible(false); }}
                        deleteRecord={() => deleteService()}
                        info="centerBeneficiary.delete.info"
                    />
                </>
            )}
        </>
    );
};

export default CenterBeneficiaryListComponent;
