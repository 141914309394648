import ApiService from "./ApiService";

export class ServicesService {

    async getServicesByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/service/list`, criteria)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getService(serviceId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/service/${serviceId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveService(service) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/service`, service)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteService(serviceId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/service/${serviceId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }

    //  Service supply
    async getServiceSupplyListByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/serviceSupply/list`, criteria)
                .then((response) => {
                    response.data.items.map(item => {
                        item.startDate = item.startDate ? new Date(Date.parse(item.startDate)) : '';
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        return item;
                    })
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getServiceSupply(serviceId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/serviceSupply/${serviceId}`)
                .then(response => {
                    response.data.startDate = response.data.startDate ? new Date(Date.parse(response.data.startDate)) : '';
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveServiceSupply(service) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/serviceSupply`, service)
                .then(response => {
                    response.data.startDate = response.data.startDate ? new Date(Date.parse(response.data.startDate)) : '';
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteServiceSupply(serviceId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/serviceSupply/${serviceId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }

    async getServiceViewByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/serviceView/list`, criteria)
                .then((response) => {
                    response.data.items.map(item => {
                        return item;
                    })
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }
}