import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { CenterService } from '../../service/CenterService';
import CenterViewComponent from './CenterViewComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CenterViewDialogComponent = (props) => {
    const [center, setCenter] = useState(null);
    const [centerComplexes, setCenterComplexes] = useState([]);
    const [socialServiceTypes, setSocialServiceTypes] = useState([]);

    const toast = useRef(null);
    const navigate = useNavigate();

    const centerService = useMemo(() => new CenterService(), []);

    useEffect(() => {        
        const getCenter = async () => {
            await centerService.getCenter(props?.centerId)
                .then(async (_data) => {
                    setCenter(_data);
                    setCenterComplexes(props?.centerComplexes);
                    setSocialServiceTypes(props?.socialServiceTypes);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        if(props?.visible) {
            getCenter()
        }
    }, [centerService, props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const dialogHeader = (
        <>
            <div className='mb-1'>{translatedMessage('center.details')}</div>
            <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/center/${center.id}`)}>
                <div className="flex align-items-center">
                    <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" className="mr-1 " />
                    <span>{translatedMessage('center.goTO.page')}</span>
                </div>
            </Button>
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={dialogHeader}
                modal
                className="pcn-dialog"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <CenterViewComponent center={center} centerComplexes={centerComplexes} socialServiceTypes={socialServiceTypes} />
            </Dialog>
        </>);
};

export default CenterViewDialogComponent;
