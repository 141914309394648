import React from 'react';
import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';
import GeneralUtils from './GeneralUtils';
import BeneficiaryExportDataUtils from './BeneficiaryExportDataUtils';

const CenterDataTableUtils = {
    entityLookupBodyTemplate(_entityLookup) {
        return (
            <div className={`inline-block`}>{_entityLookup?.label}</div>
        )
    },

    entityLookupFilterTemplate(options, statuses, placeholder) {
        return <MultiSelect
            value={options.value}
            options={statuses}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder={placeholder}
            className="p-column-filter"
            maxSelectedLabels={1}
            showSelectAll={false}
            selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
            itemTemplate={(e) => this.entityLookupBodyTemplate(e)}
            filter
        />
    },

    centerListTableHeader(_useCreatedOn = false) {
        let centerCols = [
            translatedMessage("center.center"),
            translatedMessage("generic.description"),
            translatedMessage("department.parent"),
            translatedMessage("center.complex"),
            translatedMessage("center.socialServiceCode"),
            translatedMessage("center.socialServiceType"),
            translatedMessage("center.maxCapacity"),
            translatedMessage("center.provLicenseDate"),
            translatedMessage("center.termLicenseDate"),
            translatedMessage("center.termLicenseNumber"),
            translatedMessage("center.relicensingDecisionDate"),
            translatedMessage("center.relicensingDecisionNumber"),
            translatedMessage("center.licenseIssuer")
        ]

        if (_useCreatedOn) {
            centerCols.push(translatedMessage("generic.created.on"))
        }

        return centerCols
    },

    centerListSortOrderHeader(_useCreatedOn = false) {
        let centerCols = [
            "name", "description", "parentOrganization", "complex", "socialServiceCode", "socialServiceType", "maxCapacity", "provLicenseDate", "termLicenseDate", "termLicenseNumber", "relicensingDecisionDate", "relicensingDecisionNumber", "licenseIssuer"
        ]

        if (_useCreatedOn) {
            centerCols.push("createdOn")
        }

        return centerCols
    },

    computeCenterExportFields(_center, _useCreatedOn = false) {
        let exportData = { ..._center };

        exportData.parentOrganization = _center.department?.name;
        exportData.complex = _center.centerDetails?.complex?.label;
        exportData.socialServiceType = _center.centerDetails?.socialServiceType?.label;
        exportData.socialServiceCode = _center.centerDetails?.socialServiceCode;
        exportData.maxCapacity = _center.centerDetails?.maxCapacity;
        exportData.provLicenseDate = GeneralUtils.formatDate(_center.centerDetails?.provLicenseDate);
        exportData.termLicenseDate = GeneralUtils.formatDate(_center.centerDetails?.termLicenseDate);
        exportData.termLicenseNumber = _center.centerDetails?.termLicenseNumber;
        exportData.relicensingDecisionDate = GeneralUtils.formatDate(_center.centerDetails?.relicensingDecisionDate);
        exportData.relicensingDecisionNumber = _center.centerDetails?.relicensingDecisionNumber;
        exportData.licenseIssuer = _center.centerDetails?.licenseIssuer;

        if (_useCreatedOn) {
            exportData.createdOn = GeneralUtils.formatDate(_center.createdOn);
        } else {
            delete exportData.createdOn
        }

        delete exportData.status;
        delete exportData.public;
        delete exportData.type;

        return exportData
    },

    centerBeneficiaryListTableHeader(_isForBeneficiary = false) {
        let cbCols = []
        if (_isForBeneficiary) {
            cbCols = [
                translatedMessage('centerBeneficiary.beneficiary'),
                translatedMessage("generic.status"),
                translatedMessage("generic.startDate"),
                translatedMessage("generic.endDate"),
                translatedMessage("centerBeneficiary.comment"),
                translatedMessage("generic.created.on")
            ]

            cbCols = cbCols.concat(this.centerListTableHeader())
        } else {
            cbCols = [
                translatedMessage('center.center'),
                translatedMessage("generic.status"),
                translatedMessage("generic.startDate"),
                translatedMessage("generic.endDate"),
                translatedMessage("centerBeneficiary.comment"),
                translatedMessage("generic.created.on")
            ]

            cbCols = cbCols.concat(BeneficiaryExportDataUtils.beneficiaryTableHeader())
        }

        return cbCols
    },

    centerBeneficiaryListSortOrderHeader(_isForBeneficiary = false) {
        let cbCols = []

        if (_isForBeneficiary) {
            cbCols = [
                "beneficiary", "status", "startDate", "endDate", "comment", "createdOn"
            ]

            cbCols = cbCols.concat(this.centerListSortOrderHeader())
        } else {
            cbCols = [
                "organizationCenter", "status", "startDate", "endDate", "comment", "createdOn"
            ]

            cbCols = cbCols.concat(BeneficiaryExportDataUtils.beneficiarySortOrderHeader())         
        }

        return (cbCols)
    },

};

export default CenterDataTableUtils;