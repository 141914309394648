import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';

import MonitoringListComponent from '../../components/monitoring/MonitoringListComponent';

const MonitoringList = () => {

    const toast = useRef(null);    
    
    return (
        <>
            <Toast ref={toast} />
            <div className="grid h-full">                
                <div className="col-12">
                    <div className="card h-full">
                        <MonitoringListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MonitoringList;
