import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import { hasPermission } from '../../service/UserService';
import EnumService from "../../service/EnumService";
import DataTableUtils from "../../utilities/DataTableUtils";
import { DashboardService } from "../../service/DashboardService";
import { Dropdown } from "primereact/dropdown";
import useAuth from "../../hooks/useAuth";
import ProfilelUtils from "../../utilities/ProfilelUtils";

const DashboardHearingList = (props) => {
    const [hearings, setHearings] = useState([]);
    const [hearingTypeList, setHearingTypeList] = useState([])

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 1,
        sortField: "date",
        sortOrder: -1,
        filters: {
            responsibleName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: null, matchMode: FilterMatchMode.EQUALS },
            conclusion: { value: null, matchMode: FilterMatchMode.IN },
        },
    });

    const propToColumnMap = {
        responsibleName: "reporter_name",
        name: "name",
        type: "type",
        conclusion: "conclusion_id",
        date: "date",
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [canBeResonsible, setCanBeResponsible] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();

    const navigate = useNavigate();

    const dashboardService = useMemo(() => new DashboardService(), []);

    useEffect(() => {

        const getHearingTypeList = async () => {
            const _hearingTypeList = await EnumService.getEnumByName("HearingType")
            setHearingTypeList(_hearingTypeList)
        }

        setCanBeResponsible(ProfilelUtils.canBeResponsibleForEntities(auth?.user))

        getHearingTypeList();
    }, [props, auth]);

    useEffect(() => {
        loadHearings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";

        criteria.name = lazyParams.filters.name.value;
        criteria.responsibleProfileName = lazyParams.filters.responsibleName.value;
        criteria.conclusionIds = lazyParams.filters.conclusion.value;
        criteria.type = lazyParams.filters.type.value;
        criteria.noticeId = props?.notice?.id;
        return criteria;
    };

    const loadHearings = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await dashboardService.getHearingList(criteria)
            .then((data) => {
                setHearings(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    }

    const showHearingViewPage = (_hearing) => {
        navigate(`/hearing/${_hearing.id}`);
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-hearing justify-content-end">
                {hasPermission("HEARING_VIEW") &&
                    <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-primary m-1"
                        onClick={() => showHearingViewPage(rowData)}
                        tooltip={translatedMessage("generic.view")}
                        tooltipOptions={{ showOnDisabled: true, position: "top" }}
                    />
                }
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateTemplate(rowData.date)
    }

    const typeRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={hearingTypeList}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter"
            showClear
            itemTemplate={options.label}
        />
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    return (
        <div className="card mb-3">
            <Toast ref={toast} />

            <div className='w-full text-align-left'>
                <h5 className="m-0">{canBeResonsible ? translatedMessage('dashboard.responsibleForHearings') : translatedMessage("hearing.list")}</h5>
            </div>

            <DataTable
                ref={dt}
                value={hearings}
                dataKey="id"
                paginator
                lazy
                totalRecords={totalRecords}
                first={lazyParams.first}
                rows={lazyParams.rows}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                rowsPerPageOptions={5}
                className="datatable-responsive pcn-datatable"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                scroll="true"
                filterDisplay="row"
                filters={lazyParams.filters}
                loading={isLoading}
                alwaysShowPaginator={false}
            >
                <Column
                    field="name"
                    header={translatedMessage("hearing.name")}
                    headerStyle={{ minWidth: "8rem" }}
                    body={(e) => e.firstName + ' ' + e.lastName}
                    sortable
                    filter
                    showFilterMenu={false}
                />
                <Column
                    field="type"
                    header={translatedMessage("hearing.type")}
                    headerStyle={{ minWidth: "8rem" }}
                    body={(e) => translatedMessage("HearingType." + e.type)}
                    sortable
                    filter
                    showFilterMenu={false}
                    filterElement={typeRowFilterTemplate}
                />
                <Column
                    field="date"
                    header={translatedMessage("hearing.date")}
                    headerStyle={{ minWidth: "8rem" }}
                    body={dateBodyTemplate}
                    sortable
                />
                <Column body={actionBodyTemplate}></Column>
            </DataTable>
        </div>
    )
};

export default DashboardHearingList;
