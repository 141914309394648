import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { translatedMessage } from '../service/LanguageService';

const Unauthorized = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/home');
    };

    return (
        <div className="flex flex-column">            
            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card flex flex-column">                    
                    <div className="card mt-3 px-6 text-center">
                        <img src="assets/layout/images/pages/404.png" alt={translatedMessage("page.notFound")} />
                    </div>
                    <div className="pages-detail pb-6 text-align-center">{translatedMessage("page.notAuthorized")}</div>
                    <Button type="button" onClick={goHome} label={translatedMessage("page.notFound.button")} className="p-button-text"></Button>
                </div>
            </div>
        </div>
    );
};

export default Unauthorized;
