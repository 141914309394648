import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import FamilyPlacementListComponent from '../../components/familyPlacement/FamilyPlacementListComponent';


const FamilyPlacementList = () => {
    const toast = useRef(null);

    return (
        <>
            <div className="grid h-full">
                <Toast ref={toast}></Toast>
                <div className="col-12">
                    <div className="card h-full">

                        <FamilyPlacementListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FamilyPlacementList;