import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { getIn, useFormik } from "formik";
import { translatedMessage } from '../../service/LanguageService';
import { BeneficiaryService } from '../../service/BeneficiaryService';
import OrganizationAutocompleteFieldComponent from '../../components/organization/OrganizationAutocompleteFieldComponent';

const BeneficiaryDepartmentDialog = (props) => {
    const [beneficiary, setBeneficiary] = useState(null);
    const [beneficiaryDepartment, setBeneficiaryDepartment] = useState(null);

    const toast = useRef(null);
    const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

    useEffect(() => {
        const getBeneficiaryDepartment = async (beneficiaryId) => {
            await beneficiaryService.getBeneficiaryDepartment(beneficiaryId)
                .then(async (_data) => {
                    setBeneficiaryDepartment(_data);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }

        let _beneficiary = props?.value ? props.value : null;
        if (_beneficiary) {
            setBeneficiary(_beneficiary)
            getBeneficiaryDepartment(_beneficiary.id)
        }
    }, [props, beneficiaryService]);

    const formik = useFormik({
        initialValues: beneficiaryDepartment ? beneficiaryDepartment : { departmentOrganization: null },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.departmentOrganization) {
                errors.departmentOrganization = translatedMessage("form.error.department.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            // console.log("saved organization: ", data)
            beneficiaryService.saveBeneficiaryDepartment(beneficiary.id, data.departmentOrganization.id)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData);
                    }
                    toast.current.show({ severity: 'success', summary: translatedMessage("beneficiary.save.success"), life: 3000 });
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: error, life: 5000 });
                });

            hideDialog()
        }
    });

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false)
            setBeneficiary(null)
            setBeneficiaryDepartment(null)
            formik.resetForm()
        }
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="responsible-form" type="submit" />
        </>
    );

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    // const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const handleOrganizationChange = async (_organization) => {
        setBeneficiaryDepartment({ ...formik.values, departmentOrganization: _organization })
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('beneficiary.responsible')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >

                <form id="responsible-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <OrganizationAutocompleteFieldComponent
                            setOrganization={(e) => handleOrganizationChange(e)}
                            organization={beneficiaryDepartment?.departmentOrganization}
                            validationError={isFormFieldValid('departmentOrganization')}
                            types={["DEPARTMENT"]}
                            title={translatedMessage("department.list")}
                        />
                    </div>
                </form>
            </Dialog>
        </>);
};

export default BeneficiaryDepartmentDialog;
