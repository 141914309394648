import ApiService from "./ApiService";
import GeneralUtils from "../utilities/GeneralUtils";

export class CenterService {

    async getCentersByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/center/list`, criteria)
                .then((response) => {
                    response.data.items.map(item => {
                        item.centerDetails.provLicenseDate = item.centerDetails.provLicenseDate ? new Date(Date.parse(item.centerDetails.provLicenseDate)) : '';
                        item.centerDetails.termLicenseDate = item.centerDetails.termLicenseDate ? new Date(Date.parse(item.centerDetails.termLicenseDate)) : '';
                        item.centerDetails.relicensingDecisionDate = item.centerDetails.relicensingDecisionDate ? new Date(Date.parse(item.centerDetails.relicensingDecisionDate)) : '';
                        return item;
                    })
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getCenter(centerId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/center/${centerId}`)
                .then(response => {
                    response.data.centerDetails.provLicenseDate = response.data.centerDetails.provLicenseDate ? new Date(Date.parse(response.data.centerDetails.provLicenseDate)) : '';
                    response.data.centerDetails.termLicenseDate = response.data.centerDetails.termLicenseDate ? new Date(Date.parse(response.data.centerDetails.termLicenseDate)) : '';
                    response.data.centerDetails.relicensingDecisionDate = response.data.centerDetails.relicensingDecisionDate ? new Date(Date.parse(response.data.centerDetails.relicensingDecisionDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveCenter(center) {
        center = GeneralUtils.nullIfEmptyForObjectFields(center)
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/center`, center)
                .then(response => {
                    response.data.centerDetails.provLicenseDate = response.data.centerDetails.provLicenseDate ? new Date(Date.parse(response.data.centerDetails.provLicenseDate)) : '';
                    response.data.centerDetails.termLicenseDate = response.data.centerDetails.termLicenseDate ? new Date(Date.parse(response.data.centerDetails.termLicenseDate)) : '';
                    response.data.centerDetails.relicensingDecisionDate = response.data.centerDetails.relicensingDecisionDate ? new Date(Date.parse(response.data.centerDetails.relicensingDecisionDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteCenter(centerId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/center/${centerId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }

}