
import React from "react";
import CenterBeneficiaryListComponent from "../../components/centerBeneficiary/CenterBeneficiaryListComponent";

const CenterBeneficiaryListPage = () => {

    return (
        <>
            <div className="grid h-full">
                <div className="col-12">
                    <div className="card h-full">
                        <CenterBeneficiaryListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CenterBeneficiaryListPage;
