import React from 'react';
import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';

const FileUtils = {
    usageFilterTemplate(options, usageLookup) {
        return <MultiSelect
            value={options.value}
            options={usageLookup}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder={translatedMessage('generic.type')}
            className="p-column-filter"
            maxSelectedLabels={1}
            showSelectAll={false}
            selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
            itemTemplate={(e) => e.label}
        />
    },

};

export default FileUtils;