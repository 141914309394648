import React from 'react';

const FooterBeforeLogin = () => {

  return (
    <div className='w-full flex flex-column justify-content-center py-2' style={{ height: 'auto', backgroundColor: "#FFFFFF" }}>
      <div className='w-full text-center my-1'>
        <span className='mr-1'>Programul Operațional Capacitate Administrativă</span>
        <a href="https://www.poca.ro/" target="_blank" rel="noreferrer">www.poca.ro</a>.
      </div>
      <div className='w-full text-center my-1'>Conținutul acestei pagini web nu reprezintă în mod obligatoriu poziția oficială a Uniunii Europene.
        Întreaga responsabilitate asupra corectitudinii și coerenței informațiilor prezentate revine inițiatorilor paginii web.</div>
    </div>

  );
};

export default FooterBeforeLogin;
