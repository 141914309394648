import React from 'react';
import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';
import OrganizationDataTableUtils from "./OrganizationDataTableUtils";
import GeneralUtils from './GeneralUtils';

const ServiceDataTableUtils = {
    entityLookupBodyTemplate(_entityLookup) {
        return (
            <div className={`inline-block`}>{_entityLookup?.label}</div>
        )
    },

    entityLookupFilterTemplate(options, statuses, placeholder) {
        return <MultiSelect
            value={options.value}
            options={statuses}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder={placeholder}
            className="p-column-filter"
            maxSelectedLabels={1}
            showSelectAll={false}
            selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
            itemTemplate={(e) => this.entityLookupBodyTemplate(e)}
        />
    },

    serviceListTableHeader() {
        return [
            translatedMessage("service.organization"),
            translatedMessage("service.category"),
            translatedMessage("service.type"),
            translatedMessage('service.center'),
            translatedMessage('generic.created.on'),
        ]
    },

    serviceListSortOrderHeader() {
        return [
            "organizationName", "categoryName", "typeName", "centerName", "createdOn"
        ]
    },

    serviceExpertBeneficiaryListTableHeader(_useCreatedOn = false) {
        let sebCol = [
            translatedMessage('serviceExpertBeneficiary.beneficiary'),
            translatedMessage("service.serviceCategory"),
            translatedMessage("service.serviceType"),
            translatedMessage("service.organization"),
            translatedMessage("service.center"),
            translatedMessage('serviceExpertBeneficiary.expert'),
            translatedMessage('serviceExpertBeneficiary.venue'),
            translatedMessage("generic.startDate"),
            translatedMessage("generic.endDate"),
            translatedMessage("serviceExpertBeneficiary.objective"),
        ]

        if(_useCreatedOn){
            sebCol.push(translatedMessage("generic.created.on"))
        }

        return sebCol
    },

    serviceExpertBeneficiaryListSortOrderHeader(_useCreatedOn = false) {
        let sebCol = [
            "beneficiary", "serviceCategory", "serviceType", "serviceOrganization", "serviceCenter", "expert", 
            "serviceVenue", "startDate", "endDate", "objective"
        ]

        if(_useCreatedOn){
            sebCol.push("createdOn")
        }

        return sebCol
    },

    computeServiceExpertBeneficiaryExportFields(_seb, _useCreatedOn = false) {
        let exportData = {}
        exportData.beneficiary = _seb.beneficiary?.profile?.fullName
        
        exportData.serviceCategory = _seb.service?.category?.label
        exportData.serviceType = _seb.service?.type?.label
        exportData.serviceOrganization = _seb.service?.organization?.name
        exportData.serviceCenter = _seb.service?.center?.name
        
        exportData.expert = _seb.organizationProfile?.profile?.fullName        

        exportData.serviceVenue = _seb.serviceVenue?.label
        exportData.objective = _seb.objective
        exportData.startDate = GeneralUtils.formatDate(_seb.startDate)
        exportData.endDate = GeneralUtils.formatDate(_seb.endDate)        

        if (_useCreatedOn) {
            exportData.createdOn = GeneralUtils.formatDate(_seb.createdOn)
          } else {
            delete exportData.createdOn
          }
    
        return exportData
      },

    serviceSupplyListTableHeader() {
        let ssCol = this.serviceExpertBeneficiaryListTableHeader()

        ssCol = ssCol.concat([
            translatedMessage("generic.startDate"),
            translatedMessage("generic.endDate"),
            translatedMessage("serviceSupply.hasBeenDone"),
            translatedMessage('serviceSupply.description'),
            translatedMessage('generic.created.on'),
        ])

        return ssCol
    },

    serviceSupplyListSortOrderHeader() {
        let ssCol = this.serviceExpertBeneficiaryListSortOrderHeader()
        
        ssCol = ssCol.concat( [
            "supplyStartDate", "supplyEndDate", "hasBeenDone", "description", "createdOn"
        ])

        return ssCol
    },

    getServiceDisplayName(service) {
        return service?.serviceName ? service?.serviceName :
            (
                service?.category
                    ? (service?.category?.label + ', ' +
                        service?.type?.label + ', ' +
                        service?.organization?.name +
                        (service?.center ? ', ' + service?.center?.name : ''))
                    : ''
            )
    },

    getServiceExpertBeneficiaryDisplayName(serviceExpertBeneficiary) {
        return !serviceExpertBeneficiary.id ? '' :
            (serviceExpertBeneficiary?.serviceName ? serviceExpertBeneficiary?.serviceName : ServiceDataTableUtils.getServiceDisplayName(serviceExpertBeneficiary?.service)) + ', ' +
            (serviceExpertBeneficiary?.expert ? serviceExpertBeneficiary?.expert : OrganizationDataTableUtils.getOrganizationProfileDisplayName(serviceExpertBeneficiary?.organizationProfile)) + ', ' +
            (serviceExpertBeneficiary?.beneficiaryProfileName ? serviceExpertBeneficiary?.beneficiaryProfileName : serviceExpertBeneficiary?.beneficiary?.profile?.fullName);
    }

};

export default ServiceDataTableUtils;