import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import LanguageService, { translatedMessage } from '../../service/LanguageService';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';

import GeneralUtils from '../../utilities/GeneralUtils';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import BeneficiaryAutocompleteFieldComponent from '../profile/BeneficiaryAutocompleteFieldComponent';
import { FamilyPlacementService } from '../../service/FamilyPlacementService';
import FamilyPlacementAutocompleteFieldComponent from './FamilyPlacementAutocompleteFieldComponent';

let emptyRecord = {
    id: null,
    familyPlacement: null,
    beneficiary: null,
    startDate: '',
    endDate: '',
    comment: ''
};

const FamilyPlacementBeneficiaryEditComponent = (props) => {
    const [fPBeneficiary, setFPBeneficiary] = useState(emptyRecord)

    const familyPlacementService = new FamilyPlacementService();

    useEffect(() => {
        const computeAmp = async () => {
            let _fPBeneficiary = emptyRecord
            if (props?.value) {
                _fPBeneficiary = GeneralUtils.emptyInNullForObjectFields(props.value)
            }

            if (props?.familyPlacement) {
                _fPBeneficiary.familyPlacement = props.familyPlacement
            }

            if (props?.beneficiary) {
                _fPBeneficiary.beneficiary = props.beneficiary
            }            

            setFPBeneficiary(_fPBeneficiary)
        }

        computeAmp()
    }, [props]);

    const formik = useFormik({
        initialValues: fPBeneficiary ? fPBeneficiary : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.startDate) {
                errors.startDate = translatedMessage("form.error.startDate.required");
            }

            if (data.endDate && data.startDate >= data.endDate) {
                errors.endDate = translatedMessage("form.error.endDate.toEarly");
            }

            if (!data.beneficiary) {
                errors.beneficiary = translatedMessage("form.error.beneficiary.required");
            }

            if (!data.familyPlacement) {
                errors.familyPlacement = translatedMessage("form.error.profile.required");
            }            

            return errors;
        },
        onSubmit: (data) => {
            // console.log("save family placement beneficiary: ", data)
            let isNew = data?.id ? false : true            

            familyPlacementService.saveFamilyPlacementBeneficiary(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, isNew, true, null);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const onEditorChange = (content) => {
        formik.setFieldValue("comment", content)
    }

    const handleProfileChange = async (_existingProfile) => {
        setFPBeneficiary({ ...formik.values, familyPlacement: _existingProfile })
    }

    const handleBeneficiaryChange = async (_beneficiary) => {
        setFPBeneficiary({ ...formik.values, beneficiary: _beneficiary })
    }

    return (
        <>
            <div className="col-12">
                <form id="familyPlacementBeneficiary-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <FamilyPlacementAutocompleteFieldComponent
                            familyPlacement={fPBeneficiary?.familyPlacement}
                            setFamilyPlacement={(e) => handleProfileChange(e)}
                            validationError={isFormFieldValid('familyPlacement')}
                            disabled={props?.familyPlacement}
                            title={translatedMessage("familyPlacement")}
                        />

                        <BeneficiaryAutocompleteFieldComponent
                            beneficiary={fPBeneficiary?.beneficiary}
                            setBeneficiary={(e) => handleBeneficiaryChange(e)}
                            validationError={isFormFieldValid('beneficiary')}
                            disabled={props?.beneficiary}
                        />

                        <div className="col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="startDate" name="startDate" value={formik.values.startDate}
                                        onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                        className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} />
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                        {translatedMessage("generic.startDate")} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('startDate')}
                        </div>

                        <div className="col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="endDate" name="endDate" value={formik.values.endDate}
                                        onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                        className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} />
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                        {translatedMessage("generic.endDate")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('endDate')}
                        </div>

                        <div className="col-12">
                            <div className='filed-label mb-1'>{translatedMessage('amp.comments')}</div>
                            <SunEditor
                                id="comment" name="comment"
                                lang={LanguageService.getCurrentLanguage}
                                height="250px"
                                width='100%'
                                setContents={formik.values.comment}
                                setOptions={{
                                    buttonList: GeneralUtils.getSunEditorButtons(),
                                    font: GeneralUtils.getSunEditorFonts(),
                                    defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                }}
                                onChange={onEditorChange}
                            />
                            {getFormErrorMessage('comment')}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default FamilyPlacementBeneficiaryEditComponent;
