const ExportServices = {

  export(type, data, fileName, header = null, sortHeader = null) {
    if(type) {
      if(type==="excel") {
        this.exportExcel(data, fileName, header, sortHeader)
      } else if(type==="csv") {
        this.exportCSV(data, fileName, header, sortHeader)
      }
    }
  },

  exportExcel(data, fileName, header = null, sortHeader = null) {
    let formatedData = data
    formatedData.map(item => {
      delete item.id
      delete item.version

      return item
    })

    if (formatedData && formatedData.length > 0) {
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(formatedData, {header: sortHeader, skipHeader: true, origin: "A2"});
        xlsx.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, fileName);
      });
    }
  },

  saveAsExcelFile(buffer, fileName) {
    import('file-saver').then(module => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  },

  exportCSV(data, fileName, header = null, sortHeader = null) {
    let formatedData = data
    formatedData.map(item => {
      delete item.id
      delete item.version

      return item
    })

    if (formatedData && formatedData.length > 0) {
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(formatedData, {header: sortHeader, skipHeader: true, origin: "A2"});
        xlsx.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
        const csv = xlsx.utils.sheet_to_csv(worksheet, {forceQuotes: true});
        this.saveAsCSVFile('\uFEFF' + csv, fileName);
      });
    }
  },

  saveAsCSVFile(buffer, fileName) {
    import('file-saver').then(module => {
      if (module && module.default) {
        let EXCEL_TYPE = 'text/plain;charset=UTF-8';
        let EXCEL_EXTENSION = '.csv';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  }
};

export default ExportServices;
