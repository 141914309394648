import React from 'react';
// import { classNames } from 'primereact/utils';
// import { Button } from 'primereact/button';
// import { RTLContext } from './App';
import { translatedMessage } from './service/LanguageService';

const AppFooter = (props) => {
    // const isRTL = useContext(RTLContext);

    return (
        <div className="layout-footer flex align-items-center py-3 px-4 shadow-2">
            <div className='w-full flex flex-row align-items-center justify-content-end'>
                {/* <img id="footer-logo" src={`assets/layout/images/footer-logo${props.colorMode === 'light' ? '' : '-dark'}.png`} alt="logo"
                    style={{ height: '80px' }} /> */}

                {/* <Button type="button" icon="pi pi-github fs-large" className={classNames('p-button-rounded p-button-text p-button-plain', { 'ml-auto mr-2': !isRTL, 'ml-2 mr-auto': isRTL })}></Button>
                    <Button type="button" icon="pi pi-facebook fs-large" className={classNames('p-button-rounded p-button-text p-button-plain', { 'mr-2': !isRTL, 'ml-2': isRTL })}></Button>
                    <Button type="button" icon="pi pi-twitter fs-large" className={classNames('p-button-rounded p-button-text p-button-plain', { 'mr-2': !isRTL, 'ml-2': isRTL })}></Button> */}
                
                <div className='ml-3 flex flex-column sm:flex-row justify-content-end'>
                    <div className='my-1 sm:my-0'>
                        <a href="/" target="_blank" rel="noreferrer">{translatedMessage("terms.tc")}</a>
                        <span className='hidden sm:inline mx-1'>|</span>
                    </div>
                    <div className='my-1 sm:my-0'>
                        <a href="/" target="_blank" rel="noreferrer">{translatedMessage("terms.privacy")}</a>
                        <span className='hidden sm:inline mx-1'>|</span>
                    </div>
                    <div className='my-1 sm:my-0'>
                        <a href="https://copilprahova.ro/" target="_blank" rel="noreferrer">copilprahova.ro</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppFooter;
