import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';

import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import ProfilelUtils from '../../utilities/ProfilelUtils';
import { ProfileService } from '../../service/ProfileService';
import { Dropdown } from 'primereact/dropdown';

let emptyRecord = ProfilelUtils.newProfileExtendedRecord();

const ProfileExtendedEditComponent = (props) => {
    const [profile, setProfile] = useState()
    const [healthConditions, setHealthConditions] = useState([])
    const [disabilityDegrees, setDisabilityDegrees] = useState([])
    const [disabilityTypes, setDisabilityTypes] = useState([])
    const [idStatuses, setIdStatuses] = useState([])
    const [diseaseTypes, setDiseaseTypes] = useState([])
    const [edLevels, setEdLevels] = useState([])
    const [empStatuses, setEmpStatuses] = useState([])
    const [maritalStatuses, setMaritalStatuses] = useState([])

    const [filteredentityLookups, setFilteredentityLookups] = useState([]);

    const profileService = new ProfileService();

    useEffect(() => {
        const getIdStatus = async () => {
            const _idStatus = await EnumService.getEntityLookupByField("NOM:PROFILE_ID_STATUS")
            setIdStatuses(_idStatus)
            return _idStatus
        }

        const getHealthC = async () => {
            const _healthC = await EnumService.getEntityLookupByField("NOM:HEALTH_CONDITION")
            setHealthConditions(_healthC)
            return _healthC
        }

        const getDisabilityD = async () => {
            const _disabilityD = await EnumService.getEntityLookupByField("NOM:DISABILITY_DEGREE")
            setDisabilityDegrees(_disabilityD)
            return _disabilityD
        }

        const getDisabilityT = async () => {
            const _disabilityT = await EnumService.getEntityLookupByField("NOM:DISABILITY_TYPE")
            setDisabilityTypes(_disabilityT)
            return _disabilityT
        }

        const getDiseaseT = async () => {
            const _diseaseT = await EnumService.getEntityLookupByField("NOM:DISEASE_TYPE")
            setDiseaseTypes(_diseaseT)
            return _diseaseT
        }

        const getEducationLevel = async () => {
            const _edLevel = await EnumService.getEntityLookupByField("NOM:EDUCATION_LEVEL")
            setEdLevels(_edLevel)
            return _edLevel
        }

        const getEmpStatus = async () => {
            const _empStatus = await EnumService.getEntityLookupByField("NOM:EMPLOYMENT_STATUS")
            setEmpStatuses(_empStatus)
            return _empStatus
        }

        const getMaritalStatuses = async () => {
            const response = await EnumService.getEnumByName("MaritalStatus");
            setMaritalStatuses(response)
        }

        const computeProfile = async () => {
            getMaritalStatuses()

            let _profile = emptyRecord
            if (props?.value) {
                _profile = GeneralUtils.emptyInNullForObjectFields(props.value.profileExtended)
            }
            let _isStatuses = await getIdStatus()
            _profile.idStatus = EnumService.getEntityLookupRecord(_profile.idStatusId, _isStatuses)

            let _healthCs = await getHealthC()
            _profile.healthStatus = EnumService.getEntityLookupRecord(_profile.healthStatusId, _healthCs)

            let disabilityDs = await getDisabilityD()
            _profile.disabilityDegree = EnumService.getEntityLookupRecord(_profile.disabilityDegreeId, disabilityDs)

            let disabilityTs = await getDisabilityT()
            _profile.disabilityType = EnumService.getEntityLookupRecord(_profile.disabilityTypeId, disabilityTs)

            let diseaseTs = await getDiseaseT()
            _profile.diseaseType = EnumService.getEntityLookupRecord(_profile.diseaseTypeId, diseaseTs)

            let edLevels = await getEducationLevel()
            _profile.educationLevel = EnumService.getEntityLookupRecord(_profile.educationLevelId, edLevels)

            let empStatuses = await getEmpStatus()
            _profile.employmentStatus = EnumService.getEntityLookupRecord(_profile.employmentStatusId, empStatuses)

            setProfile(_profile)
        }

        computeProfile()
    }, [props]);

    const formik = useFormik({
        initialValues: profile ? profile : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (props?.editSection === 'IDENTITY' && (!data.idStatus || !data.idStatus.value)) {
                errors.idStatusId = translatedMessage("form.error.idStatus.required");
            }

            if (props?.editSection === 'MARITAL' && (!data.maritalStatus)) {
                errors.maritalStatus = translatedMessage("form.error.maritalStatus.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            data.idStatusId = data.idStatus && data.idStatus.value ? data.idStatus.value : null
            data.healthStatusId = data.healthStatus && data.healthStatus.value ? data.healthStatus.value : null
            data.disabilityDegreeId = data.disabilityDegree && data.disabilityDegree.value ? data.disabilityDegree.value : null
            data.disabilityTypeId = data.disabilityType && data.disabilityType.value ? data.disabilityType.value : null
            data.diseaseTypeId = data.diseaseType && data.diseaseType.value ? data.diseaseType.value : null
            data.educationLevelId = data.educationLevel && data.educationLevel.value ? data.educationLevel.value : null
            data.educationStatusId = data.educationStatus && data.educationStatus.value ? data.educationStatus.value : null
            data.employmentStatusId = data.employmentStatus && data.employmentStatus.value ? data.employmentStatus.value : null
            let _profile = GeneralUtils.nullIfEmptyForObjectFields({ ...data })

            profileService.saveExtendedProfile(_profile)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const handleTextValidation = (event) => {
        let charCode = event.nativeEvent.data;
        if (/^[a-zA-Z]+$/.test(charCode)) {
            event.target.value = event.target.value.toUpperCase();
            formik.handleChange(event);
        }
    }

    const handleNumberValidation = (event) => {
        let charCode = event.nativeEvent.data;
        if (charCode === null || /^[0-9\b]+$/.test(charCode)) {
            formik.handleChange(event);
        }
    }

    const handleListChange = async (data, field) => {
        // console.log("handleListChange")
        formik.setFieldValue(field, data.value)
    }

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredentityLookups(_filteredItems);
    }


    return (
        <>
            <div className="flex flex-column align-items-center">
                <div className="col-12">
                    <form id="profile-form" onSubmit={formik.handleSubmit}>
                        <div className='grid'>
                            {/* ID data */}
                            {props?.editSection === 'IDENTITY' &&
                                <div className="col-12 pb-0 pt-0">
                                    <div className='grid'>
                                        <div className="col-12 md:col-6">
                                            <div className="p-inputgroup">
                                                <span className="p-float-label">
                                                    <AutoComplete
                                                        dropdown
                                                        id="idStatusId"
                                                        name="idStatusId"
                                                        value={formik.values.idStatus}
                                                        suggestions={filteredentityLookups}
                                                        completeMethod={(e) => searchListItems(e, idStatuses)}
                                                        virtualScrollerOptions={{ itemSize: 38 }}
                                                        field="label"
                                                        onChange={(d) => handleListChange(d, "idStatus")}
                                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('idStatusId') })}
                                                    />
                                                    <label htmlFor="idStatusId" className={classNames({ 'p-error': isFormFieldValid('idStatusId') })}>
                                                        {translatedMessage("profile.idStatus")} *
                                                    </label>
                                                </span>
                                            </div>
                                            {getFormErrorMessage('idStatusId')}
                                        </div>

                                        <div className="col-12 md:col-6">
                                            <div className="p-inputgroup">
                                                <span className="p-float-label">
                                                    <Calendar id="idCardExpiryDate" name="idCardExpiryDate" value={formik.values.idCardExpiryDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                                        className={classNames({ 'p-invalid': isFormFieldValid('idCardExpiryDate') })} />
                                                    <label htmlFor="idCardExpiryDate" className={classNames({ 'p-error': isFormFieldValid('idCardExpiryDate') })}>
                                                        {translatedMessage("profile.idCardExpiryDate")}
                                                    </label>
                                                </span>
                                            </div>
                                            {getFormErrorMessage('idCardExpiryDate')}
                                        </div>

                                        <div className="col-12 md:col-6">
                                            <div className="p-inputgroup mb-2">
                                                <span className="p-float-label">
                                                    <InputText type="text" name="idCardSeries" id="idCardSeries"
                                                        value={formik.values.idCardSeries} onChange={handleTextValidation}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('idCardSeries') })}
                                                        maxLength={2} />
                                                    <label htmlFor="idCardSeries" className={classNames({ 'p-error': isFormFieldValid('idCardSeries') })}>
                                                        {translatedMessage("profile.idCardSeries")}
                                                    </label>
                                                </span>
                                            </div>
                                            {getFormErrorMessage('idCardSeries')}
                                        </div>

                                        <div className="col-12 md:col-6">
                                            <div className="p-inputgroup mb-2">
                                                <span className="p-float-label">
                                                    <InputText type="text" name="idCardNumber" id="idCardNumber"
                                                        value={formik.values.idCardNumber} onChange={handleNumberValidation}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('idCardNumber') })}
                                                        maxLength={6} />
                                                    <label htmlFor="idCardNumber" className={classNames({ 'p-error': isFormFieldValid('idCardNumber') })}>
                                                        {translatedMessage("profile.idCardNumber")}
                                                    </label>
                                                </span>
                                            </div>
                                            {getFormErrorMessage('idCardNumber')}
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* Health data */}
                            {props?.editSection === 'HEALTH' &&
                                <>
                                    <div className="col-12 md:col-6">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <AutoComplete
                                                    dropdown
                                                    id="healthStatusId"
                                                    name="healthStatusId"
                                                    value={formik.values.healthStatus}
                                                    suggestions={filteredentityLookups}
                                                    completeMethod={(e) => searchListItems(e, healthConditions)}
                                                    virtualScrollerOptions={{ itemSize: 38 }}
                                                    field="label"
                                                    onChange={(d) => handleListChange(d, "healthStatus")}
                                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('healthStatusId') })}
                                                />
                                                <label htmlFor="healthStatusId" className={classNames({ 'p-error': isFormFieldValid('healthStatusId') })}>
                                                    {translatedMessage("profile.extended.healthStatus")}
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('healthStatusId')}
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <AutoComplete
                                                    dropdown
                                                    id="disabilityDegreeId"
                                                    name="disabilityDegreeId"
                                                    value={formik.values.disabilityDegree}
                                                    suggestions={filteredentityLookups}
                                                    completeMethod={(e) => searchListItems(e, disabilityDegrees)}
                                                    virtualScrollerOptions={{ itemSize: 38 }}
                                                    field="label"
                                                    onChange={(d) => handleListChange(d, "disabilityDegree")}
                                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('disabilityDegreeId') })}
                                                />
                                                <label htmlFor="disabilityDegreeId" className={classNames({ 'p-error': isFormFieldValid('disabilityDegreeId') })}>
                                                    {translatedMessage("profile.extended.disabilityDegree")}
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('disabilityDegreeId')}
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <AutoComplete
                                                    dropdown
                                                    id="disabilityTypeId"
                                                    name="disabilityTypeId"
                                                    value={formik.values.disabilityType}
                                                    suggestions={filteredentityLookups}
                                                    completeMethod={(e) => searchListItems(e, disabilityTypes)}
                                                    virtualScrollerOptions={{ itemSize: 38 }}
                                                    field="label"
                                                    onChange={(d) => handleListChange(d, "disabilityType")}
                                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('disabilityTypeId') })}
                                                />
                                                <label htmlFor="disabilityTypeId" className={classNames({ 'p-error': isFormFieldValid('disabilityTypeId') })}>
                                                    {translatedMessage("profile.extended.disabilityType")}
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('disabilityTypeId')}
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <AutoComplete
                                                    dropdown
                                                    id="diseaseTypeId"
                                                    name="diseaseTypeId"
                                                    value={formik.values.diseaseType}
                                                    suggestions={filteredentityLookups}
                                                    completeMethod={(e) => searchListItems(e, diseaseTypes)}
                                                    virtualScrollerOptions={{ itemSize: 38 }}
                                                    field="label"
                                                    onChange={(d) => handleListChange(d, "diseaseType")}
                                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('diseaseTypeId') })}
                                                />
                                                <label htmlFor="diseaseTypeId" className={classNames({ 'p-error': isFormFieldValid('diseaseTypeId') })}>
                                                    {translatedMessage("profile.extended.diseaseType")}
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('diseaseTypeId')}
                                    </div>
                                </>
                            }

                            {/* Education data */}
                            {props?.editSection === 'EDUCATION' &&
                                <>
                                    <div className="col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <AutoComplete
                                                    dropdown
                                                    id="educationLevelId"
                                                    name="educationLevelId"
                                                    value={formik.values.educationLevel}
                                                    suggestions={filteredentityLookups}
                                                    completeMethod={(e) => searchListItems(e, edLevels)}
                                                    virtualScrollerOptions={{ itemSize: 38 }}
                                                    field="label"
                                                    onChange={(d) => handleListChange(d, "educationLevel")}
                                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('educationLevelId') })}
                                                />
                                                <label htmlFor="educationLevelId" className={classNames({ 'p-error': isFormFieldValid('educationLevelId') })}>
                                                    {translatedMessage("profile.extended.educationLevel")}
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('educationLevelId')}
                                    </div>
                                </>
                            }

                            {/* Employment data */}
                            {props?.editSection === 'EMPLOYMENT' &&
                                <>
                                    <div className="col-12 md:col-6">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <AutoComplete
                                                    dropdown
                                                    id="employmentStatusId"
                                                    name="employmentStatusId"
                                                    value={formik.values.employmentStatus}
                                                    suggestions={filteredentityLookups}
                                                    completeMethod={(e) => searchListItems(e, empStatuses)}
                                                    virtualScrollerOptions={{ itemSize: 38 }}
                                                    field="label"
                                                    onChange={(d) => handleListChange(d, "employmentStatus")}
                                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('employmentStatusId') })}
                                                />
                                                <label htmlFor="employmentStatusId" className={classNames({ 'p-error': isFormFieldValid('employmentStatusId') })}>
                                                    {translatedMessage("profile.extended.employmentStatus")}
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('employmentStatusId')}
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <InputText type="text" name="employerName" id="employerName"
                                                    value={formik.values.employerName} onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('employerName') })} />
                                                <label htmlFor="employerName" className={classNames({ 'p-error': isFormFieldValid('employerName') })}>
                                                    {translatedMessage("profile.extended.employerName")}
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('employerName')}
                                    </div>
                                </>
                            }

                            {/* Employment data */}
                            {props?.editSection === 'MARITAL' &&
                                <div className="col-12 md:col-6">
                                    <div className="p-inputgroup">
                                        <span className="p-float-label">
                                            <Dropdown id="maritalStatus" name="maritalStatus" value={formik.values.maritalStatus}
                                                options={maritalStatuses} onChange={(e) => formik.setFieldValue("maritalStatus", e.value)} 
                                                className={classNames({ 'p-invalid': isFormFieldValid('maritalStatus') })}/>
                                            <label htmlFor="maritalStatus" className={classNames({ 'p-error': isFormFieldValid('maritalStatus') })}>
                                                {translatedMessage("profile.extended.maritalStatus")} *
                                            </label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('maritalStatus')}
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ProfileExtendedEditComponent;
