import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import useNavProps from '../../hooks/useNavProps';
import GeneralUtils from '../../utilities/GeneralUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from '../../service/UserService';
import { HearingService } from '../../service/HearingService';
import HearingToolbar from './HearingToolbar';
import HearingEditDialogComponent from '../../components/hearing/HearingEditDialogComponent';

const HearingView = () => {
    const [hearing, setHearing] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const toast = useRef(null);

    const hearingService = useMemo(() => new HearingService(), []);

    const { hearingIdParam } = useParams();
    const navigate = useNavigate();
    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', hearingIdParam));
        // };        

        const getHearing = async () => {
            await hearingService.getHearing(hearingIdParam)
                .then(async (_data) => {
                    setHearing(_data);

                    // await checkEditPermission()

                    setNavPropsValue('hearing', _data.id, _data.firstName + ' ' + _data.lastName)

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getHearing();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hearingService, hearingIdParam]);

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <HearingToolbar hearing={hearing} code="DETAIL" />


                            <div className='w-full grid pcn-data-view-panel mb-5'>
                                <div className='col-12 flex align-items-center'>
                                    <span className='entity-view-subtitle mr-2'>{translatedMessage("hearing.details")}</span>
                                    <Button
                                        text
                                        className="pcn-button-slim p-button p-button-icon-only"
                                        onClick={() => setShowEditDialog(true)}
                                        visible={hasPermission('HEARING_EDIT')}
                                        disabled={!hearing?.actions?.ADD_HEARING}
                                    >
                                        <div className='flex align-items-center'>
                                            <FontAwesomeIcon icon='fa-solid fa-pencil' />
                                        </div>
                                    </Button>
                                </div>

                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('generic.firstName')}</div>
                                    <div className='filed-value'>{hearing.firstName}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('generic.lastName')}</div>
                                    <div className='filed-value'>{hearing.lastName}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('profile.pin')}</div>
                                    <div className='filed-value'>{hearing.pin}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('profile.birthday')}</div>
                                    <div className='filed-value'>{GeneralUtils.formatDate(hearing.birthday)}</div>
                                </div>
                                <div className='col-12'>
                                    <div className='filed-label'>{translatedMessage('hearing.homeAddress')}</div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(hearing.homeAddress)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.motherName')}</div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(hearing.motherName)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.fatherName')}</div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(hearing.fatherName)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.type')}</div>
                                    <div className='filed-value'>{hearing.type ? translatedMessage('HearingType.'+hearing.type) : "-"}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.offenseName')}</div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(hearing.offenseName)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.hearingLocation')}</div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(hearing.hearingLocation)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.hearingSpace')}</div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(hearing.hearingSpace)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.conclusion')}</div>
                                    <div className='filed-value'>{hearing.conclusion.label}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.date')}</div>
                                    <div className='filed-value'>{GeneralUtils.formatDate(hearing.date)}</div>
                                </div>
                                <div className='col-12'>
                                    <div className='filed-label'>{translatedMessage('hearing.comment')}</div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(hearing.comment)}</div>
                                </div>                                
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.notice')}</div>
                                    <div className='filed-value'>{
                                        hearing.notice && hasPermission('NOTICE_VIEW')
                                            ? <Button text label={hearing.notice?.noticeType?.label + " / " + GeneralUtils.formatDate(hearing.notice?.noticeDate) + 
                                            (hearing.notice?.beneficiary ? (" - " + hearing.notice?.beneficiary?.profile?.fullName) : "")} className="aspc-button-filed-text p-button"
                                                onClick={() => navigate(`/notice/${hearing.notice.id}`)} />
                                            : hearing.notice?.subject || '-'
                                    }</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('hearing.responsible')}</div>
                                    <div className='filed-value'>{
                                        hearing.organizationProfile && hasPermission('PROFILE_VIEW')
                                            ? <Button text label={hearing.organizationProfile?.profile?.fullName} className="aspc-button-filed-text p-button"
                                                onClick={() => navigate(`/profile/${hearing.organizationProfile?.profile?.id}`)} />
                                            : hearing.organizationProfile?.profile?.fullName || '-'
                                    }</div>
                                </div>                                                                
                            </div>                            
                        </div>
                    </div>
                </div>

                <HearingEditDialogComponent
                    value={hearing}
                    visible={showEditDialog}
                    afterSave={(savedData) => setHearing(savedData)}
                    visibleSetter={() => setShowEditDialog(false)}
                />
            </>
        );
    }
};

export default HearingView;
