import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import BeneficiaryEditProfileExtendedComponent from '../../components/profile/BeneficiaryEditProfileExtendedComponent';

const BeneficiaryEditProfileExtendedDialog = (props) => {
    const [profile, setProfile] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        let _profile = props.value ? props.value : null;
        setProfile(_profile);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            setProfile(null)
            props.visibleSetter(false);
        }
    };

    const afterSave = async (savedData, isSuccess, errorMessage = null) => {
        if (typeof props.afterSave === 'function') {            
            if(isSuccess) {
                props.afterSave(savedData);
                toast.current.show({ severity: 'success', summary: translatedMessage("beneficiary.save.success"), life: 3000 });                   
            } else {
                toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
            }
        }
        hideDialog()
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="profile-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage(props?.dialogTitle) +  " - " + profile?.fullName}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <BeneficiaryEditProfileExtendedComponent 
                    value={profile}
                    afterSave={afterSave}
                    editSection={props?.editSection}
                />
            </Dialog>
        </>);
};

export default BeneficiaryEditProfileExtendedDialog;
