import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useNavProps from '../../hooks/useNavProps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import OrganizationProfileListComponent from "../../components/organizationProfile/OrganizationProfileListComponent";
import { ProviderService } from '../../service/ProviderService';
import ProviderToolbar from './ProviderToolbar';

const ProviderProfilesView = () => {
    const [provider, setProvider] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const providerService = useMemo(() => new ProviderService(), []);

    const { providerIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getProvider = async () => {
            await providerService.getProvider(providerIdParam)
                .then(async (_data) => {
                    setProvider(_data);
                    setNavPropsValue('provider', _data.id, _data.name);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getProvider();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerService, providerIdParam]);

    return (
        <>
            <Toast ref={toast} />
            {isLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <ProviderToolbar provider={provider} code="PROFILES" />

                                <OrganizationProfileListComponent
                                    organization={provider}
                                    pageTitle={translatedMessage("provider.experts")}
                                    organizationType="PROVIDER"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ProviderProfilesView;
