import { translatedMessage } from "../service/LanguageService";
import GeneralUtils from "./GeneralUtils";
import ProfileExportDataUtils from "./ProfileExportDataUtils";

const BeneficiaryExportDataUtils = {

  beneficiarySortOrderHeader() {
    return (
      [        
        "lastName", "firstName", "registrationNumber", "registrationDate", // "hasPin",
        "pin", "birthCounty", "birthEnvironmentType", 
        "birthday", "age", "gender", 
        "departmentName", "responsibleName", "assistantName", "status",
        "idStatusLabel", "idCardSeries", "idCardNumber", "idCardExpiryDate",
        "canBeAdopted",
        "healthStatusLabel", "disabilityDegreeLabel", "disabilityTypeLabel", "diseaseTypeLabel",
        "needsSchoolGuidance",
        "educationLevelLabel", "educationStatusLabel", "schoolName", "schoolGrade",
        "employmentStatusLabel", "employerName", "createdOn"
      ]
    )
  },

  beneficiaryTableHeader() {
    return (
      [        
        translatedMessage("generic.lastName"),
        translatedMessage("generic.firstName"),
        translatedMessage("beneficiary.registrationNumber"),
        translatedMessage("beneficiary.registrationDate"),
        // translatedMessage("profile.hasPin"),
        translatedMessage("profile.pin"),
        translatedMessage("profile.birthCounty"),
        translatedMessage("profile.birthEnvironmentType"),
        translatedMessage("profile.birthday"),
        translatedMessage("profile.age"),
        translatedMessage("profile.gender"),
        translatedMessage("beneficiary.department"),
        translatedMessage("beneficiary.responsible"),
        translatedMessage("beneficiary.socialAssistant"),
        translatedMessage("beneficiary.status"),        
        translatedMessage("profile.idStatus"),
        translatedMessage("profile.idCardSeries"),
        translatedMessage("profile.idCardNumber"),
        translatedMessage("profile.idCardExpiryDate"),
        translatedMessage("profile.extended.canBeAdopted"),
        translatedMessage("profile.extended.healthStatus"),
        translatedMessage("profile.extended.disabilityDegree"),
        translatedMessage("profile.extended.disabilityType"),
        translatedMessage("profile.extended.diseaseType"),
        translatedMessage("profile.extended.needsSchoolGuidance"),
        translatedMessage("profile.extended.educationLevel"),
        translatedMessage("profile.extended.educationStatus"),
        translatedMessage("profile.extended.schoolName"),
        translatedMessage("profile.extended.schoolGrade"),
        translatedMessage("profile.extended.employmentStatus"),
        translatedMessage("profile.extended.employerName"),
        translatedMessage("generic.created.on"),
      ]
    )
  }, 

  computeExportFields(_beneficiary) {
    let exportData = ProfileExportDataUtils.computeExtendedExportFields(_beneficiary.profile);
    exportData.departmentName = _beneficiary.departmentName
    exportData.responsibleName = _beneficiary.responsibleName
    exportData.assistantName = _beneficiary.assistantName
    exportData.registrationNumber = _beneficiary.registrationNumber
    exportData.registrationDate = GeneralUtils.formatDate(_beneficiary.registrationDate)
    exportData.createdOn = GeneralUtils.formatDate(_beneficiary.createdOn)
    exportData.status = translatedMessage("BeneficiaryStatus." + _beneficiary.status)

    return exportData
  },
};

export default BeneficiaryExportDataUtils;