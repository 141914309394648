import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import GeneralUtils from '../../utilities/GeneralUtils';
import { BeneficiaryService } from '../../service/BeneficiaryService';

const BeneficiaryResponsibleHistoryDialogComponent = (props) => {
    const [beneficiaryResponsibles, setBeneficiaryResponsibles] = useState([]);

    const dt = useRef(null);

    const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

    useEffect(() => {
        const getResponsibles = async () => {
            await beneficiaryService.getBeneficiaryResponsibleHistory(props?.beneficiary.id, props?.type)
                .then(response => {
                    setBeneficiaryResponsibles(response)
                })
        }

        if (props?.visible) {
            getResponsibles()
        }
    }, [beneficiaryService, props]);

    const hideDialog = () => {
        setBeneficiaryResponsibles([])
        props.visibleSetter()
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <span className={`pill status status-${rowData.status.toString().toLowerCase()}`}>
                {translatedMessage('BeneficiaryResponsibleStatus.' + rowData.status)}
            </span>
        )
    }

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    return (
        <>
            <Dialog
                visible={props.visible}
                header={props?.title ? props.title : translatedMessage('beneficiary.responsible.history')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <DataTable
                    ref={dt}
                    value={beneficiaryResponsibles}
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive pcn-datatable"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                >
                    <Column
                        field="organizationProfile.profile.fullName"
                        header={translatedMessage("generic.name")}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '5rem' }}
                    />
                    <Column
                        field="status"
                        header={translatedMessage("generic.status")}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '5rem' }}
                        body={statusBodyTemplate}
                    />
                    <Column
                        field="lastModifiedOn"
                        header={translatedMessage("generic.lastUpdated.on")}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '5rem' }}
                        body={e => GeneralUtils.formatDateTime(e.lastModifiedOn)}
                    />                    
                </DataTable>
            </Dialog>
        </>
    );
};

export default BeneficiaryResponsibleHistoryDialogComponent;
