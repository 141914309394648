import GeneralUtils from "../utilities/GeneralUtils";
import ApiService from "./ApiService";

export class NoticeService {

    async getNoticesByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/notice/list`, criteria)
                .then((response) => {
                    response.data.items.map(item => {
                        item.noticeDate = item.noticeDate ? new Date(Date.parse(item.noticeDate)) : '';
                        return item;
                    })
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getNotice(noticeId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/notice/${noticeId}`)
                .then(response => {
                    response.data.noticeDate = response.data.noticeDate ? new Date(Date.parse(response.data.noticeDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveNotice(notice) {
        let _reporterProfile = notice.reporterProfile ? { id: notice.reporterProfile?.id } : null;
        let _beneficiary = notice.beneficiary ? { id: notice.beneficiary?.id } : null;
        let _organizationProfile = notice.organizationProfile ? { id: notice.organizationProfile?.id } : null;
        let _notice = GeneralUtils.nullIfEmptyForObjectFields({ ...notice, beneficiary: _beneficiary, reporterProfile: _reporterProfile, organizationProfile: _organizationProfile })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/notice`, _notice)
                .then(response => {
                    response.data.noticeDate = response.data.noticeDate ? new Date(Date.parse(response.data.noticeDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteNotice(noticeId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/notice/${noticeId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }

}