import React from 'react';

import { translatedMessage } from '../service/LanguageService';

const ErrorMessageDisplayComponent = (props) => {

  return (
      <div className="col-12">
        <div className="card">
          <div className='w-full text-align-left mt-3 mb-3 '>
            <h5>{translatedMessage(props.message)}</h5>
          </div>
        </div>
      </div>

  );
};

export default ErrorMessageDisplayComponent;
