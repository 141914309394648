import React, { useEffect, useState, useMemo, useRef } from 'react';
import { translatedMessage } from '../../service/LanguageService';
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';
import DataTableUtils from '../../utilities/DataTableUtils';
import { MonitoringService } from '../../service/MonitoringService';
import GeneralUtils from "../../utilities/GeneralUtils";

const MonitoringAutocompleteFieldComponent = (props) => {
    const [monitoring, setMonitoring] = useState()
    const [monitoringList, setMonitoringList] = useState([])
    const [validationError, setValidationError] = useState(false);

    const toast = useRef(null);
    const monitoringService = useMemo(() => new MonitoringService(), [])

    useEffect(() => {
        const getData = async () => {
            await search()
        }
        getData()

        setValidationError(props?.validationError)
        if (props?.selectedMonitoring) {
            let _selectedMonitoring = { ...props.selectedMonitoring }
            _selectedMonitoring.displayName = getMonitoringDisplayName(_selectedMonitoring)

            setMonitoring(_selectedMonitoring)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const getMonitoringDisplayName = (_monitoring) => {
        return _monitoring?.type?.label + ' (' + GeneralUtils.formatDate(_monitoring?.startDate) + ' - ' + GeneralUtils.formatDate(_monitoring?.endDate) + ") - " + _monitoring?.beneficiary.profile.fullName;
    }

    const search = async (e = null) => {
        let criteria = {}
        criteria.pageSize = DataTableUtils.maxRowsForSearch();

        criteria.notParentMonitoringId = props?.parentMonitoring?.id ? props?.parentMonitoring?.id : null;
        criteria.beneficiaryId = props?.parentMonitoring?.beneficiary?.id ? props?.parentMonitoring?.beneficiary?.id : null;

        await monitoringService.getMonitoringsByCriteria(criteria)
            .then((data) => {
                data.items.map(item => {
                    item.displayName = getMonitoringDisplayName(item);
                    return item;
                })
                let _items = data.items.filter(item => !e?.query || item.displayName.indexOf(e.query) !== -1)
                setMonitoringList(_items);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const handleMonitoringChange = async (_selected) => {
        setMonitoring(_selected)

        if (_selected?.id && typeof props.setMonitoring === 'function') {
            //console.log("_selected: ", _selected)
            props.setMonitoring(_selected);
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="col-12 flex flex-row">
                <div className='flex-grow-1'>
                    <div className="p-inputgroup">
                        <span className="p-float-label">
                            <AutoComplete
                                dropdown
                                id="monitoring"
                                name="monitoring"
                                value={monitoring}
                                suggestions={monitoringList}
                                completeMethod={(e) => search(e)}
                                virtualScrollerOptions={{ itemSize: 38 }}
                                field={"displayName"}
                                onChange={(d) => handleMonitoringChange(d.value)}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                className={validationError ? 'p-invalid' : ''}
                                disabled={props?.disabled}
                            />
                            <label htmlFor="monitoring" className={validationError ? 'p-error' : ''}>
                                {props?.title ? props?.title : translatedMessage("monitoring.search")}
                            </label>
                        </span>
                    </div>
                    <small className={"p-error text-align-left mt-1 ".concat(validationError ? "block" : "hidden")}>
                        {translatedMessage("form.error.monitoring.required")}
                    </small>
                </div>
            </div>
        </>
    );
};

export default MonitoringAutocompleteFieldComponent;
