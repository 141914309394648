import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../service/LanguageService';

const DeleteModalComponent = (props) => {

  const dialogDeleteFooter = (
    <>
      <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
      <Button label={translatedMessage("generic.delete")} icon="pi pi-check" className="p-button-text" onClick={() => props.deleteRecord()} />
    </>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: '450px' }}
      header={translatedMessage("generic.delete.confirmation.title")}
      modal
      footer={dialogDeleteFooter}
      onHide={() => props.closeDialog()}
    >
      <div className="flex align-items-center">
        <i className="pi pi-exclamation-triangle mr-3 text-red-500" style={{ fontSize: '3rem' }} />
        <div className='w-full text-align-left align-items-center'>
          <div className="mb-2">{translatedMessage("generic.delete.confirmation.message")}</div>
          {props.item &&
            <div className="font-bold text-lg">{props.item}</div>
          }
          {props.info &&
            <div className="mt-1">{translatedMessage(props.info)}</div>
          }
        </div>
      </div>
    </Dialog>

  );
};

export default DeleteModalComponent;
