import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import LanguageService, { translatedMessage } from '../../service/LanguageService';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';

import GeneralUtils from '../../utilities/GeneralUtils';
import { AmpService } from '../../service/AmpService';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import BeneficiaryAutocompleteFieldComponent from '../profile/BeneficiaryAutocompleteFieldComponent';
import AmpAutocompleteFieldComponent from './AmpAutocompleteFieldComponent';

let emptyRecord = {
    id: null,
    amp: null,
    beneficiary: null,
    startDate: '',
    endDate: '',
    comment: ''
};

const AmpBeneficiaryEditComponent = (props) => {
    const [ampBeneficiary, setAmpBeneficiary] = useState(emptyRecord)

    const ampService = new AmpService();

    useEffect(() => {
        const computeAmp = async () => {
            let _ampBeneficiary = emptyRecord
            if (props?.value) {
                _ampBeneficiary = GeneralUtils.emptyInNullForObjectFields(props.value)
            }

            if (props?.amp) {
                _ampBeneficiary.amp = props.amp
            }

            if (props?.beneficiary) {
                _ampBeneficiary.beneficiary = props.beneficiary
            }

            setAmpBeneficiary(_ampBeneficiary)
        }

        computeAmp()
    }, [props]);

    const formik = useFormik({
        initialValues: ampBeneficiary ? ampBeneficiary : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.startDate) {
                errors.startDate = translatedMessage("form.error.startDate.required");
            }

            if (data.endDate && data.startDate >= data.endDate) {
                errors.endDate = translatedMessage("form.error.endDate.toEarly");
            }

            if (!data.beneficiary) {
                errors.beneficiary = translatedMessage("form.error.beneficiary.required");
            }

            if (!data.amp) {
                errors.amp = translatedMessage("form.error.amp.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            // console.log("save amp beneficiary: ", data)
            let isNew = data?.id ? false : true

            ampService.saveAmpBeneficiary(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, isNew, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const onEditorChange = (content) => {
        formik.setFieldValue("comment", content)
    }

    const handleProfileChange = async (_existingProfile) => {
        setAmpBeneficiary({ ...formik.values, amp: _existingProfile })
    }

    const handleBeneficiaryChange = async (_beneficiary) => {
        setAmpBeneficiary({ ...formik.values, beneficiary: _beneficiary })
    }

    return (
        <>
            <div className="col-12">
                <form id="ampBeneficiary-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>

                        <AmpAutocompleteFieldComponent
                            amp={ampBeneficiary?.amp}
                            setAmp={(e) => handleProfileChange(e)}
                            validationError={isFormFieldValid('amp')}
                            disabled={props?.amp}
                            title={translatedMessage("amp.amp")}
                        />

                        <BeneficiaryAutocompleteFieldComponent
                            beneficiary={ampBeneficiary?.beneficiary}
                            setBeneficiary={(e) => handleBeneficiaryChange(e)}
                            validationError={isFormFieldValid('beneficiary')}
                            disabled={props?.beneficiary}
                        />

                        <div className="col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="startDate" name="startDate" value={formik.values.startDate}
                                        onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                        className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} />
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                        {translatedMessage("generic.startDate")} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('startDate')}
                        </div>

                        <div className="col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="endDate" name="endDate" value={formik.values.endDate}
                                        onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                        className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} />
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                        {translatedMessage("generic.endDate")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('endDate')}
                        </div>

                        <div className="col-12">
                            <div className='filed-label mb-1'>{translatedMessage('amp.comments')}</div>
                            <SunEditor
                                id="comment" name="comment"
                                lang={LanguageService.getCurrentLanguage}
                                height="250px"
                                width='100%'
                                setContents={formik.values.comment}
                                setOptions={{
                                    buttonList: GeneralUtils.getSunEditorButtons(),
                                    font: GeneralUtils.getSunEditorFonts(),
                                    defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                }}
                                onChange={onEditorChange}
                            />
                            {getFormErrorMessage('comment')}
                        </div>


                    </div>
                </form>
            </div>
        </>
    );
};

export default AmpBeneficiaryEditComponent;
