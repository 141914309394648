import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { translatedMessage } from '../../service/LanguageService';

import { Toast } from 'primereact/toast';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { HearingService } from '../../service/HearingService';
import HearingToolbar from './HearingToolbar';

const HearingFileList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [hearing, setBeneficiary] = useState(null);
    const [error] = useState({ isError: false, message: '' });
    const toast = useRef(null);

    const hearingService = useMemo(() => new HearingService(), []);

    const { hearingIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        // };       

        const getBeneficiary = async () => {
            await hearingService.getHearing(hearingIdParam)
                .then(async (_data) => {
                    setBeneficiary(_data);

                    // await checkEditPermission()

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getBeneficiary();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hearingService, hearingIdParam]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <div className="grid h-full">
                <Toast ref={toast}></Toast>                            
                <div className="col-12">
                    <div className="card h-full">
                        <HearingToolbar hearing={hearing} code="FILES" />

                        <StorageFolderFileList
                            folderId={hearing?.folder?.id}
                            folderName={hearing?.profile?.fullName}
                            showUploadButton={true}
                            pageTitle={translatedMessage("hearing.files")}
                            multiple={false}
                            hasFileProperties={true}
                            readOnly={!hearing?.actions?.ADD_HEARING}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default HearingFileList;
