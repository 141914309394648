import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useNavProps from '../../hooks/useNavProps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { CenterService } from '../../service/CenterService';
import CenterToolbar from './CenterToolbar';
import ServiceListComponent from '../../components/service/ServiceListComponent';

const CenterServicesView = () => {
    const [center, setCenter] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const centerService = useMemo(() => new CenterService(), []);

    const { centerIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getCenter = async () => {
            await centerService.getCenter(centerIdParam)
                .then(async (_data) => {
                    setCenter(_data);
                    setNavPropsValue('center', _data.id, _data.name);
                    setIsLoading(false);

                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCenter();
    }, [centerService, centerIdParam]);

    return (
        <>
            <Toast ref={toast} />
            {isLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <CenterToolbar center={center} code="SERVICES" />
                                <ServiceListComponent center={center} organization={null} pageTitle={translatedMessage("center.services")} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CenterServicesView;
