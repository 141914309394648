import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { translatedMessage } from '../../service/LanguageService';
import EnumService from "../../service/EnumService";

let emptyRecord = {
    id: null,
    county: null,
    locality: null,
    home: false,
    residence: false,
    sirutaCode: null,
    address: '',
    sameAsHome: false
};

const AddressEditDialogComponent = (props) => {
    const [address, setAddress] = useState(emptyRecord);
    const [isNew, setIsNew] = useState(false);
    const [counties, setCounties] = useState([]);
    const [localities, setLocalities] = useState([]);
    const [filteredEntityLookups, setFilteredEntityLookups] = useState([]);
    const [environmentTypeOptions, setEnvironmentTypeOptions] = useState([]);
    const [disableForm, setDisableForm] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        let _address = props.value ? props.value : emptyRecord;

        if (_address?.county) {
            _address.county = { ..._address.county, value: _address.county.id }
        }

        if (_address?.locality) {
            _address.locality = { ..._address.locality, value: _address.locality.id }
        }

        if (!props.value) {
            if (props.addressType === 'HOME') {
                _address.home = true
                _address.residence = false
            } else if (props.addressType === 'RESIDENCE') {
                _address.home = false
                _address.residence = true
            } else {
                _address.home = false
                _address.residence = false
            }
        }

        setAddress(_address)
        setIsNew(props.value ? false : true)
        const getEnvironmentTypes = async () => {
            setEnvironmentTypeOptions(await EnumService.getEnumByName("EnvironmentType"));
        };

        const getCounties = async () => {
            const _counties = await EnumService.getEntityLookupByField("NOM:COUNTY")
            setCounties(_counties)
        };

        const getLocalities = async (countyId) => {
            if (countyId) {
                const _localities = await EnumService.getEntityLookupWithParentByParentId(countyId)
                setLocalities(_localities)
            }
        }

        getCounties();
        getLocalities(_address?.county?.id);
        getEnvironmentTypes();

    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setAddress(emptyRecord)
        setDisableForm(false)
        formik.resetForm();
    };

    const saveAddress = (_address) => {
        if (!disableForm) {
            _address.county.id = _address.county?.value
            _address.locality.id = _address.locality?.value
        }

        if (typeof props.afterSave === 'function') {
            props.afterSave(_address, isNew)
        }

        hideDialog()
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: address,
        validate: (data) => {
            let errors = {};

            if (!disableForm) {
                if (!data.county || !data.county.value) {
                    errors.county = translatedMessage('form.error.county.required');
                }
                if (!data.locality || !data.locality.value) {
                    errors.locality = translatedMessage('form.error.locality.required');
                }
                if (!data.address) {
                    errors.address = translatedMessage('form.error.address.required');
                }
                if (props?.hasHomeAndResidence && !data.home && !data.residence) {
                    errors.home = translatedMessage('form.error.addressType.required');
                    errors.residence = translatedMessage('form.error.addressType.required');
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            saveAddress(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredEntityLookups(_filteredItems);
    }

    const handleListChange = async (data, field) => {
        //console.log("handleListChange", data, field);
        formik.setFieldValue(field, data.value)

        if (data.originalEvent.type === 'click') {
            if (field === 'county') {
                const response = await EnumService.getEntityLookupWithParentByParentId(data.value.value)
                setLocalities(response)
                formik.setFieldValue('locality', null)
            }
        }
    }

    const handleSameAsHome = async (data) => {
        formik.setFieldValue("sameAsHome", data.checked)
        setDisableForm(data.checked)
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('address.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        {props?.hasHomeAndResidence && address.residence && props.homeAddress &&
                            <div className='col-12 mb-2'>
                                <div className="p-inputgroup">
                                    <span>
                                        <Checkbox inputId="sameAsHome" name="sameAsHome" checked={formik.values.sameAsHome}
                                            onChange={handleSameAsHome} className={classNames({ 'p-invalid': isFormFieldValid('sameAsHome') })} />
                                        <label htmlFor="sameAsHome" className={"ml-2 text-form-label ".concat(classNames({ 'p-error': isFormFieldValid('sameAsHome') }))}>
                                            {translatedMessage("address.sameAsHome")}
                                        </label>
                                    </span>
                                </div>
                            </div>
                        }
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="county"
                                        name="county"
                                        value={formik.values.county}
                                        suggestions={filteredEntityLookups}
                                        completeMethod={(e) => searchListItems(e, counties)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="label"
                                        onChange={(d) => handleListChange(d, "county")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('county') })}
                                        disabled={disableForm}
                                    />
                                    <label htmlFor="county" className={classNames({ 'p-error': isFormFieldValid('county') })}>
                                        {translatedMessage("generic.county")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('county')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="locality"
                                        name="locality"
                                        value={formik.values.locality}
                                        suggestions={filteredEntityLookups}
                                        completeMethod={(e) => searchListItems(e, localities)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="label"
                                        onChange={(d) => handleListChange(d, "locality")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('locality') })}
                                        disabled={disableForm}
                                    />
                                    <label htmlFor="locality" className={classNames({ 'p-error': isFormFieldValid('locality') })}>
                                        {translatedMessage("generic.locality")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('locality')}
                        </div>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="address" name="address" value={formik.values.address} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('address') })} disabled={disableForm} />
                                    <label htmlFor="address" className={classNames({ 'p-error': isFormFieldValid('address') })}>
                                        {translatedMessage('address.address')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('address')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputNumber id="sirutaCode" name="sirutaCode" value={formik.values.sirutaCode} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('sirutaCode') })}
                                        mode="decimal" useGrouping={false} disabled={disableForm} />
                                    <label htmlFor="sirutaCode" className={classNames({ 'p-error': isFormFieldValid('sirutaCode') })}>
                                        {translatedMessage('address.sirutaCode')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('sirutaCode')}
                        </div>
                        <div className="col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown id="environmentType" name="environmentType" value={formik.values.environmentType}
                                        options={environmentTypeOptions} onChange={(e) => formik.setFieldValue("environmentType", e.value)}
                                        disabled={disableForm} />
                                    <label htmlFor="environmentType" className={classNames({ 'p-error': isFormFieldValid('environmentType') })}>
                                        {translatedMessage("address.environmentType")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('environmentType')}
                        </div>
                        {props?.hasHomeAndResidence &&
                            <>
                                <div className='col-12 md:col-6 mt-2'>
                                    <div className="p-inputgroup">
                                        <span>
                                            <Checkbox inputId="home" name="home" checked={formik.values.home} disabled={true}
                                                onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('home') })} />
                                            <label htmlFor="home" className={"ml-2 text-form-label ".concat(classNames({ 'p-error': isFormFieldValid('home') }))}>
                                                {translatedMessage("address.isHome")}
                                            </label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('home')}
                                </div>
                                <div className='col-12 md:col-6 mt-2'>
                                    <div className="p-inputgroup">
                                        <span>
                                            <Checkbox inputId="residence" name="residence" checked={formik.values.residence} disabled={true}
                                                onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('residence') })} />
                                            <label htmlFor="residence" className={"ml-2 text-form-label ".concat(classNames({ 'p-error': isFormFieldValid('residence') }))}>
                                                {translatedMessage("address.isResidence")}
                                            </label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('residence')}
                                </div>
                            </>
                        }
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default AddressEditDialogComponent;
