import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { hasPermission } from '../../service/UserService';
import GeneralUtils from '../../utilities/GeneralUtils';
import ServiceDataTableUtils from '../../utilities/ServiceDataTableUtils';
import OrganizationDataTableUtils from '../../utilities/OrganizationDataTableUtils';
import DataTableUtils from '../../utilities/DataTableUtils';

const ServiceSupplyViewDialogComponent = (props) => {
    const [sSupply, setSSupply] = useState(null);

    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (props?.visible) {
            setSSupply(props?.value)
        }
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const dialogHeader = (
        <>
            <div className='mb-1'>{translatedMessage('serviceSupply.details')}</div>
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={dialogHeader}
                modal
                className="pcn-dialog"
                footer={formDialogFooter}
                onHide={hideDialog}
            >

                <div className='w-full grid pcn-data-view-panel'>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('serviceSupply.service')}</div>
                        <div className='filed-value'>
                            {hasPermission('SERVICE_VIEW')
                                ? <Button text label={ServiceDataTableUtils.getServiceDisplayName(sSupply?.serviceExpertBeneficiary?.service)} className="aspc-button-filed-text p-button"
                                    onClick={() => navigate(`/service/${sSupply?.serviceExpertBeneficiary?.service?.id}/`)} />
                                : ServiceDataTableUtils.getServiceDisplayName(sSupply?.serviceExpertBeneficiary?.service)
                            }
                        </div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('serviceExpertBeneficiary.expert')}</div>
                        <div className='filed-value'>{OrganizationDataTableUtils.getOrganizationProfileDisplayName(sSupply?.serviceExpertBeneficiary?.organizationProfile)}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('beneficiary.beneficiary')}</div>
                        <div className='filed-value'>
                            {hasPermission('BENEFICIARY_VIEW')
                                ? <Button text label={sSupply?.serviceExpertBeneficiary?.beneficiary?.profile?.fullName} className="aspc-button-filed-text p-button"
                                    onClick={() => navigate(`/beneficiary/${sSupply?.serviceExpertBeneficiary?.beneficiary?.id}/`)} />
                                : sSupply?.serviceExpertBeneficiary?.beneficiary?.profile?.fullName
                            }
                        </div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('serviceExpertBeneficiary.venue')}</div>
                        <div className='filed-value'>{GeneralUtils.dashIfNullForObject(sSupply?.serviceExpertBeneficiary?.serviceVenue?.label)}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.startDate')}</div>
                        <div className='filed-value'>{GeneralUtils.formatDate(sSupply?.startDate)}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.endDate')}</div>
                        <div className='filed-value'>{GeneralUtils.formatDate(sSupply?.endDate)}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('serviceSupply.hasBeenDone')}</div>
                        <div className='filed-value'>{DataTableUtils.yesNoTemplate(sSupply?.hasBeenDone)}</div>
                    </div>                    
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('serviceSupply.description')}</div>
                        <div className='filed-value'>{GeneralUtils.dashIfNullForObject(sSupply?.description)}</div>
                    </div>
                </div>
            </Dialog>
        </>);
};

export default ServiceSupplyViewDialogComponent;