import axios from 'axios';
import { Base64 } from 'js-base64';

let token;
let authenticatedInstance = null;

// DEV API
//const baseURL = 'http://localhost:8080/rest';
// STAGE API
//const baseURL = 'https://test.esocial.ro/rest';
// PRODUCTION API
const baseURL = 'https://ph.esocial.ro/rest';

// DEV CAPTCHA
//const captchaKey = '6LfgwaMZAAAAABb8GbDskgQAOZat24YkMZyc1yVr';
// STAGE CAPTCHA
//const captchaKey = '6LeLgBEnAAAAAHUWKPEq-I0eUUQraUrMIPBXKMU4';
// PRODUCTION CAPTCHA
const captchaKey = '6LeLgBEnAAAAAHUWKPEq-I0eUUQraUrMIPBXKMU4';

// DEV & STAGE MoveCompetitionStatusForward 
//const isProduction = false
// PRODUCTION MoveCompetitionStatusForward
const isProduction = true

const ApiService = {
  getAuthenticatedInstance: () => authenticatedInstance,

  instance: axios.create({ baseURL }),

  loginInstance: (username, password) => axios.create({
    baseURL,
    headers: {
      "Authorization": `Basic ` + Base64.encode(username + ":" + password)
    },
  }),

  async initToken() {
    token = localStorage.getItem('_t');
    authenticatedInstance = axios.create({
      baseURL,
      headers: {
        "Authorization": `Bearer ${token}`
      },
    });
    return !!token;
  },

  async persistToken(newToken) {
    token = newToken;
    localStorage.setItem('_t', token);
    authenticatedInstance = axios.create({
      baseURL,
      headers: {
        "Authorization": `Bearer ${token}`
      },
    });
  },

  getToken: () => token,

  getBaseUrl: () => baseURL,

  getRedirectUrl: () => {
    return localStorage.getItem('_aspc_redirect');
  },

  setRedirectUrl: (path) => {
    if (typeof path !== 'undefined' && path !== null && path.length > 0 && path !== '/') {
      localStorage.setItem('_aspc_redirect', path);
    }
  },

  getSafe(fn, defaultVal) {
    try {
      return fn();
    } catch (e) {
      return defaultVal;
    }
  },

  getCaptchaSiteKey() {
    return captchaKey
  },

  getIsProduction() {
    return isProduction
  },

};

export default ApiService;
