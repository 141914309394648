import React, { useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { translatedMessage } from "../service/LanguageService";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const GenericTableView = (props) => {
    const toast = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
    }, [props]);

    const getColumnProperty = (index, property) => {
        let _column = props.columns[index]

        if (_column && !_column.isActionButton) {
            return _column[property];
        } else {
            if (property === 'hidden') {
                return true
            } else {
                return ''
            }
        }
    }

    const actionBodyTemplate = (rowData) => {
        let actionColumn = props.columns.filter(item => item.isActionButton)
        return (
            <>
                {actionColumn && actionColumn[0] &&
                    <div className="actions flex flex-wrap align-items-center justify-content-end">
                        <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1"
                            onClick={() => navigate(actionColumn[0].url(rowData))}
                            title={translatedMessage("generic.view")} />
                    </div>
                }
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <div className='w-full text-align-left'>
                <h5>{props.pageTitle ? props.pageTitle : translatedMessage("generic.list")}</h5>
            </div>
            <DataTable
                value={props.result.items}
                dataKey="id"
                paginator
                rows={5}
                rowsPerPageOptions={5}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                alwaysShowPaginator={false}
                loading={props.loading}
            >
                <Column
                    field={getColumnProperty(0, 'fieldName')}
                    header={translatedMessage(getColumnProperty(0, 'name'))}
                    headerStyle={{ minWidth: '10rem' }}
                    body={getColumnProperty(0, 'template')}
                    hidden={getColumnProperty(0, 'hidden')}
                />
                <Column
                    field={getColumnProperty(1, 'fieldName')}
                    header={translatedMessage(getColumnProperty(1, 'name'))}
                    headerStyle={{ minWidth: '10rem' }}
                    body={getColumnProperty(1, 'template')}
                    hidden={getColumnProperty(1, 'hidden')}
                />
                <Column
                    field={getColumnProperty(2, 'fieldName')}
                    header={translatedMessage(getColumnProperty(2, 'name'))}
                    headerStyle={{ minWidth: '10rem' }}
                    body={getColumnProperty(2, 'template')}
                    hidden={getColumnProperty(2, 'hidden')}
                />
                <Column
                    field={getColumnProperty(3, 'fieldName')}
                    header={translatedMessage(getColumnProperty(3, 'name'))}
                    headerStyle={{ minWidth: '10rem' }}
                    body={getColumnProperty(3, 'template')}
                    hidden={getColumnProperty(3, 'hidden')}
                />

                <Column body={actionBodyTemplate} hidden={!props?.hasEntityLink} />
            </DataTable>
        </>
    );
};

export default GenericTableView;
