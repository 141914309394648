import ApiService from "./ApiService";
import GeneralUtils from "../utilities/GeneralUtils";

export class ProviderService {
    
    async getProvidersByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/provider/list`, criteria)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getProvider(providerId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/provider/${providerId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveProvider(provider) {
        provider = GeneralUtils.nullIfEmptyForObjectFields(provider)
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/provider', provider)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteProvider(providerId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/provider/${providerId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }    

}