import React from 'react';
import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';

const OrganizationDataTableUtils = {

    organizationStatusBodyTemplate(_status) {
        return (
            <div className={`inline-block status status-${_status.toLowerCase()}`}>{translatedMessage("OrganizationStatus." + _status)}</div>
        )
    },

    organizationStatusFilterTemplate(options, statuses) {
        return <MultiSelect
            value={options.value}
            options={statuses}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder={translatedMessage('generic.status')}
            className="p-column-filter"
            maxSelectedLabels={1}
            showSelectAll={false}
            selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
            itemTemplate={(e) => this.organizationStatusBodyTemplate(e.value)}
        />
    },

    organizationTypeBodyTemplate(_type) {
        return (
            <div className={`pill inline-block organization-type-${_type.toLowerCase()}`}>{translatedMessage("OrganizationType." + _type)}</div>
        )
    },

    organizationTypeFilterTemplate(options, types) {
        return <MultiSelect
            value={options.value}
            options={types}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder={translatedMessage('generic.type')}
            className="p-column-filter"
            maxSelectedLabels={1}
            showSelectAll={false}
            selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
            itemTemplate={(e) => this.organizationTypeBodyTemplate(e.value)}
        />
    },

    providerListTableHeader() {
        return [
            translatedMessage("generic.nameRo"),
            translatedMessage('provider.cif'),
            translatedMessage("generic.county"),
            translatedMessage("generic.status"),
            translatedMessage('generic.type'),
            translatedMessage("provider.isPublic"),
            translatedMessage("generic.description"),
            translatedMessage("generic.created.on")
        ]
    },

    providerListSortOrderHeader() {
        return [
            "name", "cif", "county", "status", "type", "public", "description", "createdOn"
        ]
    },

    departmentProfileListTableHeader() {
        return [
            translatedMessage('generic.firstName'),
            translatedMessage('generic.lastName'),
            translatedMessage("organization.organization"),
            translatedMessage("organizationProfile.position"),
            translatedMessage("organizationProfile.positionType"),
            translatedMessage("organizationProfile.email"),
            translatedMessage("organizationProfile.phone"),
            translatedMessage("organizationProfile.status"),
            translatedMessage("organizationProfile.comment"),
            translatedMessage("generic.created.on")
        ]
    },

    departmentProfileListSortOrderHeader() {
        return [
            "firstName", "lastName", "organization", "position", "positionType", "email", "phone", "status", "comment", "createdOn"
        ]
    },

    getOrganizationProfileName(organizationProfile) {
        if (organizationProfile) {
            return organizationProfile?.profile?.fullName +
                (organizationProfile?.organization?.code ? ' (' + organizationProfile?.organization?.code + ')' : "")
        } else {
            return ''
        }
    },

    getOrganizationProfileDisplayName(organizationProfile) {
        if (organizationProfile) {
            return organizationProfile?.profile?.fullName +
                ' (' +
                (organizationProfile.position ? organizationProfile.position : '') +
                (organizationProfile.position && organizationProfile.positionType ? ' - ' : '') +
                (organizationProfile.positionType ? translatedMessage("PositionType." + organizationProfile.positionType) : '') +
                (organizationProfile.position || organizationProfile.positionType ? ' - ' : '') +
                organizationProfile?.organization?.name +
                ')';
        } else {
            return '';
        }
    },

    getOrganizationProfileTemplate(organizationProfile) {
        if (organizationProfile) {
            return (
                <span>
                    <div style={{ display: 'block', marginBottom: 5 }}>
                        {
                            organizationProfile?.profile?.fullName +
                            (organizationProfile.position || organizationProfile.positionType ?
                                ' (' + (organizationProfile.position ? organizationProfile.position : '') +
                                (organizationProfile.position && organizationProfile.positionType ? ' - ' : '') +
                                (organizationProfile.positionType ? translatedMessage("PositionType." + organizationProfile.positionType) : '') + ')' :
                                '')
                        }
                    </div>
                    <div>{organizationProfile?.organization?.name}</div>
                </span>
            )
        } else {
            return <></>;
        }
    },

    getOrganizationProfilePositionDisplayName(organizationProfile) {
        return (organizationProfile.position ? organizationProfile.position : '') +
            (organizationProfile.position && organizationProfile.positionType ? ' - ' : '') +
            (organizationProfile.positionType ? translatedMessage("PositionType." + organizationProfile.positionType) : '');
    },

    getOrganizationProfilePositionTableDisplayName(organizationProfile) {
        if (organizationProfile) {
            return (
                <>
                    <div>{organizationProfile.profile?.fullName}</div>
                    {organizationProfile.positionType
                        ? <div className='mt-2 aspc-datatable-small-font'>{translatedMessage("PositionType." + organizationProfile.positionType)}</div>
                        : (organizationProfile.position ? <div className='mt-2 aspc-datatable-small-font'>{organizationProfile.position}</div> : "")}
                    <div className='mt-1 aspc-datatable-small-font'>{organizationProfile.organization?.name}</div>
                </>
            )
        } else {
            return ''
        }
    }

};

export default OrganizationDataTableUtils;