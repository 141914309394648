import React, { useState, useEffect } from 'react';
import { getIn, useFormik } from "formik";
import { InputText } from 'primereact/inputtext';
import { translatedMessage } from '../../service/LanguageService';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { SolutionService } from '../../service/SolutionService';
import BeneficiaryAutocompleteFieldComponent from '../profile/BeneficiaryAutocompleteFieldComponent';

let emptyRecord = {
    id: null,
    issuer: '',
    documentNumber: '',
    documentDate: null,
    type: null,
    folder: null,
    beneficiary: null
};

const SolutionEditComponent = (props) => {
    const [solution, setSolution] = useState()
    const [solutionTypeList, setSolutionTypeList] = useState([])

    const solutionService = new SolutionService();

    useEffect(() => {
        const getSolutionTypeList = async () => {
            const _solutionTypes = await EnumService.getEntityLookupByField("NOM:SOLUTION_TYPE")
            setSolutionTypeList(_solutionTypes)
        }
        const setupSolution = async () => {
            let _solution = props.value;
            if (!_solution) {
                _solution = { ...emptyRecord };
                if (props?.beneficiary) {
                    _solution.beneficiary = props?.beneficiary;
                }
            } else {
                _solution = GeneralUtils.emptyInNullForObjectFields(_solution);
            }
            setSolution(_solution);

            if (props?.solutionTypeList) {
                setSolutionTypeList(props?.solutionTypeList);
            } else {
                await getSolutionTypeList();
            }
        }

        setupSolution();
    }, [props]);

    const formik = useFormik({
        initialValues: solution ? solution : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.issuer) {
                errors.issuer = translatedMessage("form.error.issuer.required");
            }
            if (!data.documentNumber) {
                errors.documentNumber = translatedMessage("form.error.documentNumber.required");
            }
            if (!data.documentDate) {
                errors.documentDate = translatedMessage("form.error.documentDate.required");
            }
            if (!data.type) {
                errors.type = translatedMessage("form.error.solutionType.required");
            }            
            if (!data.beneficiary) {
                errors.beneficiary = translatedMessage("form.error.beneficiary.required");
            }
            return errors;
        },
        onSubmit: (data) => {
            solutionService.saveSolution(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;


    const handleBeneficiaryChange = async (_beneficiary) => {
        setSolution({ ...formik.values, beneficiary: _beneficiary })
    }

    return (
        <>
            <div className="flex flex-column align-items-center">
                <div className="col-12">
                    <form id="profile-form" onSubmit={formik.handleSubmit}>
                        <div className='grid'>

                            <div className='col-12 md:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" id="issuer" name="issuer" value={formik.values.issuer} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('issuer') })} />
                                        <label htmlFor="issuer" className={classNames({ 'p-error': isFormFieldValid('issuer') })}>
                                            {translatedMessage('solution.issuer')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('issuer')}
                            </div>

                            <div className='col-12 md:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" id="documentNumber" name="documentNumber" value={formik.values.documentNumber} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('documentNumber') })} />
                                        <label htmlFor="documentNumber" className={classNames({ 'p-error': isFormFieldValid('documentNumber') })}>
                                            {translatedMessage('solution.documentNumber')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('documentNumber')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="documentDate" name="documentDate" value={formik.values.documentDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                            className={classNames({ 'p-invalid': isFormFieldValid('documentDate') })} />
                                        <label htmlFor="documentDate" className={classNames({ 'p-error': isFormFieldValid('documentDate') })}>
                                            {translatedMessage("solution.documentDate")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('documentDate')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="type.code" name="type.code" value={formik.values.type?.code} onChange={formik.handleChange}
                                            options={solutionTypeList} optionValue='code' className={classNames({ 'p-invalid': isFormFieldValid('type') })} />
                                        <label htmlFor="type.code" className={classNames({ 'p-error': isFormFieldValid('type') })}>
                                            {translatedMessage('solution.solutionType')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('type')}
                            </div>

                            <BeneficiaryAutocompleteFieldComponent
                                beneficiary={solution?.beneficiary}
                                setBeneficiary={(e) => handleBeneficiaryChange(e)}
                                validationError={isFormFieldValid('beneficiary')}
                                title={translatedMessage('monitoring.beneficiary') + ' *'}
                                disabled={props?.beneficiary}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default SolutionEditComponent;
