import React, { useEffect, useState, useRef } from 'react';
import { Outlet, Navigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import useInitUserData from '../../hooks/useInitUserData';
import { ProgressSpinner } from 'primereact/progressspinner';
import { translatedMessage } from '../../service/LanguageService';
import { Toast } from 'primereact/toast';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { auth } = useAuth();
  const initUserData = useInitUserData();
  const toast = useRef(null);

  useEffect(() => {
    let isMounted = true;

    const initToken = async () => {
      try {
        await initUserData();
      }
      catch (error) {
        let errorMessage = "generic.error.get.data";
        if (error && error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message
        }
        toast?.current?.show({ severity: 'error', summary: translatedMessage(errorMessage) });
      }
      finally {
        isMounted && setIsLoading(false);
      }
    }

    !auth?.token ? initToken() : setIsLoading(false);

    return () => isMounted = false;
  }, [auth?.token, initUserData]);

  return (
    <>
      <Toast ref={toast}></Toast>
      {isLoading
        ? <div className='w-full flex align-items-center'>
          <ProgressSpinner />
        </div>
        : auth?.token
          ? <Outlet />
          : <Navigate to="/login" replace />
      }
    </>
  )
}

export default PersistLogin