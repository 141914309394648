import React, { useState, useEffect, useRef, useMemo } from "react";
import { Toast } from "primereact/toast";
import ServiceExpertBeneficiaryListComponent from "../../components/serviceExpertBeneficiary/ServiceExpertBeneficiaryListComponent";
import ServiceToolbar from "./ServiceToolbar";
import { ServicesService } from "../../service/ServicesService";
import { useParams } from "react-router-dom";
import useNavProps from "../../hooks/useNavProps";
import { translatedMessage } from "../../service/LanguageService";
import { ProgressSpinner } from "primereact/progressspinner";

const ServiceBeneficiariesView = () => {
    const [service, setService] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const serviceService = useMemo(() => new ServicesService(), []);

    const { serviceIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getService = async () => {
            await serviceService.getService(serviceIdParam)
                .then(async (_data) => {
                    setService(_data);
                    setNavPropsValue('service', _data.id, _data.category?.label);
                    setIsLoading(false);
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getService();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceService, serviceIdParam]);

    return (
        <>
            <Toast ref={toast} />
            {isLoading && (
                <div className="w-full flex align-items-service">
                    <ProgressSpinner />
                </div>
            )}
            {!isLoading && (
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <ServiceToolbar service={service} code="BENEFICIARIES" />

                            <ServiceExpertBeneficiaryListComponent service={service} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ServiceBeneficiariesView;