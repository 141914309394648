import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import LanguageService, { translatedMessage } from '../../service/LanguageService';

import GeneralUtils from '../../utilities/GeneralUtils';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import ProfileAutocompleteFieldComponent from '../profile/ProfileAutocompleteFieldComponent';
import { FamilyPlacementService } from '../../service/FamilyPlacementService';

let emptyRecord = {
    id: null,
    householdDetail: '',
    profile: null
};

const FamilyPlacementEditComponent = (props) => {
    const [familyPlacement, setFamilyPlacement] = useState(emptyRecord)

    const familyPlacementService = new FamilyPlacementService();

    useEffect(() => {
        const computeFP = async () => {
            let _fp = emptyRecord
            if (props?.value) {
                _fp = GeneralUtils.emptyInNullForObjectFields(props.value)
            }

            setFamilyPlacement(_fp)
        }

        computeFP()
    }, [props]);

    const formik = useFormik({
        initialValues: familyPlacement ? familyPlacement : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.profile) {
                errors.profile = translatedMessage("form.error.profile.required");
            }            

            return errors;
        },
        onSubmit: (data) => {
            //console.log("save fp: ", data)

            familyPlacementService.saveFamilyPlacement(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const onEditorChange = (content) => {
        formik.setFieldValue("householdDetail", content)
    }

    const handleProfileChange = async (_existingProfile) => {
        setFamilyPlacement({ ...formik.values, profile: _existingProfile })
    }

    return (
        <>
            <ProfileAutocompleteFieldComponent
                profile={familyPlacement?.profile}
                setExistingProfile={(e) => handleProfileChange(e)}
                entityName="FAMILY_PLACEMENT"
                showAddProfile={true}
                validationError={isFormFieldValid('profile')}
                disabled={props?.disableProfile}
            />

            <div className="col-12">
                <form id="familyPlacement-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>                        
                        <div className="col-12">
                            <div className='filed-label mb-1'>{translatedMessage('familyPlacement.householdDetail')}</div>
                            <SunEditor
                                id="householdDetail" name="householdDetail"
                                lang={LanguageService.getCurrentLanguage}
                                height="250px"
                                width='100%'
                                setContents={formik.values.householdDetail}
                                setOptions={{
                                    buttonList: GeneralUtils.getSunEditorButtons(),
                                    font: GeneralUtils.getSunEditorFonts(),
                                    defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                }}
                                onChange={onEditorChange}
                            />
                            {getFormErrorMessage('householdDetail')}
                        </div>


                    </div>
                </form>
            </div>
        </>
    );
};

export default FamilyPlacementEditComponent;
