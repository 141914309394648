import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from "primereact/dropdown";
import { translatedMessage } from "../service/LanguageService";
import GeneralUtils from "./GeneralUtils";

const DataTableUtils = {

  defalRowsPerPage() {
    return 10
  },

  rowsPerPageOptions() {
    return [5, 10, 25, 50, 100]
  },

  maxRowsForSearch() {
    return 50
  },

  dateTemplate(date) {
    return (
      <span>
        <div style={{ display: 'block' }}>{GeneralUtils.formatDate(date)}</div>
      </span>
    )
  },

  dateIntervalTemplate(startDate, endDate) {
    return (
      <span>
        <div style={{ display: 'block', marginBottom: 5 }}>{GeneralUtils.formatDate(startDate)}</div>
        <div>{GeneralUtils.formatDate(endDate)}</div>
      </span>
    )
  },

  dateTimeIntervalTemplate(startDate, endDate) {
    return (
      <span>
        <div style={{ display: 'block', marginBottom: 5 }}>{GeneralUtils.formatDateTimeNoSeconds(startDate)}</div>
        <div>{GeneralUtils.formatDateTimeNoSeconds(endDate)}</div>
      </span>
    )
  },

  getEntityLookupBodyTemplate(id, list) {
    const entityLookup = list.filter(element => element.value === id);
    return entityLookup[0] ? entityLookup[0].label : '';
  },

  yesNoTemplate(value) {
    return (
      <span>
        <div style={{ display: 'block' }}>{value ? translatedMessage("generic.yes") : translatedMessage("generic.no")}</div>
      </span>
    )
  },

  updateTableList(list, savedData, isNew) {
    let _list = []
    if (!isNew) {
      list.forEach(item => {
        if (item.id === savedData.id) {
          _list.push(savedData)
        } else {
          _list.push(item)
        }
      })
    } else {
      _list = list.map(item => ({ ...item }))
      _list.push(savedData)
    }

    return _list;
  },

  yesNoFilterTemplate(options) {
    let dropdownOptions = [
      { label: translatedMessage('generic.yes'), value: true },
      { label: translatedMessage('generic.no'), value: false },
    ]

    return <Dropdown
      value={options.value}
      options={dropdownOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      className="p-column-filter"
      // placeholder={translatedMessage('profile.accessibility')}
      itemTemplate={(e) => this.yesNoTemplate(e.value)}
    />
  },

  entityLookupBodyTemplate(_entityLookup) {
    return (
      <div className={`inline-block`}>{_entityLookup?.label}</div>
    )
  },

  entityLookupFilterTemplate(options, statuses, placeholder) {
    return <MultiSelect
      value={options.value}
      options={statuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={placeholder}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      itemTemplate={(e) => this.entityLookupBodyTemplate(e)}
    />
  },

  countyFilterTemplate(options, counties) {
    return <MultiSelect
      value={options.value}
      options={counties}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.county')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      itemTemplate={(e) => e.label}
      filter
    />;
  }

};

export default DataTableUtils;