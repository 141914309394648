import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from '../../service/UserService';
import SolutionEditDialogComponent from './SolutionEditDialogComponent';

const SolutionViewComponent = (props) => {
    const [solution, setSolution] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const toast = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        setSolution(props?.solution)
    }, [props]);

    return (
        <>
            <Toast ref={toast} />

            <div className='w-full grid pcn-data-view-panel mb-5'>
                {!props?.hideTitle &&
                    <div className='col-12 flex align-items-center'>
                        <span className='entity-view-subtitle mr-2'>{translatedMessage("solution.details")}</span>
                        <Button
                            text
                            className="pcn-button-slim p-button p-button-icon-only"
                            onClick={() => setShowEditDialog(true) && props?.showEdit}
                            visible={hasPermission('NOTICE_EDIT')}
                            disabled={!solution?.actions?.ADD_SOLUTION}
                        >
                            <div className='flex align-items-center'>
                                <FontAwesomeIcon icon='fa-solid fa-pencil' />
                            </div>
                        </Button>
                    </div>
                }
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('solution.issuer')}</div>
                    <div className='filed-value'>{solution?.issuer}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('solution.documentNumber')}</div>
                    <div className='filed-value'>{solution?.documentNumber}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('solution.documentDate')}</div>
                    <div className='filed-value'>{GeneralUtils.formatDate(solution?.documentDate)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('solution.solutionType')}</div>
                    <div className='filed-value'>{solution?.type.label}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('beneficiary.beneficiary')}</div>
                    <div className='filed-value'>{
                        solution?.beneficiary && hasPermission('BENEFICIARY_VIEW')
                            ? <Button text label={solution?.beneficiary?.profile?.fullName} className="aspc-button-filed-text p-button text-align-left"
                                onClick={() => navigate(`/beneficiary/${solution?.beneficiary.id}`)} />
                            : solution?.beneficiary?.profile?.fullName || '-'
                    }</div>
                </div>
            </div>

            <SolutionEditDialogComponent
                value={solution}
                visible={showEditDialog}
                afterSave={(savedData) => setSolution(savedData)}
                visibleSetter={() => setShowEditDialog(false)}
            />
        </>
    );
};

export default SolutionViewComponent;
