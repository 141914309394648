import { useEffect, useState } from 'react';
import useAuth from "./useAuth";
import { translatedMessage } from "../service/LanguageService";

const useMenu = () => {
    const { auth } = useAuth();
    const [administrationMenu, setAdministrationMenu] = useState({});
    const [benefiaciaryMenu, setBeneficiarynMenu] = useState({});
    const [organizationMenu, setOrganizationMenu] = useState({});
    const [profileMenu, setProfileMenu] = useState({});
    const [serviceMenu, setServiceMenu] = useState({});

    useEffect(() => {
        const hasPermission = (permissionName) => {
            if (auth && auth.user && auth.user.permissions) {
                return auth.user.permissions.indexOf(permissionName) > -1;
            } else {
                return false;
            }
        };

        const couldDoAction = (actionName) => {
            if (auth && auth.user && auth.user.actions) {
                return auth.user.actions[actionName];
            } else {
                return false;
            }
        };

        // Administration menu
        let administrationItems = [];
        if (hasPermission('USER_LIST')) {
            administrationItems.push({ label: translatedMessage('menu.administration.users'), icon: 'fa-regular fa-user', to: '/administration/users' });
        }
        if (hasPermission('ROLE_LIST')) {
            administrationItems.push({ label: translatedMessage('menu.administration.roles'), icon: 'fa-solid fa-tags', to: '/administration/roles' });
        }
        administrationItems.push({ label: translatedMessage('menu.administration.entitylookup'), icon: 'fa-solid fa-tags', to: '/administration/entityLookup' });

        setAdministrationMenu(hasPermission('USER_LIST') || hasPermission('ROLE_LIST') ?
            {
                label: translatedMessage('menu.administration'),
                items: administrationItems
            } : '')


        // Beneficiary menu
        let beneficiaryItems = [];
        if (hasPermission('BENEFICIARY_LIST')) {
            beneficiaryItems.push({ label: translatedMessage('menu.beneficiaries'), icon: 'fa-solid fa-user', to: '/beneficiary' });
        }
        if (hasPermission('LEGAL_REPRESENTATIVE_LIST')) {
            beneficiaryItems.push({ label: translatedMessage('menu.legal-reps'), icon: 'fa-solid fa-user-tie', to: '/legal-rep' });
        }
        if (hasPermission('AMP_LIST')) {
            beneficiaryItems.push({ label: translatedMessage('menu.amp'), icon: 'fa-solid fa-user-nurse', to: '/amp' });
        }
        if (hasPermission('FAMILY_PLACEMENT_LIST')) {
            beneficiaryItems.push({ label: translatedMessage('menu.family-placement'), icon: 'fa-solid fa-user-shield', to: '/family-placement' });
        }
        if (hasPermission('ANALYSIS_LIST') && couldDoAction('VIEW_ANALYSIS')) {
            beneficiaryItems.push({ label: translatedMessage('menu.analysis'), icon: 'fa-solid fa-magnifying-glass-chart', to: '/analysis' });
        }
        if (hasPermission('NOTICE_LIST')) {
            beneficiaryItems.push({ label: translatedMessage('menu.notice'), icon: 'fa-solid fa-flag', to: '/notice' });
        }        
        if (hasPermission('SOLUTION_LIST')) {
            beneficiaryItems.push({ label: translatedMessage('menu.solution'), icon: 'fa-regular fa-file-lines', to: '/solution' });
        }
        if (hasPermission('MONITORING_LIST')) {
            beneficiaryItems.push({ label: translatedMessage('menu.monitoring'), icon: 'fa-solid fa-binoculars', to: '/monitoring' });
        }
        if (hasPermission('HEARING_LIST')) {
            beneficiaryItems.push({ label: translatedMessage('menu.hearing'), icon: 'fa-solid fa-ear-listen', to: '/hearing' });
        }

        setBeneficiarynMenu(hasPermission('BENEFICIARY_LIST') || hasPermission('ANALYSIS_LIST') || hasPermission('LEGAL_REPRESENTATIVE_LIST') || hasPermission('AMP_LIST') ?
            {
                label: translatedMessage('menu.beneficiaries'),
                items: beneficiaryItems
            } : '')

        // Beneficiary menu
        let profileItems = [];
        if (hasPermission('PROFILE_LIST')) {
            profileItems.push({ label: translatedMessage('menu.persons'), icon: 'fa-solid fa-users', to: '/profile' });
        }

        setProfileMenu(hasPermission('PROFILE_LIST') ?
            {
                label: translatedMessage('menu.persons'),
                items: profileItems
            } : '')

        // Organization menu
        let organizationItems = [];
        if (hasPermission('ORGANIZATION_LIST')) {
            organizationItems.push({ label: translatedMessage('menu.department'), icon: 'fa-solid fa-building-columns', to: '/department' });
            organizationItems.push({ label: translatedMessage('menu.center'), icon: 'fa-solid fa-person-shelter', to: '/center' });
        }

        organizationItems.push({ label: translatedMessage('menu.centerbeneficiary'), icon: 'fa-solid fa-tags', to: '/centerBeneficiary' });

        if (hasPermission('ORGANIZATION_LIST')) {
            organizationItems.push({ label: translatedMessage('menu.provider'), icon: 'fa-regular fa-building', to: '/provider' });
        }

        if (hasPermission('ORGANIZATION_PROFILE_LIST')) {
            organizationItems.push({ label: translatedMessage('menu.organizationProfile'), icon: 'fa-solid fa-users', to: '/organizationProfile' });
        }

        setOrganizationMenu((hasPermission('ORGANIZATION_LIST') || hasPermission('ORGANIZATION_PROFILE_LIST')) && 
            { label: translatedMessage('menu.organization'), items: organizationItems });

        let serviceItems = [];
        if (hasPermission('SERVICE_LIST')) {
            serviceItems.push({ label: translatedMessage('menu.service'), icon: 'fa-solid fa-clipboard', to: '/service' });
            serviceItems.push({ label: translatedMessage('menu.serviceexpertbeneficiary'), icon: 'fa-solid fa-clipboard-user', to: '/serviceExpertBeneficiary' });
        }

        if (hasPermission('SERVICE_SUPPLY_LIST')) {
            serviceItems.push({ label: translatedMessage('menu.servicesupply'), icon: 'fa-solid fa-clipboard-check', to: '/serviceSupply' });
        }

        setServiceMenu((hasPermission('SERVICE_LIST') || hasPermission('SERVICE_SUPPLY_LIST')) && { label: translatedMessage('menu.service'), items: serviceItems });

    }, [auth]);

    return [
        administrationMenu,
        benefiaciaryMenu,
        profileMenu,
        organizationMenu,
        serviceMenu
    ];
}

export default useMenu
