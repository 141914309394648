import React, { useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { translatedMessage } from '../service/LanguageService';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ApiService from '../service/ApiService';
import FooterBeforeLogin from '../components/FooterBeforeLogin';

const RegisterVerifyCode = () => {
    const [searchParams] = useSearchParams();
    const [username] = useState(searchParams.get("username"));

    const navigate = useNavigate();
    const toast = useRef(null);

    const formik = useFormik({
        initialValues: { verificationCode: '' },
        validate: (data) => {
            let errors = {};

            if (!data.verificationCode || !data.verificationCode.trim()) {
                errors.verificationCode = translatedMessage("register.error.code.length");
            } else if (data.verificationCode.trim().length !== 4) {
                errors.verificationCode = translatedMessage("register.error.code.length");
            }

            return errors;
        },
        onSubmit: (data) => {
            ApiService.instance.post('/user/activate', {
                username: username,
                code: data.verificationCode
            })
                .then(() => {
                    navigate("/login");
                })
                .catch(error => {
                    let errorMessage = "register.error.generic";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage), life: 7000 });
                });
        }
    });

    const resendCode = () => {
        ApiService.instance.post(`/user/register/notify?username=${username}`)
            .then(() => {
                toast.current.show({ severity: 'success', summary: translatedMessage("register.code.resend.success"), life: 7000 });
            })
            .catch(error => {
                let errorMessage = "register.error.generic";
                if (error && error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message
                }
                toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage), life: 7000 });
            });

    }

    const handleNumberValidation = (event) => {
        let charCode = event.nativeEvent.data;
        if (charCode === null || /^[0-9\b]+$/.test(charCode)) {
            formik.handleChange(event);
        }
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    return (
        <div className="pages-body login-page flex flex-column before-login-pages">
            <Toast ref={toast}></Toast>
            <div className='pcn-login-logos w-full flex justify-content-center py-1'></div>
            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card flex flex-column">
                    <div className="pages-header px-4 py-2 mb-0" style={{ height: '89px' }}>
                        <img src="assets/layout/images/logo.png" alt="logo" />
                    </div>

                    <h4 className='mt-3 mb-2'>{translatedMessage("register.verificationCode.title")}</h4>

                    <form id="validate-code-form" onSubmit={formik.handleSubmit}>
                        <div className="input-panel flex flex-column mb-5">
                            <div className="field p-inputgroup mb-2">
                                <span className="p-float-label">
                                    <InputText type="text" name="verificationCode" id="verificationCode"
                                        value={formik.values.verificationCode}
                                        onChange={handleNumberValidation}
                                        className={classNames({ 'p-invalid': isFormFieldValid('verificationCode') })}
                                        maxLength={4}
                                    />
                                    <label htmlFor="verificationCode" className={classNames({ 'p-error': isFormFieldValid('verificationCode') })}>
                                        {translatedMessage("register.verificationCode")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('verificationCode')}
                        </div>
                    </form>

                    <Button className="login-button mb-3 px-3" label={translatedMessage("register.verify")} form="validate-code-form" type="submit"></Button>

                    <div className="grid">
                        <div className="col text-align-center">
                            {translatedMessage("register.code.resend.text")}
                            <span onClick={() => resendCode()} className="ml-1 underline text-primary font-bold cursor-pointer">
                                {translatedMessage("generic.here")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBeforeLogin />
        </div>
    );
};

export default RegisterVerifyCode;