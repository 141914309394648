import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';

import HearingListComponent from '../../components/hearing/HearingListComponent';

const HearingList = () => {

    const toast = useRef(null);    
    
    return (
        <>
            <div className="grid h-full">
                <Toast ref={toast}></Toast>
                <div className="col-12">
                    <div className="card h-full">

                        <HearingListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default HearingList;
