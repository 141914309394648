import React, { useEffect, useState, useMemo, useRef } from 'react';
import { translatedMessage } from '../../service/LanguageService';
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';
import DataTableUtils from '../../utilities/DataTableUtils';
import { FamilyPlacementService } from '../../service/FamilyPlacementService';

const FamilyPlacementAutocompleteFieldComponent = (props) => {
    const [familyPlacement, setFamilyPlacement] = useState()
    const [familyPlacements, setFamilyPlacements] = useState([])
    const [validationError, setValidationError] = useState(false);

    const toast = useRef(null);
    const familyPlacementService = useMemo(() => new FamilyPlacementService(), [])

    useEffect(() => {
        const getData = async () => {
            await search()
        }
        getData()

        setValidationError(props?.validationError)

        if (props?.familyPlacement) {
            let _familyPlacement = { ...props.familyPlacement }

            setFamilyPlacement(_familyPlacement)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const search = async (e = null) => {
        let criteria = {}
        criteria.pageSize = DataTableUtils.maxRowsForSearch();        

        if (e?.query) {
            criteria.fullName = e.query
        }

        await familyPlacementService.getList(criteria)
            .then((data) => {
                setFamilyPlacements(data.items);
                return data
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const handleFPChange = async (_selected) => {
        setFamilyPlacement(_selected)

        if (_selected?.id && typeof props.setFamilyPlacement === 'function') {
            //console.log("_selected: ", _selected)
            props.setFamilyPlacement(_selected);
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="col-12 flex flex-row">
                <div className='flex-grow-1'>
                    <div className="p-inputgroup">
                        <span className="p-float-label">
                            <AutoComplete
                                dropdown
                                id="familyPlacement"
                                name="familyPlacement"
                                value={familyPlacement}
                                suggestions={familyPlacements}
                                completeMethod={(e) => search(e)}
                                virtualScrollerOptions={{ itemSize: 38 }}
                                field={"profile.fullName"}
                                onChange={(d) => handleFPChange(d.value)}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                className={validationError ? 'p-invalid' : ''}
                                disabled={props?.disabled}
                            />
                            <label htmlFor="familyPlacement" className={validationError ? 'p-error' : ''}>
                                {props?.title ? props?.title : translatedMessage("profile.search")}
                            </label>
                        </span>
                    </div>
                    <small className={"p-error text-align-left mt-1 ".concat(validationError ? "block" : "hidden")}>
                        {translatedMessage("form.error.profile.required")}
                    </small>
                </div>
            </div>
        </>
    );
};

export default FamilyPlacementAutocompleteFieldComponent;
