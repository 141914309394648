import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getIn, useFormik } from "formik";
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';
import { translatedMessage } from '../../service/LanguageService';
import { ServicesService } from '../../service/ServicesService';
import { ServiceExpertBeneficiaryService } from '../../service/ServiceExpertBeneficiaryService';
import ServiceDataTableUtils from "../../utilities/ServiceDataTableUtils";
import DataTableUtils from "../../utilities/DataTableUtils";
import { InputTextarea } from 'primereact/inputtextarea';

let emptyServiceSupply = {
    id: null,
    description: '',
    serviceExpertBeneficiary: null,
    startDate: null,
    endDate: null,
    hasBeenDone: false
};

const ServiceSupplyEditDialog = (props) => {
    const [serviceSupply, setServiceSupply] = useState(emptyServiceSupply);
    const [isNew, setIsNew] = useState(false);
    const [filteredServiceExpertBeneficiaryList, setFilteredServiceExpertBeneficiaryList] = useState([]);

    const yesNoDropdownOptions = [
        { label: translatedMessage('generic.yes'), value: true },
        { label: translatedMessage('generic.no'), value: false },
    ]

    const toast = useRef(null);

    const servicesService = useMemo(() => new ServicesService(), []);
    const serviceExpertBeneficiaryService = useMemo(() => new ServiceExpertBeneficiaryService(), []);

    useEffect(() => {
        let _serviceSupply = props.value;
        if (!_serviceSupply) {
            _serviceSupply = { ...emptyServiceSupply };
        } else {
            _serviceSupply.serviceExpertBeneficiary.displayName = ServiceDataTableUtils.getServiceExpertBeneficiaryDisplayName(_serviceSupply.serviceExpertBeneficiary);
        }        
        setServiceSupply(_serviceSupply);
        setIsNew(props.value ? false : true);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const saveServiceSupply = async (data) => {
        await servicesService.saveServiceSupply(data)
            .then((savedServiceSupply) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedServiceSupply, isNew);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: serviceSupply,
        validate: (data) => {
            let errors = {};

            if (!data.serviceExpertBeneficiary?.id) {
                errors.serviceExpertBeneficiary = translatedMessage('form.error.service.required');
            }
            if (!data.description) {
                errors.description = translatedMessage('form.error.description.required');
            }
            if (!data.startDate) {
                errors.startDate = translatedMessage('form.error.startDate.required');
            }
            if (!data.endDate) {
                errors.endDate = translatedMessage('form.error.endDate.required');
            }
            if (data.hasBeenDone !== true && data.hasBeenDone !== false) {
                errors.hasBeenDone = translatedMessage('form.error.organization.hasBeenDone.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveServiceSupply(data);
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const searchServiceExpertBeneficiaryList = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "objective";
        criteria.serviceExpertBeneficiaryDisplayName = query.toLowerCase();

        serviceExpertBeneficiaryService.getServiceExpertBeneficiaryViewListByCriteria(criteria)
            .then((result) => {
                result.items.map(item => {
                    item.displayName = ServiceDataTableUtils.getServiceExpertBeneficiaryDisplayName(item);
                    return item;
                })
                setFilteredServiceExpertBeneficiaryList(result.items);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('serviceSupply.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="serviceExpertBeneficiary"
                                        name="serviceExpertBeneficiary"
                                        value={formik.values.serviceExpertBeneficiary}
                                        suggestions={filteredServiceExpertBeneficiaryList}
                                        completeMethod={(e) => searchServiceExpertBeneficiaryList(e)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="displayName"
                                        onChange={formik.handleChange}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('serviceExpertBeneficiary') })}
                                        autoFocus
                                    />
                                    <label htmlFor="serviceExpertBeneficiary" className={classNames({ 'p-error': isFormFieldValid('serviceExpertBeneficiary') })}>
                                        {translatedMessage("serviceSupply.service")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('serviceExpertBeneficiary')}
                        </div>                        
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="startDate" name="startDate" value={formik.values.startDate} onChange={formik.handleChange} showTime stepMinute={5}
                                        className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} />
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                        {translatedMessage("generic.startDate")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('startDate')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="endDate" name="endDate" value={formik.values.endDate} onChange={formik.handleChange} showTime stepMinute={5}
                                        className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} />
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                        {translatedMessage("generic.endDate")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('endDate')}
                        </div>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputTextarea id="description" name="description" rows={5} cols={30} value={formik.values.description} onChange={formik.handleChange} autoResize 
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') })} />
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>
                                        {translatedMessage('serviceSupply.description')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('description')}
                        </div>                        
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown id="hasBeenDone" name="hasBeenDone" value={formik.values.hasBeenDone} onChange={formik.handleChange} options={yesNoDropdownOptions}
                                        itemTemplate={(e) => DataTableUtils.yesNoTemplate(e.value)} />
                                    <label htmlFor="hasBeenDone" className={classNames({ 'p-error': isFormFieldValid('hasBeenDone') })}>
                                        {translatedMessage('serviceSupply.hasBeenDone')}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('hasBeenDone')}
                        </div>
                        {formik.values.file?.id &&
                            <div className='col-12'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="fileName" readOnly={true} value={formik.values.file?.name} />
                                        <label htmlFor="fileName" className={classNames({ 'p-error': isFormFieldValid('hasBeenDone') })}>
                                            {translatedMessage('generic.file')}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('hasBeenDone')}
                            </div>
                        }
                    </div>
                </form>
            </Dialog>
        </>);
};

export default ServiceSupplyEditDialog;
