import ApiService from "./ApiService";

export class DashboardService {

    async getDashboardResult() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/dashboard`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getBeneficiaryList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/dashboard/beneficiary/list', criteria)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data'
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                });
        });
    }

    async getNoticeList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/dashboard/notice/list', criteria)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data'
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                });
        });
    }

    async getHearingList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/dashboard/hearing/list', criteria)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data'
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                });
        });
    }

}