import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';
import { translatedMessage } from '../../service/LanguageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BeneficiaryToolbar = (props) => {
    const [beneficiary, setBeneficiary] = useState(null);

    const _sections = [
        { label: translatedMessage('generic.details'), code: 'DETAIL', icon: 'fa-regular fa-eye', url: "/" },
        { label: translatedMessage('profile.extended'), code: 'EXTENDED', icon: 'fa-solid fa-up-right-and-down-left-from-center', url: `/profile-extended` },
        { label: translatedMessage('legalRep.legalRep'), code: 'LEGAL_REP', icon: 'fa-solid fa-user-tie', url: `/legal-rep` },
        { label: translatedMessage('person.persons'), code: 'RELATED', icon: 'fa-solid fa-users', url: `/related` },
        { label: translatedMessage('familyPlacement'), code: 'FAMILY_PLACEMENT', icon: 'fa-solid fa-user-shield', url: `/family-placement` },
        { label: translatedMessage('amp.amp.short'), code: 'AMP', icon: 'fa-solid fa-user-nurse', url: `/amp` },
        { label: translatedMessage('center.list'), code: 'CENTERS', icon: 'fa-solid fa-person-shelter', url: `/center` },
        { label: translatedMessage('service.list'), code: 'SERVICES', icon: 'fa-solid fa-clipboard-user', url: `/service` },
        { label: translatedMessage('notice.list'), code: 'NOTICE', icon: 'fa-solid fa-flag', url: `/notice` },
        { label: translatedMessage('solution.list.short'), code: 'SOLUTION', icon: 'fa-solid fa-file-lines', url: `/solution` },
        { label: translatedMessage('monitoring.list'), code: 'MONITORING', icon: 'fa-solid fa-binoculars', url: `/monitoring` },
        { label: translatedMessage('analysis.analysis'), code: 'ANALYSIS', icon: 'fa-solid fa-magnifying-glass-chart', url: `/analysis`, visible_action: 'VIEW_ANALYSIS' },
        { label: translatedMessage('generic.files'), code: 'FILES', icon: 'fa-regular fa-file', url: `/file` },
        { label: translatedMessage('generic.backToList'), code: 'BACK', icon: 'fa-solid fa-arrow-left' }
    ];

    let _section = props ? _sections.filter(item => item.code === props.code)[0] : _sections[0];
    const [section, setSection] = useState(_section);
    const [sections, setSections] = useState(_sections);


    const navigate = useNavigate();

    useEffect(() => {
        setBeneficiary(props?.beneficiary)
        let _filteredSections = _sections.filter(section => !section.visible_action || props?.beneficiary?.actions[section.visible_action]);
        setSections(_filteredSections)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const navigationTemplate = (option) => {
        return (
            <div className='flex align-items-center'>
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </div>
        )
    }

    const handleSelectButtonChange = (value) => {
        let url = null
        if (value) {
            if (value.code === 'BACK') {
                navigate(`/beneficiary`);
            } else {
                if (value && value.url && value.url !== '#') {
                    url = `/beneficiary/${beneficiary.id}${value.url}`
                    navigate(url);
                } else {
                    setSection(value)
                }
            }
        }
    }

    return (
        <>
            <h3 className='mb-0'>{beneficiary?.profile.fullName}</h3>
            <SelectButton
                value={section}
                options={sections}
                onChange={(e) => handleSelectButtonChange(e.value)}
                itemTemplate={navigationTemplate}
                className="pcn-selectbutton-slim mb-3"
            />

        </>
    );
};

export default BeneficiaryToolbar;
