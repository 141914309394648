import React, { useRef } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import useAuth from '../../hooks/useAuth';

import { Toast } from 'primereact/toast';
import ServiceSupplyListComponent from '../../components/serviceSupply/ServiceSupplyListComponent';

const MyServicesSupply = () => {
    const { auth } = useAuth();
    const toast = useRef(null);

    return (
        <>
            <Toast ref={toast}></Toast>
            <div className="grid h-full" >
                <div className="col-12">
                    <div className="card h-full">
                        <ServiceSupplyListComponent expertProfile={auth?.user} pageTitle={translatedMessage("profile.myServicesSupply")}/>
                    </div>
                </div>
            </div >
        </>
    );
};

export default MyServicesSupply;
