import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import App from './App';
import Login from './pages/Login';
import Register from './pages/Register';
import RegisterVerifyCode from './pages/RegisterVerifyCode';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/ResetPassword';

const Layout = () => {
    return (
        <div>
            <Navigate to="/login" replace />
        </div>

    )
}

const AppWrapper = (props) => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={<Layout />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/:username" element={<Login />} />
            <Route path="/register" element={<Register />}></Route>
            <Route path="/verify" element={<RegisterVerifyCode />}></Route>
            <Route path="/reset-password-request" element={<ResetPasswordRequest />}></Route>
            <Route path="/reset-password/:username/:token" element={<ResetPassword />}></Route>
            <Route path="/notfound" element={<NotFound />} />
            <Route path="*" element={<App />} />
        </Routes>
    );
};

export default AppWrapper;
