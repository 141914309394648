import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useNavProps from '../../hooks/useNavProps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { hasPermission } from '../../service/UserService';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EnumService from "../../service/EnumService";
import { ServicesService } from '../../service/ServicesService';
import ServiceEditDialog from '../../components/service/ServiceEditDialog';
import ServiceToolbar from './ServiceToolbar';

const ServiceView = () => {
    const [service, setService] = useState(null);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [serviceCategories, setServiceCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editDialogVisible, setEditDialogVisible] = useState(false);

    const toast = useRef(null);
    const navigate = useNavigate();

    const serviceService = useMemo(() => new ServicesService(), []);

    const { serviceIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getServiceCategories = async () => {
            const _serviceCategories = await EnumService.getEntityLookupByField("NOM:SERVICE_CATEGORY");
            setServiceCategories(_serviceCategories);
        };

        const getServiceTypes = async () => {
            const _serviceTypes = await EnumService.getEntityLookupByField("NOM:SERVICE_TYPE");
            setServiceTypes(_serviceTypes);
        };

        const getService = async () => {
            await serviceService.getService(serviceIdParam)
                .then(async (_data) => {
                    setService(_data);
                    await getServiceCategories()
                    await getServiceTypes()
                    setNavPropsValue('service', _data.id, _data.category?.label);
                    setIsLoading(false);
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getService();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceService, serviceIdParam]);

    return (
        <>
            <Toast ref={toast} />
            {isLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <ServiceToolbar service={service} code="DETAIL" />

                                <div className='w-full grid pcn-data-view-panel mb-5'>
                                    <div className='col-12 flex align-items-center'>
                                        <span className='entity-view-subtitle mr-2'>{translatedMessage("service.details")}</span>
                                        <Button
                                            text
                                            className="pcn-button-slim p-button p-button-icon-only"
                                            onClick={() => setEditDialogVisible(true)}
                                            visible={hasPermission('SERVICE_EDIT')}
                                            disabled={!service?.actions?.ADD_SERVICES}
                                        >
                                            <div className='flex align-items-center'>
                                                <FontAwesomeIcon icon='fa-solid fa-pencil' />
                                            </div>
                                        </Button>
                                    </div>
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('service.organization')}</div>
                                        <div className='filed-value'>
                                            <div className='filed-value'>
                                                {hasPermission('ORGANIZATION_VIEW')
                                                    ? <Button text label={service?.organization?.name} className="aspc-button-filed-text p-button"
                                                        onClick={() => navigate(`/${service?.organization?.type?.toLowerCase()}/${service?.organization?.id}/`)} />
                                                    : service?.organization?.name
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('service.category')}</div>
                                        <div className='filed-value'>{service?.category?.label}</div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('service.type')}</div>
                                        <div className='filed-value'>{service?.type?.label}</div>
                                    </div>
                                    <div className='col-12 md:col-6'>
                                        <div className='filed-label'>{translatedMessage('service.center')}</div>
                                        <div className='filed-value'>
                                            {service?.center
                                                ? (
                                                    <div className='filed-value'>
                                                        {hasPermission('ORGANIZATION_VIEW')
                                                            ? <Button text label={service?.center?.name} className="aspc-button-filed-text p-button"
                                                                onClick={() => navigate(`/center/${service?.center?.id}/`)} />
                                                            : service?.center?.name
                                                        }
                                                    </div>)
                                                : "-"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ServiceEditDialog
                        value={service}
                        visible={editDialogVisible}
                        afterSave={(savedData) => setService(savedData)}
                        visibleSetter={setEditDialogVisible}
                        serviceCategories={serviceCategories}
                        serviceTypes={serviceTypes}
                        organization={service?.organization}
                    />
                </>
            )}
        </>
    );
};

export default ServiceView;
