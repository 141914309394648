import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { ProfileService } from '../../service/ProfileService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralUtils from '../../utilities/GeneralUtils';

const OrganizationProfileViewDialogComponent = (props) => {
    const [oProfile, setOProfile] = useState(null);

    const toast = useRef(null);
    const navigate = useNavigate();

    const profileService = useMemo(() => new ProfileService(), []);

    useEffect(() => {
        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', profileIdParam));
        // };        

        if (props?.visible && props?.organizationProfile) {
            //getProfile();
            setOProfile(props.organizationProfile)            
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileService, props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            setOProfile(null)
            props.visibleSetter(false);
        }
    };

    const dialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const dialogHeader = (
        <>
            <div className='mb-1'>{oProfile?.profile?.fullName}</div>
            <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/profile/${oProfile.profile.id}`)}>
                <div className="flex align-items-center">
                    <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" className="mr-1 " />
                    <span>{translatedMessage('person.goTO.profile')}</span>
                </div>
            </Button>
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                modal
                header={dialogHeader}
                className="pcn-dialog"
                footer={dialogFooter}
                onHide={hideDialog}
            >
                <div className='w-full grid pcn-data-view-panel mb-5'>
                    <div className='col-12 flex align-items-center'>
                        <span className='entity-view-subtitle mr-2'>{translatedMessage("generic.details")}</span>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('generic.name')}</div>
                        <div className='filed-value'>{oProfile?.profile?.fullName}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('organizationProfile.' + oProfile?.organization?.type?.toString().toLowerCase())}</div>
                        <div className='filed-value'>{oProfile?.organization?.name}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        {oProfile?.organization?.type === 'DEPARTMENT' &&
                            <>
                                <div className='filed-label'>{translatedMessage('organizationProfile.positionType')}</div>
                                <div className='filed-value'>{oProfile?.positionType ? translatedMessage('PositionType.' + oProfile?.positionType) : "-"}</div>
                            </>
                        }
                        {oProfile?.organization?.type !== 'DEPARTMENT' &&
                            <>
                                <div className='filed-label'>{translatedMessage('organizationProfile.position')}</div>
                                <div className='filed-value'>{GeneralUtils.dashIfNullForObject(oProfile?.position)}</div>
                            </>
                        }
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('organizationProfile.email')}</div>
                        <div className='filed-value'>{GeneralUtils.dashIfNullForObject(oProfile?.email)}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('organizationProfile.phone')}</div>
                        <div className='filed-value'>{GeneralUtils.dashIfNullForObject(oProfile?.phone)}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('organizationProfile.comment')}</div>
                        <div className='filed-value'>{GeneralUtils.dashIfNullForObject(oProfile?.comment)}</div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default OrganizationProfileViewDialogComponent;
