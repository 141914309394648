
import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import DepartmentListComponent from "../../components/department/DepartmentListComponent";

const DepartmentListPage = () => {

    const toast = useRef(null);

    return (
        <>
            <div className="grid h-full">
                <Toast ref={toast} />

                <div className="col-12">
                    <div className="card h-full">
                        <DepartmentListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DepartmentListPage;


