import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import DOMPurify from 'dompurify';

const ViewNotificationDialog = (props) => {
    const [dialogVisible, setDialogVisible] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setDialogVisible(false);
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.close')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const getContentTemplate = (content) => {
        let cleanContent = DOMPurify.sanitize(content, { ADD_ATTR: ['target'] });
        return <span className="notificationContent" dangerouslySetInnerHTML={{ __html: cleanContent }} />;
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog visible={dialogVisible} style={{ width: '750px' }} header={translatedMessage('notification.details')} footer={formDialogFooter} modal className="p-fluid" onHide={hideDialog}>
                <>
                    <div className="field grid">
                        <div className="col-12 md:col-2 md:mb-0">
                            <span style={{ fontWeight: '600' }}>{translatedMessage('notification.subject')}</span>
                        </div>
                        <div className="col-12 md:col-10 md:mb-0">{props.notification.subject}</div>
                    </div>
                    <div className="field grid">
                        <div className="col-12 md:col-2 md:mb-0">
                            <span style={{ fontWeight: '600' }}>{translatedMessage('notification.sentDate')}</span>
                        </div>
                        <div className="col-12 md:col-10 md:mb-0">{GeneralUtils.formatDateTime(props.notification.sentDate)}</div>
                    </div>
                    <div className="field grid">
                        <div className="col-12 md:col-2 md:mb-0">
                            <span style={{ fontWeight: '600' }}>{translatedMessage('notification.content')}</span>
                        </div>
                        <div className="col-12 md:col-10 md:mb-0">{getContentTemplate(props?.notification?.content)}</div>
                    </div>
                </>
            </Dialog>
        </>
    );
};

export default ViewNotificationDialog;
