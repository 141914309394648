import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from '../../service/UserService';
import DataTableUtils from '../../utilities/DataTableUtils';
import AddressEditDialogComponent from '../address/AddressEditDialogComponent';
import AddressViewComponent from '../address/AddressViewComponent';
import AddressHistoryDialogComponent from '../address/AddressHistoryDialogComponent';
import { ProfileService } from '../../service/ProfileService';
import ProfileEditDialogComponent from './ProfileEditDialogComponent';
import BeneficiaryEditDialog from '../../pages/beneficiary/BeneficiaryEditDialog';
import LegalRepEditDialogComponent from '../legalRepresentative/LegalRepEditDialogComponent';

const ProfileViewComponent = (props) => {
    const [profile, setProfile] = useState(null);
    const [beneficiary, setBeneficiary] = useState(null);
    const [legalRep, setLegalRep] = useState(null);
    const [showProfileEditDialog, setShowProfileEditDialog] = useState(false);
    const [showBeneficiaryEditDialog, setShowBeneficiaryEditDialog] = useState(false);
    const [showLegaRepEditDialog, setShowLegalRepEditDialog] = useState(false);
    const [showAddressDialog, setShowAddressDialog] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(false);
    const [addressType, setAddressType] = useState(null);
    const [showAddressHistoryDialog, setShowAddressHistoryDialog] = useState(false);
    const [editPermission, setEditPermission] = useState(null);
    const [allowEdit, setAllowEdit] = useState(true);

    const toast = useRef(null);
    const navigate = useNavigate();

    const profileService = useMemo(() => new ProfileService(), []);

    useEffect(() => {
        if (props.entity) {
            if (props.entityName === 'BENEFICIARY') {
                setBeneficiary(props.entity)
                setProfile(props.entity.profile)
                setAllowEdit(props?.entity?.actions?.EDIT_BENEFICIARY)
            } else if (props.entityName === 'LEGAL_REPRESENTATIVE') {
                setLegalRep(props.entity)
                setBeneficiary(null)
                setProfile(props.entity.profile)
                setAllowEdit(props?.entity?.actions?.SET_BENEFICIARY_LEGAL_REPRESENTATIVE)
            } else if (props.entityName === 'AMP') {
                setProfile(props.entity)
            } else if (props.entityName === 'FAMILY_PLACEMENT') {
                setProfile(props.entity)
            } else if (props.entityName === 'PROFILE') {
                setProfile(props.entity)
            }

            setEditPermission(props.entityName + '_EDIT')
        }
    }, [props]);

    const onAddressEditDialog = (_address, _type) => {
        setSelectedAddress(_address)
        setAddressType(_type)
        setShowAddressDialog(true)
    }

    const onAddressHistoryDialog = (_type) => {
        setAddressType(_type)
        setShowAddressHistoryDialog(true)
    }

    const onShowEditDialog = () => {
        setShowProfileEditDialog(props?.entityName === 'PROFILE' || props?.entityName === 'AMP' || props?.entityName === 'FAMILY_PLACEMENT')
        setShowBeneficiaryEditDialog(props?.entityName === 'BENEFICIARY')
        setShowLegalRepEditDialog(props?.entityName === 'LEGAL_REPRESENTATIVE')
    }

    const updateBeneficiary = (_beneficiary) => {
        setBeneficiary({ ..._beneficiary })
        setProfile({ ..._beneficiary.profile })
    }

    const updateLegalRep = (_legalRep) => {
        setLegalRep({ ..._legalRep })
        setProfile({ ..._legalRep.profile })
    }

    const saveAddress = (_address, _isNew = false) => {
        profileService.saveAddress(profile.id, _address)
            .then((savedData) => {
                let _profile = { ...profile }
                if (_address.home) {
                    _profile = { ..._profile, homeAddress: savedData }
                } else if (_address.residence) {
                    _profile = { ..._profile, residenceAddress: savedData }
                }

                setProfile(_profile)
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const getAddressButtons = (_address, _type) => {
        return (
            <>
                <Button
                    text
                    className="aspc-button-icon-slim p-button p-button-icon-only ml-1"
                    onClick={() => onAddressEditDialog(null, _type)}
                    tooltip={translatedMessage("address.addNew")}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                >
                    <div className='flex align-items-center'>
                        <FontAwesomeIcon icon='fa-solid fa-plus' />
                    </div>
                </Button>
                <Button
                    text
                    className="aspc-button-icon-slim p-button p-button-icon-only ml-1"
                    onClick={() => onAddressEditDialog(_address, _type)}
                    disabled={!_address}
                    tooltip={translatedMessage("address.editExisting")}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                >
                    <div className='flex align-items-center'>
                        <FontAwesomeIcon icon='fa-solid fa-pencil' />
                    </div>
                </Button>
                <Button
                    text
                    className="aspc-button-icon-slim p-button p-button-icon-only ml-1"
                    onClick={() => onAddressHistoryDialog(_type)}
                    disabled={!_address}
                    tooltip={translatedMessage("address.history")}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                >
                    <div className='flex align-items-center'>
                        <FontAwesomeIcon icon='fa-solid fa-clock-rotate-left' />
                    </div>
                </Button>
            </>
        )
    }


    return (
        <>
            <Toast ref={toast} />

            <div className='w-full grid pcn-data-view-panel mb-5'>
                <div className='col-12 flex align-items-center'>
                    <span className='entity-view-subtitle mr-2'>{translatedMessage(props?.detailTitle)}</span>
                    <Button
                        text
                        className="pcn-button-slim p-button p-button-icon-only"
                        onClick={onShowEditDialog}
                        visible={hasPermission(editPermission)}
                        disabled={!allowEdit}
                    >
                        <div className='flex align-items-center'>
                            <FontAwesomeIcon icon='fa-solid fa-pencil' />
                        </div>
                    </Button>
                </div>
                <div className={'col-12 '.concat(props?.entityName === 'LEGAL_REPRESENTATIVE' ? 'md:col-6' : '')}>
                    <div className='filed-label'>{translatedMessage('generic.name')}</div>
                    <div className='filed-value'>{profile?.fullName}</div>
                </div>
                {props?.entityName === 'BENEFICIARY' &&
                    <>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('beneficiary.registrationNumber')}</div>
                            <div className='filed-value'>
                                {
                                    GeneralUtils.dashIfNullForObject(beneficiary?.registrationNumber) + " / " +  
                                    GeneralUtils.formatDate(beneficiary?.registrationDate)
                                }
                            </div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('generic.status')}</div>
                            <div className='filed-value'>
                                <span className={`pill status status-${beneficiary?.status.toLowerCase()}`}>{translatedMessage("BeneficiaryStatus." + beneficiary?.status)}</span>
                            </div>
                        </div>
                    </>
                }
                {props?.entityName === 'LEGAL_REPRESENTATIVE' &&
                    <>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('beneficiary.beneficiary')}</div>
                            <div className='filed-value'>
                                {hasPermission('BENEFICIARY_VIEW')
                                    ? <Button text label={legalRep?.beneficiary?.profile.fullName} className="aspc-button-filed-text p-button"
                                        onClick={() => navigate(`/beneficiary/${legalRep?.beneficiary?.id}/legal-rep`)} />
                                    : legalRep?.beneficiary?.profile.fullName
                                }
                            </div>
                        </div>
                    </>
                }
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.hasPin')}</div>
                    <div className='filed-value'>{DataTableUtils.yesNoTemplate(profile?.hasPin)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.pin')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.pin)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.birthday')}</div>
                    <div className='filed-value'>{GeneralUtils.formatDate(profile?.birthday)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.gender')}</div>
                    <div className='filed-value'>{profile?.gender ? translatedMessage("Gender." + profile?.gender) : "-"}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.birthCounty')}</div>
                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(profile?.birthCounty?.label)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('profile.birthEnvironmentType')}</div>
                    <div className='filed-value'>{profile?.birthEnvironmentType ? translatedMessage("EnvironmentType." + profile?.birthEnvironmentType) : "-"}</div>
                </div>
                {props?.entityName === 'LEGAL_REPRESENTATIVE' &&
                    <>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('generic.status')}</div>
                            <div className='filed-value'>
                                <span className={`pill status status-${legalRep?.status.toLowerCase()}`}>{translatedMessage("LegalRepresentativeStatus." + legalRep?.status)}</span>
                            </div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('generic.startDate')}</div>
                            <div className='filed-value'>{GeneralUtils.formatDate(legalRep?.startDate)}</div>
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className='filed-label'>{translatedMessage('generic.endDate')}</div>
                            <div className='filed-value'>{GeneralUtils.formatDate(legalRep?.endDate)}</div>
                        </div>
                    </>
                }
            </div>

            {!props?.hideAddress &&
                <div className='w-full grid pcn-data-view-panel mb-5'>
                    <div className='col-12 md:col-6'>
                        <div className='flex align-items-center w-100'>
                            <span className='entity-view-subtitle mr-2'>{translatedMessage("address.homeAddress")}</span>
                            {hasPermission(editPermission) && getAddressButtons(profile?.homeAddress, "HOME")}
                        </div>
                        <AddressViewComponent address={profile?.homeAddress} />
                    </div>

                    <div className='col-12 md:col-6'>
                        <div className='flex align-items-center w-100'>
                            <span className='entity-view-subtitle mr-2'>{translatedMessage("address.residenceAddress")}</span>
                            {hasPermission(editPermission) && getAddressButtons(profile?.residenceAddress, "RESIDENCE")}
                        </div>
                        <AddressViewComponent address={profile?.residenceAddress} />
                    </div>
                </div>
            }

            <ProfileEditDialogComponent
                value={profile}
                visible={showProfileEditDialog}
                afterSave={(savedData) => setProfile(savedData)}
                visibleSetter={() => setShowProfileEditDialog(false)}
            />

            <BeneficiaryEditDialog
                value={beneficiary}
                visible={showBeneficiaryEditDialog}
                afterSave={(savedData) => updateBeneficiary(savedData)}
                visibleSetter={() => setShowBeneficiaryEditDialog(false)}
            />

            <LegalRepEditDialogComponent
                value={legalRep}
                visible={showLegaRepEditDialog}
                afterSave={(savedData) => updateLegalRep(savedData)}
                visibleSetter={() => setShowLegalRepEditDialog(false)}
            />

            <AddressEditDialogComponent
                value={selectedAddress}
                visible={showAddressDialog}
                afterSave={(savedData, isNew) => saveAddress(savedData, isNew)}
                visibleSetter={() => setShowAddressDialog(false)}
                hasHomeAndResidence={true}
                addressType={addressType}
                homeAddress={profile?.homeAddress}
            />

            <AddressHistoryDialogComponent
                profile={profile}
                visible={showAddressHistoryDialog}
                visibleSetter={setShowAddressHistoryDialog}
                addressType={addressType}
            />
        </>
    );
};

export default ProfileViewComponent;
