import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { hasPermission } from '../../service/UserService';
import GeneralUtils from '../../utilities/GeneralUtils';

const CenterBeneficiaryViewDialogComponent = (props) => {
    const [centerBeneficiary, setCenterBeneficiary] = useState(null);

    const toast = useRef(null);
    const navigate = useNavigate();


    useEffect(() => {
        if (props?.visible) {
            setCenterBeneficiary(props?.value)
        }
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const dialogHeader = (
        <>
            <div className='mb-1'>{translatedMessage('centerBeneficiary.details')}</div>
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={dialogHeader}
                modal
                className="pcn-dialog"
                footer={formDialogFooter}
                onHide={hideDialog}
            >

                <div className='w-full grid pcn-data-view-panel'>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('centerBeneficiary.center')}</div>
                        <div className='filed-value'>{centerBeneficiary?.center?.name}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('beneficiary.beneficiary')}</div>
                        <div className='filed-value'>
                            {hasPermission('BENEFICIARY_VIEW')
                                ? <Button text label={centerBeneficiary?.beneficiary?.profile?.fullName} className="aspc-button-filed-text p-button"
                                    onClick={() => navigate(`/beneficiary/${centerBeneficiary?.beneficiary?.id}/`)} />
                                : centerBeneficiary?.beneficiary?.profile?.fullName
                            }
                        </div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.startDate')}</div>
                        <div className='filed-value'>{GeneralUtils.formatDate(centerBeneficiary?.startDate)}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.endDate')}</div>
                        <div className='filed-value'>{GeneralUtils.formatDate(centerBeneficiary?.endDate)}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.status')}</div>
                        <div className='filed-value'>
                            <span className={`pill status status-${centerBeneficiary?.status.toLowerCase()}`}>
                                {translatedMessage('CenterBeneficiaryStatus.'+centerBeneficiary?.status)}
                            </span>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('centerBeneficiary.comment')}</div>
                        <div className='filed-value'>{centerBeneficiary?.comment}</div>
                    </div>
                </div>
            </Dialog>
        </>);
};

export default CenterBeneficiaryViewDialogComponent;