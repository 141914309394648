import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import ServiceListComponent from "../../components/service/ServiceListComponent";

const ServiceListPage = () => {

    const toast = useRef(null);

    return (
        <>
            <Toast ref={toast} />
            <div className="grid h-full">
                <div className="col-12">
                    <div className="card h-full">
                        <ServiceListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceListPage;