
import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import ProviderListComponent from "../../components/provider/ProviderListComponent";

const ProviderListPage = () => {

    const toast = useRef(null);

    return (
        <>
            <Toast ref={toast} />
            <ProviderListComponent />
        </>
    );
};

export default ProviderListPage;


