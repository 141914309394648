import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from '../../service/UserService';
import NoticeEditDialogComponent from './NoticeEditDialogComponent';

const NoticeViewComponent = (props) => {
    const [notice, setNotice] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const toast = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        setNotice(props?.notice)

    }, [props]);

    return (
        <>
            <Toast ref={toast} />

            <div className='w-full grid pcn-data-view-panel mb-5'>
                {!props?.hideTitle &&
                    <div className='col-12 flex align-items-center'>
                        <span className='entity-view-subtitle mr-2'>{translatedMessage("notice.details")}</span>
                        <Button
                            text
                            className="pcn-button-slim p-button p-button-icon-only"
                            onClick={() => setShowEditDialog(true)}
                            visible={hasPermission('NOTICE_EDIT') && props?.showEdit}
                            disabled={!notice?.actions?.ADD_NOTICE}
                        >
                            <div className='flex align-items-center'>
                                <FontAwesomeIcon icon='fa-solid fa-pencil' />
                            </div>
                        </Button>
                    </div>
                }
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('notice.noticeType')}</div>
                    <div className='filed-value'>{notice?.noticeType.label}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('notice.noticeDate')}</div>
                    <div className='filed-value'>{GeneralUtils.formatDate(notice?.noticeDate)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('notice.reporterType')}</div>
                    <div className='filed-value'>{notice?.reporterType.label}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('notice.reportMode')}</div>
                    <div className='filed-value'>{notice?.reportMode.label}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('beneficiary.beneficiary')}</div>
                    <div className='filed-value'>{
                        notice?.beneficiary && hasPermission('BENEFICIARY_VIEW')
                            ? <Button text label={notice?.beneficiary?.profile?.fullName} className="aspc-button-filed-text p-button text-align-left"
                                onClick={() => navigate(`/beneficiary/${notice?.beneficiary.id}`)} />
                            : notice?.beneficiary?.profile?.fullName || '-'
                    }</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('notice.reporterProfile')}</div>
                    <div className='filed-value'>{
                        notice?.reporterProfile && hasPermission('PROFILE_VIEW')
                            ? <Button text label={notice?.reporterProfile?.fullName} className="aspc-button-filed-text p-button text-align-left"
                                onClick={() => navigate(`/profile/${notice?.reporterProfile.id}`)} />
                            : notice?.reporterProfile?.fullName || '-'
                    }</div>
                </div>
                <div className='col-12'>
                    <div className='filed-label'>{translatedMessage('notice.subject')}</div>
                    <div className='filed-value'>{notice?.subject}</div>
                </div>
            </div>

            <NoticeEditDialogComponent
                value={notice}
                visible={showEditDialog}
                afterSave={(savedData) => setNotice(savedData)}
                visibleSetter={() => setShowEditDialog(false)}
            />
        </>
    )
};

export default NoticeViewComponent;
