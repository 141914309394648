import React, { useState, useEffect } from 'react';
import { getIn, useFormik } from "formik";
import { translatedMessage } from '../../service/LanguageService';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { MonitoringService } from '../../service/MonitoringService';
import BeneficiaryAutocompleteFieldComponent from '../profile/BeneficiaryAutocompleteFieldComponent';

let emptyRecord = {
    id: null,
    type: null,
    conclusion: null,
    startDate: null,
    endDate: null,
    folder: null,
    beneficiary: null
};

const MonitoringEditComponent = (props) => {
    const [monitoring, setMonitoring] = useState()
    const [monitoringTypeList, setMonitoringTypeList] = useState([])
    const [monitoringConclusionList, setMonitoringConclusionList] = useState([])

    const monitoringService = new MonitoringService();

    useEffect(() => {
        const getMonitoringTypeList = async () => {
            const _monitoringTypes = await EnumService.getEntityLookupByField("NOM:MONITORING_TYPE")
            setMonitoringTypeList(_monitoringTypes)
        }

        const getMonitoringConclusionList = async () => {
            const _monitoringConclusionList = await EnumService.getEntityLookupByField("NOM:MONITORING_CONCLUSION")
            setMonitoringConclusionList(_monitoringConclusionList)
        }

        const setupMonitoring = async () => {
            let _monitoring = props.value;
            if (!_monitoring) {
                _monitoring = { ...emptyRecord };
                if (props?.beneficiary) {
                    _monitoring.beneficiary = props?.beneficiary;
                }
            } else {
                _monitoring = GeneralUtils.emptyInNullForObjectFields(_monitoring);
            }
            setMonitoring(_monitoring);

            if (props?.monitoringTypeList) {
                setMonitoringTypeList(props?.monitoringTypeList);
            } else {
                await getMonitoringTypeList();
            }
            if (props?.monitoringReporterTypeList) {
                setMonitoringConclusionList(props?.monitoringConclusionList)
            } else {
                await getMonitoringConclusionList();
            }
        }

        setupMonitoring();
    }, [props]);

    const formik = useFormik({
        initialValues: monitoring ? monitoring : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.startDate) {
                errors.startDate = translatedMessage("form.error.startDate.required");
            }
            if (!data.type) {
                errors.type = translatedMessage("form.error.monitoringType.required");
            }
            if (!data.conclusion) {
                errors.conclusion = translatedMessage("form.error.conclusion.required");
            }
            if (!data.beneficiary) {
                errors.beneficiary = translatedMessage("form.error.beneficiary.required");
            }
            return errors;
        },
        onSubmit: (data) => {
            monitoringService.saveMonitoring(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const handleBeneficiaryChange = async (_beneficiary) => {
        setMonitoring({ ...formik.values, beneficiary: _beneficiary })
    }

    return (
        <>
            <div className="flex flex-column align-items-center">
                <div className="col-12">
                    <form id="profile-form" onSubmit={formik.handleSubmit}>
                        <div className='grid'>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="type.code" name="type.code" value={formik.values.type?.code} onChange={formik.handleChange}
                                            options={monitoringTypeList} optionValue='code' className={classNames({ 'p-invalid': isFormFieldValid('type') })} />
                                        <label htmlFor="type.code" className={classNames({ 'p-error': isFormFieldValid('type') })}>
                                            {translatedMessage('monitoring.monitoringType')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('type')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="conclusion.code" name="conclusion.code" value={formik.values.conclusion?.code} onChange={formik.handleChange}
                                            options={monitoringConclusionList} optionValue='code' className={classNames({ 'p-invalid': isFormFieldValid('conclusion') })} />
                                        <label htmlFor="conclusion.code" className={classNames({ 'p-error': isFormFieldValid('conclusion') })}>
                                            {translatedMessage('monitoring.conclusion')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('conclusion')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="startDate" name="startDate" value={formik.values.startDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                            className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} />
                                        <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                            {translatedMessage("generic.startDate")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('startDate')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="endDate" name="endDate" value={formik.values.endDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                            className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} />
                                        <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                            {translatedMessage("generic.endDate")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('endDate')}
                            </div>

                            <BeneficiaryAutocompleteFieldComponent
                                beneficiary={monitoring?.beneficiary}
                                setBeneficiary={(e) => handleBeneficiaryChange(e)}
                                validationError={isFormFieldValid('beneficiary')}
                                title={translatedMessage('monitoring.beneficiary') + ' *'}
                                disabled={props?.beneficiary}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default MonitoringEditComponent;
