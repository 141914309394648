import React, { useState, useEffect } from 'react';
import { useFormik, getIn, setIn } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import ValidationService from '../../service/ValidationServices';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';

import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { BeneficiaryService } from '../../service/BeneficiaryService';

let emptyRecord = {
    id: null,
    registrationNumber: '',
    profile: {
        hasPin: true,
        pin: '',
        firstName: '',
        lastName: '',
        birthCounty: null,
        birthEnvironmentType: '',
        birthday: '',
        gender: ''
    }
};

const BeneficiaryEditComponent = (props) => {
    const [beneficiary, setBeneficiary] = useState(emptyRecord)
    const [counties, setCounties] = useState([])
    const [envTypes, setEnvTypes] = useState([])
    const [genders, setGenders] = useState([])
    const [filteredentityLookups, setFilteredentityLookups] = useState([]);

    const beneficiaryService = new BeneficiaryService();

    useEffect(() => {
        const getEnvTypes = async () => {
            const response = await EnumService.getEnumByName("EnvironmentType");
            setEnvTypes(response)
        }

        const getGenders = async () => {
            const response = await EnumService.getEnumByName("Gender");
            setGenders(response)
        }

        const getCounty = async () => {
            const _counties = await EnumService.getEntityLookupByField("NOM:COUNTY")
            setCounties(_counties)
        }

        const computeProfile = async () => {
            await getEnvTypes()
            await getGenders()
            await getCounty()

            let _beneficiary = { ...emptyRecord }
            if (props?.value) {
                _beneficiary = GeneralUtils.emptyInNullForObjectFields(props.value)
            }

            setBeneficiary(_beneficiary)
        }

        computeProfile()
    }, [props]);

    const formik = useFormik({
        initialValues: beneficiary ? beneficiary : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (props?.isBeneficiary && (!data.registrationNumber || !data.registrationNumber.trim())) {
                errors.registrationNumber = translatedMessage("form.error.registrationNumber.required");
            }

            if (props?.isBeneficiary && !data.registrationDate) {
                errors.registrationDate = translatedMessage("form.error.registrationDate.required");
            }            

            if (!data.profile.firstName || !data.profile.firstName.trim()) {
                errors = setIn(errors, 'profile.firstName', translatedMessage('form.error.firstName.required'));
            }

            if (!data.profile.lastName || !data.profile.lastName.trim()) {
                errors = setIn(errors, 'profile.lastName', translatedMessage('form.error.lastName.required'));
            }

            if (!data.profile.pin) {
                if (data.profile.hasPin) {
                    errors = setIn(errors, 'profile.pin', translatedMessage('form.error.pin.required'));
                }
            } else {
                let validationResponse = ValidationService.validateCNP(data.profile.pin)
                if (!validationResponse.success) {
                    errors = setIn(errors, 'profile.pin', translatedMessage(validationResponse.message));
                } else {
                    data.profile.birthday = GeneralUtils.computeBirthDayFromPin(data.profile.pin)
                    data.profile.gender = GeneralUtils.computeGenderFromPin(data.profile.pin)
                    setBeneficiary(data)
                }
            }

            if (!data.profile.birthday) {
                errors = setIn(errors, 'profile.birthday', translatedMessage('form.error.birthday.required'));
            }

            if (!data.profile.gender) {
                errors = setIn(errors, 'profile.gender', translatedMessage('form.error.gender.required'));
            }

            if (!data.profile.birthCounty || !data.profile.birthCounty.value) {
                errors = setIn(errors, 'profile.birthCounty', translatedMessage('form.error.birthCounty.required'));
            }

            if (!data.profile.birthEnvironmentType) {
                errors = setIn(errors, 'profile.birthEnvironmentType', translatedMessage('form.error.birthEnvironmentType.required'));
            }

            return errors;
        },
        onSubmit: (data) => {
            if (props?.isBeneficiary) {
                beneficiaryService.saveBeneficiary(data)
                    .then(savedData => {
                        if (typeof props.afterSave === 'function') {
                            props.afterSave(savedData, true);
                        }
                    })
                    .catch(error => {
                        props.afterSave(null, false, translatedMessage(error));
                    });
            }
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const handleNumberValidation = (event) => {
        let charCode = event.nativeEvent.data;
        if (charCode === null || /^[0-9\b]+$/.test(charCode)) {
            formik.handleChange(event);
        }
    }

    const handleHasPinCheck = async (data) => {
        formik.setFieldValue("profile.hasPin", data.checked)
    }

    const handleListChange = async (data, field) => {
        // console.log("handleListChange")
        formik.setFieldValue(field, data.value)
    }

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredentityLookups(_filteredItems);
    }

    return (
        <>
            <div className="flex flex-column align-items-center">
                <div className="col-12">
                    <form id="profile-form" onSubmit={formik.handleSubmit}>
                        <div className='grid'>
                            {props?.isBeneficiary &&
                                <>
                                    <div className="col-12 md:col-6">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <InputText type="text" name="registrationNumber" id="registrationNumber"
                                                    value={formik.values.registrationNumber} onChange={formik.handleChange} autoFocus
                                                    className={classNames({ 'p-invalid': isFormFieldValid('registrationNumber') })} />
                                                <label htmlFor="registrationNumber" className={classNames({ 'p-error': isFormFieldValid('registrationNumber') })}>
                                                    {translatedMessage("beneficiary.registrationNumber")} *
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('registrationNumber')}
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <Calendar id="registrationDate" name="registrationDate" value={formik.values.registrationDate} onChange={formik.handleChange}
                                                    dateFormat="dd-mm-yy" className={classNames({ 'p-invalid': isFormFieldValid('registrationDate') })} />
                                                <label htmlFor="birthday" className={classNames({ 'p-error': isFormFieldValid('registrationDate') })}>
                                                    {translatedMessage("beneficiary.registrationDate")} *
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('registrationDate')}
                                    </div>
                                </>
                            }

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="profile.lastName" id="profile.lastName"
                                            value={formik.values.profile.lastName} onChange={formik.handleChange} autoFocus={!props?.isBeneficiary}
                                            className={classNames({ 'p-invalid': isFormFieldValid('profile.lastName') })} />
                                        <label htmlFor="profile.lastName" className={classNames({ 'p-error': isFormFieldValid('profile.lastName') })}>
                                            {translatedMessage("generic.lastName")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('profile.lastName')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="profile.firstName" id="profile.firstName"
                                            value={formik.values.profile.firstName} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('profile.firstName') })} />
                                        <label htmlFor="profile.firstName" className={classNames({ 'p-error': isFormFieldValid('profile.firstName') })}>
                                            {translatedMessage("generic.firstName")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('profile.firstName')}
                            </div>

                            <div className="col-12 md:col-6 flex align-items-center">
                                <div className="p-inputgroup">
                                    <span>
                                        <Checkbox inputId="profile.hasPin" name="profile.hasPin" checked={formik.values.profile.hasPin}
                                            onChange={handleHasPinCheck} className={classNames({ 'p-invalid': isFormFieldValid('profile.hasPin') })} />
                                        <label htmlFor="profile.hasPin" className={"ml-2 text-form-label ".concat(classNames({ 'p-error': isFormFieldValid('profile.hasPin') }))}>
                                            {translatedMessage("profile.hasPin")} *
                                        </label>
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="profile.pin" id="profile.pin"
                                            value={formik.values.profile.pin} onChange={handleNumberValidation}
                                            className={classNames({ 'p-invalid': isFormFieldValid('profile.pin') })}
                                            maxLength={13} disabled={!formik.values.profile.hasPin} />
                                        <label htmlFor="profile.pin" className={classNames({ 'p-error': isFormFieldValid('profile.pin') })}>
                                            {translatedMessage("profile.pin")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('profile.pin')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="profile.birthday" name="profile.birthday" value={formik.values.profile.birthday} onChange={formik.handleChange}
                                            dateFormat="dd-mm-yy" className={classNames({ 'p-invalid': isFormFieldValid('profile.birthday') })} />
                                        <label htmlFor="birthday" className={classNames({ 'p-error': isFormFieldValid('profile.birthday') })}>
                                            {translatedMessage("profile.birthday")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('profile.birthday')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="profile.gender" name="profile.gender" value={formik.values.profile.gender}
                                            options={genders} onChange={(e) => formik.setFieldValue("profile.gender", e.value)} />
                                        <label htmlFor="profile.gender" className={classNames({ 'p-error': isFormFieldValid('profile.gender') })}>
                                            {translatedMessage("profile.gender")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('profile.gender')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <AutoComplete
                                            dropdown
                                            id="profile.birthCounty"
                                            name="profile.birthCounty"
                                            value={formik.values.profile.birthCounty}
                                            suggestions={filteredentityLookups}
                                            completeMethod={(e) => searchListItems(e, counties)}
                                            virtualScrollerOptions={{ itemSize: 38 }}
                                            field="label"
                                            onChange={(d) => handleListChange(d, "profile.birthCounty")}
                                            emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                            className={classNames({ 'p-invalid': isFormFieldValid('profile.birthCounty') })}
                                        />
                                        <label htmlFor="profile.birthCounty" className={classNames({ 'p-error': isFormFieldValid('profile.birthCounty') })}>
                                            {translatedMessage("profile.birthCounty")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('profile.birthCounty')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="profile.birthEnvironmentType" name="profile.birthEnvironmentType" value={formik.values.profile.birthEnvironmentType}
                                            options={envTypes} onChange={(e) => formik.setFieldValue("profile.birthEnvironmentType", e.value)} />
                                        <label htmlFor="profile.birthEnvironmentType" className={classNames({ 'p-error': isFormFieldValid('profile.birthEnvironmentType') })}>
                                            {translatedMessage("profile.birthEnvironmentType")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('profile.birthEnvironmentType')}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default BeneficiaryEditComponent;
