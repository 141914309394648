import React from 'react';
import { translatedMessage } from '../../service/LanguageService';

const AddressViewComponent = (props) => {
    return (
        <>
            {props?.address?.id &&
                <div className='w-100'>
                    <div>{props.address.address}</div>
                    <div className='mt-1'>{props.address.locality.label + ", " + translatedMessage("generic.county") + " " + props.address.county.label}</div>
                    {props.address.sirutaCode && <div className='mt-1'>{translatedMessage("address.sirutaCode") + ": " + props.address.sirutaCode}</div>}
                    {props.address.environmentType && 
                        <div className='mt-1'>
                            {translatedMessage("address.environmentType") + ": " + translatedMessage("EnvironmentType."+props.address.environmentType)}
                        </div>
                    }
                </div>
            }
            {!props?.address?.id && "-"}
        </>
    );
};

export default AddressViewComponent;
