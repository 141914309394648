const LanguageService = {
  getCurrentLanguage: () => {
      const lang = localStorage.getItem("_aspc_lang");
      if(!lang){
          return "ro";
      }
      return lang;
  },
  setCurrentLanguage: (lang) => {
      localStorage.setItem("_aspc_lang", lang);     
  },  
};

export default LanguageService;

export function translatedMessage(key) {
  let str =  window.$translate(LanguageService.getCurrentLanguage(), key)
  if(arguments.length > 1) {
    for (let i = 1; i < arguments.length; i++) {
      str = str.replace('{' + (i-1) + '}', arguments[i])
    }
  }  
  return str;
};