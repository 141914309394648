import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import useNavProps from '../../hooks/useNavProps';
import ProfileExtendedViewComponent from '../../components/profile/ProfileExtendedViewComponent';
import { FamilyPlacementService } from '../../service/FamilyPlacementService';
import FamilyPlacementToolbar from './FamilyPlacementToolbar';

const FamilyPlacementProfileExtendedView = () => {
    const [familyPlacement, setFamilyPlacement] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const familyPlacementService = useMemo(() => new FamilyPlacementService(), []);

    const { fpIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', profileIdParam));
        // };

        const getFP = async () => {
            await familyPlacementService.getFamilyPlacement(fpIdParam)
                .then(async (_data) => {
                    setFamilyPlacement(_data);

                    // await checkEditPermission()

                    setNavPropsValue('family-placement', _data.id, _data.profile.fullName)

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getFP();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [familyPlacementService, fpIdParam]);

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <FamilyPlacementToolbar familyPlacement={familyPlacement} code="EXTENDED" />

                            <ProfileExtendedViewComponent entity={familyPlacement.profile} entityName="FAMILY_PLACEMENT"/>
                        </div>
                    </div>
                </div>                
            </>
        );
    }
};

export default FamilyPlacementProfileExtendedView;
