import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { Checkbox } from 'primereact/checkbox';
import { RoleService } from '../../service/RoleService';
import { translatedMessage } from '../../service/LanguageService';
import { useFormik } from 'formik';
import DataTableUtils from '../../utilities/DataTableUtils';
import { hasPermission } from '../../service/UserService';

const emptyRole = {
    id: null,
    name: '',
    description: ''
}

const Roles = () => {
    const [roles, setRoles] = useState(null);
    const [roleDialog, setRoleDialog] = useState(false);
    const [permissionsDialog, setPermissionsDialog] = useState(false);
    const [deleteRoleDialog, setDeleteRoleDialog] = useState(false);
    const [role, setRole] = useState(emptyRole);
    const [allPermissions, setAllPermissions] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const toast = useRef(null);
    const dt = useRef(null);

    const roleService = useMemo(() => new RoleService(), []);

    useEffect(() => {
        roleService.getRoles().then((data) => setRoles(data));
        roleService.getPermissions().then((data) => setAllPermissions(data.map((permission) => { return { 'label': permission.code, 'value': permission.id } })));
    }, [roleService]);

    const openNew = () => {
        setRole(emptyRole);
        setRoleDialog(true);
    };

    const hideDialog = () => {
        setRoleDialog(false);
    };

    const hideDeleteRoleDialog = () => {
        setDeleteRoleDialog(false);
    };

    const hideRolePermissionsDialog = () => {
        setPermissionsDialog(false)
    };

    const editRole = (role) => {
        setRole({ ...role });
        setRoleDialog(true);
    };

    const editRolePermissions = (role) => {
        setRole({ ...role });
        roleService.getRolePermissions(role).then((data) => setRolePermissions(data.map((permission) => permission.id)));
        setPermissionsDialog(true);
    }

    const saveRolePermissions = () => {
        roleService.saveRolePermissions(role, rolePermissions)
            .then(() => {
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.success'), detail: translatedMessage('role.permissions.update.success'), life: 3000 });
            },
                (error) => {
                    let errorMessage = error && error.response && error.response.data && error.response.data.message;
                    toast.current.show({ severity: 'error', summary: translatedMessage('generic.error'), detail: translatedMessage('role.permissions.update.error') + ' : ' + translatedMessage(errorMessage), life: 3000 });
                });
        setPermissionsDialog(false);
    }

    const confirmDeleteRole = (role) => {
        setRole(role);
        setDeleteRoleDialog(true);
    };

    const deleteRole = () => {
        roleService.deleteRole(role)
            .then(() => {
                roleService.getRoles().then((data) => setRoles(data));
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.success'), detail: translatedMessage('generic.delete.success'), life: 3000 });
            },
                (error) => {
                    let errorMessage = error && error.response && error.response.data && error.response.data.message;
                    toast.current.show({ severity: 'error', summary: translatedMessage('generic.error'), detail: translatedMessage('generic.delete.error') + ' : ' + errorMessage, life: 3000 });
                });
        setDeleteRoleDialog(false);
        setRole(emptyRole);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('ROLE_CREATE') && <Button label={translatedMessage('role.newRole')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={openNew} />}
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <></>
        );
    };

    const createdOnBodyTemplate = (rowData) => {
        return DataTableUtils.dateTemplate(new Date(Date.parse(rowData.createdOn)))

    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('ROLE_EDIT') && <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => editRole(rowData)} />}
                {hasPermission('ROLE_EDIT') && <Button icon="pi pi-cog" className="p-button-rounded p-button-info m-1" onClick={() => editRolePermissions(rowData)} />}
                {hasPermission('ROLE_DELETE') && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning m-1" onClick={() => confirmDeleteRole(rowData)} disabled={rowData.id < 1000} />}
            </div>
        );
    };

    const doOnFilter = (data) => { }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage("generic.search.dots")} />
            </span>
        </div>
    );

    const roleDialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage("generic.save")} icon="pi pi-check" className="p-button-text" form="role-form" type="submit" />
        </>
    );
    const deleteRoleDialogFooter = (
        <>
            <Button label={translatedMessage("generic.no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteRoleDialog} />
            <Button label={translatedMessage("generic.yes")} icon="pi pi-check" className="p-button-text" onClick={deleteRole} />
        </>
    );

    const rolePermissionsDialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={hideRolePermissionsDialog} />
            <Button label={translatedMessage("generic.save")} icon="pi pi-check" className="p-button-text" onClick={saveRolePermissions} />
        </>
    );

    const listBoxItemTemplate = (option) => {
        return (
            <div className="p-multiselect-item">
                <Checkbox checked={rolePermissions.indexOf(option.value) !== -1} style={{ marginRight: '0.5em' }}></Checkbox>
                <div>{translatedMessage(`permission.${option.label}`)}</div>
            </div>
        );
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: role ? role : emptyRole,
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = translatedMessage("form.error.nameRo.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            roleService.saveRole(data)
                .then(() => {
                    roleService.getRoles().then((data) => setRoles(data));
                    toast.current.show({ severity: 'success', summary: translatedMessage('generic.success'), detail: translatedMessage('generic.save.success'), life: 3000 });
                }, (error) => {
                    let errorMessage = error && error.response && error.response.data && error.response.data.message;
                    toast.current.show({ severity: 'error', summary: translatedMessage('generic.error'), detail: translatedMessage('generic.save.error') + ': ' + errorMessage, life: 3000 });
                });
            setRoleDialog(false);
            setRole(emptyRole);
            formik.resetForm();
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">{translatedMessage("menu.administration.roles")}</h5>
                    </div>
                    <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={roles}
                        dataKey="id"
                        paginator
                        rows={DataTableUtils.defalRowsPerPage()}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                        globalFilter={globalFilter} onFilter={doOnFilter}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        header={header}
                    >
                        <Column field="id" header={translatedMessage("generic.id")} sortable headerStyle={{ width: '5%', minWidth: '3rem' }}></Column>
                        <Column field="name" header={translatedMessage("generic.nameRo")} sortable headerStyle={{ width: '25%', minWidth: '10rem' }}></Column>
                        <Column field="description" header={translatedMessage("generic.description")} sortable headerStyle={{ width: '25%', minWidth: '10rem' }}></Column>
                        <Column field="createdOn" header={translatedMessage("generic.created.on")} sortable body={createdOnBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={roleDialog} style={{ width: '450px' }} header={translatedMessage("role.details")} modal className="p-fluid" footer={roleDialogFooter} onHide={hideDialog}>
                        <form id="role-form" onSubmit={formik.handleSubmit}>
                            <div className="field">
                                <label htmlFor="name">{translatedMessage("generic.nameRo")} *</label>
                                <InputText type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                {getFormErrorMessage('name')}
                            </div>
                            <div className="field">
                                <label htmlFor="description">{translatedMessage("generic.description")}</label>
                                <InputTextarea id="description" name="description" value={formik.values.description} onChange={formik.handleChange} rows={3} cols={20} />
                            </div>
                        </form>
                    </Dialog>

                    <Dialog visible={deleteRoleDialog} style={{ width: '450px' }} header={`${translatedMessage("generic.delete.confirmation.title")} "${role.name}"`} modal footer={deleteRoleDialogFooter} onHide={hideDeleteRoleDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {role && (<span> {translatedMessage("generic.delete.confirmation.message")}</span>)}
                        </div>
                    </Dialog>

                    <Dialog 
                        visible={permissionsDialog} 
                        header={`${translatedMessage("role.permissions.for")} "${role.name}"`} 
                        modal 
                        className="pcn-dialog p-fluid" 
                        footer={rolePermissionsDialogFooter} 
                        onHide={hideRolePermissionsDialog}
                    >
                        <ListBox 
                            value={rolePermissions} 
                            options={allPermissions} 
                            onChange={(e) => setRolePermissions(e.value)} 
                            multiple 
                            itemTemplate={listBoxItemTemplate} 
                            style={{ width: '100%' }}
                            listStyle={{ maxHeight: '350px' }} 
                        />
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Roles;
