import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationService } from '../service/NotificationService';
import { translatedMessage } from '../service/LanguageService';
import GeneralUtils from '../utilities/GeneralUtils';
import DataTableUtils from '../utilities/DataTableUtils';
import ViewNotificationDialog from '../components/notification/ViewNotificationDialog';

const MyNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [notification, setNotification] = useState({});
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [globalFilter, setGlobalFilter] = useState('');

    const toast = useRef(null);
    const dt = useRef(null);

    const notificationService = useMemo(() => new NotificationService(), []);

    useEffect(() => {
        setIsLoading(true);

        const getNotifications = async () => {
            await notificationService
                .getMyNotifications()
                .then((_notifications) => {                    
                    setNotifications(_notifications);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getNotifications();
    }, [notificationService]);

    const markNotificationAsRead = (_notification) => {
        _notification.isRead = true;
        notifications.find((ntfc) => ntfc.id === _notification.id).read = true;
        setNotifications([...notifications]);
        notificationService
            .markNotificationAsRead(_notification.id)
            .then(() => {})
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const viewNotification = (_notification) => {
        setNotification(_notification);
        setDialogVisible(true);
    };

    const setNotificationDialogVisible = (visible) => {
        setDialogVisible(visible);
        if (visible === false) {
            markNotificationAsRead(notification);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button
                    className="p-button-rounded p-button-primary p-button-icon-only m-1"
                    onClick={() => {
                        viewNotification(rowData);
                    }}
                    tooltip={translatedMessage('generic.view')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                >
                    <FontAwesomeIcon icon="far fa-eye" />
                </Button>
            </div>
        );
    };

    const doOnFilter = (data) => {};

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage('generic.search.dots')} />
            </span>
        </div>
    );

    const subjectTemplate = (_notification) => {
        return <span className={!_notification.read ? 'bold' : ''}>{_notification.subject}</span>;
    };

    const contentTemplate = (_notification) => {
        let cleanContent = GeneralUtils.getHTMLSubstringWithEllipsis(_notification.content, 250)
        return <span dangerouslySetInnerHTML={{ __html: cleanContent }}/>
    };

    const sentDateTemplate = (_notification) => {
        return <span>{GeneralUtils.formatDateTime(_notification.sentDate)}</span>;
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center ">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-0">{translatedMessage('notification.title')}</h3>

                            <DataTable
                                ref={dt}
                                value={notifications}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                globalFilter={globalFilter}
                                onFilter={doOnFilter}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                header={header}
                                responsiveLayout="stack"
                            >
                                <Column 
                                    field="subject" 
                                    header={translatedMessage('notification.subject')} 
                                    body={subjectTemplate} 
                                    sortable 
                                    headerStyle={{ width: '30%', minWidth: '10rem' }}
                                />
                                <Column 
                                    field="content" 
                                    header={translatedMessage('notification.content')} 
                                    body={contentTemplate} 
                                    headerStyle={{ width: '55%', minWidth: '10rem' }}
                                />
                                <Column 
                                    field="sentDate" 
                                    header={translatedMessage('notification.sentDate')} 
                                    body={sentDateTemplate} 
                                    sortable 
                                    headerStyle={{ width: '5%', minWidth: '8rem' }} 
                                />
                                <Column body={actionBodyTemplate}/>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <ViewNotificationDialog
                    notification={notification}
                    visible={dialogVisible}
                    visibleSetter={setNotificationDialogVisible}
                />
            </>
        );
    }
};

export default MyNotifications;
