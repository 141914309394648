import React, { useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import ServiceDataTableUtils from "../../utilities/ServiceDataTableUtils";
import ServiceSupplyListComponent from './ServiceSupplyListComponent';

const ServiceSupplyListDialogComponent = (props) => {
    const toast = useRef(null);

    useEffect(() => {
        //console.log(props)
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );


    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={props?.value?.beneficiary?.profile.fullName + " - " + ServiceDataTableUtils.getServiceDisplayName(props?.value?.service)}
                modal
                className="pcn-dialog-big p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <ServiceSupplyListComponent selectedServiceExpertBeneficiary={props?.value}/>
            </Dialog>
        </>);
};

export default ServiceSupplyListDialogComponent;
