import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { translatedMessage } from "../../service/LanguageService";
import { hasPermission } from "../../service/UserService";
import { DashboardService } from "../../service/DashboardService";
import { FilterMatchMode } from "primereact/api";

const DashboardBeneficiaryList = (props) => {
    const [loading, setLoading] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState(null);

    const dashboardService = useMemo(() => new DashboardService(), []);

    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "id",
        sortOrder: -1,
        filters: {
            'profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
            departmentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            responsibleName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            assistantName: { value: null, matchMode: FilterMatchMode.CONTAINS }
        },
        filterRoleValues: []
    });

    const propToColumnMap = {
        'profile.fullName': 'p.first_name, p.last_name',
        responsibleName: "responsibleName",
        assistantName: "assistantName"
    };

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        lazyLoadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {}
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC"
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id'
        criteria.fullName = lazyParams.filters['profile.fullName'].value
        criteria.responsibleName = lazyParams.filters.responsibleName.value
        criteria.assistantName = lazyParams.filters.assistantName.value

        return criteria
    }

    const loadData = async () => {
        let criteria = setCriteria();

        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await dashboardService.getBeneficiaryList(criteria)
            .then((data) => {
                setBeneficiaries(data.items);
                setTotalRecords(data.totalCount);
                setLoading(false);
            })
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const lazyLoadData = () => {
        setLoading(true);
        loadData();
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <div>{rowData.profile.fullName}</div>
                <div className='mt-1' title={translatedMessage("beneficiary.registrationNumber")}>{rowData.registrationNumber}</div>
            </>
        )
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => navigate(`/beneficiary/${rowData.id}`)}
                            title={translatedMessage("generic.view")} />
                    }
                </div>
            </>
        );
    };

    return (
        <div className="card mb-3">
            <Toast ref={toast} />
            <div className='w-full text-align-left'>
                <h5>{props.pageTitle ? props.pageTitle : translatedMessage("beneficiary.beneficiaries")}</h5>
            </div>

            <DataTable
                ref={dt}
                value={beneficiaries}
                lazy
                dataKey="id"
                paginator
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={10}
                sortField={lazyParams.sortField} 
                sortOrder={lazyParams.sortOrder}
                onPage={onPage} onSort={onSort} onFilter={onFilter}
                loading={loading}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                filterDisplay="row"
                filters={lazyParams.filters}
                alwaysShowPaginator={false}
            >
                <Column
                    field="profile.fullName"
                    header={translatedMessage("generic.name")}
                    sortable
                    body={nameBodyTemplate}
                    headerStyle={{ width: '30%', minWidth: '8rem' }}
                    filter
                    filterField="profile.fullName"
                    showFilterMenu={false}
                />
                <Column
                    field="responsibleName"
                    header={translatedMessage("beneficiary.responsible")}
                    sortable
                    headerStyle={{ width: '30%', minWidth: '8rem' }}
                    filter
                    filterField="responsibleName"
                    showFilterMenu={false}
                />
                <Column
                    field="assistantName"
                    header={translatedMessage("beneficiary.socialAssistant")}
                    sortable
                    headerStyle={{ width: '30%', minWidth: '8rem' }}
                    filter
                    filterField="assistantName"
                    showFilterMenu={false}
                />
                <Column body={actionBodyTemplate} />
            </DataTable>
        </div>
    );
};

export default DashboardBeneficiaryList;
