import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { NoticeService } from "../../service/NoticeService";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import { hasPermission } from '../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCurrentUser } from '../../service/UserService';
import GeneralUtils from "../../utilities/GeneralUtils";
import EnumService from "../../service/EnumService";
import DeleteModalComponent from "../DeleteModalComponent";
import NoticeEditDialogComponent from "./NoticeEditDialogComponent";
import DataTableUtils from "../../utilities/DataTableUtils";
import ExportButtonComponent from "../ExportButtonComponent";
import OrganizationDataTableUtils from "../../utilities/OrganizationDataTableUtils";
import OrganizationProfileChooserDialog from "../organizationProfile/OrganizationProfileChooserDialog";
import NoticeViewDialogComponent from "./NoticeViewDialogComponent";
import SecurityService from '../../service/SecurityService';

const sortOrderHeader = ["noticeType", "noticeDate", "reporterType", "reportMode", "beneficiary", "reporterProfile", "subject", "organizationProfile", "createdOn"]

const tableHeader = [
    translatedMessage("notice.noticeType"),
    translatedMessage("notice.noticeDate"),
    translatedMessage("notice.reporterType"),
    translatedMessage("notice.reportMode"),
    translatedMessage("notice.beneficiary"),
    translatedMessage("notice.reporterProfile"),
    translatedMessage("notice.subject"),
    translatedMessage("notice.responsible"),
    translatedMessage("generic.created.on"),
]

const NoticeListComponent = (props) => {
    const [notices, setNotices] = useState([]);
    const [selectedNotice, setSelectedNotice] = useState(null);

    const [noticeTypeList, setNoticeTypeList] = useState([])
    const [noticeReporterTypeList, setNoticeReporterTypeList] = useState([])
    const [noticeReportModeList, setNoticeReportModeList] = useState([])

    const [canCreateNotice, setCanCreateNotice] = useState(true);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            reporterName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            beneficiaryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            noticeType: { value: null, matchMode: FilterMatchMode.IN },
            noticeReporterType: { value: null, matchMode: FilterMatchMode.IN },
            noticeReportMode: { value: null, matchMode: FilterMatchMode.IN },
            organizationProfile: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);

    const propToColumnMap = {
        reporterName: "reporter_name",
        beneficiaryName: "beneficiary_name",
        noticeType: "type_id",
        noticeReporterType: "reporter_type_id",
        noticeReportMode: "mode_id",
        organizationProfile: "responsible_expert",
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [editResponsibleDialogVisible, setEditResponsibleDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const navigate = useNavigate();

    const noticeService = useMemo(() => new NoticeService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getNoticeTypes = async () => {
            const _noticeTypes = await EnumService.getEntityLookupByField("NOM:NOTICE_TYPE")
            setNoticeTypeList(_noticeTypes)
        }

        const getNoticeReporterList = async () => {
            const _noticeReporterList = await EnumService.getEntityLookupByField("NOM:NOTICE_REPORTER")
            setNoticeReporterTypeList(_noticeReporterList)
        }

        const getNoticeReportModeList = async () => {
            const _noticeReportModeList = await EnumService.getEntityLookupByField("NOM:NOTICE_REPORT_MODE")
            setNoticeReportModeList(_noticeReportModeList)
        }

        const checkCanCreateNotice = async () => {
            setCanCreateNotice(await SecurityService.check('ADD_NOTICE'));
        };

        getNoticeTypes();
        getNoticeReporterList();
        getNoticeReportModeList();
        checkCanCreateNotice();
        setFirstLoading(false);
    }, [noticeService, props]);

    useEffect(() => {
        loadNotices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues("NoticeList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";
        criteria.beneficiaryName = lazyParams.filters.beneficiaryName.value;
        criteria.reporterProfileName = lazyParams.filters.reporterName.value;
        criteria.noticeTypeIds = lazyParams.filters.noticeType.value;
        criteria.reporterTypeIds = lazyParams.filters.noticeReporterType.value;
        criteria.reporterModeIds = lazyParams.filters.noticeReportMode.value;
        criteria.responsibleExpert = lazyParams.filters.organizationProfile.value;
        criteria.beneficiaryId = props?.beneficiary?.id;
        return criteria;
    };

    const loadNotices = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await noticeService
            .getNoticesByCriteria(criteria)
            .then((data) => {
                setNotices(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                //setError(true)
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const saveNoticeOrganizationProfile = async (_organizationProfile) => {
        let _notice = { ...selectedNotice }
        _notice.organizationProfile = _organizationProfile;

        await noticeService.saveNotice(_notice)
            .then(savedData => {
                loadNotices();
            })
            .catch(error => {
                toast.current.show({ severity: "error", summary: translatedMessage(error), life: 5000 });
            });
    }

    const updateList = async (savedNotice, isNew, error) => {
        if (error) {
            toast.current.show({ severity: "error", summary: error, life: 5000 });
        }
        loadNotices();
    };

    const showNoticeViewPage = (_notice) => {
        if (props?.showViewInDialog) {
            setSelectedNotice(_notice)
            setViewDialogVisible(true)
        } else {
            navigate(`/notice/${_notice.id}`);
        }
    };

    const showNewDialog = () => {
        setSelectedNotice(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (_notice) => {
        setSelectedNotice(_notice);
        setEditDialogVisible(true);
    };

    const showEditResponsible = (_notice) => {
        setSelectedNotice({ ..._notice });
        setEditResponsibleDialogVisible(true);
    };

    const showDeleteDialog = (_notice) => {
        setSelectedNotice(_notice);
        setDeleteDialogVisible(true);
    };

    const deleteNotice = () => {
        noticeService
            .deleteNotice(selectedNotice.id)
            .then(() => {
                let _notices = notices.filter(
                    (item) => item.id !== selectedNotice.id
                );
                setNotices(_notices);
                toast.current.show({
                    severity: "success",
                    summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedNotice(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("NOTICE_CREATE") &&
                        (
                            <Button
                                label={translatedMessage("notice.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreateNotice || (props?.beneficiary && !props?.beneficiary?.actions?.ADD_NOTICE)}
                            />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            noticeService
                .getNoticesByCriteria(criteria)
                .then((data) => {
                    let exportData = data.items.map((item) => ({ ...item }));
                    exportData.map((item) => {
                        let exportItem = item
                        exportItem.noticeType = item.noticeType?.label
                        exportItem.noticeDate = item.noticeDate
                        exportItem.reporterType = item.reporterType?.label
                        exportItem.reportMode = item.reportMode?.label
                        exportItem.beneficiary = item.beneficiary?.profile?.fullName
                        exportItem.reporterProfile = item.reporterProfile?.fullName
                        exportItem.createdOn = GeneralUtils.formatDate(item.createdOn)
                        exportItem.organizationProfile = item.organizationProfile?.profile?.fullName
                        return exportItem;
                    });

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={GeneralUtils.computeExportFileName("notice.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-notice justify-content-end">
                {hasPermission("NOTICE_VIEW") &&
                    <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-primary m-1"
                        onClick={() => showNoticeViewPage(rowData)}
                        tooltip={translatedMessage("generic.view")}
                        tooltipOptions={{ showOnDisabled: true, position: "top" }}
                        disabled={!rowData.actions?.VIEW_NOTICE}>
                    </Button>

                }
                {hasPermission("NOTICE_EDIT") &&
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-info m-1"
                        onClick={() => showEditDialog(rowData)}
                        tooltip={translatedMessage("generic.edit")}
                        tooltipOptions={{ showOnDisabled: true, position: "top" }}
                        disabled={!rowData.actions?.ADD_NOTICE}
                    />
                }
                {hasPermission('NOTICE_EDIT') &&
                    <Button
                        className="p-button-rounded p-button-info p-button-icon-only m-1"
                        onClick={() => showEditResponsible(rowData)}
                        title={translatedMessage("notice.responsible")}
                        disabled={!rowData.actions?.ADD_NOTICE}>
                        <FontAwesomeIcon icon='fa-solid fa-user-tie' />
                    </Button>
                }
                {hasPermission("NOTICE_CREATE") &&
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning m-1"
                        onClick={() => showDeleteDialog(rowData)}
                        tooltip={translatedMessage("generic.delete")}
                        tooltipOptions={{ showOnDisabled: true, position: "top" }}
                        disabled={!rowData.actions?.ADD_NOTICE}
                    />
                }
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    const beneficiaryBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button
                            text
                            label={rowData.beneficiary?.profile?.fullName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/beneficiary/${rowData.beneficiary?.id}/notice`)}
                        />
                    }
                    {!hasPermission('BENEFICIARY_VIEW') &&
                        rowData.beneficiary?.profile?.fullName
                    }
                </div>
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateTemplate(rowData.noticeDate)
    }

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-notice">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">{props.pageTitle ? props.pageTitle : translatedMessage("notice.list")}</h5>
                    </div>

                    <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0" ></Toolbar>

                    <DataTable
                        ref={dt}
                        value={notices}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        scroll="true"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        <Column
                            field="noticeType"
                            header={translatedMessage("notice.noticeType")}
                            body={(e) => e.noticeType?.label}
                            headerStyle={{ width: "10%", minWidth: "8rem" }}
                            filter
                            filterElement={(e) => DataTableUtils.entityLookupFilterTemplate(e, noticeTypeList, translatedMessage('notice.noticeType'))}
                            filterHeaderClassName="pcn-filter-column-250"
                            showFilterMenu={false}
                        />
                        <Column
                            field="reporterType"
                            header={translatedMessage("notice.reporterType")}
                            body={(e) => e.reporterType?.label}
                            headerStyle={{ width: "10%", minWidth: "8rem" }}
                            filterField="noticeReporterType"
                            filter
                            filterElement={(e) => DataTableUtils.entityLookupFilterTemplate(e, noticeReporterTypeList, translatedMessage('notice.reporterType'))}
                            filterHeaderClassName="pcn-filter-column-250"
                            showFilterMenu={false}
                        />
                        <Column
                            field="reportMode"
                            header={translatedMessage("notice.reportMode")}
                            body={(e) => e.reportMode?.label}
                            headerStyle={{ width: "10%", minWidth: "8rem" }}
                            filterField="noticeReportMode"
                            filter
                            filterElement={(e) => DataTableUtils.entityLookupFilterTemplate(e, noticeReportModeList, translatedMessage('notice.reportMode'))}
                            filterHeaderClassName="pcn-filter-column-250"
                            showFilterMenu={false}
                        />
                        {!props?.reporter &&
                            <Column
                                field="reporterName"
                                header={translatedMessage("notice.reporter")}
                                headerStyle={{ width: "15%", minWidth: "8rem" }}
                                body={(e) => e.reporterProfile?.fullName}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        {!props?.beneficiary &&
                            <Column
                                field="beneficiaryName"
                                header={translatedMessage("notice.beneficiary")}
                                headerStyle={{ width: "15%", minWidth: "8rem" }}
                                body={beneficiaryBodyTemplate}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        <Column
                            field="organizationProfile"
                            header={translatedMessage("notice.responsible")}
                            headerStyle={{ width: "15%", minWidth: "8rem" }}
                            body={(e) => OrganizationDataTableUtils.getOrganizationProfileName(e.organizationProfile)}
                            sortable
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="noticeDate"
                            header={translatedMessage("notice.noticeDate")}
                            headerStyle={{ width: "5%", minWidth: "8rem" }}
                            body={dateBodyTemplate}
                            sortable
                        />
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <NoticeEditDialogComponent
                        value={selectedNotice}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={setEditDialogVisible}
                        beneficiary={props?.beneficiary}
                        noticeTypeList={noticeTypeList}
                        noticeReporterTypeList={noticeReporterTypeList}
                        noticeReportModeList={noticeReportModeList}
                    />

                    <NoticeViewDialogComponent
                        value={selectedNotice}
                        visible={viewDialogVisible}
                        visibleSetter={setViewDialogVisible}
                    />

                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedNotice ? selectedNotice.noticeType.label + ' / ' + selectedNotice.reportMode.label + ' / ' + GeneralUtils.formatDate(selectedNotice.noticeDate) : ""}
                        closeDialog={() => { setDeleteDialogVisible(false); }}
                        deleteRecord={() => deleteNotice()}
                    />

                    <OrganizationProfileChooserDialog
                        selectedOrganizationProfile={selectedNotice?.organizationProfile}
                        organization={getCurrentUser().organizationProfile?.organization}
                        visible={editResponsibleDialogVisible}
                        visibleSetter={setEditResponsibleDialogVisible}
                        saveFunction={saveNoticeOrganizationProfile}
                        title={translatedMessage('notice.responsible')}
                    />
                </>
            )}
        </>
    );
};

export default NoticeListComponent;
