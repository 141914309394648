const ValidationService = {
  validateCNP: (cnp) => {
    if (cnp.toString().length !== 13) {
      return { success: false, message: "form.error.pin.length" };
    }

    if (isNaN(cnp.toString()) && isNaN(parseFloat(cnp.toString()))) {
      return { success: false, message: "form.error.pin.numeric" };
    }

    let hashTable = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    let hashResult = 0;
    let cnpArray = cnp.toString().split("");

    for (let i = 0; i < 13; i++) {
      cnpArray[i] = parseInt(cnpArray[i]);
      if (i < 12) {
        hashResult = hashResult + (cnpArray[i] * hashTable[i]);
      }
    }

    hashResult = hashResult % 11;
    if (hashResult === 10) {
      hashResult = 1;
    }

    let year = (cnpArray[1] * 10) + cnpArray[2];
    switch (cnpArray[0]) {
      case 1:
      case 2:
        year += 1900;
        break;
      case 3:
      case 4:
        year += 1800;
        break;
      case 5:
      case 6:
        year += 2000;
        break;
      case 7:
      case 8:
      case 9:
        year += 2000;
        if (year > (parseInt(new Date().getYear(), 10) - 14)) {
          year -= 100;
        }
        break;
      default:
        return false;
    }

    // console.log(year);

    // check the year
    if (year < 1800 || year > 2099) {
      return { success: false, message: "form.error.pin.year" };
    }

    // check the month
    let month = (cnpArray[3] * 10) + cnpArray[4];
    if (month < 1 || month > 12) {
      return { success: false, message: "form.error.pin.month" };
    }

    // check the day
    let day = (cnpArray[5] * 10) + cnpArray[6];
    if (day < 1 || day > 31) {
      return { success: false, message: "form.error.pin.day" };
    } else {
      if ([4, 6, 9, 11].indexOf(month) > 0 && day > 30) {
        return { success: false, message: "form.error.pin.day" };
      } else if (month === 2) {
        let leapYear = ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) ? true : false;

        if (leapYear && day > 29) {
          return { success: false, message: "form.error.pin.day" };
        } else if (!leapYear && day > 28) {
          return { success: false, message: "form.error.pin.day" };
        }
      }
    }

    // check the validation character
    if (cnpArray[12] !== hashResult) {
      return { success: false, message: "form.error.pin.validation" };
    } else {
      return { success: true, message: "" };
    }
  }

};

export default ValidationService;