import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { translatedMessage } from '../../service/LanguageService';

import { Toast } from 'primereact/toast';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { BeneficiaryService } from '../../service/BeneficiaryService';
import BeneficiaryToolbar from './BeneficiaryToolbar';

const BeneficiaryFileList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [beneficiary, setBeneficiary] = useState(null);
    const [error] = useState({ isError: false, message: '' });
    const toast = useRef(null);
    const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

    const { beneficiaryIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_BENEFICIARY', beneficiaryIdParam));
        // };       

        const getBeneficiary = async () => {
            await beneficiaryService.getBeneficiary(beneficiaryIdParam)
                .then(async (_data) => {
                    setBeneficiary(_data);

                    // await checkEditPermission()

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getBeneficiary();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiaryService, beneficiaryIdParam]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <div className="grid h-full">
                <Toast ref={toast}></Toast>
                <div className="col-12">
                    <div className="card h-full">
                        <BeneficiaryToolbar beneficiary={beneficiary} code="FILES" />

                        <StorageFolderFileList
                            folderId={beneficiary?.profile?.folderId}
                            folderName={beneficiary?.profile?.fullName}
                            showUploadButton={true}
                            pageTitle={translatedMessage("beneficiary.files")}
                            multiple={false}
                            entityName={"PROFILE"}
                            hasFileProperties={true}
                            readOnly={false} //FIXME: based on the rights
                            beneficiary={beneficiary}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default BeneficiaryFileList;
