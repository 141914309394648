import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { ProfileService } from '../../service/ProfileService';
import ProfileViewComponent from './ProfileViewComponent';
import ProfileExtendedViewComponent from './ProfileExtendedViewComponent';
import { SelectButton } from 'primereact/selectbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProfileViewDialogComponent = (props) => {
    const [profile, setProfile] = useState(null);

    const _sections = [
        { label: translatedMessage('generic.details'), code: 'DETAIL', icon: 'fa-regular fa-eye' },
        { label: translatedMessage('profile.extended'), code: 'EXTENDED', icon: 'fa-solid fa-up-right-and-down-left-from-center' },
        { label: translatedMessage('person.goTO.profile'), code: 'TO_PROFILE', icon: 'fa-solid fa-arrow-up-right-from-square' }
    ];

    let _section = _sections[0]
    const [section, setSection] = useState(_section)
    const [sections, setSections] = useState(_sections)

    const toast = useRef(null);
    const navigate = useNavigate();

    const profileService = useMemo(() => new ProfileService(), []);

    useEffect(() => {
        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', profileIdParam));
        // };        

        const getProfile = async () => {
            await profileService.getProfile(props?.profileId)
                .then(async (_data) => {
                    setProfile(_data);

                    // await checkEditPermission()
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        if (props?.visible) {
            getProfile();
            let _secList = _sections
            if(props?.hideProfileExtended) {
                _secList = _sections.filter(item => item.code !== 'EXTENDED')
            }
            setSections(_secList)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileService, props]);


    const navigationTemplate = (option) => {
        return (
            <div className='flex align-items-center'>
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </div>
        )
    }

    const handleSelectButtonChange = (value) => {
        let url = null
        if (value) {
            if (value.code === 'TO_PROFILE') {
                url = `/profile/${profile.id}`
                navigate(url);
            } else {
                setSection(value)
            }
        }
    }

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            setProfile(null)
            props.visibleSetter(false);
        }
    };

    const dialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const dialogHeader = (
        <>
            <div className='mb-1'>{profile?.fullName}</div>
            <SelectButton
                value={section}
                options={sections}
                onChange={(e) => handleSelectButtonChange(e.value)}
                itemTemplate={navigationTemplate}
                className="pcn-selectbutton-slim"
            />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                modal
                header={dialogHeader}
                className="pcn-dialog"
                footer={dialogFooter}
                onHide={hideDialog}
            >


                {section.code === 'DETAIL' &&
                    <ProfileViewComponent entity={profile} entityName="PROFILE" detailTitle="person.details" />
                }

                {section.code === 'EXTENDED' &&
                    <ProfileExtendedViewComponent entity={profile} entityName="PROFILE" />
                }
            </Dialog>
        </>
    );
};

export default ProfileViewDialogComponent;
