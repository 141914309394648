import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { hasPermission } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import ExportButtonComponent from '../ExportButtonComponent';
import { AmpService } from '../../service/AmpService';
import AmpBeneficiaryEditDialog from './AmpBeneficiaryEditDialog';
import AmpBeneficiaryViewDialogComponent from './AmpBeneficiaryViewDialogComponent';
import { Dialog } from 'primereact/dialog';
import StorageFolderFileList from '../file/StorageFolderFileList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AmpBeneficiaryResponsibleDialog from '../../pages/amp/AmpBeneficiaryResponsibleDialog';
import ProfileExportDataUtils from '../../utilities/ProfileExportDataUtils';
import GeneralUtils from '../../utilities/GeneralUtils';
import DOMPurify from 'dompurify';
import SecurityService from '../../service/SecurityService';

const AmpBeneficiaryListComponent = (props) => {
    const [loading, setLoading] = useState(false);
    const [ampBeneficiaries, setAmpBeneficiaries] = useState(null);
    const [ampBen, setAmpBen] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showViewDialog, setShowViewDialog] = useState(false);
    const [showFilesDialog, setShowFilesDialog] = useState(false);
    const [showResponsibleDialog, setShowResponsibleDialog] = useState(false);

    const [canCreate, setCanCreate] = useState(true);

    const [filters] = useState({
        'beneficiary.profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'beneficiary.profile.pin': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'amp.profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'amp.profile.pin': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'responsible.profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const ampService = useMemo(() => new AmpService(), []);
    const { ampIdParam } = useParams();

    useEffect(() => {
        setLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        // };       

        const getAmpBeneficiaries = async () => {
            let criteria = {}
            if (props?.amp) {
                criteria = { ampId: props?.amp?.id }
            } else if (props?.beneficiary) {
                criteria = { beneficiaryId: props?.beneficiary?.id }
            }

            await ampService.getAmpBeneficiaryList(criteria)
                .then(async (_data) => {
                    setAmpBeneficiaries(_data)
                    checkCanCreate()
                    setLoading(false)
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const checkCanCreate = async () => {
            if (props?.beneficiary?.id) {
                setCanCreate(await SecurityService.check('SET_BENEFICIARY_AMP', props?.beneficiary?.id));
            } else {
                setCanCreate(await SecurityService.check('SET_BENEFICIARY_AMP'));
            }
        };

        getAmpBeneficiaries();



    }, [ampIdParam, ampService, props]);

    const addNew = () => {
        setShowEditDialog(true);
    };

    const editData = (_ampBen) => {
        setAmpBen({ ..._ampBen });
        setShowEditDialog(true);
    };

    const viewData = (_ampBen) => {
        setAmpBen({ ..._ampBen });
        setShowViewDialog(true);
    };

    const updateList = async (savedData, isNew) => {
        let updatedData = DataTableUtils.updateTableList(ampBeneficiaries, savedData, isNew)

        setAmpBeneficiaries(updatedData);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('BENEFICIARY_SET_AMP') &&
                        <Button
                            label={translatedMessage("amp.newAmp")}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={addNew}
                            disabled={!canCreate}
                        />
                    }
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={ProfileExportDataUtils.ampBeneficiaryTableHeader()}
                sortOrderHeader={ProfileExportDataUtils.ampBeneficiarySortOrderHeader()}
                fileName={GeneralUtils.computeExportFileName("amp.ampList")}
            />
        );
    };

    const handleExport = async () => {
        let criteria = {};
        return new Promise((resolve, reject) => {
            ampService.getAmpBeneficiaryList(criteria)
                .then((data) => {
                    let exportData = []
                    data.forEach(item => {
                        let exportItem = ProfileExportDataUtils.computeAmpExportFields(item.amp)
                        exportItem.startDate = GeneralUtils.formatDate(item.startDate)
                        exportItem.endDate = GeneralUtils.formatDate(item.endDate)
                        exportItem.comment = DOMPurify.sanitize(item.comment, { ALLOWED_TAGS: [] })
                        exportItem.beneficiary = item.beneficiary?.profile?.fullName
                        exportItem.responsible = item.responsible ? item.responsible.profile?.fullName : "-"
                        exportItem.ampBencreatedOn = GeneralUtils.formatDate(item.createdOn)

                        exportData.push(exportItem)
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const displayEditDialog = (_display) => {
        setShowEditDialog(_display)
        setAmpBen(null)
    }

    const handleViewFiles = (_ampBen) => {
        setAmpBen(_ampBen);
        setShowFilesDialog(true);
    }

    const editResponsible = (_ampBen) => {
        setAmpBen({ ..._ampBen });
        setShowResponsibleDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {hasPermission('AMP_VIEW') &&
                        <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1"
                            onClick={() => viewData(rowData)}
                            title={translatedMessage("generic.view")}
                            disabled={!rowData.actions?.VIEW_AMP} />
                    }
                    {hasPermission('AMP_EDIT') &&
                        <Button icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => editData(rowData)}
                            title={translatedMessage("generic.edit")}
                            disabled={!rowData.actions?.SET_BENEFICIARY_AMP} />
                    }
                    {hasPermission('AMP_VIEW') &&
                        <Button icon="pi pi-file"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => handleViewFiles(rowData)}
                            title={translatedMessage("generic.files")}
                            disabled={!rowData.actions?.VIEW_AMP} />
                    }
                    {hasPermission('AMP_EDIT') &&
                        <Button className="p-button-rounded p-button-info p-button-icon-only m-1"
                            onClick={() => editResponsible(rowData)}
                            title={translatedMessage("beneficiary.responsible")}
                            disabled={!rowData.actions?.SET_BENEFICIARY_AMP} >
                            <FontAwesomeIcon icon='fa-solid fa-user-tie' />
                        </Button>
                    }
                </div>
            </>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.endDate)
    }

    const beneficiaryBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button
                            text
                            label={rowData.beneficiary.profile.fullName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/beneficiary/${rowData.beneficiary.id}/amp`)}
                        />
                    }
                    {!hasPermission('BENEFICIARY_VIEW') &&
                        rowData.beneficiary.profile.fullName
                    }
                </div>
            </>
        );
    }

    const ampBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('AMP_VIEW') &&
                        <Button
                            text
                            label={rowData.amp.profile.fullName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/amp/${rowData.amp.id}`)}
                        />
                    }
                    {!hasPermission('AMP_VIEW') &&
                        rowData.amp.profile.fullName
                    }
                </div>
            </>
        );
    }

    return (
        <>
            <Toast ref={toast} />


            {loading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!loading && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">{props?.pageTitle ? props?.pageTitle : translatedMessage("amp.ampList")}</h5>
                    </div>
                    <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={ampBeneficiaries}
                        dataKey="id"
                        paginator
                        rows={DataTableUtils.defalRowsPerPage()}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        filterDisplay="row"
                        filters={filters}
                        globalFilterFields={['relatedProfile.fullName', 'relatedProfile.pin', 'relationType.label']}
                    >
                        {props?.amp &&
                            <Column
                                field="beneficiary.profile.fullName"
                                header={translatedMessage("beneficiary.beneficiary")}
                                sortable
                                headerStyle={{ width: '25%', minWidth: '8rem' }}
                                body={beneficiaryBodyTemplate}
                                filter
                                filterField="beneficiary.profile.fullName"
                                showFilterMenu={false}
                            />
                        }
                        {props?.amp &&
                            <Column
                                field="beneficiary.profile.pin"
                                header={translatedMessage("beneficiary.pin")}
                                body={(e) => e.beneficiary.profile?.pin ? e.beneficiary.profile?.pin : "-"}
                                sortable
                                headerStyle={{ width: '12%', minWidth: '8rem' }}
                                filter
                                filterField='beneficiary.profile.pin'
                                showFilterMenu={false}
                            />
                        }
                        {props.beneficiary &&
                            <Column
                                field="amp.profile.fullName"
                                header={translatedMessage("generic.name")}
                                sortable
                                headerStyle={{ width: '25%', minWidth: '8rem' }}
                                body={ampBodyTemplate}
                                filter
                                filterField="amp.profile.fullName"
                                showFilterMenu={false}
                            />
                        }
                        {props.beneficiary &&
                            <Column
                                field="amp.profile.pin"
                                header={translatedMessage("profile.pin")}
                                body={(e) => e.amp.profile?.pin ? e.amp.profile?.pin : "-"}
                                sortable
                                headerStyle={{ width: '12%', minWidth: '8rem' }}
                                filter
                                filterField='amp.profile.pin'
                                showFilterMenu={false}
                            />
                        }

                        <Column
                            field="startDate"
                            header={translatedMessage("generic.interval")}
                            body={dateBodyTemplate}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                        />

                        <Column
                            field="responsible.profile.fullName"
                            header={translatedMessage("beneficiary.responsible")}
                            sortable
                            headerStyle={{ width: '25%', minWidth: '8rem' }}
                            filter
                            filterField="responsible.profile.fullName"
                            showFilterMenu={false}
                        />
                        <Column body={actionBodyTemplate} />
                    </DataTable>

                    <AmpBeneficiaryViewDialogComponent
                        value={ampBen}
                        visible={showViewDialog}
                        visibleSetter={setShowViewDialog}
                    />

                    <AmpBeneficiaryEditDialog
                        value={ampBen}
                        visible={showEditDialog}
                        afterSave={(savedData, isNew) => updateList(savedData, isNew)}
                        visibleSetter={(value) => displayEditDialog(value)}
                        amp={props?.amp}
                        beneficiary={props?.beneficiary}
                    />

                    <Dialog
                        visible={showFilesDialog}
                        closable={true}
                        onHide={() => setShowFilesDialog(false)}
                        header={translatedMessage("generic.files")}
                        modal
                        className="pcn-dialog-big"
                        footer={
                            <Button
                                label={translatedMessage("generic.cancel")}
                                icon="pi pi-times"
                                className="p-button-text"
                                onClick={() => setShowFilesDialog(false)}
                            />
                        }
                    >
                        <StorageFolderFileList
                            folderId={ampBen?.folderId}
                            folderName={ampBen?.beneficiary?.profile?.fullName}
                            showUploadButton={true}
                            multiple={false}
                            entityName={"AMP"}
                            hasFileProperties={true}
                            readOnly={!ampBen?.actions?.SET_BENEFICIARY_AMP}
                        />
                    </Dialog>

                    <AmpBeneficiaryResponsibleDialog
                        value={ampBen}
                        visible={showResponsibleDialog}
                        afterSave={(savedData) => updateList(savedData, false)}
                        visibleSetter={setShowResponsibleDialog}
                    />
                </>
            )}

        </>
    );
};

export default AmpBeneficiaryListComponent;