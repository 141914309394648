import GeneralUtils from "../utilities/GeneralUtils";
import ApiService from "./ApiService";

export class MonitoringService {

    async getMonitoringsByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/monitoring/list`, criteria)
                .then((response) => {
                    response.data.items.map(item => {
                        item.startDate = item.startDate ? new Date(Date.parse(item.startDate)) : '';
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        return item;
                    })
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getMonitoring(monitoringId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/monitoring/${monitoringId}`)
                .then(response => {
                    response.data.startDate = response.data.startDate ? new Date(Date.parse(response.data.startDate)) : '';
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveMonitoring(monitoring) {
        let _beneficiary = { id: monitoring.beneficiary?.id }
        let _monitoring = GeneralUtils.nullIfEmptyForObjectFields({ ...monitoring, beneficiary: _beneficiary })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/monitoring`, _monitoring)
                .then(response => {
                    response.data.startDate = response.data.startDate ? new Date(Date.parse(response.data.startDate)) : '';
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteMonitoring(monitoringId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/monitoring/${monitoringId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }

    async saveMonitoringHierarchy(parentId, childId) {
        let _parent = { id: parentId }
        let _child = { id: childId }
        let _monitoringHierarchy = { parent: _parent, child: _child }

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/monitoring/hierarchy`, _monitoringHierarchy)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    
    async deleteMonitoringHierarchy(parentId, childId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/monitoring/${parentId}/hierarchy/${childId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    } 
}