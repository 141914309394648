const MenuService = {
    // get the routes for the Breadcrumb menu
    getRoutes: () => {
        return [
            {
                path: '/',
                parent: { name: '', label: '', url: '/' },
                label: { name: '', label: '', url: '/' }
            },                                                     
        ];
    }
};

export default MenuService;
