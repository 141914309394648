import GeneralUtils from "../utilities/GeneralUtils";
import ApiService from "./ApiService";

export class SolutionService {

    async getSolutionsByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/solution/list`, criteria)
                .then((response) => {
                    response.data.items.map(item => {
                        item.documentDate = item.documentDate ? new Date(Date.parse(item.documentDate)) : '';
                        return item;
                    })
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getSolution(solutionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/solution/${solutionId}`)
                .then(response => {
                    response.data.documentDate = response.data.documentDate ? new Date(Date.parse(response.data.documentDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveSolution(solution) {
        let _beneficiary = { id: solution.beneficiary?.id }
        let _solution = GeneralUtils.nullIfEmptyForObjectFields({ ...solution, beneficiary: _beneficiary })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/solution`, _solution)
                .then(response => {
                    response.data.documentDate = response.data.documentDate ? new Date(Date.parse(response.data.documentDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteSolution(solutionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/solution/${solutionId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }

}