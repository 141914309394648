import ApiService from '../service/ApiService';
import useAuth from './useAuth';
import { UserService } from '../service/UserService';
import LanguageService from '../service/LanguageService';

const useInitUserData = () => {
  const { setAuth } = useAuth();
  const userService = new UserService();

  const initUserData = async () => {
    await ApiService.initToken();
    const token = ApiService.getToken();
    const authenticatedInstance = ApiService.getAuthenticatedInstance();

    await userService.initCurrentUser()
      .then((user) => {
        LanguageService.setCurrentLanguage(user.language);

        setAuth(prev => {
          // console.log(JSON.stringify(prev));
          // console.log(token);
          return {
            ...prev,
            token: token,
            authenticatedInstance: authenticatedInstance,
            user: user
          }
        });
      })      
  }
  return initUserData;
};

export default useInitUserData;