import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { hasPermission } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import ExportButtonComponent from '../ExportButtonComponent';
import { FamilyPlacementService } from '../../service/FamilyPlacementService';
import FamilyPlacementEditDialog from '../../pages/familyPlacement/FamilyPlacementEditDialog';
import ProfileExportDataUtils from '../../utilities/ProfileExportDataUtils';
import GeneralUtils from '../../utilities/GeneralUtils';
import SecurityService from '../../service/SecurityService';

const FamilyPlacementListComponent = () => {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [error, setError] = useState(false);
    const [familyPlacementList, setFamilyPlacementList] = useState(null);
    const [familyPlacement, setFamilyPlacement] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const [canCreate, setCanCreate] = useState(true);

    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            'profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'profile.pin': { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const propToColumnMap = {
        'profile.fullName': 'first_name',
        'profile.pin': 'pin',
    };

    const familyPlacementService = useMemo(() => new FamilyPlacementService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const checkCanCreate = async () => {
            setCanCreate(await SecurityService.check('ADD_FAMILY_PLACEMENT'));
        };

        checkCanCreate()
        setFirstLoading(false);
    }, []);

    useEffect(() => {
        lazyLoadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {}
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC"
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id'
        criteria.fullName = lazyParams.filters['profile.fullName'].value
        criteria.pin = lazyParams.filters['profile.pin'].value

        return criteria
    }

    const loadData = async () => {
        let criteria = setCriteria();

        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await familyPlacementService.getList(criteria)
            .then((data) => {
                setFamilyPlacementList(data.items);
                setTotalRecords(data.totalCount);
                setLoading(false);
            })
            .catch((error) => {
                setError(true)
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const lazyLoadData = () => {
        setLoading(true);
        loadData();
    }

    const addNew = () => {
        setShowEditDialog(true);
    };

    const editData = (_data) => {
        setFamilyPlacement({ ..._data });
        setShowEditDialog(true);
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('FAMILY_PLACEMENT_CREATE') &&
                        <Button
                            label={translatedMessage("familyPlacement.new")}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={addNew}
                            disabled={!canCreate}
                        />
                    }
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={ProfileExportDataUtils.faimlyPlacementTableHeader()}
                sortOrderHeader={ProfileExportDataUtils.faimlyPlacementSortOrderHeader()}
                fileName={GeneralUtils.computeExportFileName("familyPlacement.list")}
            />
        );
    };

    const handleExport = async () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            familyPlacementService.getList(criteria)
                .then((data) => {
                    let exportData = []
                    data.items.forEach(item => {
                        let exportItem = ProfileExportDataUtils.computefaimlyPlacementExportFields(item)

                        exportData.push(exportItem)
                    })
                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const displayEditDialog = (_display) => {
        setShowEditDialog(_display)
        setFamilyPlacement(null)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {hasPermission('FAMILY_PLACEMENT_VIEW') &&
                        <Button icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => navigate(`/family-placement/${rowData.id}`)}
                            title={translatedMessage("generic.view")}
                            disabled={!rowData.actions?.VIEW_FAMILY_PLACEMENT} />
                    }
                    {hasPermission('FAMILY_PLACEMENT_EDIT') &&
                        <Button icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => editData(rowData)}
                            title={translatedMessage("generic.edit")}
                            disabled={!rowData.actions?.ADD_FAMILY_PLACEMENT} />
                    }
                </div>
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && !error && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">{translatedMessage("familyPlacement.list")}</h5>
                    </div>
                    <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={familyPlacementList}
                        lazy
                        dataKey="id"
                        paginator
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onPage={onPage} onSort={onSort} onFilter={onFilter}
                        loading={loading}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        filterDisplay="row"
                        filters={lazyParams.filters}
                    >
                        <Column
                            field="profile.fullName"
                            header={translatedMessage("generic.name")}
                            sortable
                            headerStyle={{ width: '25%', minWidth: '8rem' }}
                            filter
                            filterField="profile.fullName"
                            showFilterMenu={false}
                        />
                        <Column
                            field="profile.pin"
                            header={translatedMessage('profile.pin')}
                            sortable
                            headerStyle={{ width: '12%', minWidth: '8rem' }}
                            filter
                            filterField="profile.pin"
                            showFilterMenu={false}
                        />
                        <Column body={actionBodyTemplate} />
                    </DataTable>

                    <FamilyPlacementEditDialog
                        value={familyPlacement}
                        visible={showEditDialog}
                        afterSave={lazyLoadData}
                        visibleSetter={(value) => displayEditDialog(value)}
                    />
                </>
            )}
        </>
    );
};

export default FamilyPlacementListComponent;