import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getIn, useFormik } from "formik";
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';
import { translatedMessage } from '../../service/LanguageService';
import { DepartmentService } from '../../service/DepartmentService';
import { OrganizationProfileService } from '../../service/OrganizationProfileService';
import ProfileAutocompleteFieldComponent from '../profile/ProfileAutocompleteFieldComponent';
import GeneralUtils from '../../utilities/GeneralUtils';
import { ProviderService } from '../../service/ProviderService';
import { InputTextarea } from 'primereact/inputtextarea';
import { CenterService } from '../../service/CenterService';
import { OrganizationService } from '../../service/OrganizationService';

let emptyRecord = {
    id: null,
    position: '',
    email: '',
    phone: '',
    comment: '',
    organization: null,
    profile: null,
    positionType: null,
    version: null
};

const OrganizationProfileEditDialog = (props) => {
    const [organizationProfile, setOrganizationProfile] = useState(emptyRecord);
    const [isNew, setIsNew] = useState(false);
    const [filteredOrganization, setFilteredOrganization] = useState([]);

    const toast = useRef(null);

    const organizationProfileService = useMemo(() => new OrganizationProfileService(), []);
    const departmentService = useMemo(() => new DepartmentService(), []);
    const providerService = useMemo(() => new ProviderService(), []);
    const centerService = useMemo(() => new CenterService(), []);
    const organizationService = useMemo(() => new OrganizationService(), []);

    useEffect(() => {
        let _organizationProfile = GeneralUtils.emptyInNullForObjectFields(props?.value);

        if (!_organizationProfile) {
            _organizationProfile = { ...emptyRecord };
            _organizationProfile.organization = props?.organization;
        }

        setOrganizationProfile(_organizationProfile);
        setIsNew(props.value ? false : true);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const saveOrganizationProfile = async (data) => {
        // console.log("save org profile: ", data)
        await organizationProfileService.saveOrganizationProfile(data)
            .then((savedData) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedData, isNew);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: organizationProfile,
        validate: (data) => {
            let errors = {}

            if (!data.organization && !data.organization?.id) {
                errors.organization = translatedMessage('form.error.department.required');
            }
            if (!data.profile?.id) {
                errors.profile = translatedMessage('form.error.profile.required');
            }
            if (data.organization?.type === 'DEPARTMENT' && !data.positionType) {
                errors.positionType = translatedMessage('form.error.positionType.required');
            }
            if (data.organization?.type !== 'DEPARTMENT' && !data.position) {
                errors.position = translatedMessage('form.error.position.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveOrganizationProfile(data);
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );



    const searchOrganization = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "name";
        criteria.name = query.toLowerCase();

        if (props?.organizationType === 'DEPARTMENT') {
            departmentService.getDepartmentsByCriteria(criteria)
                .then((result) => {
                    setFilteredOrganization(result.items);
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        } else if (props?.organizationType === 'PROVIDER') {
            providerService.getProvidersByCriteria(criteria)
                .then((result) => {
                    setFilteredOrganization(result.items);
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        } else if (props?.organizationType === 'CENTER') {
            centerService.getCentersByCriteria(criteria)
                .then((result) => {
                    setFilteredOrganization(result.items);
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        } else {
            organizationService.getOrganizationsByCriteria(criteria)
                .then((result) => {
                    setFilteredOrganization(result.items);
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });

        }
    }

    const handleProfileChange = async (_existingProfile) => {
        let _organizationProfile = { ...formik.values, profile: _existingProfile }

        setOrganizationProfile(_organizationProfile)
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('organizationProfile.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="organization"
                                        name="organization"
                                        value={formik.values.organization}
                                        suggestions={filteredOrganization}
                                        completeMethod={(e) => searchOrganization(e)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="name"
                                        onChange={formik.handleChange}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('organization') })}
                                        disabled={props?.organization}
                                        autoFocus
                                    />
                                    <label htmlFor="organization" className={classNames({ 'p-error': isFormFieldValid('organization') })}>
                                        {translatedMessage("organizationProfile.department")} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('organization')}
                        </div>

                        <ProfileAutocompleteFieldComponent
                            profile={formik.values.profile}
                            setExistingProfile={(e) => handleProfileChange(e)}
                            entityName="EXPERT"
                            showAddProfile={true}
                            validationError={isFormFieldValid('profile')}
                        />


                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="position" name="position" value={formik.values.position} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('position') })} />
                                    <label htmlFor="position" className={classNames({ 'p-error': isFormFieldValid('position') })}>
                                        {translatedMessage('organizationProfile.position')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('position')}
                        </div>

                        {formik.values.organization?.type === 'DEPARTMENT' &&
                            <div className='col-12'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="positionType" name="positionType" value={formik.values.positionType} onChange={formik.handleChange} options={props?.positionTypes}
                                            itemTemplate={(e) => e.label} />
                                        <label htmlFor="positionType" className={classNames({ 'p-error': isFormFieldValid('positionType') })}>
                                            {translatedMessage('organizationProfile.positionType')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('positionType')}
                            </div>
                        }

                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                    <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>
                                        {translatedMessage('organizationProfile.email')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('email')}
                        </div>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phone') })} />
                                    <label htmlFor="phone" className={classNames({ 'p-error': isFormFieldValid('phone') })}>
                                        {translatedMessage('organizationProfile.phone')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('phone')}
                        </div>
                        <div className='col-12'>
                            <div className="field pcn-sun-editor w-100 mb-0">
                                <span className="p-float-label">
                                    <InputTextarea id="comment" name="comment" rows={3} cols={30} value={formik.values.comment} onChange={formik.handleChange} autoResize />
                                    <label htmlFor="comment" className={classNames({ 'p-error': isFormFieldValid('comment') })}>
                                        {translatedMessage('organizationProfile.comment')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('comment')}
                        </div>
                    </div>
                </form>
            </Dialog >
        </>);
};

export default OrganizationProfileEditDialog;
