import React, { useState, useEffect, useRef, useMemo } from 'react';
import { getIn, setIn, useFormik } from "formik";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { translatedMessage } from '../../service/LanguageService';
import { CenterService } from '../../service/CenterService';
import { DepartmentService } from '../../service/DepartmentService';
import GeneralUtils from '../../utilities/GeneralUtils';

let emptyCenter = {
    id: null,
    name: '',
    description: '',
    type: 'CENTER',
    status: 'ACTIVE',
    cif: undefined,
    public: true,
    department: null,
    address: null,
    centerDetails: {
        id: null,
        complex: null,
        licenseIssuer: '',
        maxCapacity: '',
        provLicenseDate: null,
        relicensingDecisionDate: null,
        relicensingDecisionNumber: undefined,
        socialServiceCode: '',
        socialServiceType: null,
        termLicenseDate: null,
        termLicenseNumber: undefined,
    }
};

const CenterEditDialog = (props) => {
    const [center, setCenter] = useState(emptyCenter);
    const [isNew, setIsNew] = useState(false);
    const [centerComplexes, setCenterComplexes] = useState([]);
    const [socialServiceTypes, setSocialServiceTypes] = useState([]);
    const [filteredDepartments, setFilteredDepartments] = useState([]);

    const toast = useRef(null);

    const centerService = useMemo(() => new CenterService(), []);
    const departmentService = useMemo(() => new DepartmentService(), []);

    useEffect(() => {
        let _center = props.value;
        if (!_center) {
            _center = { ...emptyCenter };
            if (props?.parent) { _center.department = props?.parent; }
        }
        setCenter(GeneralUtils.emptyInNullForObjectFields(_center));
        setIsNew(props.value ? false : true);
        setCenterComplexes(props?.centerComplexes)
        setSocialServiceTypes(props?.socialServiceTypes);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const saveCenter = async (data) => {
        if (!data.department || !data.department.id) {
            data.department = null;
        }
        await centerService.saveCenter(data)
            .then((savedCenter) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedCenter, isNew);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: center,
        validate: (data) => {
            let errors = {};

            if (!data.department) {
                errors.department = translatedMessage('form.error.department.required');
            }
            if (!data.name) {
                errors.name = translatedMessage('form.error.nameRo.required');
            }
            if (!data.centerDetails.socialServiceCode) {
                errors = setIn(errors, 'centerDetails.socialServiceCode', translatedMessage('form.error.socialServiceCode.required'));
            }
            // if (!data.centerDetails.maxCapacity) {
            //     errors = setIn(errors, 'centerDetails.maxCapacity', translatedMessage('form.error.maxCapacity.required'));
            // }
            return errors;
        },
        onSubmit: (data) => {
            saveCenter(data);
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const handleListChange = async (data, field) => {
        //console.log("handleListChange", data, field);
        formik.setFieldValue(field, data.value)
    }

    const searchDepartments = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "name";
        criteria.types = ['DEPARTMENT'];
        criteria.name = query.toLowerCase();

        departmentService.getDepartmentsByCriteria(criteria)
            .then((result) => {
                setFilteredDepartments(result.items);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('center.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="department"
                                        name="department"
                                        value={formik.values.department}
                                        suggestions={filteredDepartments}
                                        completeMethod={(e) => searchDepartments(e)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="name"
                                        onChange={(d) => handleListChange(d, "department")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('department') })}
                                        autoFocus
                                        disabled={props?.parent}
                                    />
                                    <label htmlFor="department" className={classNames({ 'p-error': isFormFieldValid('department') })}>
                                        {translatedMessage("department.parent")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('department')}
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown 
                                        id="centerDetails.complex.code" 
                                        name="centerDetails.complex.code" 
                                        value={formik.values.centerDetails?.complex?.code} 
                                        onChange={formik.handleChange} 
                                        options={centerComplexes} optionValue='code' 
                                        autoFocus={props?.parent}/>
                                    <label htmlFor="centerDetails.complex.code" className={classNames({ 'p-error': isFormFieldValid('centerDetails.complex.code') })}>
                                        {translatedMessage('center.complex')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.complex.code')}
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                                        {translatedMessage('generic.nameRo')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('name')}
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="centerDetails.socialServiceCode" name="centerDetails.socialServiceCode" value={formik.values.centerDetails?.socialServiceCode} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('centerDetails.socialServiceCode') })} />
                                    <label htmlFor="centerDetails.socialServiceCode" className={classNames({ 'p-error': isFormFieldValid('centerDetails.socialServiceCode') })}>
                                        {translatedMessage('center.socialServiceCode')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.socialServiceCode')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown id="centerDetails.socialServiceType.code" name="centerDetails.socialServiceType.code" value={formik.values.centerDetails?.socialServiceType?.code} onChange={formik.handleChange}
                                        options={socialServiceTypes} optionValue='code' />
                                    <label htmlFor="centerDetails.socialServiceType.code" className={classNames({ 'p-error': isFormFieldValid('centerDetails.socialServiceType.code') })}>
                                        {translatedMessage('center.socialServiceType')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.socialServiceType.code')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputNumber id="centerDetails.maxCapacity" name="centerDetails.maxCapacity" value={formik.values.centerDetails?.maxCapacity} onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('centerDetails.maxCapacity') })}
                                        mode="decimal" useGrouping={false} />
                                    <label htmlFor="centerDetails.maxCapacity" className={classNames({ 'p-error': isFormFieldValid('centerDetails.maxCapacity') })}>
                                        {translatedMessage('center.maxCapacity')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.maxCapacity')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="centerDetails.provLicenseDate" name="centerDetails.provLicenseDate" value={formik.values.centerDetails?.provLicenseDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                        className={classNames({ 'p-invalid': isFormFieldValid('centerDetails.provLicenseDate') })} />
                                    <label htmlFor="centerDetails.provLicenseDate" className={classNames({ 'p-error': isFormFieldValid('centerDetails.provLicenseDate') })}>
                                        {translatedMessage("center.provLicenseDate")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.provLicenseDate')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="centerDetails.termLicenseDate" name="centerDetails.termLicenseDate" value={formik.values.centerDetails?.termLicenseDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                        className={classNames({ 'p-invalid': isFormFieldValid('centerDetails.termLicenseDate') })} />
                                    <label htmlFor="centerDetails.termLicenseDate" className={classNames({ 'p-error': isFormFieldValid('centerDetails.termLicenseDate') })}>
                                        {translatedMessage("center.termLicenseDate")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.termLicenseDate')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="centerDetails.termLicenseNumber" name="centerDetails.termLicenseNumber" value={formik.values.centerDetails?.termLicenseNumber ?? ''} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('centerDetails.termLicenseNumber') })} />
                                    <label htmlFor="centerDetails.termLicenseNumber" className={classNames({ 'p-error': isFormFieldValid('centerDetails.termLicenseNumber') })}>
                                        {translatedMessage('center.termLicenseNumber')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.termLicenseNumber')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="centerDetails.relicensingDecisionDate" name="centerDetails.relicensingDecisionDate" value={formik.values.centerDetails?.relicensingDecisionDate} onChange={formik.handleChange}
                                        dateFormat="dd-mm-yy" className={classNames({ 'p-invalid': isFormFieldValid('centerDetails.relicensingDecisionDate') })} />
                                    <label htmlFor="centerDetails.relicensingDecisionDate" className={classNames({ 'p-error': isFormFieldValid('centerDetails.relicensingDecisionDate') })}>
                                        {translatedMessage("center.relicensingDecisionDate")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.relicensingDecisionDate')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="centerDetails.relicensingDecisionNumber" name="centerDetails.relicensingDecisionNumber" value={formik.values.centerDetails?.relicensingDecisionNumber ?? ''}
                                        onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('centerDetails.relicensingDecisionNumber') })} />
                                    <label htmlFor="centerDetails.relicensingDecisionNumber" className={classNames({ 'p-error': isFormFieldValid('centerDetails.relicensingDecisionNumber') })}>
                                        {translatedMessage('center.relicensingDecisionNumber')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.relicensingDecisionNumber')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" id="centerDetails.licenseIssuer" name="centerDetails.licenseIssuer" value={formik.values.centerDetails?.licenseIssuer} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('centerDetails.licenseIssuer') })} />
                                    <label htmlFor="centerDetails.licenseIssuer" className={classNames({ 'p-error': isFormFieldValid('centerDetails.licenseIssuer') })}>
                                        {translatedMessage('center.licenseIssuer')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('centerDetails.licenseIssuer')}
                        </div>

                        <div className='col-12'>
                            <div className="field pcn-sun-editor w-100 mb-0">
                                <span className="p-float-label">
                                    <InputTextarea id="description" name="description" rows={3} cols={30} value={formik.values.description} onChange={formik.handleChange} autoResize />
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>
                                        {translatedMessage('generic.description')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('description')}
                        </div>                        
                    </div>
                </form>
            </Dialog>
        </>);
};

export default CenterEditDialog;
