import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { hasPermission } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import ExportButtonComponent from '../../components/ExportButtonComponent';
import { AmpService } from '../../service/AmpService';
import AmpEditDialog from './AmpEditDialog';
import GeneralUtils from "../../utilities/GeneralUtils";
import ProfileExportDataUtils from '../../utilities/ProfileExportDataUtils';
import SecurityService from '../../service/SecurityService';

const AmpList = () => {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [error, setError] = useState(false);
    const [ampList, setAmpList] = useState(null);
    const [amp, setAmp] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const [canCreate, setCanCreate] = useState(true);

    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            'profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'profile.pin': { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
        filterRoleValues: []
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const propToColumnMap = {
        'profile.fullName': 'first_name',
        'profile.pin': 'pin',
    };

    const ampService = useMemo(() => new AmpService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const checkCanCreate = async () => {
            setCanCreate(await SecurityService.check('ADD_AMP'));
        };

        checkCanCreate()

        setFirstLoading(false);
    }, []);

    useEffect(() => {
        lazyLoadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {}
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC"
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id'
        criteria.fullName = lazyParams.filters['profile.fullName'].value
        criteria.pin = lazyParams.filters['profile.pin'].value

        return criteria
    }

    const loadData = async () => {
        let criteria = setCriteria();

        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await ampService.getAmpList(criteria)
            .then((data) => {
                setAmpList(data.items);
                setTotalRecords(data.totalCount);
                setLoading(false);
            })
            .catch((error) => {
                setError(true)
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const lazyLoadData = () => {
        setLoading(true);
        loadData();
    }

    const addNew = () => {
        // setAmp(emptyRecord);        
        setShowEditDialog(true);
    };

    const editData = (_amp) => {
        setAmp({ ..._amp });
        setShowEditDialog(true);
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('AMP_CREATE') &&
                        <Button
                            label={translatedMessage("amp.newAmp")}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={addNew}
                            disabled={!canCreate}
                        />
                    }
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={ProfileExportDataUtils.ampTableHeader()}
                sortOrderHeader={ProfileExportDataUtils.ampSortOrderHeader()}
                fileName={GeneralUtils.computeExportFileName("amp.ampList")}
            />
        );
    };

    const handleExport = async () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            ampService.getAmpList(criteria)
                .then((data) => {
                    let exportData = []
                    data.items.forEach(item => {
                        let exportItem = ProfileExportDataUtils.computeAmpExportFields(item)

                        exportData.push(exportItem)
                    })

                    console.log(exportData)
                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const displayEditDialog = (_display) => {
        setShowEditDialog(_display)
        setAmp(null)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {hasPermission('AMP_VIEW') &&
                        <Button icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => navigate(`/amp/${rowData.id}`)}
                            title={translatedMessage("generic.view")}
                            disabled={!rowData.actions?.VIEW_AMP} />
                    }
                    {hasPermission('AMP_EDIT') &&
                        <Button icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => editData(rowData)}
                            title={translatedMessage("generic.edit")}
                            disabled={!rowData.actions?.ADD_AMP} />
                    }
                </div>
            </>
        );
    };

    const dateBodyTemplate = (_date) => {
        return GeneralUtils.formatDate(_date);
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && !error && (
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <div className='w-full text-align-left'>
                                <h5 className="m-0">{translatedMessage("amp.ampList")}</h5>
                            </div>
                            <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                            <DataTable
                                ref={dt}
                                value={ampList}
                                lazy
                                dataKey="id"
                                paginator
                                first={lazyParams.first}
                                rows={lazyParams.rows}
                                totalRecords={totalRecords}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                                onPage={onPage} onSort={onSort} onFilter={onFilter}
                                loading={loading}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                                filterDisplay="row"
                                filters={lazyParams.filters}
                            >
                                <Column
                                    field="profile.fullName"
                                    header={translatedMessage("generic.name")}
                                    sortable
                                    headerStyle={{ width: '25%', minWidth: '8rem' }}
                                    filter
                                    filterField="profile.fullName"
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="profile.pin"
                                    header={translatedMessage('profile.pin')}
                                    sortable
                                    headerStyle={{ width: '12%', minWidth: '8rem' }}
                                    filter
                                    filterField="profile.pin"
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="householdResidents"
                                    header={translatedMessage('amp.householdResidents')}
                                    sortable
                                    headerStyle={{ width: '8%', minWidth: '8rem' }}
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="ampCertificateExpiryDate"
                                    header={translatedMessage('amp.ampCertificateExpiryDate')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '8rem' }}
                                    body={(e) => dateBodyTemplate(e.ampCertificateExpiryDate)}
                                />
                                <Column body={actionBodyTemplate} />
                            </DataTable>

                            <AmpEditDialog
                                value={amp}
                                visible={showEditDialog}
                                afterSave={lazyLoadData}
                                visibleSetter={(value) => displayEditDialog(value)}
                            />

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AmpList;