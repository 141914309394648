import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Dropdown } from 'primereact/dropdown';
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { EntityLookupService } from "../../service/EntityLookupService";
import { translatedMessage } from "../../service/LanguageService";
import GeneralUtils from "../../utilities/GeneralUtils";
import DeleteModalComponent from "../../components/DeleteModalComponent";
import EntityLookupEditDialog from "./EntityLookupEditDialog";
import DataTableUtils from "../../utilities/DataTableUtils";
import useAuth from "../../hooks/useAuth";
import EnumService from '../../service/EnumService';

const EntityLookupList = () => {
    const [fieldClass, setFieldClass] = useState('NOM:COUNTY');
    const [fieldClassOptions, setFieldClassOptions] = useState([]);
    const [entities, setEntities] = useState([]);
    const [selectedEntityLookup, setSelectedEntityLookup] = useState(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            code: { value: null, matchMode: FilterMatchMode.CONTAINS },
            labelRo: { value: null, matchMode: FilterMatchMode.CONTAINS },
            labelEn: { value: null, matchMode: FilterMatchMode.CONTAINS }
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        code: "code",
        labelRo: "label_ro",
        labelEn: "label_en"
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();

    const entityLookupService = useMemo(() => new EntityLookupService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const updateFieldClassOptions = async () => {
            const response = await EnumService.getEnumByName("EntityLookupFieldClass");
            setFieldClassOptions(response)
        }

        updateFieldClassOptions();
        setFirstLoading(false);
    }, [entityLookupService]);

    useEffect(() => {
        loadEntityLookups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams, fieldClass]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues(
            "EntityLookup_" + fieldClass + "_List",
            lazyParams,
            lazyParamsUpdatedFromSession,
            setLazyParamsUpdatedFromSession
        );

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";
        criteria.entityField = fieldClass;
        criteria.code = lazyParams.filters.code.value;
        criteria.labelRo = lazyParams.filters.labelRo.value;
        criteria.labelEn = lazyParams.filters.labelEn.value;

        return criteria;
    };

    const loadEntityLookups = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await entityLookupService
            .getEntityLookupsByCriteria(criteria)
            .then((data) => {
                setEntities(data.entityLookupDtos);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                //setError(true)
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const updateList = async (savedEntityLookup, isNew) => {
        loadEntityLookups();
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        setSelectedEntityLookup(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (entityLookup) => {
        setSelectedEntityLookup(entityLookup);
        setEditDialogVisible(true);
    };

    const showDeleteDialog = (entityLookup) => {
        setSelectedEntityLookup(entityLookup);
        setDeleteDialogVisible(true);
    };

    const deleteEntityLookup = () => {
        entityLookupService
            .deleteEntityLookup(selectedEntityLookup.id)
            .then(() => {
                loadEntityLookups();
                toast.current.show({
                    severity: "success", summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast.current.show({
                    severity: "error", summary: translatedMessage(error), life: 5000,
                });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedEntityLookup(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {//hasPermission("ENTITY_LOOKUP_CREATE") && 
                        (
                            <Button
                                label={translatedMessage("entityLookup.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                            />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission("ENTITY_LOOKUP_EDIT") && 
                    (
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showEditDialog(rowData)}
                            tooltip={translatedMessage("generic.edit")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.editable}
                        />
                    )}
                {hasPermission("ENTITY_LOOKUP_CREATE") && 
                    (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => showDeleteDialog(rowData)}
                            disabled={rowData.id < 1000 || !rowData.editable}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                        />
                    )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <h5 className="mb-0">
                                    {translatedMessage("entityLookup.fieldClass")}: {" "}
                                    <Dropdown id="fieldClass" name="fieldClass" value={fieldClass} options={fieldClassOptions}
                                        onChange={(e) => setFieldClass(e.value)} placeholder={translatedMessage('entityLookup.fieldClass')}
                                    />
                                </h5>

                                <Toolbar start={leftToolbarTemplate} className="px-0"></Toolbar>

                                <DataTable
                                    ref={dt}
                                    value={entities}
                                    dataKey="id"
                                    paginator
                                    lazy
                                    totalRecords={totalRecords}
                                    first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    sortField={lazyParams.sortField}
                                    sortOrder={lazyParams.sortOrder}
                                    onPage={onPage}
                                    onSort={onSort}
                                    onFilter={onFilter}
                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                    className="datatable-responsive pcn-datatable"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                                    scroll="true"
                                    filterDisplay="row"
                                    filters={lazyParams.filters}
                                    loading={isLoading}
                                >
                                    <Column
                                        field="code"
                                        header={translatedMessage("entityLookup.code")}
                                        sortable
                                        headerStyle={{ minWidth: "10rem" }}
                                        filter
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="labelRo"
                                        header={translatedMessage("entityLookup.labelRo")}
                                        sortable
                                        headerStyle={{ minWidth: "10rem" }}
                                        filter
                                        showFilterMenu={false}
                                    />
                                    {/* <Column
                                        field="labelEn"
                                        header={translatedMessage("entityLookup.labelEn")}
                                        sortable
                                        headerStyle={{ minWidth: "10rem" }}
                                        filter
                                        showFilterMenu={false}
                                    /> */}
                                    <Column
                                        field="relatedEntityLookup"
                                        header={translatedMessage("entityLookup.parentEntity")}
                                        headerStyle={{ minWidth: "8rem" }}
                                        body={(e) => e.relatedEntityLookup?.labelRo}
                                    />
                                    <Column
                                        field="property1"
                                        header={translatedMessage("entityLookup.property1")}
                                        headerStyle={{ minWidth: "8rem" }}
                                        body={(e) => e.property1 ? translatedMessage("el.property1."+e.property1) : "-"}
                                    />
                                    <Column body={actionBodyTemplate}></Column>
                                </DataTable>

                                <EntityLookupEditDialog
                                    value={selectedEntityLookup}
                                    visible={editDialogVisible}
                                    afterSave={updateList}
                                    visibleSetter={setEditDialogVisible}
                                    fieldClass={fieldClass}
                                />

                                <DeleteModalComponent
                                    visible={deleteDialogVisible}
                                    item={selectedEntityLookup ? selectedEntityLookup.name : ""}
                                    closeDialog={() => { setDeleteDialogVisible(false); }}
                                    deleteRecord={() => deleteEntityLookup()}
                                    info="entityLookup.delete.info"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default EntityLookupList;
