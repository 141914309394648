import ApiService from "./ApiService";
import GeneralUtils from "../utilities/GeneralUtils";

export class AmpService {

    async getAmpList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/amp/list', criteria)
                .then((response) => {
                    response.data.items.map((item) => {
                        item.ampCertificateExpiryDate = item.ampCertificateExpiryDate ? new Date(Date.parse(item.ampCertificateExpiryDate)) : ''
                        
                        let _birthday = item.profile?.birthday ? new Date(Date.parse(item.profile?.birthday)) : ''
                        item.profile = { ...item.profile, birthday: _birthday}
                        
                        return item
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getAmp(ampId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/amp/${ampId}`)
                .then(response => {      
                    response.data.ampCertificateExpiryDate = response.data.ampCertificateExpiryDate ? new Date(Date.parse(response.data.ampCertificateExpiryDate)) : ''

                    let _birthday = response.data.profile?.birthday ? new Date(Date.parse(response.data.profile?.birthday)) : ''
                    response.data.profile = { ...response.data.profile, birthday: _birthday}

                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    

    async saveAmp(amp) {
        let _profile = { id: amp.profile.id }
        let _amp = GeneralUtils.nullIfEmptyForObjectFields({ ...amp, profile: _profile })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/amp/save', _amp)
                .then(response => {
                    response.data.ampCertificateExpiryDate = response.data.ampCertificateExpiryDate ? new Date(Date.parse(response.data.ampCertificateExpiryDate)) : ''
                    
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getAmpBeneficiaryList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post(`/amp/beneficiary/list`, criteria)
                .then((response) => {
                    response.data.map((item) => {
                        item.startDate = item.startDate ? new Date(Date.parse(item.startDate)) : '';                    
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';   
                        return item
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveAmpBeneficiary(ampBeneficiary) {
        let _amp = { ...ampBeneficiary.amp, profile: { id: ampBeneficiary.amp.profile.id } }
        let _beneficiary = { ...ampBeneficiary.beneficiary, profile: { id: ampBeneficiary.beneficiary.profile.id } }
        let _ampBeneficiary = GeneralUtils.nullIfEmptyForObjectFields({ ...ampBeneficiary, amp: _amp, beneficiary: _beneficiary })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/amp/beneficiary/save', _ampBeneficiary)
                .then(response => {
                    response.data.startDate = response.data.startDate ? new Date(Date.parse(response.data.startDate)) : '';                    
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : '';                                        
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
}