import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { hasPermission } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import ExportButtonComponent from '../../components/ExportButtonComponent';
import { BeneficiaryService } from '../../service/BeneficiaryService';
import BeneficiaryToolbar from '../beneficiary/BeneficiaryToolbar';
import { ProfileService } from '../../service/ProfileService';
import RelatedProfileEditDialogComponent from '../../components/profile/RelatedProfileEditDialogComponent';
import ProfileViewDialogComponent from '../../components/profile/ProfileViewDialogComponent';
import ProfileExportDataUtils from '../../utilities/ProfileExportDataUtils';
import GeneralUtils from '../../utilities/GeneralUtils';

const RelatedProfileList = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [relatedProfiles, setRelatedProfiles] = useState(null);
    const [beneficiary, setBeneficiary] = useState(null);
    const [relProfile, setRelProfile] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showViewDialog, setShowViewDialog] = useState(false);

    const [filters] = useState({
        'relatedProfile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'relatedProfile.pin': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'relationType.label': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const profileService = useMemo(() => new ProfileService(), []);
    const beneficiaryService = useMemo(() => new BeneficiaryService(), []);
    const { beneficiaryIdParam } = useParams();

    useEffect(() => {
        setLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        // };       

        const getBeneficiary = async () => {
            await beneficiaryService.getBeneficiary(beneficiaryIdParam)
                .then(async (_data) => {
                    setBeneficiary(_data);

                    // await checkEditPermission()
                    await getRelatedProfiles(_data)

                    setLoading(false);
                })
                .catch((error) => {
                    setError(true)
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        const getRelatedProfiles = async (_beneficiary) => {
            let criteria = { profileId: _beneficiary.profile?.id }

            await profileService.getRelatedProfileForProfileList(criteria)
                .then(async (_data) => {
                    setRelatedProfiles(_data);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getBeneficiary();
    }, [beneficiaryIdParam, beneficiaryService, profileService]);

    const addNew = () => {
        setShowEditDialog(true);
    };

    const editData = (_relProfile) => {
        setRelProfile({ ..._relProfile });
        setShowEditDialog(true);
    };

    const viewData = (_relProfile) => {
        setRelProfile({ ..._relProfile });
        setShowViewDialog(true);
    };

    const updateList = async (savedData, isNew) => {
        let updatedData = DataTableUtils.updateTableList(relatedProfiles, savedData, isNew)

        setRelatedProfiles(updatedData);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('RELATED_PROFILE_EDIT') &&
                        <Button
                            label={translatedMessage("person.add")}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={addNew}
                        />
                    }
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={ProfileExportDataUtils.relatedTableHeader()}
                sortOrderHeader={ProfileExportDataUtils.relatedSortOrderHeader()}
                fileName={GeneralUtils.computeExportFileName("person.persons")}
            />
        );
    };

    const handleExport = async () => {
        let criteria = {};
        return new Promise((resolve, reject) => {
            profileService.getRelatedProfileForProfileList(criteria)
                .then((data) => {
                    let exportData = []
                    data.forEach(item => {
                        let exportItem = ProfileExportDataUtils.computeRelatedExportFields(item)

                        exportData.push(exportItem)
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const displayEditDialog = (_display) => {
        setShowEditDialog(_display)
        setRelProfile(null)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {hasPermission('RELATED_PROFILE_VIEW') &&
                        <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" 
                            onClick={() => viewData(rowData)}
                            title={translatedMessage("generic.view")} />
                    }
                    {hasPermission('RELATED_PROFILE_EDIT') &&
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => editData(rowData)}
                            title={translatedMessage("generic.edit")} />
                    }
                </div>
            </>
        );
    };

    return (
        <>
            <div className="grid h-full">
                <Toast ref={toast} />
                <div className="col-12">
                    <div className="card h-full">                        
                        <BeneficiaryToolbar beneficiary={beneficiary} code="RELATED" />

                        {loading && (
                            <div className="w-full flex align-items-center">
                                <ProgressSpinner />
                            </div>
                        )}
                        {!loading && !error && (
                            <>                               
                                <div className='w-full text-align-left'>
                                    <h5 className="m-0">{translatedMessage("person.persons")}</h5>
                                </div>
                                <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                                <DataTable
                                    ref={dt}
                                    value={relatedProfiles}
                                    dataKey="id"
                                    paginator
                                    rows={DataTableUtils.defalRowsPerPage()}
                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                    className="datatable-responsive pcn-datatable"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                                    filterDisplay="row"
                                    filters={filters}
                                    globalFilterFields={['relatedProfile.fullName', 'relatedProfile.pin', 'relationType.label']}
                                >
                                    <Column
                                        field="relatedProfile.fullName"
                                        header={translatedMessage("generic.name")}
                                        sortable
                                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                                        filter
                                        filterField="relatedProfile.fullName"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="relatedProfile.pin"
                                        header={translatedMessage("profile.pin")}
                                        body={(e) => e.relatedProfile?.pin ? e.relatedProfile?.pin : "-"}
                                        sortable
                                        headerStyle={{ width: '12%', minWidth: '8rem' }}
                                        filter
                                        filterField='relatedProfile.pin'
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="relationType.label"
                                        header={translatedMessage("person.relationType")}
                                        sortable
                                        headerStyle={{ width: '12%', minWidth: '8rem' }}
                                        filter
                                        filterField='relationType.label'
                                        showFilterMenu={false}
                                    />
                                    <Column body={actionBodyTemplate} />
                                </DataTable>

                                <RelatedProfileEditDialogComponent
                                    value={relProfile}
                                    visible={showEditDialog}
                                    afterSave={(savedData, isNew) => updateList(savedData, isNew)}
                                    visibleSetter={(value) => displayEditDialog(value)}
                                    profileId={beneficiary?.profile?.id}
                                />

                                <ProfileViewDialogComponent 
                                    profileId={relProfile?.relatedProfile?.id}
                                    visible={showViewDialog}
                                    visibleSetter={(value) => setShowViewDialog(value)}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RelatedProfileList;