import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { DepartmentService } from "../../service/DepartmentService";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import { hasPermission } from '../../service/UserService';
import GeneralUtils from "../../utilities/GeneralUtils";
import EnumService from "../../service/EnumService";
import DeleteModalComponent from "../DeleteModalComponent";
import DepartmentEditDialog from "./DepartmentEditDialog";
import DataTableUtils from "../../utilities/DataTableUtils";
import ExportButtonComponent from "../ExportButtonComponent";
import SecurityService from '../../service/SecurityService';

const listTableHeader = [
    translatedMessage("generic.nameRo"),
    translatedMessage("department.code"),
    translatedMessage('department.cif'),
    translatedMessage("generic.county"),    
    translatedMessage("generic.status"),
    translatedMessage('generic.type'),    
    translatedMessage('department.isPublic'),
    translatedMessage("department.parent"),   
    translatedMessage("generic.description"),
    translatedMessage("generic.created.on") 
];

const listSortOrderHeader = [
    "name", "code", "cif", "county", "status", "type", "public", "parentOrganization", "description", "createdOn"
];

const DepartmentListComponent = (props) => {
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [counties, setCounties] = useState([]);

    const [canCreate, setCanCreate] = useState(false);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            cif: { value: null, matchMode: FilterMatchMode.CONTAINS },
            "parent.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
            "address.county": { value: null, matchMode: FilterMatchMode.EQUALS },
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);

    const propToColumnMap = {
        name: "name",
        cif: "cif",
        "address.county": "a.county_id"
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const navigate = useNavigate();

    const departmentService = useMemo(() => new DepartmentService(), []);

    useEffect(() => {
        setFirstLoading(true)

        const getStatuses = async () => {
            setStatuses(await EnumService.getEnumByName("OrganizationStatus"));
        };

        const getCounties = async () => {
            const _counties = await EnumService.getEntityLookupByField("NOM:COUNTY")
            setCounties(_counties)
        };

                
        const checkCanCreate = async () => {
            setCanCreate(await SecurityService.check('ADD_ORGANIZATION'));
        };

        getStatuses()
        getCounties()
        checkCanCreate()
        setFirstLoading(false)
    }, [departmentService, props]);

    useEffect(() => {
        loadDepartments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues("DepartmentList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";
        criteria.name = lazyParams.filters.name.value;
        criteria.cif = lazyParams.filters.cif.value;
        criteria.parentName = lazyParams.filters["parent.name"].value;
        criteria.countyIds = lazyParams.filters["address.county"].value;
        criteria.providerId = props?.provider?.id;
        return criteria;
    };

    const loadDepartments = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await departmentService
            .getDepartmentsByCriteria(criteria)
            .then((data) => {
                setDepartments(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const updateList = async (savedDepartment, isNew) => {
        loadDepartments();
    };

    const showDepartmentViewPage = (department) => {
        navigate(`/department/${department.id}`);
    };

    const showNewDialog = () => {
        setSelectedDepartment(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (department) => {
        setSelectedDepartment(department);
        setEditDialogVisible(true);
    };

    const showDeleteDialog = (department) => {
        setSelectedDepartment(department);
        setDeleteDialogVisible(true);
    };

    const deleteDepartment = () => {
        departmentService
            .deleteDepartment(selectedDepartment.id)
            .then(() => {
                let _departments = departments.filter(
                    (item) => item.id !== selectedDepartment.id
                );
                setDepartments(_departments);
                toast.current.show({
                    severity: "success",
                    summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedDepartment(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("ORGANIZATION_CREATE") &&
                        (
                            <Button
                                label={translatedMessage("department.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreate}
                            />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            departmentService
                .getDepartmentsByCriteria(criteria)
                .then((data) => {
                    let exportData = data.items.map((item) => ({ ...item }));
                    exportData.map((item) => {
                        let exportItem = item;
                        exportItem.status = translatedMessage("OrganizationStatus." + item.status);
                        exportItem.type = translatedMessage("OrganizationType." + item.type);
                        exportItem.public = translatedMessage("generic." + (item.public ? "yes" : "no"));
                        exportItem.county = item.address?.county?.label;
                        exportItem.parentOrganization = item.parent?.name;
                        exportItem.code = item.code;
                        exportItem.cif = item.cif;
                        exportItem.createdOn = GeneralUtils.formatDate(item.createdOn);
                        return exportItem;
                    });

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={listTableHeader}
                sortOrderHeader={listSortOrderHeader}
                fileName={GeneralUtils.computeExportFileName("department.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-department justify-content-end">
                {hasPermission("ORGANIZATION_VIEW") &&
                    (
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => showDepartmentViewPage(rowData)}
                            tooltip={translatedMessage("generic.view")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.VIEW_ORGANIZATION}
                        />
                    )}
                {hasPermission("ORGANIZATION_EDIT") &&
                    (
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showEditDialog(rowData)}
                            tooltip={translatedMessage("generic.edit")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_ORGANIZATION}
                        />
                    )}
                {hasPermission("ORGANIZATION_CREATE") &&
                    (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => showDeleteDialog(rowData)}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_ORGANIZATION}
                        />
                    )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">
                            {props.pageTitle ? props.pageTitle : translatedMessage("department.list")}
                        </h5>
                    </div>

                    <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0" ></Toolbar>

                    <DataTable
                        ref={dt}
                        value={departments}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        scroll="true"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        <Column
                            field="name"
                            header={translatedMessage("generic.nameRo")}
                            headerStyle={{ width: "30%", minWidth: "10rem" }}
                            body={(e) => e.name + (e.code ? (" (" + e.code + ")") : "")}
                            sortable
                            filterField="name"
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="cif"
                            header={translatedMessage("department.cif")}
                            headerStyle={{ width: "10%",  minWidth: "8rem" }}
                            sortable
                            filterField="cif"
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="address.county"
                            header={translatedMessage("generic.county")}
                            headerStyle={{ width: "10%",  minWidth: "8rem" }}
                            body={(e) => e.address?.county ? e.address.county.label : "-"}
                            sortable
                            filterElement={(e) => DataTableUtils.countyFilterTemplate(e, counties)}
                            filter
                            showFilterMenu={false}
                        />
                        {!props?.department &&
                            <Column
                                field="parent.name"
                                header={translatedMessage("department.parent")}
                                headerStyle={{ width: "25%",  minWidth: "10rem" }}
                                filter
                                filterField="parent.name"
                                showFilterMenu={false}
                            />
                        }

                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DepartmentEditDialog
                        value={selectedDepartment}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={setEditDialogVisible}
                        provider={props?.provider}
                        statuses={statuses}
                    />

                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedDepartment ? selectedDepartment.name : ""}
                        closeDialog={() => { setDeleteDialogVisible(false); }}
                        deleteRecord={() => deleteDepartment()}
                        info="department.delete.info"
                    />
                </>
            )}
        </>
    );
};

export default DepartmentListComponent;
