import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';
import { translatedMessage } from '../../service/LanguageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ServiceDataTableUtils from '../../utilities/ServiceDataTableUtils';

const ServiceToolbar = (props) => {
    const [service, setService] = useState(null);

    const _sections = [
        { label: translatedMessage('generic.details'), code: 'DETAIL', icon: 'fa-regular fa-eye', url: "/" },
        { label: translatedMessage('beneficiary.beneficiaries'), code: 'BENEFICIARIES', icon: 'fa-solid fa-user', url: `/beneficiaries` },
        { label: translatedMessage('generic.backToList'), code: 'BACK', icon: 'fa-solid fa-arrow-left' }
    ];

    let _section = props ? _sections.filter(item => item.code === props.code)[0] : _sections[0];
    const [section, setSection] = useState(_section);
    const [sections, setSections] = useState(_sections);


    const navigate = useNavigate();

    useEffect(() => {
        setService(props?.service)
        setSections(_sections)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const navigationTemplate = (option) => {
        return (
            <div className='flex align-items-center'>
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </div>
        )
    }

    const handleSelectButtonChange = (value) => {
        let url = null
        if (value) {
            if (value.code === 'BACK') {
                navigate(`/service`);
            } else {
                if (value && value.url && value.url !== '#') {
                    url = `/service/${service.id}${value.url}`
                    navigate(url);
                } else {
                    setSection(value)
                }
            }
        }
    }

    return (
        <>
            <h3 className='mb-0'>{ServiceDataTableUtils.getServiceDisplayName(service)}</h3>
            <SelectButton
                value={section}
                options={sections}
                onChange={(e) => handleSelectButtonChange(e.value)}
                itemTemplate={navigationTemplate}
                className="pcn-selectbutton-slim mb-3"
            />

        </>
    );
};

export default ServiceToolbar;
