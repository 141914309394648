import React, { useEffect, useState, useMemo, useRef } from 'react';
import { translatedMessage } from '../../service/LanguageService';
import { AutoComplete } from 'primereact/autocomplete';
import { ProfileService } from '../../service/ProfileService';
import { Toast } from 'primereact/toast';
import DataTableUtils from '../../utilities/DataTableUtils';
import { Button } from 'primereact/button';
import ProfileEditDialogComponent from './ProfileEditDialogComponent';

const ProfileAutocompleteFieldComponent = (props) => {
    const [existingProfile, setExistingProfile] = useState()
    const [profiles, setProfiles] = useState([])
    const [showProfileEditDialog, setShowProfileEditDialog] = useState(false);
    const [validationError, setValidationError] = useState(false);

    const toast = useRef(null);
    const profileService = useMemo(() => new ProfileService(), [])

    useEffect(() => {
        const getData = async () => {
            await searchProfiles()
        }
        getData()

        setValidationError(props?.validationError)
        if(props?.profile) {
            setExistingProfile(props?.profile)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const searchProfiles = async (e = null) => {
        let criteria = {}
        criteria.pageSize = DataTableUtils.maxRowsForSearch();

        if (e?.query) {
            criteria.firstName = e.query
            criteria.lastName = e.query
            criteria.pin = e.query
        }

        await profileService.getProfilesForSearchByCriteria(criteria)
            .then((data) => {
                setProfiles(data);
                return data
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const handleProfileChange = async (_selectedProfile) => {
        setExistingProfile(_selectedProfile)

        if (_selectedProfile?.id && typeof props.setExistingProfile === 'function') {
            // console.log("_selectedProfile: ", _selectedProfile)
            props.setExistingProfile(_selectedProfile);
        }
    }

    const handleButtonClick = () => {    
        setShowProfileEditDialog(true)
    }

    const displayEditDialog = (_display) => {
        setShowProfileEditDialog(_display)
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="col-12 flex flex-row">
                <div className='flex-grow-1'>
                    <div className="p-inputgroup">
                        <span className="p-float-label">
                            <AutoComplete
                                dropdown
                                id="existingProfile"
                                name="existingProfile"
                                value={existingProfile}
                                suggestions={profiles}
                                completeMethod={(e) => searchProfiles(e)}
                                virtualScrollerOptions={{ itemSize: 38 }}
                                field="fullName"
                                onChange={(d) => handleProfileChange(d.value)}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                className={validationError ? 'p-invalid' : ''}
                                disabled={props?.disabled}
                            />
                            <label htmlFor="existingProfile" className={validationError ? 'p-error' : ''}>
                                {props?.title ? props?.title : translatedMessage("profile.search")}
                            </label>
                        </span>
                    </div>
                    <small className={"p-error text-align-left mt-1 ".concat(validationError ? "block" : "hidden")}>
                        {translatedMessage("form.error.profile.required")}
                    </small>
                </div>
                {props.showAddProfile &&
                    <Button
                        type='button'
                        icon={"pi pi-".concat(existingProfile?.id ? "pencil" : "plus")}
                        className="p-button-rounded p-button-info ml-3"
                        onClick={handleButtonClick}
                        tooltip={translatedMessage(existingProfile?.id ? 'person.editSelected' : 'person.add.ifNotFound')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={props?.disabled}
                    />
                }
            </div>

            <ProfileEditDialogComponent
                value={existingProfile}
                entityName={props?.entityName}
                visible={showProfileEditDialog}
                afterSave={(savedData) => handleProfileChange(savedData)}
                visibleSetter={(value) => displayEditDialog(value)}
            />
        </>
    );
};

export default ProfileAutocompleteFieldComponent;
