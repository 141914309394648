import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { CenterService } from "../../service/CenterService";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import { hasPermission } from '../../service/UserService';
import GeneralUtils from "../../utilities/GeneralUtils";
import EnumService from "../../service/EnumService";
import DeleteModalComponent from "../DeleteModalComponent";
import CenterEditDialog from "./CenterEditDialog";
import DataTableUtils from "../../utilities/DataTableUtils";
import CenterDataTableUtils from "../../utilities/CenterDataTableUtils";
import ExportButtonComponent from "../ExportButtonComponent";
import CenterViewDialogComponent from "./CenterViewDialogComponent";
import SecurityService from '../../service/SecurityService';

const CenterListComponent = (props) => {
    const [centers, setCenters] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [centerComplexes, setCenterComplexes] = useState([]);
    const [socialServiceTypes, setSocialServiceTypes] = useState([]);

    const [canCreate, setCanCreate] = useState(false);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            "centerDetails.socialServiceCode": { value: null, matchMode: FilterMatchMode.CONTAINS },
            "centerDetails.socialServiceType": { value: null, matchMode: FilterMatchMode.IN },
            complex: { value: null, matchMode: FilterMatchMode.IN },
            department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);

    const propToColumnMap = {
        name: "name",
        "centerDetails.socialServiceCode": "social_service_code",
        "centerDetails.socialServiceType": "social_service_type_id",
        complex: "complex_id",
        department: "department_name",
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const navigate = useNavigate();

    const centerService = useMemo(() => new CenterService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getCenterComplex = async () => {
            const _centerComplexes = await EnumService.getEntityLookupByField("NOM:CENTER_COMPLEX");
            setCenterComplexes(_centerComplexes);
        };

        const getSocialServiceTypes = async () => {
            const _socialServiceTypes = await EnumService.getEntityLookupByField("NOM:SOCIAL_SERVICE_TYPE");
            setSocialServiceTypes(_socialServiceTypes);
        };

        const checkCanCreate = async () => {
            setCanCreate(await SecurityService.check('ADD_ORGANIZATION'));
        };


        getCenterComplex();
        getSocialServiceTypes();
        checkCanCreate()
        setFirstLoading(false);
    }, [centerService, props]);

    useEffect(() => {
        loadCenters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues("CenterList", lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";
        criteria.name = lazyParams.filters.name.value;
        criteria.socialServiceCode = lazyParams.filters["centerDetails.socialServiceCode"].value;
        criteria.socialServiceTypes = lazyParams.filters["centerDetails.socialServiceType"].value;
        criteria.complexes = lazyParams.filters.complex.value;
        criteria.parentName = lazyParams.filters.department.value;
        criteria.parentId = props?.parent?.id;
        return criteria;
    };

    const loadCenters = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await centerService
            .getCentersByCriteria(criteria)
            .then((data) => {
                setCenters(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const updateList = async (savedCenter, isNew) => {
        loadCenters();
    };

    const showCenterViewPage = (center) => {
        if (props.useDialog) {
            setSelectedCenter(center);
            setViewDialogVisible(true);
        } else {
            navigate(`/center/${center.id}`);
        }
    };

    const showNewDialog = () => {
        setSelectedCenter(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (center) => {
        setSelectedCenter(center);
        setEditDialogVisible(true);
    };

    const showDeleteDialog = (center) => {
        setSelectedCenter(center);
        setDeleteDialogVisible(true);
    };

    const deleteCenter = () => {
        centerService
            .deleteCenter(selectedCenter.id)
            .then(() => {
                let _centers = centers.filter(
                    (item) => item.id !== selectedCenter.id
                );
                setCenters(_centers);
                toast.current.show({
                    severity: "success",
                    summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedCenter(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("ORGANIZATION_CREATE") &&
                        (
                            <Button
                                label={translatedMessage("center.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreate}
                            />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            centerService
                .getCentersByCriteria(criteria)
                .then((data) => {
                    let exportData = []
                    data.items.forEach((item) => {
                        let exportItem = CenterDataTableUtils.computeCenterExportFields(item, true);
                        
                        exportData.push(exportItem)
                    });

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={CenterDataTableUtils.centerListTableHeader(true)}
                sortOrderHeader={CenterDataTableUtils.centerListSortOrderHeader(true)}
                fileName={GeneralUtils.computeExportFileName("center.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission("ORGANIZATION_VIEW") &&
                    (
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => showCenterViewPage(rowData)}
                            tooltip={translatedMessage("generic.view")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.VIEW_ORGANIZATION}
                        />
                    )}
                {hasPermission("ORGANIZATION_EDIT") &&
                    (
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showEditDialog(rowData)}
                            tooltip={translatedMessage("generic.edit")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_ORGANIZATION}
                        />
                    )}
                {hasPermission("ORGANIZATION_CREATE") &&
                    (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => showDeleteDialog(rowData)}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_ORGANIZATION}
                        />
                    )}
            </div>
        );
    };

    const parentBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('ORGANIZATION_VIEW') &&
                        <Button
                            text
                            label={rowData.department.name}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/department/${rowData.department.id}`)}
                        />
                    }
                    {!hasPermission('ORGANIZATION_VIEW') &&
                        rowData.department.name
                    }
                </div>
            </>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">
                            {props.pageTitle ? props.pageTitle : translatedMessage("center.list")}
                        </h5>
                    </div>

                    <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0" ></Toolbar>

                    <DataTable
                        ref={dt}
                        value={centers}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        scroll="true"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        <Column
                            field="name"
                            header={translatedMessage("generic.nameRo")}
                            headerStyle={{ width: "20%", minWidth: "10rem" }}
                            sortable
                            filterField="name"
                            filter
                            showFilterMenu={false}
                        />
                        {!props?.parent &&
                            <Column
                                field="department"
                                header={translatedMessage("department.parent")}
                                headerStyle={{ width: "20%", minWidth: "8rem" }}
                                body={parentBodyTemplate}
                                sortable
                                filter
                                showFilterMenu={false}
                            />
                        }
                        <Column
                            field="complex"
                            header={translatedMessage("center.complex")}
                            body={(e) => e.centerDetails?.complex?.label}
                            headerStyle={{ width: "20%", minWidth: "8rem" }}
                            filter
                            filterElement={(e) => CenterDataTableUtils.entityLookupFilterTemplate(e, centerComplexes, translatedMessage('center.complex'))}
                            filterHeaderClassName="pcn-filter-column-250"
                            showFilterMenu={false}
                        />
                        <Column
                            field="centerDetails.socialServiceCode"
                            header={translatedMessage("center.socialServiceCode")}
                            sortable
                            headerStyle={{ width: "10%", minWidth: "5rem" }}
                            filter
                            showFilterMenu={false}
                        />
                        <Column
                            field="centerDetails.socialServiceType"
                            header={translatedMessage("center.socialServiceType")}
                            body={(e) => e.centerDetails?.socialServiceType?.label}
                            headerStyle={{ width: "15%", minWidth: "8rem" }}
                            filter
                            filterElement={(e) => CenterDataTableUtils.entityLookupFilterTemplate(e, socialServiceTypes, translatedMessage('center.socialServiceType'))}
                            filterHeaderClassName="pcn-filter-column-300"
                            showFilterMenu={false}
                        />
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <CenterEditDialog
                        value={selectedCenter}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={setEditDialogVisible}
                        centerComplexes={centerComplexes}
                        socialServiceTypes={socialServiceTypes}
                        parent={props?.parent}
                    />

                    <CenterViewDialogComponent
                        centerId={selectedCenter?.id}
                        visible={viewDialogVisible}
                        visibleSetter={setViewDialogVisible}
                        centerComplexes={centerComplexes}
                        socialServiceTypes={socialServiceTypes}
                    />

                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedCenter ? selectedCenter.name : ""}
                        closeDialog={() => { setDeleteDialogVisible(false); }}
                        deleteRecord={() => deleteCenter()}
                        info="center.delete.info"
                    />
                </>
            )}
        </>
    );
};

export default CenterListComponent;
