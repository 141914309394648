import React, { useState, useEffect } from 'react';
import { getIn, useFormik } from "formik";

import { InputTextarea } from 'primereact/inputtextarea';
import { translatedMessage } from '../../service/LanguageService';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { NoticeService } from '../../service/NoticeService';
import ProfileAutocompleteFieldComponent from '../profile/ProfileAutocompleteFieldComponent';
import BeneficiaryAutocompleteFieldComponent from '../profile/BeneficiaryAutocompleteFieldComponent';

let emptyRecord = {
    id: null,
    noticeType: null,
    reporterProfile: null,
    reporterType: null,
    reportMode: null,
    subject: '',
    noticeDate: null,
    beneficiary: null,
    organizationProfile: null
};

const NoticeEditComponent = (props) => {
    const [notice, setNotice] = useState()
    const [noticeTypeList, setNoticeTypeList] = useState([])
    const [noticeReporterTypeList, setNoticeReporterTypeList] = useState([])
    const [noticeReportModeList, setNoticeReportModeList] = useState([])

    const noticeService = new NoticeService();

    useEffect(() => {
        const getNoticeTypeList = async () => {
            const _noticeTypes = await EnumService.getEntityLookupByField("NOM:NOTICE_TYPE")
            setNoticeTypeList(_noticeTypes)
        }

        const getNoticeReporterList = async () => {
            const _noticeReporterList = await EnumService.getEntityLookupByField("NOM:NOTICE_REPORTER")
            setNoticeReporterTypeList(_noticeReporterList)
        }

        const getNoticeReportModeList = async () => {
            const _noticeReportModeList = await EnumService.getEntityLookupByField("NOM:NOTICE_REPORT_MODE")
            setNoticeReportModeList(_noticeReportModeList)
        }

        const setupNotice = async () => {
            let _notice = props.value;
            if (!_notice) {
                _notice = { ...emptyRecord };
                if (props?.beneficiary) {
                    _notice.beneficiary = props?.beneficiary;
                }
            } else {
                _notice = GeneralUtils.emptyInNullForObjectFields(_notice);
                _notice.reporterProfile = GeneralUtils.emptyInNullForObjectFields(_notice.reporterProfile);
            }
            setNotice(_notice);

            if (props?.noticeTypeList) {
                setNoticeTypeList(props?.noticeTypeList);
            } else {
                await getNoticeTypeList();
            }
            if (props?.noticeReporterTypeList) {
                setNoticeReporterTypeList(props?.noticeReporterTypeList)
            } else {
                await getNoticeReporterList();
            }
            if (props?.noticeReportModeList) {
                setNoticeReportModeList(props?.noticeReportModeList);
            } else {
                await getNoticeReportModeList();
            }
        }

        setupNotice();
    }, [props]);

    const formik = useFormik({
        initialValues: notice ? notice : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.subject) {
                errors.subject = translatedMessage("form.error.subject.required");
            }
            if (!data.noticeDate) {
                errors.noticeDate = translatedMessage("form.error.noticeDate.required");
            }
            if (!data.noticeType) {
                errors.noticeType = translatedMessage("form.error.noticeType.required");
            }
            if (!data.reporterType) {
                errors.reporterType = translatedMessage("form.error.reporterType.required");
            }
            if (!data.reportMode) {
                errors.reportMode = translatedMessage("form.error.reportMode.required");
            }

            // if (!data.reporterProfile) {
            //     errors.reporterProfile = translatedMessage("form.error.profile.required");
            // }

            return errors;
        },
        onSubmit: (data) => {
            //console.log("save notice data:", data)
            noticeService.saveNotice(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const handleProfileChange = async (_existingProfile) => {
        setNotice({ ...formik.values, reporterProfile: _existingProfile })
    }

    const handleBeneficiaryChange = async (_beneficiary) => {
        setNotice({ ...formik.values, beneficiary: _beneficiary })
    }

    return (
        <>
            <div className="flex flex-column align-items-center">
                <div className="col-12">
                    <form id="notice-form" onSubmit={formik.handleSubmit}>
                        <div className='grid'>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="noticeType.code" name="noticeType.code" value={formik.values.noticeType?.code} onChange={formik.handleChange}
                                            options={noticeTypeList} optionValue='code' className={classNames({ 'p-invalid': isFormFieldValid('noticeType') })} />
                                        <label htmlFor="noticeType.code" className={classNames({ 'p-error': isFormFieldValid('noticeType') })}>
                                            {translatedMessage('notice.noticeType')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('noticeType')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="noticeDate" name="noticeDate" value={formik.values.noticeDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                            className={classNames({ 'p-invalid': isFormFieldValid('noticeDate') })} />
                                        <label htmlFor="noticeDate" className={classNames({ 'p-error': isFormFieldValid('noticeDate') })}>
                                            {translatedMessage("notice.noticeDate")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('noticeDate')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="reporterType.code" name="reporterType.code" value={formik.values.reporterType?.code} onChange={formik.handleChange}
                                            options={noticeReporterTypeList} optionValue='code' className={classNames({ 'p-invalid': isFormFieldValid('reporterType') })} />
                                        <label htmlFor="reporterType.code" className={classNames({ 'p-error': isFormFieldValid('reporterType') })}>
                                            {translatedMessage('notice.reporterType')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('reporterType')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="reportMode.code" name="reportMode.code" value={formik.values.reportMode?.code} onChange={formik.handleChange}
                                            options={noticeReportModeList} optionValue='code' className={classNames({ 'p-invalid': isFormFieldValid('reportMode') })} />
                                        <label htmlFor="reportMode.code" className={classNames({ 'p-error': isFormFieldValid('reportMode') })}>
                                            {translatedMessage('notice.reportMode')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('reportMode')}
                            </div>

                            <div className="col-12">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputTextarea name="subject" id="subject" value={formik.values.subject} onChange={formik.handleChange} rows={5}
                                            className={classNames({ 'p-invalid': isFormFieldValid('subject') })} />
                                        <label htmlFor="subject" className={classNames({ 'p-error': isFormFieldValid('subject') })}>
                                            {translatedMessage("notice.subject")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('subject')}
                            </div>

                            <BeneficiaryAutocompleteFieldComponent
                                beneficiary={notice?.beneficiary}
                                setBeneficiary={(e) => handleBeneficiaryChange(e)}
                                validationError={isFormFieldValid('beneficiary')}
                                disabled={props?.beneficiary}
                            />

                            <ProfileAutocompleteFieldComponent
                                profile={props?.value?.reporterProfile}
                                setExistingProfile={(e) => handleProfileChange(e)}
                                entityName="PROFILE"
                                showAddProfile={true}
                                validationError={isFormFieldValid('reporterProfile')}
                                title={translatedMessage('notice.reporterProfile')}
                                disabled={props?.disableProfile}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default NoticeEditComponent;
