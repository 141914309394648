import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useNavProps from '../../hooks/useNavProps';
import { ProgressSpinner } from 'primereact/progressspinner';
import { translatedMessage } from '../../service/LanguageService';
import { CenterService } from '../../service/CenterService';
import CenterToolbar from './CenterToolbar';
import EnumService from "../../service/EnumService";
import CenterViewComponent from '../../components/center/CenterViewComponent';
import { Toast } from 'primereact/toast';

const CenterView = () => {
    const [center, setCenter] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [centerComplexes, setCenterComplexes] = useState([]);
    const [socialServiceTypes, setSocialServiceTypes] = useState([]);

    const toast = useRef(null);

    const centerService = useMemo(() => new CenterService(), []);

    const { centerIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getCenter = async () => {
            await centerService.getCenter(centerIdParam)
                .then(async (_data) => {
                    setCenter(_data);
                    setNavPropsValue('center', _data.id, _data.name);                    
                    getCenterComplex();
                    getSocialServiceTypes();
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getCenterComplex = async () => {
            const _centerComplexes = await EnumService.getEntityLookupByField("NOM:CENTER_COMPLEX");
            setCenterComplexes(_centerComplexes);
        };

        const getSocialServiceTypes = async () => {
            const _socialServiceTypes = await EnumService.getEntityLookupByField("NOM:SOCIAL_SERVICE_TYPE");
            setSocialServiceTypes(_socialServiceTypes);
        };

        getCenter();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [centerService, centerIdParam]);

    return (
        <>
            <Toast ref={toast} />
            {isLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!isLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <CenterToolbar center={center} code="DETAIL" />

                                <CenterViewComponent center={center} centerComplexes={centerComplexes} socialServiceTypes={socialServiceTypes} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CenterView;
