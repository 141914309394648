import GeneralUtils from "../utilities/GeneralUtils";
import ApiService from "./ApiService";

export class ProfileService {

    async getUserProfilesByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/profile/users`, criteria)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getProfilesByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post(`/profile/list`, criteria)
                .then((response) => {
                    response.data.items.map(item => {
                        item.birthday = item.birthday ? new Date(Date.parse(item.birthday)) : ''
                        return item
                    });
                    resolve(response.data)
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getProfilesForSearchByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/list/for-search`, criteria)
                .then((response) => {
                    let _profiles = []
                    response.data.forEach((item) => {
                        let _profile = GeneralUtils.emptyInNullForObjectFields(item)
                        _profile.birthday = item?.birthday ? new Date(Date.parse(item.birthday)) : '';

                        _profiles.push(_profile)
                    });
                    resolve(_profiles);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getProfile(profileId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/profile/${profileId}`)
                .then(response => {
                    let _profile = response.data
                    _profile.birthday = _profile?.birthday ? new Date(Date.parse(_profile.birthday)) : '';
                    resolve(_profile)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveProfile(profile) {
        profile.birthCounty = GeneralUtils.computeEntityLookupObject(profile.birthCounty)
        let _profile = GeneralUtils.nullIfEmptyForObjectFields({ ...profile })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/save`, _profile)
                .then(response => {
                    response.data.birthday = response.data?.birthday ? new Date(Date.parse(response.data.birthday)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteProfile(profileId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/profile/${profileId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }

    async saveExtendedProfile(profileE) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/profile-extended/save`, profileE)
                .then(response => {
                    response.data.idCardExpiryDate = response.data.idCardExpiryDate ? new Date(Date.parse(response.data.idCardExpiryDate)) : '';
                    response.data.adoptionOpeningSentenceDate = response.data.adoptionOpeningSentenceDate ? new Date(Date.parse(response.data.adoptionOpeningSentenceDate)) : '';
                    response.data.disabilityCertificateDate = response.data.disabilityCertificateDate ? new Date(Date.parse(response.data.disabilityCertificateDate)) : '';
                    response.data.schoolGuidanceCertificateDate = response.data.schoolGuidanceCertificateDate ? new Date(Date.parse(response.data.schoolGuidanceCertificateDate)) : '';
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getProfileAddressHistory(profileId, type) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().get(`/profile/${profileId}/address-history/${type}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getLegalRespList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/legal-representative/list', criteria)
                .then((response) => {
                    response.data.items.map((item) => {
                        item.startDate = item.startDate ? new Date(Date.parse(item.startDate)) : ''
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : ''

                        let _birthday = item.profile?.birthday ? new Date(Date.parse(item.profile?.birthday)) : ''
                        item.profile = { ...item.profile, birthday: _birthday}
                        
                        return item
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveLegalRep(legalRep) {
        let _profile = { id: legalRep.profile.id }
        let _beneficiary = { id: legalRep.beneficiary.id }        
        let _legalRep = GeneralUtils.nullIfEmptyForObjectFields({ ...legalRep, profile: _profile, beneficiary: _beneficiary })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/legal-representative/save', _legalRep)
                .then(response => {
                    response.data.startDate = response.data.startDate ? new Date(Date.parse(response.data.startDate)) : ''
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : ''
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getLegalRep(id) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/legal-representative/${id}`)
                .then(response => {
                    let _legalRep = { ...response.data }
                    let _profile = _legalRep.profile

                    _profile.birthday = _profile?.birthday ? new Date(Date.parse(_profile.birthday)) : '';
                    _legalRep.profile = _profile

                    resolve(_legalRep)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveLegalRepAddress(id, address) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/legal-representative/${id}/save-address`, address)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveAddress(profileId, address) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/${profileId}/save-address`, address)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getRelatedProfileForProfileList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/related-profile/list/for-profile', criteria)
                .then((response) => {
                    response.data.map((item) => {
                        item.relatedProfile = GeneralUtils.emptyInNullForObjectFields(item.relatedProfile)
                        item.relatedProfile.birthday = item.relatedProfile.birthday ? new Date(Date.parse(item.relatedProfile.birthday)) : ''
                        return item
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveRelatedProfile(related) {
        related.relatedProfile.birthCounty = GeneralUtils.computeEntityLookupObject(related.relatedProfile.birthCounty)
        related.relationType = GeneralUtils.computeEntityLookupObject(related.relationType)
        let _profile = { id: related.profile.id }
        let _relProfile = { id: related.relatedProfile.id }
        let _related = GeneralUtils.nullIfEmptyForObjectFields({ ...related, profile: _profile, relatedProfile: _relProfile })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/related-profile/save', _related)
                .then(response => {
                    response.data.relatedProfile = GeneralUtils.emptyInNullForObjectFields(response.data.relatedProfile)
                    response.data.relatedProfile.birthday = response.data?.relatedProfile?.birthday ? new Date(Date.parse(response.data.relatedProfile.birthday)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getProfileCredentialList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/profileCredential/list', criteria)
                .then((response) => {
                    resolve(response.data.items);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveProfileCredential(profileCredential) {
        let _profileCredential = { ...profileCredential, profile: GeneralUtils.nullIfEmptyForObjectFields(profileCredential.profile) };

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/profileCredential', _profileCredential)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteProfileCredential(profileCredentialId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/profileCredential/${profileCredentialId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }
}