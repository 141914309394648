import React from 'react';
import { Divider } from 'primereact/divider';
import { translatedMessage } from '../service/LanguageService';

const PasswordRequirement = () => {
    return (
        <React.Fragment>
            <Divider />
            <p className="mt-2">{translatedMessage("password.req")}</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>{translatedMessage("password.req.oneLowercase")}</li>
                <li>{translatedMessage("password.req.oneUpppercase")}</li>
                <li>{translatedMessage("password.req.oneNumeric")}</li>
                <li>{translatedMessage("password.req.oneSpecial")}</li>
                <li>{translatedMessage("password.req.minCharacters")}</li>
            </ul>
        </React.Fragment>
    );
};
export default PasswordRequirement;
