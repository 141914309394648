const ProfilelUtils = {

  newProfileExtendedRecord() {
    return {
      idStatusId: null,
      idStatus: null,
      idCardType: '',
      idCardSeries: '',
      idCardNumber: '',
      idCardExpiryDate: '',
      canBeAdopted: false,
      adoptionOpeningSentenceNumber: '',
      adoptionOpeningSentenceDate: '',
      healthStatusId: null,
      healthStatus: null,
      disabilityDegreeId: null,
      disabilityDegree: null,
      disabilityTypeId: null,
      disabilityType: null,
      disabilityCertificateNumber: '',
      disabilityCertificateDate: '',
      disabilityCertificateIssuer: '',
      diseaseTypeId: null,
      diseaseType: null,
      needsSchoolGuidance: false,
      schoolGuidanceCertificateNumber: '',
      schoolGuidanceCertificateDate: '',
      schoolGuidanceCertificateIssuerId: null,
      schoolGuidanceCertificateIssuer: null,
      educationLevelId: null,
      educationLevel: null,
      educationStatusId: null,
      educationStatus: null,
      schoolName: '',
      schoolGrade: '',
      employmentStatusId: null,
      employmentStatus: null,
      employerName: '',
      maritalStatusId: null,
      maritalStatus: null,
    }
  },

  checkHasPinMandatory(entityName) {
    return entityName === "AMP" || entityName === "FAMILY_PLACEMENT"
  },

  checkPinMandatory(entityName) {
    return entityName === "RELATED_PROFILE" || entityName === "AMP" || entityName === "FAMILY_PLACEMENT"
  },

  checkBirthdayMandatory(entityName) {
    return entityName === "RELATED_PROFILE" || entityName === "AMP" || entityName === "FAMILY_PLACEMENT"
  },

  checkGenderMandatory(entityName) {
    return entityName === "RELATED_PROFILE" || entityName === "AMP" || entityName === "FAMILY_PLACEMENT"
  },

  checkBirthCountyMandatory(entityName) {
    return entityName === "RELATED_PROFILE" || entityName === "AMP" || entityName === "FAMILY_PLACEMENT"
  },

  checkBirthEnvironmentTypeMandatory(entityName) {
    return entityName === "RELATED_PROFILE" || entityName === "AMP" || entityName === "FAMILY_PLACEMENT"
  },

  showMaritalStatus(entityName) {
    return entityName === "PROFILE" || entityName === "AMP" || entityName === "FAMILY_PLACEMENT"
  },

  canBeResponsibleForEntities(userProfile) {
    return userProfile?.organizationProfile && userProfile?.organizationProfile.positionType !== "SEF_SERVICIU"
  }
};

export default ProfilelUtils;
