import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import ServiceSupplyListComponent from "../../components/serviceSupply/ServiceSupplyListComponent";

const ServiceSupplyListPage = () => {

    const toast = useRef(null);

    return (
        <>
            <Toast ref={toast} />
            <div className="grid h-full">
                <div className="col-12">
                    <div className="card h-full">
                        <ServiceSupplyListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceSupplyListPage;
