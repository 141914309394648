import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import { hasPermission } from '../../service/UserService';
import EnumService from "../../service/EnumService";
import DataTableUtils from "../../utilities/DataTableUtils";
import { DashboardService } from "../../service/DashboardService";
import useAuth from "../../hooks/useAuth";
import ProfilelUtils from "../../utilities/ProfilelUtils";

const DashboardNoticeList = (props) => {
    const [notices, setNotices] = useState([]);

    const [noticeTypeList, setNoticeTypeList] = useState([])

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 1,
        sortField: "noticeDate",
        sortOrder: -1,
        filters: {
            reporterName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            beneficiaryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            noticeType: { value: null, matchMode: FilterMatchMode.IN }
        },
    });

    const propToColumnMap = {
        reporterName: "reporter_name",
        beneficiaryName: "beneficiary_name",
        noticeType: "type_id",
        noticeDate: "notice_date",
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [canBeResonsible, setCanBeResponsible] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();

    const navigate = useNavigate();

    const dashboardService = useMemo(() => new DashboardService(), []);

    useEffect(() => {
        const getNoticeTypes = async () => {
            const _noticeTypes = await EnumService.getEntityLookupByField("NOM:NOTICE_TYPE")
            setNoticeTypeList(_noticeTypes)
        }

        setCanBeResponsible(ProfilelUtils.canBeResponsibleForEntities(auth?.user))

        getNoticeTypes()
    }, [props, auth]);

    useEffect(() => {
        loadNotices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";
        criteria.beneficiaryName = lazyParams.filters.beneficiaryName.value;
        criteria.noticeTypeIds = lazyParams.filters.noticeType.value;
        return criteria;
    };

    const loadNotices = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await dashboardService.getNoticeList(criteria)
            .then((data) => {
                setNotices(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast?.current?.show({ severity: "error", summary: translatedMessage(error), life: 5000, });
            });
    };

    const showNoticeViewPage = (_notice) => {
        navigate(`/notice/${_notice.id}`);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-notice justify-content-end">
                {hasPermission("NOTICE_VIEW") &&
                    <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-primary m-1"
                        onClick={() => showNoticeViewPage(rowData)}
                        tooltip={translatedMessage("generic.view")}
                        tooltipOptions={{ showOnDisabled: true, position: "top" }}
                    />
                }
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    const beneficiaryBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button
                            text
                            label={rowData.beneficiary?.profile?.fullName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/beneficiary/${rowData.beneficiary?.id}/notice`)}
                        />
                    }
                    {!hasPermission('BENEFICIARY_VIEW') &&
                        rowData.beneficiary?.profile?.fullName
                    }
                </div>
            </>
        );
    }

    const noticeBodyTemplate = (rowData) => {
        return (
            <>
                <div>{rowData.noticeType?.label}</div>
                <div className="mt-2">{translatedMessage("notice.reporterType") + ": " + rowData.reporterType?.label}</div>
                <div className="mt-2">{translatedMessage("notice.reportMode") + ": " + rowData.reportMode?.label}</div>
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateTemplate(rowData.noticeDate)
    }

    return (
        <div className="card mb-3">
            <Toast ref={toast} />

            <div className='w-full text-align-left'>
                <h5 className="m-0">{canBeResonsible ? translatedMessage('dashboard.responsibleForNotices') : translatedMessage("notice.list")}</h5>
            </div>

            <DataTable
                ref={dt}
                value={notices}
                dataKey="id"
                paginator
                lazy
                totalRecords={totalRecords}
                first={lazyParams.first}
                rows={lazyParams.rows}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                rowsPerPageOptions={5}
                className="datatable-responsive pcn-datatable"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                filterDisplay="row"
                filters={lazyParams.filters}
                loading={isLoading}
                alwaysShowPaginator={false}
            >
                <Column
                    field="noticeType"
                    header={translatedMessage("notice.noticeType")}
                    body={noticeBodyTemplate}
                    headerStyle={{ width: "40%", minWidth: "8rem" }}
                    filter
                    filterElement={(e) => DataTableUtils.entityLookupFilterTemplate(e, noticeTypeList, translatedMessage('notice.noticeType'))}
                    filterHeaderClassName="pcn-filter-column-250"
                    showFilterMenu={false}
                />
                <Column
                    field="beneficiaryName"
                    header={translatedMessage("notice.beneficiary")}
                    headerStyle={{ width: "25%", minWidth: "8rem" }}
                    body={beneficiaryBodyTemplate}
                    sortable
                    filter
                    showFilterMenu={false}
                />
                <Column
                    field="noticeDate"
                    header={translatedMessage("notice.noticeDate")}
                    headerStyle={{ width: "5%", minWidth: "8rem" }}
                    body={dateBodyTemplate}
                    sortable
                />
                <Column body={actionBodyTemplate}></Column>
            </DataTable>
        </div>
    )
};

export default DashboardNoticeList;
