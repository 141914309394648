import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';

import { translatedMessage } from '../../service/LanguageService';
import { useFormik } from 'formik';
import GeneralUtils from '../../utilities/GeneralUtils';
import { StorageService } from '../../service/StorageService';
import EnumService from '../../service/EnumService';

const emptyRecord = { issuer: '', registrationNumber: '', registrationDate: '' }

const FilePropertiesEditDialogComponent = (props) => {
    const [file, setFile] = useState(null);
    const [usageLookup, setUsageLookup] = useState([]);
    const [filteredentityLookups, setFilteredentityLookups] = useState([]);
    const [issuers, setIssuers] = useState([]);

    const toast = useRef(null);

    const storageService = useMemo(() => new StorageService(), []);

    useEffect(() => {
        const getIssuers = async () => {
            const _issuers = await EnumService.getEntityLookupByField("NOM:DOC_ISSUER")
            setIssuers(_issuers)
        }

        let _file = props.value ? GeneralUtils.emptyInNullForObjectFields(props.value) : emptyRecord
        _file.usage = EnumService.getEntityLookupRecord(_file.usageId, props?.usageLookup)
        setFile(_file);
        setUsageLookup(props?.usageLookup)
        getIssuers()
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false)
            setFile(emptyRecord)
        }
    };

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredentityLookups(_filteredItems);
    }

    const handleListChange = async (data, field) => {
        formik.setFieldValue(field, data.value)
    }

    const formik = useFormik({
        initialValues: file ? file : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            data.usageId = data.usage && data.usage.value ? data.usage.value : null
            data.issuer = data.issuer && data.issuer.label ? data.issuer.label : data.issuer

            let _file = GeneralUtils.nullIfEmptyForObjectFields({ ...data })

            storageService.saveFileProperties(_file)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData);
                        toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success"), life: 3000 });
                    }
                })
                .catch(errorMessage => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage), life: 5000 });
                })

            hideDialog()
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="profile-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('file.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <div className="flex flex-column align-items-center">
                    <div className="col-12">
                        <form id="profile-form" onSubmit={formik.handleSubmit}>
                            <div className='grid'>
                                <div className="col-12 md:col-6">
                                    <div className="p-inputgroup">
                                        <span className="p-float-label">
                                            <AutoComplete
                                                dropdown
                                                id="issuer"
                                                name="issuer"
                                                value={formik.values.issuer}
                                                suggestions={filteredentityLookups}
                                                completeMethod={(e) => searchListItems(e, issuers)}
                                                virtualScrollerOptions={{ itemSize: 38 }}
                                                field="label"
                                                onChange={(d) => handleListChange(d, "issuer")}
                                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                className={classNames({ 'p-invalid': isFormFieldValid('issuer') })}
                                            />
                                            <label htmlFor="issuer" className={classNames({ 'p-error': isFormFieldValid('issuer') })}>
                                                {translatedMessage("file.issuer")}
                                            </label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('issuer')}
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="p-inputgroup">
                                        <span className="p-float-label">
                                            <InputText type="text" name="registrationNumber" id="registrationNumber" value={formik.values.registrationNumber}
                                                onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('registrationNumber') })} />
                                            <label htmlFor="registrationNumber" className={classNames({ 'p-error': isFormFieldValid('registrationNumber') })}>
                                                {translatedMessage("file.registrationNumber")}
                                            </label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('registrationNumber')}
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="p-inputgroup">
                                        <span className="p-float-label">
                                            <Calendar id="registrationDate" name="registrationDate" value={formik.values.registrationDate} dateFormat="dd-mm-yy"
                                                onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('registrationDate') })} />
                                            <label htmlFor="registrationDate" className={classNames({ 'p-error': isFormFieldValid('registrationDate') })}>
                                                {translatedMessage("file.registrationDate")}
                                            </label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('registrationDate')}
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="p-inputgroup">
                                        <span className="p-float-label">
                                            <AutoComplete
                                                dropdown
                                                id="usageId"
                                                name="usageId"
                                                value={formik.values.usage}
                                                suggestions={filteredentityLookups}
                                                completeMethod={(e) => searchListItems(e, usageLookup)}
                                                virtualScrollerOptions={{ itemSize: 38 }}
                                                field="label"
                                                onChange={(d) => handleListChange(d, "usage")}
                                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                className={classNames({ 'p-invalid': isFormFieldValid('usageId') })}
                                            />
                                            <label htmlFor="usageId" className={classNames({ 'p-error': isFormFieldValid('usageId') })}>
                                                {translatedMessage("file.usage")}
                                            </label>
                                        </span>
                                    </div>
                                    {getFormErrorMessage('usageId')}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </>);
};

export default FilePropertiesEditDialogComponent;
