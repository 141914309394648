import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getIn, setIn, useFormik } from "formik";
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';
import { translatedMessage } from '../../service/LanguageService';
import { ServicesService } from '../../service/ServicesService';
import { ServiceExpertBeneficiaryService } from '../../service/ServiceExpertBeneficiaryService';
import { OrganizationProfileService } from "../../service/OrganizationProfileService";
import ServiceDataTableUtils from "../../utilities/ServiceDataTableUtils";
import OrganizationDataTableUtils from "../../utilities/OrganizationDataTableUtils";
import BeneficiaryAutocompleteFieldComponent from '../profile/BeneficiaryAutocompleteFieldComponent';
import { InputTextarea } from 'primereact/inputtextarea';

let emptyServiceExpertBeneficiary = {
    id: null,
    objective: '',
    service: null,
    organizationProfile: null,
    beneficiary: null,
    serviceVenue: {
        id: null,
        code: ''
    },
    startDate: null,
    endDate: null
};

const ServiceExpertBeneficiaryEditDialog = (props) => {
    const [serviceExpertBeneficiary, setServiceExpertBeneficiary] = useState(emptyServiceExpertBeneficiary);
    const [isNew, setIsNew] = useState(false);
    const [filteredServiceList, setFilteredServiceList] = useState([]);
    const [filteredExpertList, setFilteredExpertList] = useState([]);
    const [serviceVenues, setServiceVenues] = useState([]);

    const toast = useRef(null);

    const servicesService = useMemo(() => new ServicesService(), []);
    const serviceExpertBeneficiaryService = useMemo(() => new ServiceExpertBeneficiaryService(), []);
    const organizationProfileService = useMemo(() => new OrganizationProfileService(), []);

    useEffect(() => {
        let _serviceExpertBeneficiary = props.value;
        if (!_serviceExpertBeneficiary) {
            _serviceExpertBeneficiary = { ...emptyServiceExpertBeneficiary }
            _serviceExpertBeneficiary.beneficiary = props?.beneficiary
            if(props?.service) {
                _serviceExpertBeneficiary.service = props?.service                
                _serviceExpertBeneficiary.service.display = ServiceDataTableUtils.getServiceDisplayName(props?.service);    
            }            
        } else {
            _serviceExpertBeneficiary = setIn(_serviceExpertBeneficiary, 'service.display', ServiceDataTableUtils.getServiceDisplayName(_serviceExpertBeneficiary.service));
            _serviceExpertBeneficiary = setIn(_serviceExpertBeneficiary, 'organizationProfile.displayName', OrganizationDataTableUtils.getOrganizationProfileDisplayName(_serviceExpertBeneficiary.organizationProfile));
        }
        setServiceExpertBeneficiary(_serviceExpertBeneficiary);
        setServiceVenues(props?.serviceVenues);
        setIsNew(props.value ? false : true);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const saveServiceExpertBeneficiary = async (data) => {
        await serviceExpertBeneficiaryService.saveServiceExpertBeneficiary(data)
            .then((savedServiceSupply) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedServiceSupply, isNew);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: serviceExpertBeneficiary,
        validate: (data) => {
            let errors = {};

            if (!data.service?.id) {
                errors.service = translatedMessage('form.error.service.required');
            }
            if (!data.organizationProfile?.id) {
                errors.organizationProfile = translatedMessage('form.error.expert.required');
            }
            if (!data.beneficiary?.id) {
                errors.beneficiary = translatedMessage('form.error.beneficiary.required');
            }
            if (!data.serviceVenue?.code) {
                errors.serviceVenue = {};
                errors.serviceVenue.code = translatedMessage('form.error.serviceVenue.required');
            }
            if (!data.objective) {
                errors.objective = translatedMessage('form.error.objective.required');
            }
            if (!data.startDate) {
                errors.startDate = translatedMessage('form.error.startDate.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveServiceExpertBeneficiary(data);
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const searchServices = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "organization_name";
        criteria.serviceName = query.toLowerCase();

        servicesService.getServiceViewByCriteria(criteria)
            .then((result) => {
                result.items.map(item => {
                    item.display = ServiceDataTableUtils.getServiceDisplayName(item);
                    return item;
                })
                setFilteredServiceList(result.items);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    }

    const searchExperts = async (event) => {
        let query = event.query;

        let criteria = {};
        criteria.sortOrder = "ASC";
        criteria.sortField = "profile_name";
        criteria.expert = query.toLowerCase();
        criteria.organizationIds = [];
        let _service = formik.values.service;
        if(_service?.organizationId){
            criteria.organizationIds.push(_service.organizationId);
        }
        if(_service?.centerId){
            criteria.organizationIds.push(_service.centerId);
        }
        if(_service?.organization?.id){
            criteria.organizationIds.push(_service.organization.id);
        }
        if(_service?.center?.id){
            criteria.organizationIds.push(_service.center.id);
        }
        criteria.pageSize = 50;

        organizationProfileService.getOrganizationProfilesByCriteria(criteria)
            .then((result) => {
                result.items.map(item => {
                    item.displayName = OrganizationDataTableUtils.getOrganizationProfileDisplayName(item);
                    return item;
                })
                setFilteredExpertList(result.items);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    }

    const handleBeneficiaryChange = async (_beneficiary) => {
        setServiceExpertBeneficiary({ ...formik.values, beneficiary: _beneficiary })
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('serviceExpertBeneficiary.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="service"
                                        name="service"
                                        value={formik.values.service}
                                        suggestions={filteredServiceList}
                                        completeMethod={(e) => searchServices(e)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="display"
                                        onChange={formik.handleChange}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('service') })}
                                        autoFocus
                                        disabled={props?.service}
                                    />
                                    <label htmlFor="service" className={classNames({ 'p-error': isFormFieldValid('service') })}>
                                        {translatedMessage("serviceSupply.service")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('service')}
                        </div>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="organizationProfile"
                                        name="organizationProfile"
                                        value={formik.values.organizationProfile}
                                        suggestions={filteredExpertList}
                                        completeMethod={(e) => searchExperts(e)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="displayName"
                                        onChange={formik.handleChange}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('organizationProfile') })}
                                    />
                                    <label htmlFor="organizationProfile" className={classNames({ 'p-error': isFormFieldValid('organizationProfile') })}>
                                        {translatedMessage("serviceExpertBeneficiary.expert")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('organizationProfile')}
                        </div>
                        <BeneficiaryAutocompleteFieldComponent
                            beneficiary={formik.values.beneficiary}
                            setBeneficiary={(e) => handleBeneficiaryChange(e)}
                            validationError={isFormFieldValid('beneficiary')}
                            disabled={props?.beneficiary}
                        />                        
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown id="serviceVenue.code" name="serviceVenue.code" value={formik.values.serviceVenue?.code} onChange={formik.handleChange}
                                        options={serviceVenues} optionValue='code' className={classNames({ 'p-invalid': isFormFieldValid('serviceVenue.code') })} />
                                    <label htmlFor="serviceVenue.code" className={classNames({ 'p-error': isFormFieldValid('serviceVenue.code') })}>
                                        {translatedMessage('serviceExpertBeneficiary.venue')}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('serviceVenue.code')}
                        </div>                        
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="startDate" name="startDate" value={formik.values.startDate} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} />
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                        {translatedMessage("generic.startDate")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('startDate')}
                        </div>
                        <div className='col-12 md:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar id="endDate" name="endDate" value={formik.values.endDate} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} />
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                        {translatedMessage("generic.endDate")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('endDate')}
                        </div>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputTextarea id="objective" name="objective" rows={5} cols={30} value={formik.values.objective} onChange={formik.handleChange} autoResize 
                                        className={classNames({ 'p-invalid': isFormFieldValid('objective') })} />
                                    <label htmlFor="objective" className={classNames({ 'p-error': isFormFieldValid('objective') })}>
                                        {translatedMessage('serviceExpertBeneficiary.objective')}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('objective')}
                        </div>
                    </div>
                </form>
            </Dialog>
        </>);
};

export default ServiceExpertBeneficiaryEditDialog;
