import React from 'react';
import { Button } from 'primereact/button';
import ApiService from '../../service/ApiService';
import { translatedMessage } from '../../service/LanguageService';

const StorageFileViewTableButton = (props) => {

    async function downloadFile(fileUuid, fileName) {
        try {
            const response = await ApiService.getAuthenticatedInstance().get('/storage/file/inline/' + fileUuid, { responseType: 'blob' });
            const url = URL.createObjectURL(new Blob([response.data], { type: response.data?.type }));
            window.open(url, "_blank", "noreferrer");
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <Button icon="pi pi-file"
                className="p-button-rounded p-button-primary m-1"
                disabled={!props.fileUuid || props.disabled}
                onClick={() => downloadFile(props.fileUuid, props.fileName)}
                tooltip={translatedMessage("generic.file.view")}
                tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                type='button' />
        </>
    );
};

export default StorageFileViewTableButton;
