import React, { useEffect, useState, useMemo, useRef } from 'react';
import { translatedMessage } from '../../service/LanguageService';
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';
import DataTableUtils from '../../utilities/DataTableUtils';
import { OrganizationService } from '../../service/OrganizationService';

const OrganizationAutocompleteFieldComponent = (props) => {
    const [organization, setOrganization] = useState()
    const [organizations, setOrganizations] = useState([])
    const [validationError, setValidationError] = useState(false);

    const toast = useRef(null);
    const organizationService = useMemo(() => new OrganizationService(), [])

    useEffect(() => {
        const getData = async () => {
            await search()
        }
        getData()

        setValidationError(props?.validationError)
        if (props?.organization) {
            let _organization = { ...props.organization }
            _organization.displayName = _organization.name + (_organization.code ? ( " (" + _organization.code + ")") : "")
            setOrganization(_organization)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const search = async (e = null) => {
        let criteria = {}
        criteria.pageSize = DataTableUtils.maxRowsForSearch();

        criteria.types = props?.types ? props?.types : null
        criteria.statuses = ['ACTIVE']

        if (e?.query) {
            criteria.name = e.query
        }

        organizationService.getOrganizationsByCriteria(criteria)
            .then((result) => {
                result.items.map(item => {
                    item.displayName = item.name + (item.code ? ( " (" + item.code + ")") : "")
                    return item
                })
                setOrganizations(result.items);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    }

    const handleProfileChange = async (_selected) => {
        setOrganization(_selected)

        if (_selected?.id && typeof props.setOrganization === 'function') {
            //console.log("_selected: ", _selected)
            props.setOrganization(_selected);
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="col-12 flex flex-row">
                <div className='flex-grow-1'>
                    <div className="p-inputgroup">
                        <span className="p-float-label">
                            <AutoComplete
                                dropdown
                                id="organization"
                                name="organization"
                                value={organization}
                                suggestions={organizations}
                                completeMethod={(e) => search(e)}
                                virtualScrollerOptions={{ itemSize: 38 }}
                                field={"displayName"}
                                onChange={(d) => handleProfileChange(d.value)}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                className={validationError ? 'p-invalid' : ''}
                                disabled={props?.disabled}
                            />
                            <label htmlFor="organization" className={validationError ? 'p-error' : ''}>
                                {props?.title ? props?.title : translatedMessage("department.list")}
                            </label>
                        </span>
                    </div>
                    <small className={"p-error text-align-left mt-1 ".concat(validationError ? "block" : "hidden")}>
                        {translatedMessage("form.error.department.required")}
                    </small>
                </div>
            </div>
        </>
    );
};

export default OrganizationAutocompleteFieldComponent;
