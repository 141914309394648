import React, { useState, useEffect } from 'react';
import { useFormik, getIn } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';

import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { ProfileService } from '../../service/ProfileService';
import ProfileAutocompleteFieldComponent from './ProfileAutocompleteFieldComponent';

let emptyRecord = {
    relationType: null,
    relatedProfile: null,
    profile: null,
};

const RelatedProfileEditComponent = (props) => {
    const [related, setRelated] = useState()
    const [isNew, setIsNew] = useState(true)
    const [relTypes, setRelTypes] = useState([])
    const [filteredentityLookups, setFilteredentityLookups] = useState([]);

    const profileService = new ProfileService();

    useEffect(() => {
        const getRelType = async () => {
            const _relTypes = await EnumService.getEntityLookupByField("NOM:USER_RELATION_TYPE")
            setRelTypes(_relTypes)
        }

        const computeRelated = async () => {
            await getRelType()

            let _related = emptyRecord
            if (props?.value) {
                _related = GeneralUtils.emptyInNullForObjectFields(props.value)
                setIsNew(false)
            }

            if (props?.profileId) {
                _related.profile = { id: props.profileId }
            }

            setRelated(_related)
        }

        computeRelated()
    }, [props]);

    const formik = useFormik({
        initialValues: related ? related : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.relationType || !data.relationType.value) {
                errors.relationType = translatedMessage("form.error.relationType.required");
            }

            if (!data.profile) {
                errors.profile = translatedMessage("form.error.beneficiary.required");
            }

            if (!data.relatedProfile) {
                errors.relatedProfile = translatedMessage("form.error.profile.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            // console.log("save: ", data)

            profileService.saveRelatedProfile(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, isNew, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    const handleListChange = async (data, field) => {
        formik.setFieldValue(field, data.value)
    }

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredentityLookups(_filteredItems);
    }

    const handleProfileChange = async (_existingProfile) => {
        setRelated({ ...formik.values, relatedProfile: _existingProfile })
    }

    return (
        <>
            <ProfileAutocompleteFieldComponent
                profile={related?.relatedProfile}
                setExistingProfile={(e) => handleProfileChange(e)}
                entityName="RELATED_PROFILE"
                showAddProfile={true}
                validationError={isFormFieldValid('relatedProfile')}
            />

            <div className="col-12">
                <form id="related-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>                        
                        <div className="col-12">
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <AutoComplete
                                        dropdown
                                        id="relationType"
                                        name="relationType"
                                        value={formik.values.relationType}
                                        suggestions={filteredentityLookups}
                                        completeMethod={(e) => searchListItems(e, relTypes)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="label"
                                        onChange={(d) => handleListChange(d, "relationType")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('relationType') })}
                                    />
                                    <label htmlFor="relationType" className={classNames({ 'p-error': isFormFieldValid('relationType') })}>
                                        {translatedMessage("person.relationType")} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('relationType')}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default RelatedProfileEditComponent;
