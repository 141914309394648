import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { UserService } from '../../service/UserService';

const emptyRecord = { username: '' }

const UserCreateForProfileDialogComponent = (props) => {
    const [profile, setProfile] = useState(null);
    const toast = useRef(null);

    const userService = new UserService();

    useEffect(() => {
        let _profile = props.profile ? props.profile : null;
        setProfile(_profile);
    }, [props]);

    const formik = useFormik({
        initialValues: emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.username || !data.username.trim()) {
                errors.username = translatedMessage("form.error.username.required");
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9._-]+\.[A-Z]{2,4}$/i.test(data.username)) {
                errors.username = translatedMessage("form.error.username.invalid");
            }

            return errors;
        },
        onSubmit: (data) => {
            // console.log("save user profile: ", data)
            userService.createUserForProfile(profile.id, data.username)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        toast.current.show({ severity: 'success', summary: translatedMessage("profile.save.success"), life: 3000 });
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });

            hideDialog()
        }
    });

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
            formik.resetForm();
            setProfile(null)            
        }
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="user-form" type="submit" />
        </>
    );

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('person.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <div className="flex flex-column align-items-center">
                    <div className="col-12">
                        <form id="user-form" onSubmit={formik.handleSubmit}>
                            <div className='grid'>
                                <div className="field p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="username" id="username"
                                            value={formik.values.username} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('username') })} />
                                        <label htmlFor="username" className={classNames({ 'p-error': isFormFieldValid('username') })}>
                                            {translatedMessage("register.username")}*
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('username')}
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </>);
};

export default UserCreateForProfileDialogComponent;
