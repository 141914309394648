import GeneralUtils from "../utilities/GeneralUtils";
import ApiService from "./ApiService";

export class HearingService {

    async getHearingsByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/hearing/list`, criteria)
                .then((response) => {
                    response.data.items.map(item => {
                        item.date = item.date ? new Date(Date.parse(item.date)) : '';
                        item.birthday = item.birthday ? new Date(Date.parse(item.birthday)) : '';
                        if (item.responsibleProfile) {
                            item.responsibleProfile.birthday = item.responsibleProfile.birthday ? new Date(Date.parse(item.responsibleProfile.birthday)) : '';
                        }
                        return item;
                    })
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getHearing(hearingId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/hearing/${hearingId}`)
                .then(response => {
                    response.data.date = response.data.date ? new Date(Date.parse(response.data.date)) : '';
                    response.data.birthday = response.data.birthday ? new Date(Date.parse(response.data.birthday)) : '';
                    if (response.data.responsibleProfile) {
                        response.data.responsibleProfile.birthday = response.data.responsibleProfile.birthday ? new Date(Date.parse(response.data.responsibleProfile.birthday)) : '';
                    }
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveHearing(hearing) {
        let _organizationProfile = { id: hearing.organizationProfile?.id }
        let _hearing = GeneralUtils.nullIfEmptyForObjectFields({ ...hearing, organizationProfile: _organizationProfile })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/hearing`, _hearing)
                .then(response => {
                    response.data.date = response.data.date ? new Date(Date.parse(response.data.date)) : '';
                    response.data.birthday = response.data.birthday ? new Date(Date.parse(response.data.birthday)) : '';
                    if (response.data.responsibleProfile) {
                        response.data.responsibleProfile.birthday = response.data.responsibleProfile.birthday ? new Date(Date.parse(response.data.responsibleProfile.birthday)) : '';
                    }
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteHearing(hearingId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/hearing/${hearingId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }

}