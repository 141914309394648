import ApiService from "./ApiService";
import GeneralUtils from "../utilities/GeneralUtils";

let currentUser = null;

export class UserService {

    async getUsers(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance()
                .post('/profile/users', criteria)
                .then((response) => {
                    response.data.items.map((item) => {
                        item.username = item.user.username
                        item.status = item.user.status
                        return item;
                    });
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async registerUserByAdmin(user) {
        return ApiService.getAuthenticatedInstance().post('/user/register/admin',
            { username: user.username, firstName: user.firstName, lastName: user.lastName })
            .then((res) => res.data);
    }

    async updateUser(profile) {
        return ApiService.getAuthenticatedInstance().patch('/profile-user/' + profile.id,
            { user: profile.user, firstName: profile.firstName, lastName: profile.lastName })
            .then((res) => res.data);
    }

    async searchUser(searchText) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/users/search/${searchText}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveProfile(user) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/profile/my/save', {
                firstName: user.firstName,
                lastName: user.lastName,
                pin: user.pin,
                idCardType: user.idCardType,
                idCardSeries: user.idCardSeries,
                idCardNumber: user.idCardNumber,
                phone: user.phone,
                language: user.language,
                address: user.address,
                localityId: user.localityId,
                countyId: user.countyId,
                acceptMarketing: user.acceptMarketing,
                acceptNotification: user.acceptNotification
            })
                .then(userResponse => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(userResponse.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    } 
    
    async saveProfileSettings(user) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/profile/my/save-settings', {
                firstName: user.firstName,
                lastName: user.lastName,                
                language: user.language,
                acceptMarketing: user.acceptMarketing,
                acceptNotification: user.acceptNotification
            })
                .then(userResponse => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(userResponse.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }   

    async changePassword(passwordData) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/user/change-password', passwordData)
                .then(() => {
                    resolve() 
                })
                .catch(error => {
                    let errorMessage = "error.generic_500";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    

    async initCurrentUser() {
        if (!getCurrentUser()) {
            let response = await ApiService.getAuthenticatedInstance().get('/user/my');
            // console.log("my profile: ", response.data)

            //replace null fields with empty
            const userProfile = GeneralUtils.emptyInNullForObjectFields(response.data);

            setCurrentUser(userProfile);
            return userProfile;
        } else {
            return getCurrentUser();
        }
    }

    async getAccessibility() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/profile/my/accessibility')
                .then(response => resolve(response.data))
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
    
    async saveAccessibility(accessibility) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/my/accessibility/save`, accessibility)
                .then(response => resolve(response.data) )
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    checkUserForCompetitionRegister(user) {
        let canRegister = true
        
        if(!user?.pin || !user?.idCardType || !user?.idCardNumber || !user?.idCardSeries || !user?.phone) {
            canRegister = false
        }

        return canRegister
    }

    async createUserForProfile(profileId, username) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/${profileId}/user/create/${username}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
}

export function getCurrentUser() { return currentUser }

export function setCurrentUser(user) { currentUser = user }

export function hasPermission(permissionName) {
    if (currentUser && currentUser.permissions) {
        return currentUser.permissions.indexOf(permissionName) > -1;
    } else {
        return false;
    }
};

export function hasRole(roleName) {
    if (currentUser && currentUser.roles) {
        return currentUser.roles.indexOf(roleName) > -1;
    } else {
        return false;
    }
};