import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { hasPermission } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import ExportButtonComponent from '../ExportButtonComponent';
import EnumService from '../../service/EnumService';
import { ProfileService } from '../../service/ProfileService';
import LegalRepEditDialog from './LegalRepEditDialogComponent';
import { BeneficiaryService } from '../../service/BeneficiaryService';
import ProfileViewDialogComponent from '../profile/ProfileViewDialogComponent';
import ProfileExportDataUtils from '../../utilities/ProfileExportDataUtils';
import GeneralUtils from '../../utilities/GeneralUtils';
import SecurityService from '../../service/SecurityService';

const LegalRepListComponent = (props) => {
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [error, setError] = useState(false);
    const [legalReps, setLegalReps] = useState(null);
    const [legalRep, setLegalRep] = useState(null);
    const [statuses, setStatuses] = useState([])
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showViewDialog, setShowViewDialog] = useState(false);

    const [canCreate, setCanCreate] = useState(true);

    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            'profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'beneficiary.profile.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
            status: { value: null, matchMode: FilterMatchMode.IN },
            'profile.pin': { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const propToColumnMap = {
        'profile.fullName': 'p.first_name, p.last_name',
        'beneficiary.profile.fullName': 'bp.first_name, bp.last_name',
        status: 'status',
        'profile.pin': 'pin',
    };

    const profileService = useMemo(() => new ProfileService(), []);
    const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getStatuses = async () => {
            setStatuses(await EnumService.getEnumByName('LegalRepresentativeStatus'));
        };


        const checkCanCreate = async () => {
            if (props?.beneficiary?.id) {
                setCanCreate(await SecurityService.check('SET_BENEFICIARY_LEGAL_REPRESENTATIVE', props?.beneficiary?.id));
            } else {
                setCanCreate(await SecurityService.check('SET_BENEFICIARY_LEGAL_REPRESENTATIVE'));
            }
        };

        getStatuses()
        checkCanCreate()
        setFirstLoading(false)
    }, [beneficiaryService]);

    useEffect(() => {
        lazyLoadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {}
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC"
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id'
        criteria.fullName = lazyParams.filters['profile.fullName'].value
        criteria.status = lazyParams.filters.status.value
        criteria.pin = lazyParams.filters['profile.pin'].value
        criteria.beneficiaryFullName = lazyParams.filters['beneficiary.profile.fullName'].value
        criteria.beneficiaryId = props?.beneficiary?.id

        return criteria
    }

    const loadData = async () => {
        let criteria = setCriteria();

        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await profileService.getLegalRespList(criteria)
            .then((data) => {
                setLegalReps(data.items);
                setTotalRecords(data.totalCount);
                setLoading(false);
            })
            .catch((error) => {
                setError(true)
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const lazyLoadData = () => {
        setLoading(true);
        loadData();
    }

    const addNew = () => {
        setShowEditDialog(true);
    };

    const editData = (_legalRep) => {
        setLegalRep({ ..._legalRep });
        setShowEditDialog(true);
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('LEGAL_REPRESENTATIVE_CREATE') &&
                        <Button
                            label={translatedMessage("legalRep.new")}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={addNew}
                            disabled={!canCreate}
                        />
                    }
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={ProfileExportDataUtils.legalRepTableHeader()}
                sortOrderHeader={ProfileExportDataUtils.legalRepSortOrderHeader()}
                fileName={GeneralUtils.computeExportFileName("legalRep.legalReps")}
            />
        );
    };

    const handleExport = async () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            profileService.getLegalRespList(criteria)
                .then((data) => {
                    let exportData = []
                    data.items.forEach(item => {
                        let exportItem = ProfileExportDataUtils.computeExportFields(item.profile)
                        exportItem.startDate = GeneralUtils.formatDate(item.startDate)
                        exportItem.endDate = GeneralUtils.formatDate(item.endDate)
                        exportItem.beneficiary = item.beneficiary?.profile?.fullName
                        exportItem.status = translatedMessage("LegalRepresentativeStatus." + item.status)
                        exportItem.createdOn = GeneralUtils.formatDate(item.createdOn)

                        exportData.push(exportItem)
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const displayEditDialog = (_display) => {
        setShowEditDialog(_display)
        setLegalRep(null)
    }

    const viewProfileData = (_legalRep) => {
        // if in the beneficiary page show the dialog
        if (props?.beneficiary?.id) {
            setLegalRep({ ..._legalRep });
            setShowViewDialog(true);
        } else {
            navigate(`/legal-rep/${_legalRep.id}`)
        }
    };

    const legalRepBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('LEGAL_REPRESENTATIVE_VIEW') &&
                        <Button
                            text
                            label={rowData.profile.fullName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/legal-rep/${rowData.id}`)}
                        />
                    }
                    {!hasPermission('LEGAL_REPRESENTATIVE_VIEW') &&
                        rowData.fullName
                    }
                </div>
            </>
        );
    };

    const beneficiaryBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    {hasPermission('BENEFICIARY_VIEW') &&
                        <Button
                            text
                            label={rowData.beneficiary.profile.fullName}
                            className="pcn-button-slim p-button text-align-left"
                            onClick={() => navigate(`/beneficiary/${rowData.beneficiary.id}/legal-rep`)}
                        />
                    }
                    {!hasPermission('BENEFICIARY_VIEW') &&
                        rowData.beneficiary.profile.fullName
                    }
                </div>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions flex flex-wrap align-items-center justify-content-end">
                    {hasPermission('LEGAL_REPRESENTATIVE_VIEW') &&
                        <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => viewProfileData(rowData)}
                            title={translatedMessage("generic.view")} disabled={!rowData.actions?.VIEW_BENEFICIARY_LEGAL_REPRESENTATIVE} />
                    }
                    {hasPermission('LEGAL_REPRESENTATIVE_EDIT') &&
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => editData(rowData)}
                            title={translatedMessage("generic.edit")} disabled={!rowData.actions?.SET_BENEFICIARY_LEGAL_REPRESENTATIVE} />
                    }
                    {hasPermission('LEGAL_REPRESENTATIVE_VIEW') &&
                        <Button icon="pi pi-file" className="p-button-rounded p-button-info m-1" onClick={() => navigate(`/legal-rep/${rowData.id}/file`)}
                            title={translatedMessage("legalRep.files")} disabled={!rowData.actions?.VIEW_BENEFICIARY_LEGAL_REPRESENTATIVE} />
                    }
                </div>
            </>
        );
    };

    const statusTemplate = (rowData) => {
        let isActive = 'ACTIVE' === rowData.status;
        return (
            <>
                {hasPermission('LEGAL_REPRESENTATIVE_EDIT') && (
                    <ToggleButton
                        id={`status_${rowData.id}`}
                        name={`status_${rowData.id}`}
                        checked={isActive}
                        onChange={() => {
                            updateStatus(rowData);
                        }}
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        aria-label={translatedMessage("legalRep.status")}
                        className="pcn-status-toogle"
                        onLabel={translatedMessage('LegalRepresentativeStatus.ACTIVE')}
                        offLabel={translatedMessage('LegalRepresentativeStatus.INACTIVE')}
                        tooltip={translatedMessage(isActive ? 'legalRep.inactivate' : 'legalRep.activate')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    />
                )}
                {!hasPermission('LEGAL_REPRESENTATIVE_EDIT') &&
                    <span className={`pill status status-${rowData.status.toString().toLowerCase()}`}>
                        {translatedMessage('LegalRepresentativeStatus.' + rowData.status)}
                    </span>}
            </>
        );
    };

    const updateStatus = async (_legalRep) => {
        let oldStatus = _legalRep.status;
        let newStatus = oldStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'

        profileService.saveLegalRep({ ..._legalRep, status: newStatus })
            .then(() => {
                toast.current.show({ severity: 'success', summary: translatedMessage('legalRep.save.success'), life: 3000 });
                lazyLoadData()
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const statusRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={statuses}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter"
            showClear
            itemTemplate={statusItemTemplate}
        />
    };

    const statusItemTemplate = (option) => {
        return <span className={`inline-block status status-${option.value.toString().toLowerCase()}`}>{option.label}</span>;
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.endDate)
    }

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && !error && (
                <>
                    <div className='w-full text-align-left'>
                        <h5 className="m-0">{translatedMessage("legalRep.legalReps")}</h5>
                    </div>
                    <Toolbar className="pl-0 pr-0" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={legalReps}
                        lazy
                        dataKey="id"
                        paginator
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onPage={onPage} onSort={onSort} onFilter={onFilter}
                        loading={loading}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        filterDisplay="row"
                        filters={lazyParams.filters}
                    >
                        <Column
                            field="profile.fullName"
                            header={translatedMessage("generic.name")}
                            body={legalRepBodyTemplate}
                            sortable
                            headerStyle={{ width: '20%', minWidth: '8rem' }}
                            filter
                            filterField="profile.fullName"
                            showFilterMenu={false}
                        />
                        <Column
                            field="profile.pin"
                            header={translatedMessage("profile.pin")}
                            body={(e) => e.profile?.pin ? e.profile?.pin : "-"}
                            sortable
                            headerStyle={{ width: '12%', minWidth: '8rem' }}
                            filter
                            filterField='profile.pin'
                            showFilterMenu={false}
                        />
                        <Column
                            field="status"
                            filterField="status"
                            header={translatedMessage('generic.status')}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            body={statusTemplate}
                            showFilterMenu={false}
                            filter
                            filterElement={statusRowFilterTemplate}
                        />
                        <Column
                            field="beneficiary.profile.fullName"
                            header={translatedMessage("beneficiary.beneficiary")}
                            body={beneficiaryBodyTemplate}
                            sortable
                            headerStyle={{ width: '20%', minWidth: '8rem' }}
                            filter
                            filterField='beneficiary.profile.fullName'
                            showFilterMenu={false}
                        />
                        <Column
                            field="startDate"
                            header={translatedMessage("generic.interval")}
                            body={dateBodyTemplate}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                        />
                        <Column body={actionBodyTemplate} />
                    </DataTable>

                    <LegalRepEditDialog
                        value={legalRep}
                        visible={showEditDialog}
                        afterSave={lazyLoadData}
                        visibleSetter={(value) => displayEditDialog(value)}
                        beneficiary={props?.beneficiary}
                    />

                    <ProfileViewDialogComponent
                        profileId={legalRep?.profile?.id}
                        visible={showViewDialog}
                        visibleSetter={(value) => setShowViewDialog(value)}
                        hideProfileExtended={true}
                    />
                </>
            )}
        </>
    );
};

export default LegalRepListComponent;
