import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import LegalRepListComponent from '../../components/legalRepresentative/LegalRepListComponent';

const LegalRepList = () => {

    const toast = useRef(null);    
    
    return (
        <>
            <div className="grid h-full">
                <Toast ref={toast}></Toast>
                <div className="col-12">
                    <div className="card h-full">

                        <LegalRepListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LegalRepList;
