import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import SolutionViewComponent from './SolutionViewComponent';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SolutionViewDialogComponent = (props) => {
    const [solution, setSolution] = useState(null)

    const toast = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        let _solution = props.value;
        setSolution(_solution);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            setSolution(null)
            props.visibleSetter(false);
        }
    };

    const dialogHeader = (
        <>
            <div className='mb-1'>{translatedMessage('solution.details')}</div>
            <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/solution/${solution.id}`)}>
                <div className="flex align-items-center">
                    <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" className="mr-1 " />
                    <span>{translatedMessage('solution.goTO.page')}</span>
                </div>
            </Button>
        </>
    );

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={dialogHeader}
                modal
                className="pcn-dialog"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <SolutionViewComponent solution={solution} showEdit={false}  hideTitle={true} /> 
            </Dialog>
        </>);
};

export default SolutionViewDialogComponent;
