
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import useNavProps from "../../hooks/useNavProps";
import { BeneficiaryService } from "../../service/BeneficiaryService";
import { translatedMessage } from "../../service/LanguageService";
import { ProgressSpinner } from "primereact/progressspinner";
import BeneficiaryToolbar from "./BeneficiaryToolbar";
import ServiceExpertBeneficiaryListComponent from "../../components/serviceExpertBeneficiary/ServiceExpertBeneficiaryListComponent";

const BeneficiaryServiceList = () => {
    const [beneficiary, setBeneficiary] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const beneficiaryService = useMemo(() => new BeneficiaryService(), []);
    const { beneficiaryIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();
    const toast = useRef(null);

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', beneficiaryIdParam));
        // };        

        const getBeneficiary = async () => {
            await beneficiaryService.getBeneficiary(beneficiaryIdParam)
                .then(async (_data) => {
                    setBeneficiary(_data);

                    // await checkEditPermission()

                    setNavPropsValue('beneficiary', _data.id, _data.profile.fullName)

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getBeneficiary();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiaryService, beneficiaryIdParam]);


    return (
        <>
            <Toast ref={toast} />
            {isLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!isLoading && (
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <BeneficiaryToolbar beneficiary={beneficiary} code="SERVICES" />

                            <ServiceExpertBeneficiaryListComponent beneficiary={beneficiary} pageTitle={translatedMessage("beneficiary.services")} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BeneficiaryServiceList;
