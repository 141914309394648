import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from '../../service/UserService';
import MonitoringEditDialogComponent from './MonitoringEditDialogComponent';

const MonitoringViewComponent = (props) => {
    const [monitoring, setMonitoring] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const toast = useRef(null);


    const navigate = useNavigate();

    useEffect(() => {
        setMonitoring(props?.monitoring)
    }, [props]);

    return (
        <>
            <Toast ref={toast} />

            <div className='w-full grid pcn-data-view-panel mb-5'>
                {!props?.hideTitle &&
                    <div className='col-12 flex align-items-center'>
                        <span className='entity-view-subtitle mr-2'>{translatedMessage("monitoring.details")}</span>
                        <Button
                            text
                            className="pcn-button-slim p-button p-button-icon-only"
                            onClick={() => setShowEditDialog(true)}
                            visible={hasPermission('NOTICE_EDIT') && props?.showEdit}
                        >
                            <div className='flex align-items-center'>
                                <FontAwesomeIcon icon='fa-solid fa-pencil' />
                            </div>
                        </Button>
                    </div>
                }
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('monitoring.monitoringType')}</div>
                    <div className='filed-value'>{monitoring?.type.label}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('monitoring.conclusion')}</div>
                    <div className='filed-value'>{monitoring?.conclusion.label}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('generic.startDate')}</div>
                    <div className='filed-value'>{GeneralUtils.formatDate(monitoring?.startDate)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('generic.endDate')}</div>
                    <div className='filed-value'>{GeneralUtils.formatDate(monitoring?.endDate)}</div>
                </div>
                <div className='col-12 md:col-6'>
                    <div className='filed-label'>{translatedMessage('beneficiary.beneficiary')}</div>
                    <div className='filed-value'>{
                        monitoring?.beneficiary && hasPermission('BENEFICIARY_VIEW')
                            ? <Button text label={monitoring?.beneficiary?.profile?.fullName} className="aspc-button-filed-text p-button text-align-left"
                                onClick={() => navigate(`/beneficiary/${monitoring?.beneficiary.id}`)} />
                            : monitoring?.beneficiary?.profile?.fullName || '-'
                    }</div>
                </div>
            </div>

            <MonitoringEditDialogComponent
                value={monitoring}
                visible={showEditDialog}
                afterSave={(savedData) => setMonitoring(savedData)}
                visibleSetter={() => setShowEditDialog(false)}
            />
        </>
    )
};

export default MonitoringViewComponent;
