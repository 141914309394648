import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import ValidationService from '../../service/ValidationServices';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';

import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { ProfileService } from '../../service/ProfileService';
import ProfilelUtils from '../../utilities/ProfilelUtils';

let emptyRecord = {
    hasPin: true,
    pin: '',
    firstName: '',
    lastName: '',
    birthCounty: null,
    birthEnvironmentType: '',
    birthday: '',
    gender: ''
};

const ProfileEditComponent = (props) => {
    const [profile, setProfile] = useState()
    const [entityName, setEntityName] = useState({})
    const [counties, setCounties] = useState([])
    const [envTypes, setEnvTypes] = useState([])
    const [genders, setGenders] = useState([])
    const [filteredentityLookups, setFilteredentityLookups] = useState([]);

    const profileService = new ProfileService();

    useEffect(() => {
        const getEnvTypes = async () => {
            const response = await EnumService.getEnumByName("EnvironmentType");
            setEnvTypes(response)
        }

        const getGenders = async () => {
            const response = await EnumService.getEnumByName("Gender");
            setGenders(response)
        }

        const getCounty = async () => {
            const _counties = await EnumService.getEntityLookupByField("NOM:COUNTY")
            setCounties(_counties)
        }

        const computeProfile = async () => {
            await getEnvTypes()
            await getGenders()
            await getCounty()

            let _profile = { ...emptyRecord }
            if (props?.value) {
                _profile = GeneralUtils.emptyInNullForObjectFields(props.value)
            }

            setProfile(_profile)
            setEntityName(props?.entityName)
        }

        computeProfile()
    }, [props]);

    const formik = useFormik({
        initialValues: profile ? profile : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.firstName || !data.firstName.trim()) {
                errors.firstName = translatedMessage("form.error.firstName.required");
            }

            if (!data.lastName || !data.lastName.trim()) {
                errors.lastName = translatedMessage("form.error.lastName.required");
            }

            if (!data.pin) {
                if (ProfilelUtils.checkPinMandatory(entityName) && data.hasPin) {
                    errors.pin = translatedMessage("form.error.pin.required");
                }
            } else {
                let validationResponse = ValidationService.validateCNP(data.pin)
                if (!validationResponse.success) {
                    errors.pin = translatedMessage(validationResponse.message);
                } else {
                    data.birthday = GeneralUtils.computeBirthDayFromPin(data.pin)
                    data.gender = GeneralUtils.computeGenderFromPin(data.pin)
                    setProfile(data)
                }
            }

            if (ProfilelUtils.checkBirthdayMandatory(entityName) && !data.birthday) {
                errors.birthday = translatedMessage('form.error.birthday.required');
            }

            if (ProfilelUtils.checkGenderMandatory(entityName) && !data.gender) {
                errors.gender = translatedMessage('form.error.gender.required');
            }

            if (ProfilelUtils.checkBirthCountyMandatory(entityName) && (!data.birthCounty || !data.birthCounty.value)) {
                errors.birthCounty = translatedMessage('form.error.birthCounty.required');
            }

            if (ProfilelUtils.checkBirthEnvironmentTypeMandatory(entityName) && !data.birthEnvironmentType) {
                errors.birthEnvironmentType = translatedMessage('form.error.birthEnvironmentType.required');
            }

            return errors;
        },
        onSubmit: (data) => {
            // console.log("save: ", data)
            profileService.saveProfile(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const handleNumberValidation = (event) => {
        let charCode = event.nativeEvent.data;
        if (charCode === null || /^[0-9\b]+$/.test(charCode)) {
            formik.handleChange(event);
        }
    }

    const handleHasPinCheck = async (data) => {
        formik.setFieldValue("hasPin", data.checked)
    }

    const handleListChange = async (data, field) => {
        // console.log("handleListChange")
        formik.setFieldValue(field, data.value)
    }

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredentityLookups(_filteredItems);
    }


    return (
        <>
            <div className="flex flex-column align-items-center">
                <div className="col-12">
                    <form id="profile-form" onSubmit={formik.handleSubmit}>
                        <div className='grid'>
                            {props?.isBeneficiary &&
                                <>
                                    <div className="col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-float-label">
                                                <InputText type="text" name="registrationNumber" id="registrationNumber"
                                                    value={formik.values.registrationNumber} onChange={formik.handleChange} autoFocus
                                                    className={classNames({ 'p-invalid': isFormFieldValid('registrationNumber') })} />
                                                <label htmlFor="registrationNumber" className={classNames({ 'p-error': isFormFieldValid('registrationNumber') })}>
                                                    {translatedMessage("beneficiary.registrationNumber")} *
                                                </label>
                                            </span>
                                        </div>
                                        {getFormErrorMessage('registrationNumber')}
                                    </div>
                                </>
                            }

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="lastName" id="lastName"
                                            value={formik.values.lastName} onChange={formik.handleChange} autoFocus={!props?.isBeneficiary}
                                            className={classNames({ 'p-invalid': isFormFieldValid('lastName') })} />
                                        <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') })}>
                                            {translatedMessage("generic.lastName")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('lastName')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="firstName" id="firstName"
                                            value={formik.values.firstName} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('firstName') })} />
                                        <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') })}>
                                            {translatedMessage("generic.firstName")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('firstName')}
                            </div>

                            <div className="col-12 md:col-6 flex align-items-center">
                                <div className="p-inputgroup">
                                    <span>
                                        <Checkbox inputId="hasPin" name="hasPin" checked={formik.values.hasPin} disabled={ProfilelUtils.checkHasPinMandatory(entityName)}
                                            onChange={handleHasPinCheck} className={classNames({ 'p-invalid': isFormFieldValid('hasPin') })} />
                                        <label htmlFor="hasPin" className={"ml-2 text-form-label ".concat(classNames({ 'p-error': isFormFieldValid('hasPin') }))}>
                                            {translatedMessage("profile.hasPin")}
                                        </label>
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText type="text" name="pin" id="pin"
                                            value={formik.values.pin} onChange={handleNumberValidation}
                                            className={classNames({ 'p-invalid': isFormFieldValid('pin') })}
                                            maxLength={13} disabled={!formik.values.hasPin} />
                                        <label htmlFor="pin" className={classNames({ 'p-error': isFormFieldValid('pin') })}>
                                            {translatedMessage("profile.pin")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('pin')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="birthday" name="birthday" value={formik.values.birthday} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                            className={classNames({ 'p-invalid': isFormFieldValid('birthday') })} />
                                        <label htmlFor="birthday" className={classNames({ 'p-error': isFormFieldValid('birthday') })}>
                                            {translatedMessage("profile.birthday")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('birthday')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="gender" name="gender" value={formik.values.gender}
                                            options={genders} onChange={(e) => formik.setFieldValue("gender", e.value)} />
                                        <label htmlFor="gender" className={classNames({ 'p-error': isFormFieldValid('gender') })}>
                                            {translatedMessage("profile.gender")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('gender')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <AutoComplete
                                            dropdown
                                            id="birthCounty"
                                            name="birthCounty"
                                            value={formik.values.birthCounty}
                                            suggestions={filteredentityLookups}
                                            completeMethod={(e) => searchListItems(e, counties)}
                                            virtualScrollerOptions={{ itemSize: 38 }}
                                            field="label"
                                            onChange={(d) => handleListChange(d, "birthCounty")}
                                            emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                            className={classNames({ 'p-invalid': isFormFieldValid('birthCounty') })}
                                        />
                                        <label htmlFor="birthCounty" className={classNames({ 'p-error': isFormFieldValid('birthCounty') })}>
                                            {translatedMessage("profile.birthCounty")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('birthCounty')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown id="birthEnvironmentType" name="birthEnvironmentType" value={formik.values.birthEnvironmentType}
                                            options={envTypes} onChange={(e) => formik.setFieldValue("birthEnvironmentType", e.value)} />
                                        <label htmlFor="birthEnvironmentType" className={classNames({ 'p-error': isFormFieldValid('birthEnvironmentType') })}>
                                            {translatedMessage("profile.birthEnvironmentType")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('birthEnvironmentType')}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ProfileEditComponent;
