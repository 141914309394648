import ApiService from "./ApiService";

export class EntityLookupService {

    async getEntityLookupsByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/entityLookup/list`, criteria)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getEntityLookup(entityLookupId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/entityLookup/${entityLookupId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveEntityLookup(entityLookup) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/entityLookup', entityLookup)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteEntityLookup(entityLookupId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/entityLookup/${entityLookupId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }


    async getEntityLookupFieldClasses() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/entityLookup/fieldClasses`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

}