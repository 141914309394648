import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import MonitoringEditComponent from './MonitoringEditComponent';

const MonitoringEditDialogComponent = (props) => {
    const [monitoring, setMonitoring] = useState(null);

    const toast = useRef(null);

    useEffect(() => {
        let _monitoring = props.value;
        setMonitoring(_monitoring);        
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            setMonitoring(null)
            props.visibleSetter(false);
        }
    };

    const afterSave = async (savedData, isSuccess, errorMessage = null) => {
        if (typeof props.afterSave === 'function') {
            if (isSuccess) {
                props.afterSave(savedData);
                toast.current.show({ severity: 'success', summary: translatedMessage("monitoring.save.success"), life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
            }
        }
        hideDialog()
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="profile-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('monitoring.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <MonitoringEditComponent
                    value={monitoring}
                    isMonitoring={true}
                    afterSave={afterSave}
                    beneficiary={props?.beneficiary}
                    monitoringTypeList={props?.monitoringTypeList}
                    monitoringConclusionList={props?.monitoringConclusionList}
                />
            </Dialog>
        </>);
};

export default MonitoringEditDialogComponent;
