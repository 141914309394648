import ApiService from "./ApiService";

export class OrganizationService {
    
    async getOrganizationsByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/organization/list`, criteria)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }   

    async saveOrganizationAddress(organizationId, address) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/organization/${organizationId}/save-address`, address)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

}