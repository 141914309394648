import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { getIn, useFormik } from "formik";
import { MonitoringService } from '../../service/MonitoringService';
import { translatedMessage } from '../../service/LanguageService';
import MonitoringAutocompleteFieldComponent from './MonitoringAutocompleteFieldComponent';

const ChildMonitoringChooserDialog = (props) => {
    const [selectedMonitoring, setSelectedMonitoring] = useState(null);

    const toast = useRef(null);
    const monitoringService = useMemo(() => new MonitoringService(), [])

    useEffect(() => {
        setSelectedMonitoring(props?.selectedMonitoring);
    }, [props]);

    const formik = useFormik({
        initialValues: { monitoring: selectedMonitoring },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.monitoring) {
                errors.monitoring = translatedMessage("form.error.profile.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            monitoringService.saveMonitoringHierarchy(props?.parentMonitoring?.id, data.monitoring.id)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData);
                    }
                    toast.current.show({ severity: 'success', summary: translatedMessage("monitoringHierarchy.save.success"), life: 3000 });
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: error, life: 5000 });
                });

            hideDialog()
        }
    });

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false)
            formik.resetForm()
        }
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="responsible-form" type="submit" />
        </>
    );

    const isFormFieldValid = (name) => !!(getIn(formik.touched, name) && getIn(formik.errors, name));
    // const getFormErrorMessage = (name) => isFormFieldValid(name) && <small className="p-error text-align-left">{getIn(formik.errors, name)}</small>;

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={props?.title ? props.title : translatedMessage('beneficiary.responsible')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >

                <form id="responsible-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <MonitoringAutocompleteFieldComponent
                            parentMonitoring={props?.parentMonitoring}
                            setMonitoring={(e) => setSelectedMonitoring(e)}
                            selectedMonitoring={selectedMonitoring}
                            validationError={isFormFieldValid('monitoring')}
                            title={props?.fieldLabel}
                        />
                    </div>
                </form>
            </Dialog>
        </>);
};

export default ChildMonitoringChooserDialog;
