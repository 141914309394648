import React, { useState, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { FilterMatchMode } from "primereact/api";
import { ProviderService } from "../../service/ProviderService";
import { translatedMessage } from "../../service/LanguageService";
import { useNavigate } from "react-router-dom";
import GeneralUtils from "../../utilities/GeneralUtils";
import EnumService from "../../service/EnumService";
import DeleteModalComponent from "../DeleteModalComponent";
import ProviderEditDialog from "./ProviderEditDialog";
import DataTableUtils from "../../utilities/DataTableUtils";
import OrganizationDataTableUtils from "../../utilities/OrganizationDataTableUtils";
import useAuth from "../../hooks/useAuth";
import ExportButtonComponent from "../ExportButtonComponent";
import SecurityService from '../../service/SecurityService';

const ProviderListComponent = () => {
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [counties, setCounties] = useState([]);

    const [canCreate, setCanCreate] = useState(false);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters: {
            name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: null, matchMode: FilterMatchMode.IN },
            status: { value: null, matchMode: FilterMatchMode.IN },
            public: { value: null, matchMode: FilterMatchMode.IN },
            "address.county": { value: null, matchMode: FilterMatchMode.EQUALS },
        },
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] =
        useState(false);
    const propToColumnMap = {
        name: "name",
        type: "type",
        status: "status",
        public: "is_public",
        "address.county": "a.county_id"
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();
    const navigate = useNavigate();

    const providerService = useMemo(() => new ProviderService(), []);

    useEffect(() => {
        setFirstLoading(true)

        const getStatuses = async () => {
            setStatuses(await EnumService.getEnumByName("OrganizationStatus"))
        };

        const getCounties = async () => {
            const _counties = await EnumService.getEntityLookupByField("NOM:COUNTY")
            setCounties(_counties)
        };


        const checkCanCreate = async () => {
            setCanCreate(await SecurityService.check('ADD_ORGANIZATION'));
        };

        getStatuses()
        getCounties()
        checkCanCreate()
        setFirstLoading(false)
    }, [providerService]);

    useEffect(() => {
        loadProviders();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues(
            "ProviderList",
            lazyParams,
            lazyParamsUpdatedFromSession,
            setLazyParamsUpdatedFromSession
        );

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? "DESC" : "ASC";
        criteria.sortField = propToColumnMap[lazyParams.sortField] || "id";
        criteria.name = lazyParams.filters.name.value;
        criteria.statuses = lazyParams.filters.status.value;
        criteria.types = ['PROVIDER'];//lazyParams.filters.type.value;
        criteria.countyIds = lazyParams.filters["address.county"].value;
        criteria.isPublic = lazyParams.filters.public.value;

        return criteria;
    };

    const loadProviders = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await providerService
            .getProvidersByCriteria(criteria)
            .then((data) => {
                setProviders(data.items);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                //setError(true)
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            });
    };

    const updateList = async (savedProvider, isNew) => {
        loadProviders();
    };

    const showProviderViewPage = (provider) => {
        navigate(`/provider/${provider.id}`);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        setSelectedProvider(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (provider) => {
        setSelectedProvider(provider);
        setEditDialogVisible(true);
    };

    const showDeleteDialog = (provider) => {
        setSelectedProvider(provider);
        setDeleteDialogVisible(true);
    };

    const deleteProvider = () => {
        providerService
            .deleteProvider(selectedProvider.id)
            .then(() => {
                let _providers = providers.filter(
                    (item) => item.id !== selectedProvider.id
                );
                setProviders(_providers);
                toast.current.show({
                    severity: "success",
                    summary: translatedMessage("generic.delete.success"),
                });
            })
            .catch((error) => {
                toast.current.show({
                    severity: "error",
                    summary: translatedMessage(error),
                    life: 5000,
                });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedProvider(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission("ORGANIZATION_CREATE") &&
                        (
                            <Button
                                label={translatedMessage("provider.new")}
                                icon="pi pi-plus"
                                className="p-button-primary mr-2"
                                onClick={showNewDialog}
                                disabled={!canCreate}
                            />
                        )}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            providerService
                .getProvidersByCriteria(criteria)
                .then((data) => {
                    let exportData = data.items.map((item) => ({ ...item }));
                    exportData.map((item) => {
                        let exportItem = item;
                        exportItem.status = translatedMessage("OrganizationStatus." + item.status);
                        exportItem.type = translatedMessage("OrganizationType." + item.type);
                        exportItem.public = translatedMessage("generic." + (item.public ? "yes" : "no"));
                        exportItem.county = item.address?.county?.label;
                        exportItem.cif = item.cif;

                        exportItem.createdOn = GeneralUtils.formatDate(item.createdOn);
                        return exportItem;
                    });

                    resolve(exportData);
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={OrganizationDataTableUtils.providerListTableHeader()}
                sortOrderHeader={OrganizationDataTableUtils.providerListSortOrderHeader()}
                fileName={GeneralUtils.computeExportFileName("provider.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission("ORGANIZATION_VIEW") &&
                    (
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => showProviderViewPage(rowData)}
                            tooltip={translatedMessage("generic.view")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.VIEW_ORGANIZATION}
                        />
                    )}
                {hasPermission("ORGANIZATION_EDIT") &&
                    (
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => showEditDialog(rowData)}
                            tooltip={translatedMessage("generic.edit")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_ORGANIZATION}
                        />
                    )}
                {hasPermission("ORGANIZATION_CREATE") &&
                    (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning m-1"
                            onClick={() => showDeleteDialog(rowData)}
                            tooltip={translatedMessage("generic.delete")}
                            tooltipOptions={{ showOnDisabled: true, position: "top" }}
                            disabled={!rowData.actions?.ADD_ORGANIZATION}
                        />
                    )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setLazyParams(event);
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <h5 className="mb-0">
                                    {translatedMessage("provider.list")}
                                </h5>

                                <Toolbar start={leftToolbarTemplate} end={rightToolbarTemplate} className="px-0" />

                                <DataTable
                                    ref={dt}
                                    value={providers}
                                    dataKey="id"
                                    paginator
                                    lazy
                                    totalRecords={totalRecords}
                                    first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    sortField={lazyParams.sortField}
                                    sortOrder={lazyParams.sortOrder}
                                    onPage={onPage}
                                    onSort={onSort}
                                    onFilter={onFilter}
                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                    className="datatable-responsive pcn-datatable"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate={"{first} - {last} " + translatedMessage("generic.of") + " {totalRecords}"}
                                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                                    scroll="true"
                                    filterDisplay="row"
                                    filters={lazyParams.filters}
                                    loading={isLoading}
                                >
                                    <Column
                                        field="name"
                                        header={translatedMessage("generic.nameRo")}
                                        sortable
                                        headerStyle={{ width: "20%", minWidth: "10rem" }}
                                        filter
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="cif"
                                        header={translatedMessage("department.cif")}
                                        headerStyle={{ minWidth: "10rem" }}
                                        sortable
                                        filterField="cif"
                                        filter
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="status"
                                        header={translatedMessage("generic.status")}
                                        sortable
                                        body={(e) => OrganizationDataTableUtils.organizationStatusBodyTemplate(e.status)}
                                        headerStyle={{ minWidth: "8rem" }}
                                        filter
                                        filterElement={(e) => OrganizationDataTableUtils.organizationStatusFilterTemplate(e, statuses)}
                                        filterHeaderClassName="pcn-filter-column-250"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="public"
                                        header={translatedMessage('provider.isPublic')}
                                        sortable
                                        headerStyle={{ minWidth: '8rem' }}
                                        body={(e) => DataTableUtils.yesNoTemplate(e.public)}
                                        filter
                                        filterElement={(e) => DataTableUtils.yesNoFilterTemplate(e)}
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="address.county"
                                        header={translatedMessage("generic.county")}
                                        headerStyle={{ minWidth: "10rem" }}
                                        body={(e) => e.address?.county ? e.address.county.label : "-"}
                                        sortable
                                        filterElement={(e) => DataTableUtils.countyFilterTemplate(e, counties)}
                                        filter
                                        showFilterMenu={false}
                                    />
                                    <Column body={actionBodyTemplate} />
                                </DataTable>

                                <ProviderEditDialog
                                    value={selectedProvider}
                                    visible={editDialogVisible}
                                    afterSave={updateList}
                                    visibleSetter={setEditDialogVisible}
                                    statuses={statuses}
                                />

                                <DeleteModalComponent
                                    visible={deleteDialogVisible}
                                    item={selectedProvider ? selectedProvider.name : ""}
                                    closeDialog={() => {
                                        setDeleteDialogVisible(false);
                                    }}
                                    deleteRecord={() => deleteProvider()}
                                    info="provider.delete.info"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ProviderListComponent;
