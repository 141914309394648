import ApiService from "./ApiService";
import GeneralUtils from "../utilities/GeneralUtils";

export class DepartmentService {
    
    async getDepartmentsByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/department/list`, criteria)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getDepartment(departmentId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/department/${departmentId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveDepartment(department) {
        department = GeneralUtils.nullIfEmptyForObjectFields(department)
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/department', department)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteDepartment(departmentId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/department/${departmentId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }    

}