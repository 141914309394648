import ApiService from './ApiService';

export class NotificationService {
    async getMyNotifications() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get('/notification/list')
                .then((response) => {
                    response.data.map((item) => {
                        item.sentDate = item.sentDate ? new Date(Date.parse(item.sentDate)) : '';
                        return item;
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getMyUnreadNotifications() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get('/notification/list/unread')
                .then((response) => {
                    response.data.map((item) => {
                        item.sentDate = item.sentDate ? new Date(Date.parse(item.sentDate)) : '';
                        return item;
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async markNotificationAsRead(notificationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .put(`/notification/${notificationId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }
}
