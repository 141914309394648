import { translatedMessage } from "../service/LanguageService";
import GeneralUtils from "./GeneralUtils";
import DOMPurify from 'dompurify';

const ProfileExportDataUtils = {

  profileSortOrderHeader(_useCreatedOn = false) {
    let profileCol = [
      "firstName", "lastName", // "hasPin", 
      "pin", "birthCounty", "birthEnvironmentType",
      "birthday", "age", "gender"
    ]

    if (_useCreatedOn) {
      profileCol.push("createdOn")
    }

    return profileCol
  },

  profileExtendedSortOrderHeader(_useCreatedOn = false) {
    let profileCol = [
      "firstName", "lastName", // "hasPin", 
      "pin", "birthCounty", "birthEnvironmentType",
      "birthday", "age", "gender",
      "idStatusLabel", "idCardSeries", "idCardNumber", "idCardExpiryDate",
      "canBeAdopted",
      "healthStatusLabel", "disabilityDegreeLabel", "disabilityTypeLabel", "diseaseTypeLabel",
      "needsSchoolGuidance",
      "educationLevelLabel", "educationStatusLabel", "schoolName", "schoolGrade",
      "employmentStatusLabel", "employerName"
    ]

    if (_useCreatedOn) {
      profileCol.push("createdOn")
    }

    return profileCol
  },

  profileTableHeader(_useCreatedOn = false) {
    let profileCol = [
      translatedMessage("generic.firstName"),
      translatedMessage("generic.lastName"),
      // translatedMessage("profile.hasPin"),
      translatedMessage("profile.pin"),
      translatedMessage("profile.birthCounty"),
      translatedMessage("profile.birthEnvironmentType"),
      translatedMessage("profile.birthday"),
      translatedMessage("profile.age"),
      translatedMessage("profile.gender"),
    ]

    if (_useCreatedOn) {
      profileCol.push(translatedMessage("generic.created.on"))
    }

    return profileCol
  },

  profileExtendedTableHeader(_useCreatedOn = false) {
    let profileCol = [
      translatedMessage("generic.firstName"),
      translatedMessage("generic.lastName"),
      // translatedMessage("profile.hasPin"),
      translatedMessage("profile.pin"),
      translatedMessage("profile.birthCounty"),
      translatedMessage("profile.birthEnvironmentType"),
      translatedMessage("profile.birthday"),
      translatedMessage("profile.age"),
      translatedMessage("profile.gender"),
      translatedMessage("profile.idStatus"),
      translatedMessage("profile.idCardSeries"),
      translatedMessage("profile.idCardNumber"),
      translatedMessage("profile.idCardExpiryDate"),
      translatedMessage("profile.extended.canBeAdopted"),
      translatedMessage("profile.extended.healthStatus"),
      translatedMessage("profile.extended.disabilityDegree"),
      translatedMessage("profile.extended.disabilityType"),
      translatedMessage("profile.extended.diseaseType"),
      translatedMessage("profile.extended.needsSchoolGuidance"),
      translatedMessage("profile.extended.educationLevel"),
      translatedMessage("profile.extended.educationStatus"),
      translatedMessage("profile.extended.schoolName"),
      translatedMessage("profile.extended.schoolGrade"),
      translatedMessage("profile.extended.employmentStatus"),
      translatedMessage("profile.extended.employerName")
    ]

    if (_useCreatedOn) {
      profileCol.push(translatedMessage("generic.created.on"))
    }

    return profileCol
  },

  computeExportFields(_profile, _useCreatedOn = false) {
    let exportData = { ..._profile };
    exportData.birthCounty = _profile.birthCountyLabel
    exportData.birthEnvironmentType = _profile.birthEnvironmentType ? translatedMessage("EnvironmentType." + _profile.birthEnvironmentType) : "-"
    exportData.birthday = GeneralUtils.formatDate(_profile.birthday)
    exportData.age = _profile.age >= 0 ? _profile.age : "-"
    exportData.gender = _profile.gender ? translatedMessage("Gender." + _profile.gender) : "-"
    exportData.pin = _profile.pin ? _profile.pin : (_profile.hasPin ? "-" : translatedMessage("profile.pin.unidentified"))
    //exportData.hasPin = translatedMessage(_profile.hasPin ? "generic.yes" : "generic.no")

    if (_useCreatedOn) {
      exportData.createdOn = GeneralUtils.formatDate(_profile.createdOn)
    } else {
      delete exportData.createdOn
    }

    delete exportData.hasPin
    delete exportData.birthCountyId
    delete exportData.birthCountyLabel
    delete exportData.folderId
    delete exportData.fullName
    delete exportData.homeAddress
    delete exportData.id
    delete exportData.photoUuid
    delete exportData.profileExtended
    delete exportData.residenceAddress
    delete exportData.user
    delete exportData.profileExtended

    return exportData
  },

  computeExtendedExportFields(_profile, _useCreatedOn = false) {
    let exportData = { ..._profile };
    exportData.birthCounty = _profile.birthCountyLabel
    exportData.birthEnvironmentType = _profile.birthEnvironmentType ? translatedMessage("EnvironmentType." + _profile.birthEnvironmentType) : "-"
    exportData.birthday = GeneralUtils.formatDate(_profile.birthday)
    exportData.age = _profile.age >= 0 ? _profile.age : "-"
    exportData.gender = _profile.gender ? translatedMessage("Gender." + _profile.gender) : "-"
    exportData.pin = _profile.pin ? _profile.pin : (_profile.hasPin ? "-" : translatedMessage("profile.pin.unidentified"))
    //exportData.hasPin = translatedMessage(_profile.hasPin ? "generic.yes" : "generic.no")
    exportData.canBeAdopted = translatedMessage(_profile.profileExtended.canBeAdopted ? "generic.yes" : "generic.no")
    exportData.disabilityDegreeLabel = _profile.profileExtended.disabilityDegreeLabel
    exportData.disabilityTypeLabel = _profile.profileExtended.disabilityTypeLabel
    exportData.diseaseTypeLabel = _profile.profileExtended.diseaseTypeLabel
    exportData.educationLevelLabel = _profile.profileExtended.educationLevelLabel
    exportData.educationStatusLabel = _profile.profileExtended.educationStatusLabel
    exportData.employmentStatusLabel = _profile.profileExtended.employmentStatusLabel
    exportData.employerName = _profile.profileExtended.employerName
    exportData.healthStatusLabel = _profile.profileExtended.healthStatusLabel
    exportData.idCardExpiryDate = GeneralUtils.formatDate(_profile.profileExtended.idCardExpiryDate)
    exportData.idCardNumber = _profile.profileExtended.idCardNumber
    exportData.idCardSeries = _profile.profileExtended.idCardSeries
    exportData.idStatusLabel = _profile.profileExtended.idStatusLabel
    exportData.needsSchoolGuidance = translatedMessage(_profile.profileExtended.needsSchoolGuidance ? "generic.yes" : "generic.no")
    exportData.schoolName = _profile.profileExtended.schoolName
    exportData.schoolGrade = _profile.profileExtended.schoolGrade

    if (_useCreatedOn) {
      exportData.createdOn = GeneralUtils.formatDate(_profile.createdOn)
    } else {
      delete exportData.createdOn
    }

    delete exportData.hasPin
    delete exportData.birthCountyId
    delete exportData.birthCountyLabel
    delete exportData.folderId
    delete exportData.fullName
    delete exportData.homeAddress
    delete exportData.id
    delete exportData.photoUuid
    delete exportData.profileExtended
    delete exportData.residenceAddress
    delete exportData.user
    delete exportData.profileExtended

    return exportData
  },

  legalRepSortOrderHeader() {
    let legalRepCol = this.profileSortOrderHeader()
    legalRepCol = legalRepCol.concat(["beneficiary", "startDate", "endDate", "status", "createdOn"])

    return legalRepCol
  },

  legalRepTableHeader() {
    let legalRepHeader = this.profileTableHeader()
    legalRepHeader = legalRepHeader.concat([translatedMessage("beneficiary.beneficiary"), translatedMessage("generic.startDate"),
    translatedMessage("generic.endDate"), translatedMessage("generic.status"), translatedMessage("generic.created.on")])

    return legalRepHeader
  },

  ampSortOrderHeader() {
    let legalRepCol = this.profileSortOrderHeader()
    legalRepCol = legalRepCol.concat(["householdResidents", "householdDetail", "ampCertificateExpiryDate", "createdOn"])

    return legalRepCol
  },

  ampTableHeader() {
    let legalRepHeader = this.profileTableHeader()
    legalRepHeader = legalRepHeader.concat([translatedMessage("amp.householdResidents"), translatedMessage("amp.householdDetail"),
    translatedMessage("amp.ampCertificateExpiryDate"), translatedMessage("generic.created.on")])

    return legalRepHeader
  },

  computeAmpExportFields(_amp) {
    let exportData = this.computeExportFields(_amp.profile);
    exportData.ampCertificateExpiryDate = GeneralUtils.formatDate(_amp.ampCertificateExpiryDate)
    exportData.householdDetail = DOMPurify.sanitize(_amp.householdDetail, { ALLOWED_TAGS: [] })
    exportData.householdResidents = _amp.householdResidents
    exportData.createdOn = GeneralUtils.formatDate(_amp.createdOn)

    return exportData
  },

  ampBeneficiarySortOrderHeader() {
    let legalRepCol = this.ampSortOrderHeader()
    legalRepCol = legalRepCol.concat(["beneficiary", "startDate", "endDate", "comment", "responsible", "ampBencreatedOn"])

    return legalRepCol
  },

  ampBeneficiaryTableHeader() {
    let legalRepHeader = this.ampTableHeader()
    legalRepHeader = legalRepHeader.concat([translatedMessage("beneficiary.beneficiary"), translatedMessage("generic.startDate"),
      translatedMessage("generic.endDate"), translatedMessage('amp.comments'), translatedMessage('beneficiary.responsible'), 
      translatedMessage("generic.created.on")])

    return legalRepHeader
  },

  faimlyPlacementSortOrderHeader() {
    let fpCol = this.profileSortOrderHeader()
    fpCol = fpCol.concat(["educationLevelLabel", "maritalStatus", "householdDetail", "createdOn"])

    return fpCol
  },

  faimlyPlacementTableHeader() {
    let fpHeader = this.profileTableHeader()
    fpHeader = fpHeader.concat([translatedMessage("profile.extended.educationLevel"), translatedMessage("profile.extended.maritalStatus"), 
      translatedMessage("familyPlacement.householdDetail"), translatedMessage("generic.created.on")])

    return fpHeader
  },

  computefaimlyPlacementExportFields(_familyPlacement) {
    let exportData = this.computeExportFields(_familyPlacement.profile);
    exportData.educationLevelLabel = _familyPlacement.profile?.profileExtended?.educationLevelLabel
    exportData.maritalStatus = _familyPlacement.profile?.profileExtended?.maritalStatus 
      ? translatedMessage("MaritalStatus." + _familyPlacement.profile?.profileExtended?.maritalStatus) 
      : "-"
    
    exportData.householdDetail = DOMPurify.sanitize(_familyPlacement.householdDetail, { ALLOWED_TAGS: [] })
    exportData.createdOn = GeneralUtils.formatDate(_familyPlacement.createdOn)


    return exportData
  },

  familyPlacementBeneficiarySortOrderHeader() {
    let fpbCol = this.faimlyPlacementSortOrderHeader()
    fpbCol = fpbCol.concat(["beneficiary", "startDate", "endDate", "comment", "fpBencreatedOn"])

    return fpbCol
  },

  familyPlacementBeneficiaryTableHeader() {
    let fpbHeader = this.faimlyPlacementTableHeader()
    fpbHeader = fpbHeader.concat([translatedMessage("beneficiary.beneficiary"), translatedMessage("generic.startDate"),
      translatedMessage("generic.endDate"), translatedMessage('familyPlacement.beneficiary.comments'), 
      translatedMessage("generic.created.on")])

    return fpbHeader
  },

  relatedSortOrderHeader() {
    let rCol = this.profileSortOrderHeader()
    rCol = rCol.concat(["healthStatusLabel", "disabilityDegreeLabel", "disabilityTypeLabel", "diseaseTypeLabel", "educationLevelLabel", 
      "employmentStatusLabel", "employerName", "beneficiary", "relationType", "createdOn"])

    return rCol
  },

  relatedTableHeader() {
    let rHeader = this.profileTableHeader()
    rHeader = rHeader.concat([translatedMessage("profile.extended.healthStatus"), translatedMessage("profile.extended.disabilityDegree"), 
      translatedMessage("profile.extended.disabilityType"), translatedMessage("profile.extended.diseaseType"), 
      translatedMessage("profile.extended.educationLevel"), translatedMessage("profile.extended.employmentStatus"), 
      translatedMessage("profile.extended.employerName"), translatedMessage("beneficiary.beneficiary"),
      translatedMessage("person.relationType"), translatedMessage("generic.created.on")])

    return rHeader
  },

  computeRelatedExportFields(_rp) {
    let exportData = this.computeExportFields(_rp.relatedProfile);
    exportData.healthStatusLabel = _rp.relatedProfile.profileExtended.healthStatusLabel
    exportData.disabilityDegreeLabel = _rp.relatedProfile.profileExtended.disabilityDegreeLabel
    exportData.disabilityTypeLabel = _rp.relatedProfile.profileExtended.disabilityTypeLabel
    exportData.diseaseTypeLabel = _rp.relatedProfile.profileExtended.diseaseTypeLabel    
    exportData.educationLevelLabel = _rp.relatedProfile.profileExtended.educationLevelLabel
    exportData.employmentStatusLabel = _rp.relatedProfile.profileExtended.employmentStatusLabel
    exportData.employerName = _rp.relatedProfile.profileExtended.employerName
    exportData.beneficiary = _rp.profile?.fullName
    exportData.relationType = _rp.relationType?.label    
    exportData.createdOn = GeneralUtils.formatDate(_rp.createdOn)

    return exportData
  },

};

export default ProfileExportDataUtils;