import GeneralUtils from "../utilities/GeneralUtils";
import ApiService from "./ApiService";
import OrganizationDataTableUtils from "../utilities/OrganizationDataTableUtils";

export class OrganizationProfileService {

    async getOrganizationProfilesByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/organizationProfile/list`, criteria)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getOrganizationProfile(id) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/organizationProfile/${id}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveOrganizationProfile(organizationProfile) {
        organizationProfile = GeneralUtils.nullIfEmptyForObjectFields(organizationProfile)
        organizationProfile.profile = { id: organizationProfile.profile.id }
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/organizationProfile`, organizationProfile)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteOrganizationProfile(id) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/organizationProfile/${id}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }

    async getOrganizationProfilesForSearchByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/organizationProfile/list/for-search`, criteria)
                .then((response) => {
                    let _profiles = []
                    response.data.forEach((item) => {
                        let _orgProfile = GeneralUtils.emptyInNullForObjectFields(item)
                        _orgProfile.displayName = OrganizationDataTableUtils.getOrganizationProfileDisplayName(_orgProfile)
                        _profiles.push(_orgProfile)
                    });
                    resolve(_profiles);                    
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    } 

}