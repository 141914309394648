import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import useNavProps from '../../hooks/useNavProps';
import { AmpService } from '../../service/AmpService';
import AmpToolbar from './AmpToolbar';
import ProfileViewComponent from '../../components/profile/ProfileViewComponent';
import { Button } from 'primereact/button';
import GeneralUtils from '../../utilities/GeneralUtils';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from '../../service/UserService';
import AmpEditDialog from './AmpEditDialog';

const AmpView = () => {
    const [amp, setAmp] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const toast = useRef(null);

    const ampService = useMemo(() => new AmpService(), []);

    const { ampIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        // const checkEditPermission = async () => {
        //     setHasEditPermission(await SecurityService.check('CAN_EDIT_', ampIdParam));
        // };        

        const getAmp = async () => {
            await ampService.getAmp(ampIdParam)
                .then(async (_data) => {
                    setAmp(_data)

                    // await checkEditPermission()

                    setNavPropsValue('amp', _data.id, _data.profile.fullName)

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getAmp();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ampService, ampIdParam]);

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <AmpToolbar amp={amp} code="DETAIL" />

                            <ProfileViewComponent entity={amp?.profile} entityName="AMP" detailTitle="person.details" />

                            <div className='w-full grid pcn-data-view-panel mb-5'>
                                <div className='col-12 flex align-items-center'>
                                    <span className='entity-view-subtitle mr-2'>{translatedMessage("amp.details")}</span>
                                    <Button
                                        text
                                        className="pcn-button-slim p-button p-button-icon-only"
                                        onClick={() => setShowEditDialog(true)}
                                        visible={hasPermission("AMP_EDIT")}
                                        disabled={!amp?.actions?.ADD_AMP}
                                    >
                                        <div className='flex align-items-center'>
                                            <FontAwesomeIcon icon='fa-solid fa-pencil' />
                                        </div>
                                    </Button>
                                </div>

                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('amp.householdResidents')}</div>
                                    <div className='filed-value'>{GeneralUtils.dashIfNullForObject(amp?.householdResidents)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('amp.ampCertificateExpiryDate')}</div>
                                    <div className='filed-value'>{GeneralUtils.formatDate(amp?.ampCertificateExpiryDate)}</div>
                                </div>
                                <div className='col-12'>
                                    <div className='filed-label'>{translatedMessage('amp.householdDetail')}</div>
                                    <div className='filed-value'>
                                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(amp.householdDetail) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AmpEditDialog
                    value={amp}
                    visible={showEditDialog}
                    afterSave={(savedData) => setAmp(savedData)}
                    visibleSetter={() => setShowEditDialog(false)}
                    disableProfile={true}
                />
            </>
        );
    }
};

export default AmpView;