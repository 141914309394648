import ApiService from "./ApiService";
import GeneralUtils from "../utilities/GeneralUtils";

export class BeneficiaryService {

    async getBeneficiaryList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/beneficiary/list', criteria)
                .then((response) => {
                    response.data.items.map((item) => {
                        item.registrationDate = item.registrationDate ? new Date(Date.parse(item.registrationDate)) : ''
                        item.profile = GeneralUtils.emptyInNullForObjectFields(item.profile)
                        item.profile.birthday = item.profile?.birthday ? new Date(Date.parse(item.profile.birthday)) : ''
                        item.profile.createdOn = item.profile?.createdOn ? new Date(Date.parse(item.profile.createdOn)) : ''
                        return item
                    });
                    resolve(response.data)
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data'
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                });
        });
    }

    async getBeneficiary(beneficiaryId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/beneficiary/${beneficiaryId}`)
                .then(response => {      
                    let _beneficiary = { ...response.data }
                    let _profile = _beneficiary.profile              
                    
                    _profile.birthday = _profile?.birthday ? new Date(Date.parse(_profile.birthday)) : '';                    
                    _profile.profileExtended.idCardExpiryDate = _profile?.profileExtended?.idCardExpiryDate ? new Date(Date.parse(_profile.profileExtended.idCardExpiryDate)) : '';                    
                    _beneficiary.profile = _profile
                    _beneficiary.registrationDate = _beneficiary.registrationDate ? new Date(Date.parse(_beneficiary.registrationDate)) : ''
                    
                    resolve(_beneficiary)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    

    async saveBeneficiary(beneficiary) {
        beneficiary.profile.birthCounty = GeneralUtils.computeEntityLookupObject(beneficiary.profile.birthCounty)
        let _profile = GeneralUtils.nullIfEmptyForObjectFields({ ...beneficiary.profile })
        let _beneficiary = {...beneficiary, profile: _profile}

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/beneficiary/save', _beneficiary)
                .then(response => {
                    response.data.profile.registrationData = response.data.registrationData
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }  
    
    async getAnalysisByBeneficiary(beneficiaryId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/analysis/${beneficiaryId}`)
                .then(response => {                          
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getAnalysisFileList(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().post('/analysis/file/list', criteria)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }    

    async saveBeneficiaryAddress(beneficiaryId, address) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/beneficiary/${beneficiaryId}/save-address`, address)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    } 
    
    async getBeneficiariesForSearchByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/beneficiary/list/for-search`, criteria)
                .then((response) => {
                    let _profiles = []
                    response.data.forEach((item) => {
                        let _beneficiary = GeneralUtils.emptyInNullForObjectFields(item)
                        let _profile = _beneficiary.profile
                        _profile.birthday = _profile?.birthday ? new Date(Date.parse(_profile.birthday)) : '';
                        _beneficiary.registrationDate = _beneficiary?.registrationDate ? new Date(Date.parse(_beneficiary.registrationDate)) : '';
                        _beneficiary.displayName = _profile.fullName + 
                            (_profile.pin ? " - " + _profile.pin : '') + 
                            (_beneficiary.registrationNumber ? " - " + _beneficiary.registrationNumber : '') + 
                            (_beneficiary.registrationDate ? " / " + GeneralUtils.formatDate(_beneficiary.registrationDate) : '')

                        _beneficiary = { ..._beneficiary, profile: _profile}

                        _profiles.push(_beneficiary)
                    });
                    resolve(_profiles);                    
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }    

    async getBeneficiaryDepartment(beneficiaryId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/beneficiaryDepartment/active/${beneficiaryId}`)
                .then(response => {                          
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getBeneficiaryResponsible(beneficiaryId, type) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/beneficiaryResponsible/active/${beneficiaryId}/type/${type}`)
                .then(response => {                          
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveBeneficiaryResponsible(beneficiaryId, organizationProfileId, type) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/beneficiary/${beneficiaryId}/save-responsible/${organizationProfileId}/type/${type}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveBeneficiaryDepartment(beneficiaryId, organizationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/beneficiary/${beneficiaryId}/transfer/${organizationId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getBeneficiaryResponsibleHistory(beneficiaryId, type) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance().get(`/beneficiary/${beneficiaryId}/responsible-history/${type}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    } 

    async getFilesForBeneficiary(beneficiaryId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/beneficiary/${beneficiaryId}/files`)
                .then(response => {
                    let fileList = response.data.map((item) => {
                        item.registrationDate = item.registrationDate ? new Date(Date.parse(item.registrationDate)) : '';
                        return item
                    });
                    resolve(fileList);
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
}