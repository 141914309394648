import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';

import GeneralUtils from '../../utilities/GeneralUtils';
import { ProfileService } from '../../service/ProfileService';
import ProfileAutocompleteFieldComponent from '../profile/ProfileAutocompleteFieldComponent';
import BeneficiaryAutocompleteFieldComponent from '../profile/BeneficiaryAutocompleteFieldComponent';

let emptyRecord = {
    id: null,
    profile: null,
    beneficiary: null,
    startDate: '',
    endDate: ''
};

const LegalRepEditComponent = (props) => {
    const [legalRep, setLegalRep] = useState()
    const [disableBeneficiary, setDisableBeneficiary] = useState(false);

    const profileService = new ProfileService();

    useEffect(() => {
        const computeProfile = async () => {
            let _legalRep = emptyRecord
            if (props?.value) {
                _legalRep = GeneralUtils.emptyInNullForObjectFields(props.value)
            }

            if (props?.beneficiary) {
                _legalRep.beneficiary = props.beneficiary
                setDisableBeneficiary(true)
            } else {
                if (props?.value?.beneficiary) {
                    _legalRep.beneficiary = props.value.beneficiary
                }
            }

            // setProfile(_profile)
            setLegalRep(_legalRep)
        }

        computeProfile()
    }, [props]);

    const formik = useFormik({
        initialValues: legalRep ? legalRep : emptyRecord,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.profile) {
                errors.profile = translatedMessage("form.error.profile.required");
            }

            if (!data.startDate) {
                errors.startDate = translatedMessage("form.error.startDate.required");
            }

            if (!data.beneficiary) {
                errors.beneficiary = translatedMessage("form.error.beneficiary.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            profileService.saveLegalRep(data)
                .then(savedData => {
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedData, true);
                    }
                })
                .catch(error => {
                    props.afterSave(null, false, translatedMessage(error));
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const handleProfileChange = async (_existingProfile) => {
        let _legalRep = { ...formik.values, profile: _existingProfile }

        setLegalRep(_legalRep)
    }

    const handleBeneficiaryChange = async (_beneficiary) => {
        setLegalRep({ ...formik.values, beneficiary: _beneficiary })
    }

    return (
        <>
            <div>
                <ProfileAutocompleteFieldComponent
                    profile={legalRep?.profile}
                    setExistingProfile={(e) => handleProfileChange(e)}
                    entityName="LEGAL_REPRESENTATIVE"
                    showAddProfile={true}
                    validationError={isFormFieldValid('profile')}
                />

                <div className="col-12">
                    <form id="legalRep-form" onSubmit={formik.handleSubmit}>
                        <div className='grid'>
                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="startDate" name="startDate" value={formik.values.startDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                            className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} />
                                        <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                            {translatedMessage("generic.startDate")} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('startDate')}
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar id="endDate" name="endDate" value={formik.values.endDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                            className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} />
                                        <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                            {translatedMessage("generic.endDate")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('endDate')}
                            </div>

                            <BeneficiaryAutocompleteFieldComponent
                                beneficiary={formik.values.beneficiary}
                                setBeneficiary={(e) => handleBeneficiaryChange(e)}
                                validationError={isFormFieldValid('beneficiary')}
                                disabled={disableBeneficiary}
                            />                            
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default LegalRepEditComponent;
