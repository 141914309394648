import React from "react";
import CenterListComponent from "../../components/center/CenterListComponent";

const CenterListPage = () => {

    return (
        <>
            <div className="grid h-full">
                <div className="col-12">
                    <div className="card h-full">
                        <CenterListComponent />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CenterListPage;
